import { FC, useRef } from 'react'
import { HexColorPicker } from 'react-colorful'
import { Box, SxProps } from '@mui/material'

import { useClickOutside } from '@pages/Parameters/hooks'

type ColorPickerProps = {
  open: boolean
  color?: string
  onChange: (value: string) => void
  onClickOutside: () => void
  sx?: SxProps
}

export const ColorPicker: FC<ColorPickerProps> = ({
  open,
  color,
  sx,
  onChange,
  onClickOutside,
}) => {
  const popover = useRef<HTMLDivElement>(null)

  useClickOutside(popover, onClickOutside)

  if (!open) {
    return null
  }

  return (
    <Box
      className='popover'
      ref={popover}
      position='absolute'
      top='-222px'
      zIndex={theme => theme.zIndex.tooltip}
      sx={sx}
    >
      <HexColorPicker onChange={onChange} color={color} />
    </Box>
  )
}
