import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  CONFIG_CONTROL_PANELS_COLUMNS,
  GENERATOR_INPUT_TYPE,
  ROUTES,
  SEARCH_PATTERN_DELAY,
  VISIBLE_HIDDEN,
} from '@constants'
import { getSortParamRequest } from '@helpers'
import { useDebounce } from '@hooks'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Button, Grid, Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import { CopyControlPanelPayload, GETControlPanel } from '@types'

import { EntityOrFormList } from '@components/EntityOrFormList'
import { PageTitle } from '@components/PageTitle'

import {
  useCopyControlPanelMutation,
  useDeleteControlPanelMutation,
  useFetchControlPanelListQuery,
} from '@redux/api'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { CopyDialog } from './components/CopyDialog'

export const ConfigControlPanels = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isAllControlPanels = matchPath(ROUTES.CONFIG_CONTROL_PANELS, pathname)

  const methods = useForm<{ searchPattern: string }>({ defaultValues: { searchPattern: '' } })

  const { watch } = methods

  const watchSearchPattern = watch('searchPattern')
  const debouncedSearchPattern = useDebounce(watchSearchPattern, SEARCH_PATTERN_DELAY)

  const [currentSort, setCurrentSort] = useState<GridSortModel>([])
  const [copyControlPanelData, setCopyControlPanelData] = useState<CopyControlPanelPayload | null>(
    null
  )
  const {
    data: panels,
    isLoading,
    isFetching,
  } = useFetchControlPanelListQuery(
    {
      sort: getSortParamRequest(currentSort) || undefined,
      searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
    },
    { refetchOnMountOrArgChange: true }
  )
  const loading = isLoading || isFetching

  const [copyControlPanel, { isLoading: isLoadingCopyControlPanel }] = useCopyControlPanelMutation()
  const [deleteControlPanel] = useDeleteControlPanelMutation()

  const handleCreate = () => navigate(ROUTES.CONFIG_CONTROL_PANELS_CREATE)

  const handleSetSorting = (value: GridSortModel | null) => {
    setCurrentSort(value ? value : [])
  }

  const handleEdit = (_code: string, id: string | number) =>
    navigate(`${ROUTES.CONFIG_CONTROL_PANELS}/edit/${id}`)

  const handleCopy = (panel: GETControlPanel) => {
    const { id, title } = panel
    setCopyControlPanelData({ panelId: id, title })
  }
  const handleCloseCopyDialog = () => setCopyControlPanelData(null)

  const handleCopyControlPanel = (data: CopyControlPanelPayload) => {
    copyControlPanel(data)
      .unwrap()
      .then(res => {
        setCopyControlPanelData(null)
        navigate(`${ROUTES.CONFIG_CONTROL_PANELS_EDIT}/${res.id}`)
      })
  }

  const handleDeleteControlPanel = (id: string | number) => {
    deleteControlPanel(id)
  }

  return (
    <>
      <Outlet />
      {copyControlPanelData && (
        <CopyDialog
          copyData={copyControlPanelData}
          dialogTitle={t('copying.controlPanel.dialogTitle')}
          loading={isLoadingCopyControlPanel}
          onClose={handleCloseCopyDialog}
          onCopy={handleCopyControlPanel}
        />
      )}
      <Box
        sx={{
          ...(!isAllControlPanels && VISIBLE_HIDDEN),
        }}
      >
        <PageTitle
          end={false}
          start={
            <>
              <Typography display={'inline'} sx={{ mr: 1, ml: 1 }} variant={'h6'}>
                {t('pageTitle.controlPanels')}
              </Typography>
            </>
          }
        />
        <PageContentLayout sx={{ p: 1 }}>
          <Grid container alignItems='center' flexWrap='nowrap' gap={1}>
            <Grid item>
              <Button sx={{ mb: 1, width: 190 }} variant={'contained'} onClick={handleCreate}>
                {t(`configControlPanels.add`)}
              </Button>
            </Grid>
            <Grid container item flexGrow={1} flexWrap='nowrap' spacing={2}>
              <FormProvider {...methods}>
                <Grid item flexGrow={1}>
                  <FormInput
                    inputType={GENERATOR_INPUT_TYPE.INPUT}
                    name='searchPattern'
                    placeholder={t('configViews.allTab.search')}
                    size='small'
                  />
                </Grid>
              </FormProvider>
            </Grid>
          </Grid>
          <EntityOrFormList
            columns={CONFIG_CONTROL_PANELS_COLUMNS}
            currentSort={currentSort}
            loading={loading}
            rows={panels || []}
            onCopy={handleCopy}
            onDelete={handleDeleteControlPanel}
            onEdit={handleEdit}
            onSetSorting={handleSetSorting}
          />
        </PageContentLayout>
      </Box>
    </>
  )
}
