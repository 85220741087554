import { SxProps, Theme } from '@mui/material'

// Хелпер для подсветки кнопок выбранных вариантов labelPosition и textAlign
// у элементов формы.
// Condition - условие, по которому устанавливается, выделять кнопку или нет
export const highlightActiveButton = (condition: boolean): SxProps<Theme> => {
  return {
    color: theme => (condition ? theme.palette.info.main : 'inherit'),
  }
}
