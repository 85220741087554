export const getDefaultValue = () => {
  return structuredClone({
    id: undefined,
    code: '',
    title: '',
    actionCode: null,
    eventCode: null,
    target: null,
    actionField: null,
  })
}
