import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AutocompleteOption } from 'src/types'
import { usePrompt } from '@hooks'

import { useFetchDefaultCriteriaQuery } from '@redux/api'

import { useHandlers } from './useHandlers'

export type FormDefaultCriterias = Record<string, AutocompleteOption<number>[]>

export type UseAddDefaultSettingsDialogParams = {
  searchAssistantId: string | number | undefined
}

export const useAddDefaultSettingsDialog = ({
  searchAssistantId,
}: UseAddDefaultSettingsDialogParams) => {
  const { data: objectsWithdefaultCriterias, isLoading: loadingObjects } =
    useFetchDefaultCriteriaQuery(
      { searchAssistantId: searchAssistantId ? searchAssistantId : '' },
      { refetchOnMountOrArgChange: true }
    )
  const [initialObjects, setInitialObjects] = useState<FormDefaultCriterias>({})
  const methods = useForm<FormDefaultCriterias>()
  const { handlers } = useHandlers()
  const {
    reset,
    formState: { isDirty },
  } = methods

  usePrompt({ when: isDirty })

  useEffect(() => {
    if (objectsWithdefaultCriterias) {
      // преобразуем в объект для формы {name: value}
      const initialObjects = Object.fromEntries(
        objectsWithdefaultCriterias.map(object => [
          object.objectCode,
          object.defaultSearchFilters.map(({ id, title }) => ({ id, label: title })),
        ])
      )

      reset(initialObjects)
      setInitialObjects(initialObjects)
    }
  }, [objectsWithdefaultCriterias])

  return {
    data: {
      objectsWithdefaultCriterias,
    },
    state: {
      initialObjects,
      methods,
      loadingObjects,
    },
    handlers: {
      ...handlers,
    },
  }
}
