import i18next from 'i18next'

import { GANTT_BIND_TYPE_LIST, GanttColumnForm } from '@gantt/components/GanttCreateOrEdit'

export const getGanttColumnDefaultValue = (): GanttColumnForm => {
  return {
    title: '',
    code: '',
    field: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: {
        field: '',
        pathStr: '',
        pathArray: [],
      },
    },
    value: i18next.t('placeholder.value'),
    objectValue: undefined,
    valueType: null,
    pinning: false,
    pinnedColumn: null,
    useParameters: false,
    visibility: true,
  }
}
