import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

import { getValueObjectByPath, TransformExpandsParams } from '@helpers'
import { EVENT_ACTION_FORM, EVENT_CODE, EVENT_CODE_GANTT, FORM_TYPE } from '@constants'
import { FieldObjectParams, Row, ViewActionObjectParams, ViewActionRow } from '@types'

type FindActionProps = {
  eventCode?: EVENT_CODE | EVENT_CODE_GANTT
  actions?: ViewActionRow[]
  type?: FORM_TYPE
}

export type GetVerifyFormCodeProps = {
  row?: Row
} & FindActionProps

export const getVerifyFormCode = ({ actions, eventCode, row, type }: GetVerifyFormCodeProps) => {
  let formCode: string | undefined

  if (eventCode) {
    const action = findActionByEventCode({ eventCode, actions })

    if (action) {
      formCode = action.objectFormCode || getFormCodeFromAction(action, row)
    }
  }

  if (type) {
    const action = findActionByEventCode({ type, actions })

    if (action) {
      formCode = action.objectFormCode || getFormCodeFromAction(action, row)
    }
  }

  return formCode
}

const findActionByEventCode = ({ eventCode, actions, type }: FindActionProps) => {
  if (eventCode) {
    return (actions as any[])?.find(action => action.eventCode === eventCode)
  }
  if (type) {
    return (actions as any[])?.find(
      action => action.eventCode.valueOf() === EVENT_ACTION_FORM[type].valueOf()
    )
  }
}

const getFormCodeFromAction = (action: ViewActionRow, row?: Row): string | undefined => {
  if (isObject(action.params)) {
    const { formCode, formCodeByFieldValue } = action.params as ViewActionObjectParams

    // Если указан массив formCodeByFieldValue, открываем нужную форму по условию - в зависимости от значения определённого поля
    if (formCodeByFieldValue && Array.isArray(formCodeByFieldValue) && row) {
      const found = formCodeByFieldValue.find((item: { field: string; value: string }) => {
        return row[item.field] === item.value
      })

      if (found && isString(found.formCode)) {
        return found.formCode
      }
    }

    return formCode as string
  }

  return undefined
}

export const getFormCodeFromExpandsParams = (
  action: TransformExpandsParams[],
  row?: Row
): string | undefined => {
  if (action.length && row) {
    const found = action.find(({ value, expandField }) => {
      const foundValue = getValueObjectByPath(row, expandField)

      return foundValue === value
    })

    if (found && isString(found.formCode)) {
      return found.formCode
    }
  }

  return undefined
}

export const getFormCodeFromParams = (params: FieldObjectParams, row?: Row): string | undefined => {
  const { formCode = undefined, formCodeByFieldValue } = params
  // Если указан массив formCodeByFieldValue, открываем нужную форму по условию - в зависимости от значения определённого поля
  if (formCodeByFieldValue && Array.isArray(formCodeByFieldValue) && row) {
    const found = formCodeByFieldValue.find((item: { field: string; value: string }) => {
      return row[item.field] === item.value
    })
    if (found && isString(found.formCode)) {
      return found.formCode
    }
  }

  return formCode
}
