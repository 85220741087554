import { Theme } from '@mui/material/styles'

export const buttonStylesByMode = (theme: Theme, isDialogWindow?: boolean) => {
  const height = isDialogWindow ? '17px' : '32px'

  return {
    height,
    padding: '2px 6px',
    background: theme.palette.background.button,
    boxShadow: `${theme.palette.boxShadow.button}, ${theme.palette.boxShadow.paper}`,
    color: `${theme.palette.color.default} !important`,
    fontWeight: 700,
    borderRadius: 0,
    ':hover': {
      background: theme.palette.background.buttonHovered,
      boxShadow: theme.palette.boxShadow.buttonHovered,
    },
    ':disabled': {
      color: `${theme.palette.color.buttonDisabled} !important`,
      boxShadow: theme.palette.boxShadow.buttonDisabled,
    },
  }
}
