export const getSortKeysOfColumnsForQuickSearch = (sortKeyOfColumns: Record<string, boolean>) => {
  if (!sortKeyOfColumns) {
    return []
  }
  const sortKeys: string[] = []

  Object.keys(sortKeyOfColumns).forEach(key => {
    if (sortKeyOfColumns[key]) {
      sortKeys.push(key)
    }
  })

  return sortKeys
}
