import { AutocompleteOption, GETCriteriaValueType } from 'src/types'
import {
  FIELD_VALUE_FORMAT,
  FIELD_VALUE_TYPE,
  SEARCH_FIELD_TYPE,
  ValueTypeForRequest,
} from '@constants'
import {
  getSecondsArrayForSearchAssistantFilter,
  getSecondsFromTime,
  isObjectValueType,
} from '@helpers'
import { DateRangeValue } from '@microservices/wiskey-react-components'

import { RelativeType } from '../components/RelativeDatePicker'

export const getRelativeDateWithEmptyDate = (
  storedValue: GETCriteriaValueType
): ValueTypeForRequest => {
  if (storedValue.valueType === FIELD_VALUE_TYPE.DATETIME) {
    if (
      storedValue.fieldType === SEARCH_FIELD_TYPE.RELATIVE_VALUE &&
      Array.isArray(storedValue.value) &&
      !storedValue.value[0]
    ) {
      return [[new Date().toISOString(), ...storedValue.value.slice(1)]] as RelativeType[]
    }

    if (storedValue.fieldType === SEARCH_FIELD_TYPE.EXACT_VALUE) {
      return [storedValue.value as string]
    }

    return storedValue.value as string | [DateRangeValue, DateRangeValue]
  }

  if (isObjectValueType(storedValue.valueType) && Array.isArray(storedValue.value)) {
    return (storedValue.value as AutocompleteOption[]).map(obj => obj.id)
  }

  if (storedValue.isGroupRelated && Array.isArray(storedValue.value)) {
    const valueWithOptions = storedValue.value as AutocompleteOption[]
    return valueWithOptions.map(value => value.id)
  }

  if (storedValue.valueFormat === FIELD_VALUE_FORMAT.TIME && storedValue.value) {
    return getSecondsArrayForSearchAssistantFilter(
      getSecondsFromTime(storedValue.value as string),
      storedValue.searchRule
    )
  }

  return [storedValue.value] as ValueTypeForRequest
}
