import { FC } from 'react'
import { t } from 'i18next'
import { FORM_ELEMENT_TEXT_ALIGN } from '@constants'
import { TextField } from '@microservices/wiskey-react-components'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  FormatAlignCenter as AlignCenterIcon,
  FormatAlignLeft as AlignLeftIcon,
  FormatAlignRight as AlignRightIcon,
} from '@mui/icons-material'
import { Box, IconButton, Popover } from '@mui/material'

import { highlightActiveButton } from './helpers'

type TextPopoverProps = {
  isPopoverOpen: boolean
  popoverAnchorEl: HTMLElement | null
  handlePopoverClose: () => void
  textTitle: string
  textAlign?: FORM_ELEMENT_TEXT_ALIGN
  staticTextTitle?: string
  handleSaveText: () => void
  handleCancelPoppedEdit: () => void
  handleSetText: (value: string) => void
  handleSetTextAlign?: (value: FORM_ELEMENT_TEXT_ALIGN) => void
  displayAlignmentSettings?: boolean
}

export const TextPopover: FC<TextPopoverProps> = ({
  isPopoverOpen,
  popoverAnchorEl,
  handlePopoverClose,
  textTitle,
  textAlign,
  staticTextTitle,
  handleSaveText,
  handleCancelPoppedEdit,
  handleSetText,
  handleSetTextAlign,
  displayAlignmentSettings = false,
}) => {
  const handleCancel = () => {
    handleCancelPoppedEdit()
    handleSetText(staticTextTitle ?? '')
  }

  const handleEditAlignment = (newValue: FORM_ELEMENT_TEXT_ALIGN) => {
    handleSetTextAlign?.(newValue)
  }

  return (
    <Popover
      id={'edit-item-popover'}
      open={isPopoverOpen}
      anchorEl={popoverAnchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Box sx={{ m: 1 }}>
        <Box>
          <TextField
            value={textTitle}
            onChange={event => handleSetText(event.target.value)}
            placeholder={t('fieldsConfig.placeholder.text')}
            size={'small'}
            variant={'standard'}
            sx={{ ml: 1.2 }}
            autoFocus
          />
          <IconButton onClick={handleSaveText}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={handleCancel}>
            <ClearIcon />
          </IconButton>
        </Box>
        {displayAlignmentSettings && (
          <Box>
            <IconButton
              onClick={() => handleEditAlignment(FORM_ELEMENT_TEXT_ALIGN.LEFT)}
              sx={{ ...highlightActiveButton(textAlign === FORM_ELEMENT_TEXT_ALIGN.LEFT) }}
            >
              <AlignLeftIcon />
            </IconButton>
            <IconButton
              onClick={() => handleEditAlignment(FORM_ELEMENT_TEXT_ALIGN.CENTER)}
              sx={{ ...highlightActiveButton(textAlign === FORM_ELEMENT_TEXT_ALIGN.CENTER) }}
            >
              <AlignCenterIcon />
            </IconButton>
            <IconButton
              onClick={() => handleEditAlignment(FORM_ELEMENT_TEXT_ALIGN.RIGHT)}
              sx={{ ...highlightActiveButton(textAlign === FORM_ELEMENT_TEXT_ALIGN.RIGHT) }}
            >
              <AlignRightIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Popover>
  )
}
