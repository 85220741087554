import { FC, useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { THEME_MODE, WIDTH_DEFAULT_CRITERIAS_MODAL } from '@constants'
import { ModalWrapper } from '@microservices/wiskey-react-components'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'

import { SearchAssistantConfigContext } from '@pages/ContextCreateOrEdit/components/SearchAssistant/SearchAssistant'

import { getFalseDefaultCriteriasByForm } from './helpers/getFalseDefaultCriteriasByForm'
import { getTransformDefaultCriteriasByForm } from './helpers/getTransformDefaultCriteriasByForm'
import { FormDefaultCriterias, useAddDefaultSettingsDialog } from './hooks'
import { ObjectInput } from './ObjectInput'

const Title: FC<{ text: string }> = ({ text }) => (
  <Typography
    sx={{
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      color: theme =>
        theme.palette.mode === THEME_MODE.LIGHT
          ? theme.palette.grey[600]
          : theme.palette.text.primary,
    }}
  >
    {text}
  </Typography>
)

export const AddDefaultSettingsDialog: FC = () => {
  const { t } = useTranslation()

  const { showDefaultSetting, closeDefaultSettings, isLoadingRow, searchAssistantId } = useContext(
    SearchAssistantConfigContext
  )

  const { data, state, handlers } = useAddDefaultSettingsDialog({ searchAssistantId })
  const { objectsWithdefaultCriterias } = data
  const { handleAddDefaultCriterias } = handlers
  const { initialObjects, methods, loadingObjects } = state

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods

  const handleSaveDefaultCriterias = (data: FormDefaultCriterias) => {
    // конвертируем объект из формы в массив критерий
    const transformNewDefaultCriterias = getTransformDefaultCriteriasByForm(data)
    // из начальных объектов с критериями получаем массив критерий
    const initialDefaultCriterias = Object.values(initialObjects).flat()
    // на основе начальных и новых критерий получаем измененные (те, которые стали false (т.к. их нет в форме))
    const falsedDefaultCriteras = getFalseDefaultCriteriasByForm(
      initialDefaultCriterias,
      transformNewDefaultCriterias
    )

    handleAddDefaultCriterias([...transformNewDefaultCriterias, ...falsedDefaultCriteras])
  }

  return (
    <ModalWrapper
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={showDefaultSetting}
      loading={isLoadingRow}
      title={t(`searchAssistant.defaultSetting.title`)}
      width={WIDTH_DEFAULT_CRITERIAS_MODAL}
      onClose={() => closeDefaultSettings(isDirty)}
      onSave={handleSubmit(handleSaveDefaultCriterias)}
    >
      <Box pb={0.5} sx={{ maxHeight: 500, overflowY: 'scroll', pr: 1, position: 'relative' }}>
        <Grid container item mb={1} mt={2}>
          <Grid item flex={3}>
            <Title text={t(`searchAssistant.defaultSetting.objectsLabel`)} />
          </Grid>
          <Grid item flex={5}>
            <Title text={t(`searchAssistant.defaultSetting.autocompleteLabel`)} />
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          {loadingObjects && initialObjects ? (
            <Grid container alignItems={'center'} justifyContent={'center'} my={3}>
              <CircularProgress />
            </Grid>
          ) : (
            objectsWithdefaultCriterias?.map(object => (
              <ObjectInput key={object.objectCode} object={object} />
            ))
          )}
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
