import { SelectOption } from '@types'

import { GanttActionType } from '@gantt/components/GanttCreateOrEdit/types'
import { isCellTarget, isSegmentTarget } from '@gantt/helpers/helpers'

type AvailableEventsProps = {
  actionsList: GanttActionType[]
  actionEventsList: SelectOption[]
  currentRow?: GanttActionType | null
}

type AvailableColumnTargetsProps = {
  columnTargets: SelectOption[]
  actionsList: GanttActionType[]
  currentRow?: GanttActionType | null
}

type AvailableSegmentTargetsProps = {
  segmentTargets: SelectOption[]
  actionsList: GanttActionType[]
  currentRow?: GanttActionType | null
  currentEventCode?: string
}

export const getAvailableYEvents = ({
  actionsList,
  actionEventsList,
  currentRow,
}: AvailableEventsProps) => {
  if (!actionEventsList) {
    return []
  }

  const filteredActionEventsList = actionEventsList.filter(
    item =>
      !actionsList.find(event => event.eventCode === item.name && !isCellTarget(event.eventCode))
  )

  if (currentRow) {
    const currentAction = actionEventsList.find(item => item.name === currentRow.eventCode)
    if (currentAction) {
      filteredActionEventsList.push(currentAction)
    }
  }

  return filteredActionEventsList
}

export const getAvailableColumnTargets = ({
  columnTargets,
  actionsList,
  currentRow,
}: AvailableColumnTargetsProps) => {
  if (!columnTargets) {
    return []
  }

  const filteredColumnTargets = columnTargets.filter(
    item => !actionsList.find(event => event.target === item.id && isCellTarget(event.eventCode))
  )

  if (currentRow) {
    const currentTarget = columnTargets.find(item => item.id === currentRow.target) //todo ??
    if (currentTarget) {
      filteredColumnTargets.push(currentTarget)
    }
  }

  return filteredColumnTargets
}

export const getAvailableXEvents = ({
  actionsList,
  actionEventsList,
  currentRow,
}: AvailableEventsProps) => {
  if (!actionEventsList) {
    return []
  }

  const filteredActionEventsList = actionEventsList.filter(
    item =>
      !actionsList.find(event => event.eventCode === item.name && !isSegmentTarget(event.eventCode))
  )

  if (currentRow) {
    const currentAction = actionEventsList.find(item => item.name === currentRow.eventCode)
    if (currentAction) {
      filteredActionEventsList.push(currentAction)
    }
  }

  return filteredActionEventsList
}

export const getAvailableSegmentTarget = ({
  segmentTargets,
  actionsList,
  currentRow,
  currentEventCode,
}: AvailableSegmentTargetsProps) => {
  if (!segmentTargets) {
    return []
  }

  const filteredSegmentTargets = segmentTargets.filter(
    item =>
      !actionsList.find(
        event =>
          event.target === item.name &&
          event.eventCode === currentEventCode &&
          isSegmentTarget(event.eventCode)
      )
  )

  if (currentRow) {
    const currentTarget = segmentTargets.find(item => item.name === currentRow.target)
    if (currentTarget) {
      filteredSegmentTargets.push(currentTarget)
    }
  }

  return filteredSegmentTargets
}
