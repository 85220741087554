import { createContext, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ENTITY, VISIBLE_HIDDEN } from '@constants'
import { Box, Tab, Tabs, Typography } from '@mui/material'

import { CopyDialog } from '@components/CopyDialog'
import { PageTitle } from '@components/PageTitle'
import { TabPanel } from '@components/Tabs'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { AllEntities } from './components/AllEntities'
import { EntityGroupList } from './components/EntityGroupList'
import { useConfigEntities } from './hooks'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

type ConfigEntityProps = {
  type: ENTITY
}

export const ConfigEntity: FC<ConfigEntityProps> = ({ type }) => {
  const { t } = useTranslation()

  const { state, data, handlers } = useConfigEntities({ type })

  const {
    currentSort,
    allEntities,
    currentTab,
    copyViewData,
    showCopyDialog,
    isLoadingCopyEntity,
    isAllEntities,
  } = state

  const { isFetchingViews, objects } = data

  const {
    handleDeleteEntity,
    handleDeleteEntityByCode,
    handleCopyEntity,
    handleEditEntity,
    handleSetSorting,
    handleSetObjectCode,
    handleSetSearchPattern,
    handleSetAllEntities,
    handleTabChange,
    handleSetDirty,
    handleCloseDialog,
    handleShowDialog,
  } = handlers

  const contextValue = {
    entitiesTables: allEntities,
    viewsIsLoading: isFetchingViews,
    currentSort,
    objects,
    type,
    onDeleteEntity: handleDeleteEntity,
    onDeleteEntityByCode: handleDeleteEntityByCode,
    onCopyEntity: handleCopyEntity,
    onEditEntity: handleEditEntity,
    onSetSorting: handleSetSorting,
    onSetObjectCode: handleSetObjectCode,
    onSetSearchPattern: handleSetSearchPattern,
    onShowDialog: handleShowDialog,
  }

  return (
    <PageContext.Provider value={contextValue}>
      {/*EntityCreateOrEdit*/}
      {showCopyDialog && copyViewData && (
        <CopyDialog
          copyData={copyViewData}
          dialogTitle={t(`copying.${type}.dialogTitle`)}
          isShow={showCopyDialog}
          loading={isLoadingCopyEntity}
          models={allEntities.map(entity => entity.code)}
          onClose={handleCloseDialog}
          onCopy={handleCopyEntity}
        />
      )}
      <Outlet context={{ setEntityTables: handleSetAllEntities }} />
      <Box
        key={type}
        sx={{
          ...(!isAllEntities && VISIBLE_HIDDEN),
        }}
      >
        <PageTitle
          end={false}
          start={
            <>
              <Typography display={'inline'} sx={{ mr: 1, ml: 1 }} variant={'h6'}>
                {t('pageTitle.models')}
              </Typography>
              <Typography display={'inline'} variant={'subtitle2'}>
                {t('pageTitle.modelsControl')}
              </Typography>
            </>
          }
        />
        <PageContentLayout key={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={(_e, value) => handleTabChange(value)}>
              <Tab label={t(`configEntity.${type}.tabs.all`)} />
              {type !== ENTITY.DROP_DOWN_ENTITY ? (
                <Tab label={t(`configEntity.${type}.tabs.groups`)} />
              ) : (
                <></>
              )}
            </Tabs>
          </Box>
          <TabPanel
            isAlwaysRendered
            hidden={false}
            index={0}
            sx={{ ...(currentTab !== 0 && VISIBLE_HIDDEN) }} // Пришлось кастомизировать стили, т.к display none немного ломает отображение DataGrid
            value={currentTab}
          >
            <AllEntities />
          </TabPanel>
          {type !== ENTITY.DROP_DOWN_ENTITY ? (
            <TabPanel
              hidden={false}
              index={1}
              sx={{ ...(currentTab !== 1 && VISIBLE_HIDDEN) }}
              value={currentTab}
            >
              <EntityGroupList onSetDirty={handleSetDirty} />
            </TabPanel>
          ) : (
            <></>
          )}
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}
