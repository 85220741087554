type UseDebounceFnProps = {
  fn: (...args: any[]) => void
  delay?: number
}

type ReturnValue = (...args: any[]) => void

const useDebounceFn = ({ fn, delay }: UseDebounceFnProps): ReturnValue => {
  let timer: number | undefined

  const clearTimer = () => {
    if (timer) {
      clearTimeout(timer)
      timer = undefined
    }
  }

  return (...args: unknown[]) => {
    clearTimer()
    timer = window.setTimeout(() => fn(...args), delay)
  }
}

export { useDebounceFn }
