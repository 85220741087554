// Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
export function getDirtyValues(
  dirtyFields: Record<string, unknown> | boolean,
  allValues: Record<string, unknown>
): Record<string, unknown> {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues
  }
  // Here, we have an object

  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(dirtyFields).map(key => [key, getDirtyValues(dirtyFields[key], allValues[key])])
  )
}
