import cloneDeep from 'lodash/cloneDeep'
import { TreeData } from '@atlaskit/tree'

export const transformExpandedTreeData = (tree: TreeData) => {
  const cloneTree = cloneDeep(tree)

  Object.keys(cloneTree.items).forEach(key => {
    cloneTree.items[key] = { ...cloneTree.items[key], isExpanded: false }
  })

  return cloneTree
}
