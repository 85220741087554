import moment from 'moment'
import { executeCode } from '@helpers'
import { MenuPointOptionType } from '@types'

import { MetaData } from '..'

export const getJsTitleItem = ({
  data,
  title,
  jsTitle,
  titleJsValue,
}: Pick<MenuPointOptionType, 'title' | 'jsTitle' | 'titleJsValue'> & { data?: MetaData }) => {
  if (jsTitle && titleJsValue) {
    return executeCode(titleJsValue, {
      srcObj: data?.srcObj || {},
      moment,
    }) as string
  }

  return title
}
