import {
  ComponentClass,
  CSSProperties,
  FC,
  MouseEventHandler,
  ReactNode,
  useContext,
  useState,
} from 'react'
import { SortableElement, SortableElementProps, SortableHandle } from 'react-sortable-hoc'
import { t } from 'i18next'
import { FormContainer, FormTab } from 'src/types'
import { getTabById } from '@helpers'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material'
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material'

import {
  useCreateContainerTabMutation,
  useUpdateContainerTabMutation,
} from '@redux/api/container.api'

import { ElementContextMenu } from '../ActionMenu/ElementContextMenu'
import { TextPopover } from '../ActionMenu/TextPopover'
import { useActionMenu } from '../ActionMenu/useActionMenu'
import { FieldsConfigContext } from '../FieldsConfig'

type TabButtonProps = {
  tab: FormTab
  index: number
  containerId: number
  currentTabButton: number
  onDelete: (tabId: number) => void
  handleSetCurrentTabButton: (value: number) => void
}

export const TabButton: FC<TabButtonProps> = ({
  tab,
  index,
  containerId,
  currentTabButton,
  onDelete,
  handleSetCurrentTabButton,
}) => {
  const { containers, setContainers } = useContext(FieldsConfigContext)

  const [createTab] = useCreateContainerTabMutation()
  const [updateTab] = useUpdateContainerTabMutation()
  const creationInProgress = tab.id === -1
  const [editingInProgress, setContainerEditing] = useState(false)
  const [newTabTitle, setNewTabTitle] = useState(tab.title)

  const handleDeleteButtonClick: MouseEventHandler<SVGSVGElement> = event => {
    event.stopPropagation()

    onDelete(tab.id)
  }

  const handleTabButtonClick = () => {
    handleSetCurrentTabButton(index)
  }

  const handleCancelTabCreationOrEdit = () => {
    if (creationInProgress) {
      const newContainers = structuredClone(containers) as FormContainer[]
      const { container: newContainer } = getTabById(newContainers, containerId, tab.id)

      if (!newContainer) {
        return
      }

      newContainer.tabs.pop()

      setContainers(newContainers)
    }

    setContainerEditing(false)
  }

  const handleSetNewTabTitle = (value: string) => setNewTabTitle(value)

  const handleSetTextElementEditing = (value: boolean) => setContainerEditing(value)

  const {
    popoverAnchorEl,
    menuPosition,
    isPopoverOpen,
    handleOpenPopover,
    handlePopoverClose,
    handleCancelPoppedEdit,
    handleElementContextClick: handleTabClick,
    handleCloseMenu: handleTabMenuClose,
  } = useActionMenu({ handleSetEditing: handleSetTextElementEditing })

  const handleSaveButtonClick = () => {
    if (!newTabTitle) {
      return
    }

    if (editingInProgress) {
      handlePopoverClose()
      handleTabMenuClose()
    }

    const newContainers = structuredClone(containers) as FormContainer[]
    const { tab: newTab, container: newContainer } = getTabById(newContainers, containerId, tab.id)

    if (!newTab || !newContainer) {
      return
    }

    const { id, lines, ...body } = { ...newTab, title: newTabTitle }

    newContainer.tabs[index] = { ...newTab, title: newTabTitle }
    setContainers(newContainers)

    if (creationInProgress) {
      createTab({ ...body, containerId: containerId })
    }

    if (editingInProgress) {
      updateTab({ ...body, id, containerId: containerId })
    }
  }

  const CreationInput = () => (
    <>
      <TextField
        value={newTabTitle}
        onChange={event => handleSetNewTabTitle(event.target.value)}
        placeholder={t('fieldsConfig.placeholder.title')}
        size={'small'}
        variant={'standard'}
        autoFocus
        sx={{ ml: 0.5, width: 100 }}
      />
      <IconButton onClick={handleSaveButtonClick}>
        <CheckIcon />
      </IconButton>
      <IconButton onClick={handleCancelTabCreationOrEdit}>
        <ClearIcon />
      </IconButton>
    </>
  )

  const DragHandle = SortableHandle(({ style }: { style: CSSProperties }) => (
    <Box display={'flex'}>
      <DragIndicatorIcon sx={{ ...style, fontSize: '1.25rem', cursor: 'grab' }} />
    </Box>
  ))

  const TabButtonElement: ComponentClass<SortableElementProps & { children: ReactNode }> =
    SortableElement(({ children }: { children: ReactNode }) => (
      <Grid item xs={3} p={0.5}>
        {children}
      </Grid>
    ))

  return (
    <>
      <TextPopover
        isPopoverOpen={isPopoverOpen}
        popoverAnchorEl={popoverAnchorEl}
        handlePopoverClose={handlePopoverClose}
        textTitle={newTabTitle}
        staticTextTitle={tab.title}
        handleSaveText={handleSaveButtonClick}
        handleCancelPoppedEdit={handleCancelPoppedEdit}
        handleSetText={handleSetNewTabTitle}
      />
      <ElementContextMenu
        menuPosition={menuPosition}
        handleFieldMenuClose={handleTabMenuClose}
        handleOpenEdit={handleOpenPopover}
        displayDeleteOption={false}
        displayFieldParamsOption={false}
      />
      {creationInProgress ? (
        <CreationInput />
      ) : (
        <TabButtonElement index={index}>
          <Button
            variant={currentTabButton === index ? 'contained' : 'outlined'}
            sx={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              justifyContent: 'space-between',
            }}
            fullWidth
            onClick={handleTabButtonClick}
            onContextMenu={handleTabClick}
            startIcon={<DragHandle />}
            component={'div'}
            endIcon={<ClearIcon onClick={handleDeleteButtonClick} />}
          >
            <Typography title={tab.title} sx={{ textTransform: 'none' }} noWrap>
              {tab.title}
            </Typography>
          </Button>
        </TabButtonElement>
      )}
    </>
  )
}
