import { useRef, useState } from 'react'
import { UseFormHandleSubmit, UseFormReset, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'
import { ParamCode, ParameterPropertiesByParamCode, ParameterPropertyType } from 'src/types'

import {
  ExternalTextSettings,
  GetTextParamsHandle,
  TextSettingsStateType,
} from '@components/TextSettings'
import { TextParams } from '@components/TextSettings'

import {
  useFetchParameterPropertiesQuery,
  useUpdateParameterPropertyMutation,
} from '@redux/api/parameters.api'

import { usePrompt } from '@hooks'
import {
  COMMON_PARAMETERS,
  DEFAULT_COMMON_PARAMETERS,
  DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS,
  DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS,
  DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS,
  DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS,
  GLOBAL_PARAMETER_CODE,
} from '@constants'

import { getCommonPropertiesForUpdate } from '../helpers'
import { getGlobalPropertiesForUpdate } from '../helpers/getGlobalPropertiesForUpdate'

import { CommonParametersFormType } from './useParameters'

type UseHandlersProps = {
  onSubmitCommonForm: UseFormHandleSubmit<CommonParametersFormType>
  reset: UseFormReset<CommonParametersFormType>
  isDirtyCommonParameters: boolean
  commonParameters: ParameterPropertyType[] | undefined
  initialGlobalColumnTextStyles: TextSettingsStateType | null
  initialGlobalColumnHeaderStyles: TextSettingsStateType | null
  onSetValueCommonParameters: UseFormSetValue<CommonParametersFormType>
}

export const useHandlers = ({
  onSubmitCommonForm,
  reset,
  isDirtyCommonParameters,
  commonParameters,
  initialGlobalColumnHeaderStyles,
  initialGlobalColumnTextStyles,
  onSetValueCommonParameters,
}: UseHandlersProps) => {
  const { t } = useTranslation()

  const columnHeaderSettingsRef = useRef<GetTextParamsHandle>(null)
  const columnTextSettingsRef = useRef<GetTextParamsHandle>(null)

  const [isEdit, setEdit] = useState(false)
  const [isShowResetConfirmModal, setShowResetConfirmModal] = useState(false)

  const [isDirtyHeader, setDirtyHeader] = useState(false)
  const [isDirtyText, setDirtyText] = useState(false)

  const isDirtyVisualParameters = isDirtyHeader || isDirtyText

  const { data: globalColumnHeaderStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_HEADER_STYLES,
  })

  const { data: globalColumnTextStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_TEXT_STYLES,
  })

  const [updateParameterProperty] = useUpdateParameterPropertyMutation()

  usePrompt({ when: isDirtyCommonParameters || isDirtyVisualParameters })

  const handleSetValuesColumnStyles = (
    columnHeaderStyles: TextSettingsStateType,
    columnTextStyles: TextSettingsStateType
  ) => {
    columnHeaderSettingsRef.current?.setValues(columnHeaderStyles)
    columnTextSettingsRef.current?.setValues(columnTextStyles)
  }

  const handleCancelEdit = () => {
    if (
      (isDirtyCommonParameters || isDirtyVisualParameters) &&
      !confirm(t('notifications.leave'))
    ) {
      return
    }
    if (initialGlobalColumnHeaderStyles && initialGlobalColumnTextStyles) {
      handleSetValuesColumnStyles(initialGlobalColumnHeaderStyles, initialGlobalColumnTextStyles)
    }

    reset()
    setEdit(false)
  }

  const handleSetShowResetConfirmModal = (value: boolean) => {
    setShowResetConfirmModal(value)
  }

  const handleResetCommonParams = () => {
    onSetValueCommonParameters('numberOfWindows', DEFAULT_COMMON_PARAMETERS.numberOfWindows, {
      shouldDirty: true,
    })
    onSetValueCommonParameters('timezone', DEFAULT_COMMON_PARAMETERS.timezone, {
      shouldDirty: true,
    })
    onSetValueCommonParameters('pagination', DEFAULT_COMMON_PARAMETERS.pagination, {
      shouldDirty: true,
    })
  }

  const handleSubmitParameters = (
    columnHeaderData: TextParams | undefined,
    columnTextData: TextParams | undefined
  ) => {
    onSubmitCommonForm((commonFormData: CommonParametersFormType) => {
      if (
        !columnHeaderData ||
        !columnTextData ||
        !globalColumnHeaderStyles ||
        !globalColumnTextStyles ||
        !commonParameters
      ) {
        return
      }

      const propertiesForUpdate: ParameterPropertiesByParamCode = {
        [COMMON_PARAMETERS.STATE]: [
          ...getCommonPropertiesForUpdate(
            commonParameters,
            commonFormData,
            COMMON_PARAMETERS.STATE
          ),
        ],
        [GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_HEADER_STYLES]: [
          ...getGlobalPropertiesForUpdate(
            columnHeaderData,
            globalColumnHeaderStyles,
            GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_HEADER_STYLES
          ),
        ],
        [GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_TEXT_STYLES]: [
          ...getGlobalPropertiesForUpdate(
            columnTextData,
            globalColumnTextStyles,
            GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_TEXT_STYLES
          ),
        ],
      }

      Object.keys(propertiesForUpdate).forEach((key: ParamCode) => {
        if (propertiesForUpdate[key].length) {
          updateParameterProperty({
            parameters: propertiesForUpdate[key] as ParameterPropertyType[],
            paramCode: key,
          })
        }
      })
      setDirtyHeader(false)
      setDirtyText(false)
    })()
  }

  const handleEditOrSave = () => {
    if (!isEdit) {
      setEdit(true)

      return
    }

    const columnHeaderData = columnHeaderSettingsRef.current?.getTextParams()
    const columnTextData = columnTextSettingsRef.current?.getTextParams()

    handleSubmitParameters(columnHeaderData, columnTextData)
    setEdit(false)
  }

  const handleResetParams = () => {
    handleSetValuesColumnStyles(
      DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS,
      DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS
    )

    handleSetShowResetConfirmModal(false)
  }

  const handleClickResetVisualParameters = () => {
    const columnHeaderData = columnHeaderSettingsRef.current?.getTextParams()
    const columnTextData = columnTextSettingsRef.current?.getTextParams()

    if (
      isEqual(columnHeaderData, DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS) &&
      isEqual(columnTextData, DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS)
    ) {
      return
    }

    setShowResetConfirmModal(true)
  }

  const handleHeaderExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialGlobalColumnHeaderStyles) {
      const isDirty = !isEqual(object, initialGlobalColumnHeaderStyles)

      setDirtyHeader(isDirty)
    }
  }

  const handleTextExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialGlobalColumnTextStyles) {
      const isDirty = !isEqual(object, initialGlobalColumnTextStyles)

      setDirtyText(isDirty)
    }
  }

  return {
    data: { columnHeaderSettingsRef, columnTextSettingsRef },
    state: {
      isEdit,
      isShowResetConfirmModal,
      isDirtyVisualParameters,
    },
    handlers: {
      handleEditOrSave,
      handleCancelEdit,
      handleSubmitParameters,
      handleSetShowResetConfirmModal,
      handleResetParams,
      handleResetCommonParams,
      handleClickResetVisualParameters,
      handleHeaderExternalDirty,
      handleTextExternalDirty,
    },
  }
}
