import { FC, useContext } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { FORM_DROPPABLE_TYPE } from '@constants'
import { Backdrop, Box, CircularProgress, Grid, LinearProgress } from '@mui/material'
import { alpha } from '@mui/material/styles'

import { useMutationsLoading } from '../../hooks/useMutationsLoading'

import { DraggableContainerOnForm } from './ConfiguredElementsOnForm/DraggableContainerOnForm'
import { FieldsConfigContext, Title } from './FieldsConfig'

export const ConfiguredFormElements: FC = () => {
  const { containers, isLoadingContainers, containerCreationInProgress } =
    useContext(FieldsConfigContext)
  const { t } = useTranslation()
  const isLoadingMutations = useMutationsLoading()

  const isOpenBackdrop = isLoadingContainers || isLoadingMutations

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {isLoadingContainers && (
        <LinearProgress sx={{ position: 'absolute', top: '-1rem', width: '100%' }} />
      )}
      <Backdrop
        open={isOpenBackdrop}
        sx={{ color: '#fff', position: 'absolute', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid container direction={'column'}>
        <Grid item>
          <Title sx={{ ml: 2 }} title={t('fieldsConfig.configuration')} />
        </Grid>

        <Grid item>
          <Droppable
            ignoreContainerClipping
            direction={'vertical'}
            droppableId={'formContainers'}
            isDropDisabled={containerCreationInProgress}
            type={FORM_DROPPABLE_TYPE.CONTAINERS}
          >
            {(provided, snapshot) => {
              return (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    ...(snapshot.isDraggingOver && {
                      background: theme => alpha(theme.palette.grey[600], 0.1),
                    }),
                  }}
                >
                  {containers.map((container, containerIndex) => {
                    return (
                      <DraggableContainerOnForm
                        key={container.id}
                        container={container}
                        index={containerIndex}
                      />
                    )
                  })}
                  {provided.placeholder}
                </Box>
              )
            }}
          </Droppable>
        </Grid>
      </Grid>
    </Box>
  )
}
