import { useState } from 'react'
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { ENTITY, ENTITY_PATH, MODAL_TYPE, ROUTES } from '@constants'
import { getEntityTypeForRequest } from '@helpers'
import { useAppDispatch, useAppSelector } from '@hooks'

import { setModelCode, setStep } from '@redux/reducers/stepper.reducer'

import {
  EntityType,
  GETActionView,
  ModalType,
  PayloadCreateViewColumn,
  POSTEntityDTO,
  ShareColumnType,
  ViewRow,
} from '../../../types'
import {
  createColumnType,
  createEntityType,
  deleteActionType,
  deleteColumnType,
  fetchActionType,
  fetchColumnType,
  updateColumnType,
  updateEntityType,
} from '../types'

type useHandlersParams = {
  entity?: EntityType
  type: ENTITY
  createEntity: createEntityType
  updateEntity: updateEntityType
  createColumn: createColumnType
  fetchColumn: fetchColumnType
  updateColumn: updateColumnType
  deleteColumn: deleteColumnType
  fetchAction: fetchActionType
  deleteAction: deleteActionType
}

export const useHandlers = ({
  entity,
  type,
  createEntity,
  updateEntity,
  createColumn,
  fetchColumn,
  updateColumn,
  deleteColumn,
  fetchAction,
  deleteAction,
}: useHandlersParams) => {
  const [modalProperty, setModalProperty] = useState<ShareColumnType>()
  const [objectCode, setObjectCode] = useState<string>()
  const [currentRow, setCurrentRow] = useState<ViewRow | GETActionView | null>()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [modalType, setModalType] = useState<ModalType>('create')

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation() as Location & { state?: { code?: string } }
  const { code } = useParams()

  const { currentStep, modelCode: entityCode } = useAppSelector(state => state.stepper)

  const handleSetCurrentRow = (value: ViewRow | GETActionView | null) => setCurrentRow(value)

  const handleSetModalProperty = (type: ShareColumnType) => setModalProperty(type)

  const handleSetObjectCode = (value: string) => setObjectCode(value)

  const handleAddEntity = (view: Omit<POSTEntityDTO, 'type'>) => {
    if (!view.id) {
      return createEntity({
        ...view,
        type: getEntityTypeForRequest(type),
      })
        .unwrap()
        .then(res => {
          if (!code) {
            navigate(`${ROUTES[`CONFIG_${ENTITY_PATH[type]}_EDIT`]}/${res.code}`)
          }
        })
    }
    return updateEntity({
      ...view,
      type: getEntityTypeForRequest(type),
    }).unwrap()
  }

  const handleAddColumn = (row: PayloadCreateViewColumn) => createColumn(row)

  const handleDeleteColumn = (id: number | string) => deleteColumn(id)

  const handleDeleteAction = (id: number | string) => deleteAction(id)

  const handleEditColumn = (row: PayloadCreateViewColumn) => updateColumn(row)

  const handleCloseModal = (isDirty?: boolean) => {
    if (isDirty && confirm(i18next.t('notifications.leave'))) {
      setShowDialog(false)
      setCurrentRow(null)
      return
    }
    if (!isDirty) {
      setShowDialog(false)
      setCurrentRow(null)
    }
  }

  const handleOpenDialog = (type: ModalType, id?: number | string, isAction?: boolean) => {
    if (type === MODAL_TYPE.EDIT && id) {
      if (isAction) {
        fetchAction(id)
      } else {
        fetchColumn(id)
      }
    }
    setModalType(type)
    setShowDialog(true)
  }

  const handleCancel = () => {
    navigate(`${ROUTES[`CONFIG_${ENTITY_PATH[type]}`]}`)
  }

  const handleSetStep = (value: number) => dispatch(setStep(value))

  const handleSetModelCode = (value: string) => dispatch(setModelCode(value))

  return {
    state: {
      modalProperty,
      objectCode,
      currentRow,
      currentStep,
      showDialog,
      modalType,
    },
    data: {
      entityCode,
    },
    handlers: {
      handleSetModalProperty,
      handleSetObjectCode,
      handleAddEntity,
      handleAddColumn,
      handleDeleteColumn,
      handleDeleteAction,
      handleEditColumn,
      handleCloseModal,
      handleOpenDialog,
      handleCancel,
      handleSetStep,
      handleSetModelCode,
      handleSetCurrentRow,
    },
  }
}
