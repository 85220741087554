import { CriteriaType } from '@constants'

/**
 * @descrption
 * Сортировка критериев ассистента поиска. На выходе получается массив критериев в следующем порядке:
 * сначала идут критерии с отсортированным order (по order в порядке возрастания),
 * затем критерии с order = null в порядке их создания (по id в порядке убывания)
 * @param criterias Критерии ассистента поиска
 */
export const filterAndSortCriteriasByOrder = (criterias: CriteriaType[]) => {
  const criteriasWithOrder = criterias.filter(criteria => criteria.order !== null)
  const criteriasWithoutOrder = criterias.filter(criteria => criteria.order === null)

  // Критерии с order !== null, отсортированные по order
  // На выходе будет: order = 0, order = 1, order = 2, ... / по возрастанию
  const orderedCriteriasWithOrder = criteriasWithOrder.sort((criteriaA, criteriaB) => {
    const criteriaAOrder = criteriaA.order as number
    const criteriaBOrder = criteriaB.order as number

    return criteriaAOrder - criteriaBOrder
  })

  // Критерии с order === null, отсортированные по id (в порядке их создания)
  // На выходе будет: id = 190, id = 189, id = 188, ... | по убыванию
  const orderedCriteriasWithoutOrder = criteriasWithoutOrder.sort((criteriaA, criteriaB) => {
    return criteriaB.id - criteriaA.id
  })

  // На выходе получается массив критериев в следующем порядке:
  // сначала идут критерии с отсортированным order,
  // затем критерии с order = null в порядке их создания
  return orderedCriteriasWithOrder.concat(orderedCriteriasWithoutOrder)
}
