import { FC, useEffect, useMemo, useState } from 'react'
import isObject from 'lodash/isObject'
import { AutocompleteOption, LabelPlacement } from 'src/types'
import {
  AutoComplete,
  AutoCompleteProps,
  FormLabel,
  TextField,
} from '@microservices/wiskey-react-components'
import { Grid, InputProps, SxProps } from '@mui/material'

import { useFetchEnumForObjectQuery } from '@redux/api'

import {
  generalFieldStylesByMode,
  getAutoCompletePopperSxByDisplayMethod,
  isNotEmpty,
} from '@helpers'
import { FIELD_VALUE_TYPE, UPDATES_ROW_CLASSNAME } from '@constants'

export type EnumAutocompleteProps<T> = {
  value?: AutocompleteOption | number | string
  onChange?: (details: AutocompleteOption) => void
  name: string
  fieldCode: string
  objectCode: string
  valueType: FIELD_VALUE_TYPE
  inputProps: InputProps
  onLoadEnum?: (options: AutocompleteOption[], value: any) => void
  label?: string
  labelPlacement?: LabelPlacement
  labelSx?: SxProps
  inputSx?: SxProps
  invalid?: boolean
  dirtyFields?: Partial<Readonly<{ [x: string]: boolean }>>
  isDialogWindow?: boolean
  popupIcon?: React.ReactNode
  smallPopupIconPadding?: boolean
} & Omit<AutoCompleteProps<T>, 'renderInput' | 'options'>

export const EnumAutocomplete: FC<EnumAutocompleteProps<AutocompleteOption>> = ({
  name,
  objectCode,
  placeholder,
  disabled,
  className,
  value,
  valueType,
  onChange,
  isOptionEqualToValue = (value, option) => value.id === option.id,
  onLoadEnum,
  label,
  labelPlacement,
  labelSx,
  inputSx,
  dirtyFields,
  invalid,
  isDialogWindow,
  popupIcon,
  inputProps,
  smallPopupIconPadding = true,
  fieldCode,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data: enumData, isLoading } = useFetchEnumForObjectQuery(
    { code: objectCode, name: fieldCode },
    { skip: !isOpen && !isNotEmpty(value) }
  )
  const isUpdated = className === UPDATES_ROW_CLASSNAME
  const [optionValue, setOptionValue] = useState<AutocompleteOption | null>(null)

  // Преобразование enum'а с бэка в options для autocomplete
  const getOptions = (): AutocompleteOption[] => {
    if (!enumData) {
      return []
    }

    const getOptionId = (value: string | number) => {
      switch (valueType) {
        case FIELD_VALUE_TYPE.INTEGER:
          return Number(value)
        default:
          return value
      }
    }

    return enumData.map(
      data =>
        ({
          id: getOptionId(data['value']),
          label: data['desc'] as string,
        } as AutocompleteOption)
    )
  }

  const options: AutocompleteOption[] = useMemo(() => getOptions(), [enumData])

  useEffect(() => {
    // Уже передали option
    if (isObject(value) && (value as AutocompleteOption)?.id.toString()) {
      setOptionValue(value as AutocompleteOption)

      return
    }

    // Передали строку или число
    const matchingOption = options.find(option => option.id === value)
    if (matchingOption) {
      setOptionValue(matchingOption)

      return
    }

    setOptionValue(null)
  }, [value, options])

  useEffect(() => {
    onLoadEnum?.(options, value)
  }, [options])

  const handleChange = (details: AutocompleteOption) => {
    onChange?.(details)
  }

  const handleDropDownOpen = () => setIsOpen(true)

  const handleDropDownClose = () => setIsOpen(false)

  const renderInput = () => {
    return (
      <AutoComplete
        fullWidth
        disabled={disabled}
        isOptionEqualToValue={isOptionEqualToValue}
        loading={isLoading}
        open={isOpen}
        options={options}
        popupIcon={popupIcon}
        size='small'
        value={optionValue}
        onChange={handleChange}
        onClose={handleDropDownClose}
        onOpen={handleDropDownOpen}
        {...props}
        componentsProps={{
          popper: {
            sx: {
              zIndex: theme => theme.zIndex.tooltip,
              ...getAutoCompletePopperSxByDisplayMethod(isDialogWindow),
            },
          },
        }}
        renderInput={params => (
          <TextField
            {...params}
            className={className}
            disabled={disabled}
            error={invalid}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {inputProps?.startAdornment}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            sx={theme => ({
              ...generalFieldStylesByMode(theme, dirtyFields, name, isUpdated, isDialogWindow),
              '& .MuiAutocomplete-endAdornment': {
                right: smallPopupIconPadding ? '-14px !important' : '2px !important',
                ...(smallPopupIconPadding ? { top: '-2px !important' } : {}),
              },
            })}
          />
        )}
        sx={{
          '& .MuiButtonBase-root': {
            transform: 'none',
            padding: isDialogWindow ? 0 : '2px',
          },
          '.MuiAutocomplete-popupIndicator': {
            marginRight: '10px',
          },
        }}
      />
    )
  }

  return (
    <Grid container item justifyContent='space-between'>
      <FormLabel
        control={renderInput()}
        label={label}
        labelPlacement={labelPlacement}
        labelSx={labelSx}
        name={name}
      />
    </Grid>
  )
}
