import { useMemo } from 'react'
import { createAutocompleteOptions } from '@helpers'

import { useFetchObjectByCodeQuery } from '@redux/api'

type UseCommandOptionsParams = {
  selectedObjectCode: string
}
export const useCommandOptions = ({ selectedObjectCode }: UseCommandOptionsParams) => {
  const { data: objectModel } = useFetchObjectByCodeQuery(selectedObjectCode, {
    skip: !selectedObjectCode,
  })

  return useMemo(
    () =>
      objectModel?.commands
        ? createAutocompleteOptions(objectModel.commands, { id: 'code', label: 'code' })
        : [],
    [objectModel]
  )
}
