import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GridSortItem, GridSortModel } from '@mui/x-data-grid'

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

/**
 * @param search нужен на случай, если необходимо прокинуть общие search params, чтобы не было конфликтов между хуками.
 * @returns
 */
export const useSorting = (
  search?: URLSearchParams
): { sort: GridSortItem[]; setSort: (sort?: string) => void } => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = search ?? new URLSearchParams(location.search)
  const sortParam = searchParams.get('sort')
  const paramsArray = useMemo(() => sortParam?.split(',') || ['', ''], [sortParam])
  const [sort, setSort] = useState<GridSortModel>([])

  const setSorting = (newSort?: string) => {
    newSort ? searchParams.set('sort', newSort) : searchParams.delete('sort')
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  useEffect(() => {
    if (paramsArray) {
      const result: GridSortModel = []
      paramsArray.forEach((val, i) => {
        if ((i === 0 || i % 2 === 0) && val) {
          result.push({ field: val, sort: paramsArray[i + 1] as SortDirection })
        }
      })
      setSort(result)
    }
  }, [paramsArray])

  const getSort = (): GridSortItem[] => sort

  useEffect(() => {
    if (!sortParam) {
      setSorting()
      return
    }

    setSorting(sortParam)
  }, [])

  return {
    sort: getSort(),
    setSort: setSorting,
  }
}
