import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { Chip, FormGenerator } from '@microservices/wiskey-react-components'
// import { DataGrid } from '@microservices/wiskey-react-components/DataGrid'
import { DataGrid } from '@microservices/wiskey-react-components'
import { CachedOutlined } from '@mui/icons-material'
import { Box, Grid, IconButton } from '@mui/material'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { AutocompleteOption, DictionaryCategory } from '@types'

import { Tab, TabPanel, Tabs } from '@components/Tabs'

type TableWithFiltersProps = {
  defaultValueSelect?: string
  rows: GridRowsProp
  columns: GridColDef[]
  filterName: string
  filterOptions: DictionaryCategory[]
  placeholderInput?: string
  externalOnSelectChange?: (arg?: any) => void
  onRefresh?: () => void
  loading: boolean
  onChangePage?: (value: number) => void
  countPage: number
  currentPage?: number
}

type FormValues = {
  selectCat: AutocompleteOption
}

export const TableWithFilters: FC<TableWithFiltersProps> = ({
  rows,
  columns,
  filterName,
  filterOptions,
  externalOnSelectChange,
  onRefresh,
  loading,
  onChangePage,
  countPage,
  currentPage,
}) => {
  const { t } = useTranslation()
  const methods = useForm<FormValues>({
    defaultValues: { selectCat: { id: 0, label: '' } },
  })
  const { watch, reset } = methods
  const watchSelect = watch('selectCat')

  useEffect(() => {
    onChangeSelect(watchSelect)
  }, [watchSelect])

  const onChangeSelect = (value: AutocompleteOption): void => {
    externalOnSelectChange?.(filterOptions.find(option => option?.id === value?.id)?.code)
  }

  const handleRefresh = (): void => {
    onRefresh?.()
    reset()
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={0}>
          <Tab label={t('tableWithFilters.title')} />
        </Tabs>
      </Box>
      <TabPanel index={0} value={0}>
        <Grid container spacing={3}>
          <Grid container item alignItems='start' sm={12} spacing={1} xs={12}>
            <Grid item>
              <Chip label={filterName} sx={{ height: '40px' }} />
            </Grid>
            <Grid item flexGrow={1} ml={1}>
              <FormProvider {...methods}>
                <form>
                  <FormGenerator
                    inputs={[
                      {
                        name: 'selectCat',
                        inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                        autocompleteOptions: filterOptions.map(op => ({
                          id: Number(op.id),
                          label: op.title,
                        })),
                        size: 'small',
                        placeholder: t('placeholder.category'),
                      },
                    ]}
                  />
                </form>
              </FormProvider>
            </Grid>
            <Grid item>
              <IconButton onClick={handleRefresh}>
                <CachedOutlined />
              </IconButton>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size='small'
              placeholder={placeholderInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid> */}
        </Grid>
        <DataGrid
          pagination
          columns={columns}
          loading={loading}
          page={currentPage}
          pageSize={10}
          paginationMode='server'
          rowCount={countPage * 10}
          rows={rows}
          sx={{ borderRadius: 0 }}
          onPageChange={value => onChangePage?.(value)}
        />
      </TabPanel>
    </Box>
  )
}
