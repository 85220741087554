import { FC, ReactNode, useMemo } from 'react'
import { Rnd, RndResizeCallback, RndResizeStartCallback } from 'react-rnd'
import { Divider } from '@mui/material'
import { grey } from '@mui/material/colors'

type ResizeGridItemProps = {
  children: ReactNode
  size: number
  width: number
  countSize: number
  minWidthByGrid?: number
  disableResizing?: boolean
  onResize?: RndResizeCallback
  onResizeStart?: RndResizeStartCallback
  onResizeStop?: RndResizeCallback
}

const GRID_SIZE = 12

export const ResizeGridItemWrapper: FC<ResizeGridItemProps> = ({
  children,
  size,
  width,
  countSize,
  disableResizing,
  minWidthByGrid,
  onResize,
  onResizeStart,
  onResizeStop,
}) => {
  const remains = useMemo(() => GRID_SIZE - (countSize - size), [countSize, size])
  const generalMinWidth = useMemo(() => width / GRID_SIZE, [width])
  const minWidthWithGrid = useMemo(
    () => (minWidthByGrid ? width / (GRID_SIZE / minWidthByGrid) : width / GRID_SIZE),
    [width, minWidthByGrid]
  )

  return (
    <Rnd
      enableResizing={{ right: !disableResizing }}
      disableDragging
      size={{ width: size * generalMinWidth, height: 50 }}
      resizeHandleComponent={{
        right: (
          <Divider
            orientation='vertical'
            sx={{
              width: '3px',
              height: 15,
              color: grey[400],
              borderWidth: 0,
              bgcolor: grey[400],
            }}
          />
        ),
      }}
      resizeHandleStyles={{ right: { top: '45%', paddingLeft: '3px' } }}
      minWidth={minWidthWithGrid}
      maxWidth={remains * generalMinWidth} // ширина свободного места
      onResize={onResize}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      style={{
        position: 'inherit',
        width: size * generalMinWidth + 'px',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      {children}
    </Rnd>
  )
}
