import { useCallback } from 'react'
import set from 'lodash/set'

export const useYupValidationResolver = validationSchema =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce((allErrors, currentError) => {
            const newErrors = structuredClone(allErrors)
            set(newErrors, currentError.path, {
              type: currentError.type ?? 'validation',
              message: currentError.message,
            })
            return newErrors
          }, {}),
        }
      }
    },
    [validationSchema]
  )
