import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { ItemId, TreeData, TreeItem } from '@atlaskit/tree'
import { ENTITY, ENTITY_TYPES_REQUEST } from '@constants'
import { getTree, transformTreeToMenu } from '@helpers'
import { useDraggableTree, usePrompt } from '@hooks'

import { PageContext as ConfigViewsContext } from '@pages/ConfigEntity'

import { EntityOrFormGroupList } from '@components/EntityOrFormGroupList'

import {
  useFetchAllViewQuery,
  useFetchAllViewUncertainQuery,
  useFetchViewGroupsQuery,
  useUpdateViewGroupsMutation,
} from '@redux/api'

import { NoIdOptionGroupType, OptionType, POSTOptionGroupType } from '../../../../types'

type EntityGroupListProps = {
  onSetDirty: (value: boolean) => void
}

export const EntityGroupList: FC<EntityGroupListProps> = ({ onSetDirty }) => {
  const { onDeleteEntity, onShowDialog, onEditEntity, type } = useContext(ConfigViewsContext)
  const { tree, setTree, deleteTreeItem, addTreeItem } = useDraggableTree()
  const [firstTree, setFirstTree] = useState<TreeData | null>(null)
  const { data: groups, isFetching: isFetchingGroups } = useFetchViewGroupsQuery(
    type === ENTITY.LIST_CONTROL
      ? { viewType: ENTITY_TYPES_REQUEST.LIST_CONTROL }
      : { viewType: ENTITY_TYPES_REQUEST.VIEW }
  )
  const [updateGroups, { isSuccess }] = useUpdateViewGroupsMutation()
  const { data: viewsData } = useFetchAllViewQuery(
    type === ENTITY.LIST_CONTROL ? { viewType: ENTITY_TYPES_REQUEST.LIST_CONTROL } : {},
    { refetchOnMountOrArgChange: true }
  )
  const { data: uncertainViewsData, isFetching: isFetchingUncertainViews } =
    useFetchAllViewUncertainQuery(
      type === ENTITY.LIST_CONTROL ? { viewType: ENTITY_TYPES_REQUEST.LIST_CONTROL } : {},
      { refetchOnMountOrArgChange: true }
    )
  const transformFirstTree = useMemo(() => firstTree && transformTreeToMenu(firstTree), [firstTree])
  const transformTree = useMemo(() => tree && transformTreeToMenu(tree), [tree])

  const isDirty = JSON.stringify(transformFirstTree) !== JSON.stringify(transformTree)

  useEffect(() => {
    onSetDirty(isDirty)
  }, [isDirty])

  usePrompt({ when: isDirty })

  const handleUpdateGroups = (groups: POSTOptionGroupType[]) =>
    updateGroups({
      groups,
      viewType:
        type === ENTITY.VIEW ? ENTITY_TYPES_REQUEST.VIEW : ENTITY_TYPES_REQUEST.LIST_CONTROL,
    })
      .unwrap()
      .then(res => {
        setTree(getTree(res as OptionType[]))
        setFirstTree(getTree(res as OptionType[]))
      })

  useEffect(() => {
    if (groups) {
      setTree(getTree(groups as OptionType[]))
      setFirstTree(getTree(groups as OptionType[]))
    }
  }, [groups])

  const handleDeleteTreeItem = (itemId: ItemId) => {
    deleteTreeItem(itemId)
  }

  const handleEditTreeItem = (treeItem: TreeItem) => {
    if (tree) {
      const newTree: TreeData = { ...tree }
      newTree.items[treeItem.id] = { ...treeItem }
      setTree(newTree)
    }
  }

  const handleAddTreeItem = (option: NoIdOptionGroupType, treeItem?: TreeItem) => {
    addTreeItem(option, treeItem)
  }

  const handleCancel = () => {
    if (groups) {
      setTree(getTree(groups as OptionType[]))
    }
  }

  return (
    <EntityOrFormGroupList
      type={type}
      uncertainData={uncertainViewsData?.data || []}
      isFetchingUncertain={isFetchingUncertainViews}
      allData={viewsData?.data || []}
      tree={tree}
      isFetchingGroups={isFetchingGroups}
      setTree={setTree}
      onEditTreeItem={handleEditTreeItem}
      onDeleteTreeItem={handleDeleteTreeItem}
      onAddTreeItem={handleAddTreeItem}
      updateGroups={handleUpdateGroups}
      isDirty={isDirty}
      onEdit={onEditEntity}
      onCopy={onShowDialog}
      onDelete={onDeleteEntity}
      onCancel={handleCancel}
      key={type}
    />
  )
}
