import { FC, MouseEvent, MouseEventHandler, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FIELD_VALUE_TYPE, RIGHT_SHADOW_LIST_ITEM, TOP_SHADOW_LIST_ITEM } from '@constants'
import { useSubscribeEvent } from '@hooks'
import { Checkbox } from '@microservices/wiskey-react-components'
// import {
//   GridColumnHeaderParams,
//   GridColumnHeaderTitle,
// } from '@microservices/wiskey-react-components/DataGrid'
import {
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'
import {
  CHECKBOX_IN_COLUMN_FOR_SEARCHING_EVENT_DETAIL,
  GLOBAL_CHECKBOX_IN_COLUMN_EVENT_DETAIL,
  ObjectDataRecord,
  ViewRow,
} from '@types'

import { PageContext } from '@pages/ConfiguredEntity/ConfiguredEntity'
import {
  getIsAvailableRuleByNames,
  getIsAvailableRuleByType,
} from '@pages/ConfiguredEntity/helpers'
import { ConfigActionsType } from '@pages/ConfiguredEntity/hooks'

import { MenuPortal } from '@components/MenuPortal'
import { CursorPosition } from '@components/NestedMenu'

import { EVENT_NAME, publish } from '../../../../events'

type HeaderRenderType = {
  configActions: ConfigActionsType
  column: ViewRow
  params: GridColumnHeaderParams<any, ObjectDataRecord, any>
  hasQuickSearch: boolean | undefined
}

export const HeaderRender: FC<HeaderRenderType> = ({
  column,
  configActions,
  params,
  hasQuickSearch = true,
}) => {
  const { t } = useTranslation()
  const {
    columnNameCheckBox,
    searchRule,
    valueSearchFilter,
    sortKeysColumnCheckBox,
    scrollingParams,
    dialogId,
    visibleColumns,
    handleSetBooleanMode,
    handleSetGlobalFilter,
    isBooleanSearchMode,
  } = useContext(PageContext)

  const [checked, setChecked] = useState(columnNameCheckBox[column.code])

  useEffect(() => setChecked(columnNameCheckBox[column.code]), [columnNameCheckBox[column.code]])

  useSubscribeEvent(
    EVENT_NAME.GLOBAL_CHECKBOX_IN_COLUMN,
    (data: { detail: GLOBAL_CHECKBOX_IN_COLUMN_EVENT_DETAIL }) => {
      if (dialogId !== data.detail.dialogId) {
        return
      }

      Object.keys(data.detail.columnNameCheckbox).forEach(el => {
        if (el === params.field) {
          setChecked(data.detail.columnNameCheckbox[el])
        }
      })
    }
  )

  const showCheckBox = (column: ViewRow): boolean => {
    return Boolean(column?.columnToSort)
  }

  const getDisabled = () => {
    if (column) {
      return (
        !getIsAvailableRuleByNames(column, searchRule.name) ||
        !getIsAvailableRuleByType(column, searchRule.name)
      )
    }
    return false
  }

  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    const target = e?.target as typeof e.target & { checked: boolean }
    const checkedCheckbox: boolean = target.checked

    const { page, ...requestParams } = scrollingParams

    let columnNameCheckboxInHandler = columnNameCheckBox
    const sortKeysColumnCheckBoxInHandlers = sortKeysColumnCheckBox

    // Блок управления режимом поиска (строка/boolean) (начало)
    const column = visibleColumns.find(column => column.code === params.field)
    let wasSearchModeChanged = false
    // Логика для изменения формата поиска со строки на boolean
    if (!isBooleanSearchMode) {
      if (column && column.valueType === FIELD_VALUE_TYPE.BOOLEAN) {
        const booleanColumns = visibleColumns.filter(column => {
          return column.valueType !== FIELD_VALUE_TYPE.BOOLEAN
        })
        const newColumnNameCheckbox: Record<string, boolean> = {}

        booleanColumns.forEach(column => {
          if (!column.columnToSort) {
            return
          }

          sortKeysColumnCheckBoxInHandlers[column.columnToSort] = false
        })

        visibleColumns.forEach(viewColumn => {
          const columnCheckboxValue =
            viewColumn.valueType === FIELD_VALUE_TYPE.BOOLEAN && viewColumn.code === column?.code

          newColumnNameCheckbox[viewColumn.code] = columnCheckboxValue
        })

        columnNameCheckboxInHandler = newColumnNameCheckbox

        if (checkedCheckbox) {
          handleSetBooleanMode(true)
          handleSetGlobalFilter('', true)
          wasSearchModeChanged = true
        }
      }
    }

    // Логика для изменения формата поиска с boolean на строку
    if (isBooleanSearchMode) {
      if (column && column.valueType !== FIELD_VALUE_TYPE.BOOLEAN) {
        const nonBooleanColumns = visibleColumns.filter(column => {
          return column.valueType === FIELD_VALUE_TYPE.BOOLEAN
        })
        const newColumnNameCheckbox: Record<string, boolean> = {}

        nonBooleanColumns.forEach(column => {
          if (!column.columnToSort) {
            return
          }

          sortKeysColumnCheckBoxInHandlers[column.columnToSort] = false
        })

        visibleColumns.forEach(viewColumn => {
          const columnCheckboxValue =
            viewColumn.valueType !== FIELD_VALUE_TYPE.BOOLEAN && viewColumn.code === column?.code

          newColumnNameCheckbox[viewColumn.code] = columnCheckboxValue
        })

        columnNameCheckboxInHandler = newColumnNameCheckbox

        handleSetBooleanMode(false)
        handleSetGlobalFilter('', true)
        wasSearchModeChanged = true
      }
    }

    // Блок управления режимом поиска (строка/boolean) (конец)
    const checkboxInColumnForSearchingDetail: CHECKBOX_IN_COLUMN_FOR_SEARCHING_EVENT_DETAIL = {
      sortKeys: sortKeysColumnCheckBoxInHandlers,
      titleColumns: columnNameCheckboxInHandler,
      columnName: params.field,
      sortKey: params.colDef.sortKey || '',
      value: checkedCheckbox,
      // Данное условие для searchValue нужно для избежания 400 ошибки при отправке данных в неправильном
      // формате. Если мы меняли режим поиска (boolean и "обычный"), то searchValue просто сбрасывается
      searchValue: wasSearchModeChanged ? '' : valueSearchFilter,
      searchRule,
      scrollingParams: requestParams,
      dialogId,
      wasSearchModeChanged,
      visibleColumns,
    }

    publish(EVENT_NAME.CHECKBOX_IN_COLUMN_FOR_SEARCHING, checkboxInColumnForSearchingDetail)
    setChecked(prev => !prev)
  }

  const [position, setPosition] = useState<null | CursorPosition>(null)

  const handleContextMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (configActions.actionsAvailable.on_column_right_click[column.id]) {
      event.preventDefault()
      const { left, bottom } = event.currentTarget.getBoundingClientRect()

      setPosition({
        left: left - RIGHT_SHADOW_LIST_ITEM,
        top: bottom - TOP_SHADOW_LIST_ITEM,
      })
    }
  }

  return (
    <Box sx={{ display: 'flex' }} onContextMenu={handleContextMenu}>
      <MenuPortal
        dataTransfer={{ changes: {}, config: {}, data: {}, metaData: { column } }}
        items={configActions.actionsData.on_column_right_click[column.id]?.contextMenu?.menuPoints}
        position={position}
        onClose={() => setPosition(null)}
        onItemClick={configActions.actionHandlers.on_column_right_click[column.id]}
      />
      {hasQuickSearch ? (
        <>
          <GridColumnHeaderTitle columnWidth={params.colDef.width!} label={column.title!} />
          {showCheckBox(column) && (
            <Checkbox
              checked={checked}
              disabled={getDisabled()}
              size={'1rem'}
              sx={{
                input: {
                  size: '1rem',
                  zIndex: theme => theme.zIndex.modal + 15,
                },
                color: theme => theme.palette.grey['800'],
                '&.Mui-checked': {
                  color: theme => theme.palette.grey['800'],
                },
                '&.MuiCheckbox-root': {
                  padding: 0,
                },
                '&.Mui-disabled': {
                  color: theme => theme.palette.grey['400'],
                  pointerEvents: 'fill',
                },
              }}
              title={
                getDisabled()
                  ? t('columnInputs.quickSearch.disabledColumnRule', {
                      name: searchRule.name.toLowerCase(),
                    })
                  : undefined
              }
              onChange={e => e.stopPropagation()}
              onClick={handleClick}
            />
          )}
        </>
      ) : (
        <GridColumnHeaderTitle columnWidth={params.colDef.width!} label={column.title!} />
      )}
    </Box>
  )
}
