import { Chip } from '@mui/material'

import { ScrollableLine } from '../ScrollableLine'

export type ChipType = {
  id: string | number
  title: string
}

type ScrollableChipsProps<T> = {
  width: number
  chips: (T & ChipType)[]
  onDelete?: (chip: T & ChipType, index: number) => void
  onDoubleClick?: (chip: T & ChipType, index: number) => void
}

export const ScrollableChips = <T,>({
  width,
  chips,
  onDelete,
  onDoubleClick,
}: ScrollableChipsProps<T>) => {
  return (
    <ScrollableLine width={width}>
      {chips.map((chip, index) => {
        return (
          <Chip
            key={chip.id}
            label={chip.title}
            onDelete={() => onDelete?.(chip, index)}
            onDoubleClick={() => onDoubleClick?.(chip, index)}
          />
        )
      })}
    </ScrollableLine>
  )
}
