import { EVENT_CODE } from '@constants'

import { Dictionary, GETShareColumn, Table, ViewActionRow } from '../types'

export const getAvailableActionEvents = (
  bindingValuesActionEvents?: Dictionary[],
  actionTable?: Table<ViewActionRow>,
  currentRow?: GETShareColumn | null
) => {
  if (!bindingValuesActionEvents) {
    return []
  }

  const filteredActionEvents = bindingValuesActionEvents.filter(
    actionEvent =>
      !actionTable?.rows.find(
        event =>
          event.eventCode === actionEvent.code &&
          event.eventCode !== EVENT_CODE.ON_COLUMN_RIGHT_CLICK
      )
  )

  if (currentRow) {
    if (currentRow.eventCode === EVENT_CODE.ON_COLUMN_RIGHT_CLICK) {
      return filteredActionEvents
    }

    const currentAction = bindingValuesActionEvents.find(
      actionEvent => actionEvent.code === currentRow.eventCode
    )

    if (currentAction) {
      filteredActionEvents.push(currentAction)
    }
  }

  return filteredActionEvents
}
