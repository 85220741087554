import {
  DELETED_ROW_CLASSNAME,
  FOUND_SEARCH_VALUE_CLASSNAME,
  PREV_SELECTED_ROW_CLASSNAME,
  SELECTED_ROW_CLASSNAME,
  UPDATES_ROW_CLASSNAME,
} from '@constants'
import { lighten } from '@mui/material'
import { darken, Theme } from '@mui/material/styles'

export const backgroundTableByMode = (wrapperClassName: string, theme: Theme) => {
  return {
    [`& .${UPDATES_ROW_CLASSNAME}, & .${UPDATES_ROW_CLASSNAME} > .MuiDataGrid-cell`]: {
      bgcolor: `${theme.palette.background.updatesRow} !important`,
    },
    [`& .${FOUND_SEARCH_VALUE_CLASSNAME}, & .${FOUND_SEARCH_VALUE_CLASSNAME} > .MuiDataGrid-cell`]:
      {
        bgcolor: `${theme.palette.background.searchValueRow} !important`,
      },
    [`& .${DELETED_ROW_CLASSNAME}`]: {
      bgcolor: lighten(theme.palette.error.light, 0.5),
    },
    [`& .${SELECTED_ROW_CLASSNAME} > .MuiDataGrid-cell`]: {
      bgcolor: `${theme.palette.background.selectedRow}`,
    },
    [`& .${PREV_SELECTED_ROW_CLASSNAME} > .MuiDataGrid-cell`]: {
      bgcolor: `${theme.palette.background.prevSelectedRow}`,
    },
    ['& .MuiDataGrid-row.Mui-hovered > .MuiDataGrid-cell']: {
      bgcolor: theme.palette.background.hovered,
    },
    '& .MuiDataGrid-pinnedColumns .even-row': {
      bgcolor: darken(theme.palette.background.evenRow, 0.02),
    },
    '& .even-row': {
      bgcolor: `${theme.palette.background.evenRow}`,
    },
    '& .odd-row': {
      bgcolor: `${theme.palette.background.oddRow}`,
    },
    [`.${wrapperClassName} .MuiDataGrid-pinnedColumnHeaders`]: {
      bgcolor: `${theme.palette.background.pinned}`,
    },
  }
}
