import i18next from 'i18next'

import { ErrorValidJSType } from './checkValidJS'

export const checkAvailableVariablesInScript = (
  value: string,
  variablesByObject?: Record<string, string[]>
): ErrorValidJSType | undefined => {
  if (!variablesByObject) {
    return {
      error: false,
    }
  }

  for (const objectName in variablesByObject) {
    const variables = variablesByObject[objectName]
    const regex = new RegExp(`${objectName}[.\\[]["']?([a-zA-Z0-9_а-яА-Я]+)`, 'gu')
    let match

    while ((match = regex.exec(value)) !== null) {
      if (!variables.includes(match[1])) {
        return {
          error: true,
          message: `${i18next.t('error.variable', { variable: match[1] })}`,
        }
      }
    }
  }
}
