import { SelectOption } from '@microservices/wiskey-react-components'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'
import { RadioOption } from '@microservices/wiskey-react-components/dist/hookFormControllers/RadioGroupController'
import { ObjectShortDTO } from '@types'

export const getOptionByStr = (value: string, name?: string) => ({
  id: value,
  value,
  name: name || value,
})

export function getBindTypeOptionsByEnum<T extends Record<string, string>>(
  optionsEnum: T
): SelectOption[] {
  const array: string[] = Object.values(optionsEnum)

  return array.map(value => getOptionByStr(value))
}

export function getBindTypeOptionsByStrings(strings: string[] = []): SelectOption[] {
  return strings.map(value => getOptionByStr(value))
}

export function getAutoCompleteOptions(objects: ObjectShortDTO[] = []): AutocompleteOption[] {
  return objects.map(obj => ({
    id: obj.code,
    label: obj.title,
  }))
}

export function getRadioOptionsByEnum<T extends Record<string, string>>(
  optionsEnum: T
): RadioOption[] {
  const array: string[] = Object.values(optionsEnum)

  return array.map(item => ({ label: item, value: item }))
}
