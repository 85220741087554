import { FIELD_VALUE_TYPE } from '@constants'

export const getFilterByValueType = (field: string, value: string, type: FIELD_VALUE_TYPE) => {
  switch (type) {
    case FIELD_VALUE_TYPE.INTEGER:
      return `${field}==${value}`
    default:
      return `${field}=='${value}*'`
  }
}
