import { FC, useEffect, useMemo, useRef } from 'react'
import arrowLeft from '@assets/images/arrow-left.svg'
import search from '@assets/images/search.svg'
import update from '@assets/images/update-disable.svg'
import {
  Breadcrumbs,
  BreadcrumbsProps,
  Button,
  Grid,
  Link as MUILink,
  Typography,
} from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { getBreabcrumbLinkWidth } from '@components/DisplayForm/helpers'

import { useAppSelector } from '@hooks'
import {
  SPACE_BETWEEN_BREADCRUMBS,
  WIDTH_FORM_CONTEXT_MENU_BUTTON,
  WIDTH_FORM_NAVIGATION_BUTTONS,
} from '@constants'

import { LinkType } from '../../../../types'

type BreadcrumbsFormPropsType = {
  dialogId: string
  links: LinkType[]
  windowWidth?: number
  openLink: (newIndex: number) => void
} & BreadcrumbsProps

export const BreadcrumbsForm: FC<BreadcrumbsFormPropsType> = ({
  openLink,
  dialogId,
  windowWidth,
  ...props
}) => {
  const history = useAppSelector(state => state.formHistory)
  const { selectedIndex, links } = history[dialogId]

  const focusButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    setTimeout(() => {
      focusButtonRef.current?.focus()
    }, 0)
  }, [])

  const currentLinks = links.slice(0, selectedIndex + 1)

  const breadcrumbWidth = useMemo(() => {
    return getBreabcrumbLinkWidth(
      windowWidth,
      currentLinks.length,
      WIDTH_FORM_NAVIGATION_BUTTONS +
        WIDTH_FORM_CONTEXT_MENU_BUTTON +
        (SPACE_BETWEEN_BREADCRUMBS * currentLinks.length - 1)
    )
  }, [windowWidth])

  const handleClick = (index: number) => {
    openLink(index)
  }

  const handleBackButtonClick = () => {
    openLink(selectedIndex - 1)
  }
  const handleNextButtonClick = () => {
    openLink(selectedIndex + 1)
  }

  return (
    <Grid container alignItems={'center'} mt={1}>
      <Grid container item alignItems={'center'} gap={1} mr={1} width={'auto'}>
        {/* кнопка для первоначального фокуса в форме, чтобы работал скролл через стрелки*/}
        <Button
          ref={focusButtonRef}
          sx={{ width: '0 !important', position: 'absolute', height: '0 !important', p: 0 }}
        />
        <Button
          disabled
          sx={{
            minWidth: 26,
            width: 26,
            height: 26,
            borderRadius: 0,
          }}
        >
          <CustomSvgIcon src={update} sx={{ boxShadow: theme => theme.palette.boxShadow.paper }} />
        </Button>
        <Button
          disabled
          sx={{
            minWidth: 26,
            width: 26,
            height: 26,
            borderRadius: 0,
          }}
        >
          <CustomSvgIcon src={search} sx={{ boxShadow: theme => theme.palette.boxShadow.paper }} />
        </Button>
        <Button
          disabled={selectedIndex === 0}
          sx={{
            minWidth: 26,
            width: 26,
            height: 26,
            borderRadius: 0,
            p: 0,
            opacity: selectedIndex === 0 ? 0.2 : 1,
          }}
          onClick={handleBackButtonClick}
        >
          <CustomSvgIcon
            src={arrowLeft}
            sx={{ boxShadow: theme => theme.palette.boxShadow.paper }}
          />
        </Button>
        <Button
          disabled={selectedIndex === links.length - 1}
          sx={{
            minWidth: 26,
            width: 26,
            height: 26,
            borderRadius: 0,
            opacity: selectedIndex === links.length - 1 ? 0.2 : 1,
          }}
          onClick={handleNextButtonClick}
        >
          <CustomSvgIcon
            src={arrowLeft}
            sx={{ transform: 'rotate(180deg)', boxShadow: '-2px -2px 6px 0px #000000' }}
          />
        </Button>
      </Grid>
      <Grid item>
        <Breadcrumbs {...props} aria-label='breadcrumb'>
          {currentLinks?.map((link, index) =>
            index !== selectedIndex ? (
              <MUILink
                key={link.id}
                color='inherit'
                sx={{ cursor: 'pointer' }}
                underline='hover'
                onClick={() => handleClick(index)}
              >
                <Typography
                  key={link.id}
                  maxWidth={breadcrumbWidth}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {link.title}
                </Typography>
              </MUILink>
            ) : (
              <Typography
                key={link.id}
                color='text.primary'
                maxWidth={breadcrumbWidth}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {link.title}
              </Typography>
            )
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  )
}
