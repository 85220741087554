import { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'

import { VISIBLE_HIDDEN } from '@constants'

import { AllContexts } from './components/AllContexts'
import { CopyDialog } from './components/CopyDialog'
import { useContexts } from './hooks'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const Contexts = () => {
  const { t } = useTranslation()

  const {
    state: { methods, allContexts, showCopyDialog, copyContextData, isLoadingCopyContext },
    data: { isAllContexts, isLoadingContexts },
    handlers: {
      handleShowDialog,
      handleCloseDialog,
      handleCopyContext,
      handleCreateContext,
      handleEditContext,
      handleDeleteContext,
      handleKeyDown,
    },
  } = useContexts()

  const contextValue = {
    contextTables: allContexts,
    contextIsLoading: isLoadingContexts,
    onShowCopyDialog: handleShowDialog,
    onCreateContext: handleCreateContext,
    onEditContext: handleEditContext,
    onDeleteContext: handleDeleteContext,
    onKeyDown: handleKeyDown,
  }

  return (
    <PageContext.Provider value={contextValue}>
      {showCopyDialog && copyContextData && (
        <CopyDialog
          copyData={copyContextData}
          dialogTitle={t('copying.context.dialogTitle')}
          isShow={showCopyDialog}
          loading={isLoadingCopyContext}
          onClose={handleCloseDialog}
          onCopy={handleCopyContext}
        />
      )}
      <Outlet />
      <Box sx={{ ...(!isAllContexts && VISIBLE_HIDDEN) }}>
        <PageTitle
          end={false}
          start={
            <>
              <Typography display='inline' sx={{ mr: 1, ml: 1 }} variant='h6'>
                {t('pageTitle.contexts')}
              </Typography>
            </>
          }
        />
        <PageContentLayout>
          <AllContexts methods={methods} />
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}
