import { ParameterPropertyType, POSTParameterPropertyType } from 'src/types'

import { CommonParametersFormType } from '../hooks'

export const getCommonPropertiesForUpdate = (
  initialProperties: ParameterPropertyType[],
  formProperties: CommonParametersFormType,
  paramCode: string
): POSTParameterPropertyType[] => {
  const propertiesForUpdate: POSTParameterPropertyType[] = []

  initialProperties.forEach(property => {
    const correspondingHeaderDataKey = Object.keys(formProperties).find(key => key === property.key)

    if (!correspondingHeaderDataKey) {
      return
    }

    const newValue =
      formProperties[correspondingHeaderDataKey as keyof CommonParametersFormType]?.id

    if (property.value == newValue) {
      return
    }

    propertiesForUpdate.push({
      ...property,
      value: newValue ? String(newValue) : null,
      paramCode,
    })
  })

  return propertiesForUpdate
}
