import { Route, Routes } from 'react-router-dom'

import { MainRoute } from './MainRoute'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<MainRoute />} path={'/*'} />
    </Routes>
  )
}
