import { Dispatch, SetStateAction, useEffect } from 'react'
import { FORM_TYPE } from '@constants'

import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'

import { Row } from '../../../types'

type UseHandleNavigationParams = {
  handleNavigateToDefaultPage: () => void
  setCurrentRow: Dispatch<SetStateAction<Row | undefined>>
  withPassedParams: boolean
  newEvent: FORM_TYPE | null
  globalId: string
  // row: ObjectDataRecord | undefined
  // isSuccessRow: boolean
  // isErrorRow: boolean
}

export const useHandleNavigation = ({
  handleNavigateToDefaultPage,
  setCurrentRow,
  withPassedParams,
  newEvent,
  globalId,
}: UseHandleNavigationParams) => {
  const [, { data: row, isSuccess: isSuccessRow, isError: isErrorRow }] =
    useFetchObjectDataWithFixedKey(globalId)

  useEffect(() => {
    if (row) {
      setCurrentRow(row)
      return
    }

    if (((isSuccessRow && !row) || isErrorRow) && !withPassedParams) {
      handleNavigateToDefaultPage()
      // navigate(ROUTES.DEFAULT_PAGE)
    }
  }, [row, isSuccessRow, isErrorRow])

  useEffect(() => {
    if (newEvent && !Object.values(FORM_TYPE).includes(newEvent) && !withPassedParams) {
      handleNavigateToDefaultPage()
      // navigate(ROUTES.DEFAULT_PAGE)
    }
  }, [newEvent])
}
