import { useHandlers } from './useHandlers'

export const useControlPanelsCreateOrEdit = () => {
  const { data, handlers, state } = useHandlers()

  return {
    data: { ...data },
    state: { ...state },
    handlers: { ...handlers },
  }
}
