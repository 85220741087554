import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@microservices/wiskey-react-components'
import { Grid } from '@mui/material'

import { ParametersContext } from '@pages/Parameters/Parameters'

export const Buttons = () => {
  const { t } = useTranslation()

  const {
    isEdit,
    onCancelEdit,
    onEditOrSave,
    dirtyVisualParameters,
    methods,
    onClickResetVisualParameters,
  } = useContext(ParametersContext)

  const {
    formState: { isDirty },
  } = methods

  return (
    <Grid item container justifyContent={'flex-end'} gap={0.5}>
      <Button
        onClick={onEditOrSave}
        variant='contained'
        disabled={isEdit ? !dirtyVisualParameters && !isDirty : isEdit}
      >
        {t(`parameters.visual.${isEdit ? 'save' : 'edit'}`)}
      </Button>
      {isEdit && (
        <Button onClick={onCancelEdit} variant='outlined'>
          {t('parameters.visual.cancel')}
        </Button>
      )}
      <Button variant='outlined' onClick={onClickResetVisualParameters} disabled={!isEdit}>
        {t('parameters.resetButton')}
      </Button>
    </Grid>
  )
}
