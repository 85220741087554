import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import isString from 'lodash/isString'
import { List, Typography } from '@mui/material'

import { THEME_MODE } from '@constants'

import { NavOptionProps } from '../../types'

import { NavItem } from './NavItem'

/**
 * isChild - если вложенный список, передаются рекурсией в NavItem
 * pl - паддинг слевого края, чтобы показать вложенность, передаются рекурсией в NavItem
 */
type NavListProps = {
  options: NavOptionProps[]
  isChild?: boolean
  pl?: number
  title?: string | ReactElement
  onClick?: (op: NavOptionProps) => void
  onSelected?: (item: NavOptionProps) => void
  hideText?: boolean
}

export const NavList: FC<NavListProps> = ({
  options,
  isChild,
  pl,
  title,
  onClick,
  hideText,
  onSelected,
}) => {
  const { t } = useTranslation()
  const props = isChild ? { component: 'div', disablePadding: true } : {}
  const isChildFromProps = isChild
  const isRenderTitle = !isChild && title

  return (
    <List {...props}>
      {isRenderTitle && (
        <Typography
          variant='caption'
          sx={{
            pl: 2,
            opacity: hideText ? 0 : 1,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: theme =>
              theme.palette.mode === THEME_MODE.LIGHT
                ? theme.palette.grey[600]
                : theme.palette.text.primary,
            textTransform: 'uppercase',
          }}
        >
          {isString(title) ? t(title) : title}
        </Typography>
      )}
      {options.map(op => (
        <NavItem
          key={op.code}
          hideText={hideText}
          isChild={isChildFromProps}
          item={op}
          pl={pl}
          onClick={onClick}
          onSelected={onSelected}
        />
      ))}
    </List>
  )
}
