import { CSSProperties, useEffect, useMemo, useState } from 'react'
import { arrayMove } from 'react-sortable-hoc'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Box, Table as MUITable } from '@mui/material'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
  CellContext,
  ColumnDef,
  FilterFn,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  RowData,
  SortingState,
  Table,
  useReactTable,
} from '@tanstack/react-table'

import {
  calculatedColumnsWidth,
  getActionsColumn,
  getDragHandleColumn,
  getVisibilityColumn,
} from './helpers'
import { TableBody, TableContainer, TableFooter, TableHead } from '.'

declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    loading?: boolean | ((table: Table<TData>) => boolean)
    pageIndex: number
    pageSize: number
    pageCount: number
    columnCount: number
    onHide?: (props: CellContext<TData, unknown>, checked: boolean) => void
    onEdit?: (props: CellContext<TData, unknown>) => void
    onDelete?: (props: CellContext<TData, unknown>) => void
    onRowClick?: (row: Row<TData>) => void
    sortModel?: SortItem[] | null
    onSortModelChange?: (sortModel: SortItem[] | null) => void
    disablePagination: boolean
    disableSort?: boolean
    manualSorting?: boolean
    onCopy?: (props: CellContext<TData, unknown>) => void
    useDragAndDrop: boolean
    useColumnResizing: boolean
    rowHoverCursor: CSSProperties['cursor']
    fetchNextPage?: () => void
    sourceData: TData[]
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    flex?: number
    showDivider?: boolean
  }
}

export type SortItem = { field: string; sort: 'asc' | 'desc' | null | undefined }

type ReactTableProps<T> = {
  columns: ColumnDef<T>[]
  data: T[]
  pageIndex?: number
  pageSize?: number
  pageCount?: number
  onPaginationChange?: (pagination: PaginationState) => void
  manualPagination?: boolean
  disablePagination?: boolean
  loading?: boolean | ((table: Table<T>) => boolean)
  disableSort?: boolean
  useDragAndDrop?: boolean
  useColumnResizing?: boolean
  manualSorting?: boolean
  onHide?: (props: CellContext<T, unknown>, checked: boolean) => void
  onEdit?: (props: CellContext<T, unknown>) => void
  onDelete?: (props: CellContext<T, unknown>) => void
  onRowClick?: (row: Row<T>) => void
  sortModel?: SortItem[] | null
  onDropChange?: (rows: T[]) => T[]
  onSortModelChange?: (sortModel: SortItem[] | null) => void
  rowHoverCursor?: CSSProperties['cursor']
  onCopy?: (props: CellContext<T, unknown>) => void
  showActionsColumn?: boolean // pay attention - this is not in meta since we don't need to pass it
  showVisibilityColumn?: boolean
  disabledVisibilityColumn?: boolean
  fetchNextPage?: () => void
  width?: number
  globalFilter?: string
  setGlobalFilter?: (value: string) => void
}

const AutoSizedReactTable = <T,>({
  columns: sourceColumns,
  data,
  pageIndex = 0,
  pageSize = 5,
  pageCount = 0,
  onPaginationChange,
  manualPagination = true,
  disablePagination = false,
  loading = false,
  onEdit,
  onDelete,
  onRowClick,
  sortModel = [],
  onSortModelChange,
  rowHoverCursor = 'default',
  showActionsColumn = false,
  showVisibilityColumn = false,
  onDropChange,
  useDragAndDrop = false,
  useColumnResizing = false,
  onCopy,
  onHide,
  disableSort,
  manualSorting = false,
  fetchNextPage,
  width = 600,
  disabledVisibilityColumn = false,
  globalFilter,
  setGlobalFilter,
}: ReactTableProps<T>) => {
  const [state, setState] = useState(data)

  useEffect(() => {
    if (data) {
      setState(data)
    }
  }, [data])

  const outerPagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const [innerPagination, setInnerPagination] = useState<PaginationState>({ pageSize, pageIndex })
  const [innerSorting, setInnerSorting] = useState<SortingState>([])

  useEffect(() => {
    onPaginationChange?.(innerPagination)
  }, [innerPagination])

  // TODO костыль
  const scrollBarOffset = 17
  const columns = useMemo(
    () => [
      ...calculatedColumnsWidth(
        [
          ...getDragHandleColumn<T>(useDragAndDrop),
          ...getVisibilityColumn<T>(showVisibilityColumn, disabledVisibilityColumn),
          ...sourceColumns,
          ...getActionsColumn<T>(showActionsColumn),
        ],
        width - scrollBarOffset
      ),
    ],
    [sourceColumns, width, showActionsColumn, useDragAndDrop]
  )

  const getPaginationOptions = (): Pick<
    Table<T>['options'],
    'pageCount' | 'onPaginationChange' | 'manualPagination' | 'getPaginationRowModel'
  > => {
    if (disablePagination) {
      return {}
    }

    return manualPagination
      ? {
          pageCount: pageCount ?? -1,
          onPaginationChange: setInnerPagination,
          manualPagination,
        }
      : {
          getPaginationRowModel: getPaginationRowModel(),
        }
  }

  const getSortingOptions = (): Pick<
    Table<T>['options'],
    'onSortingChange' | 'getSortedRowModel'
  > => {
    if (disableSort) {
      return {}
    }

    return manualSorting
      ? {}
      : {
          onSortingChange: setInnerSorting,
          getSortedRowModel: getSortedRowModel(),
        }
  }

  const getSortingState = (): Pick<Table<T>['options']['state'], 'sorting'> => {
    return !disableSort && manualSorting ? {} : { sorting: innerSorting }
  }

  const getPaginationState = (): Pick<Table<T>['options']['state'], 'pagination'> => {
    return !disablePagination && manualPagination ? { pagination: outerPagination } : {}
  }

  const fuzzyFilter: FilterFn<any> = (row, columnId, value): boolean => {
    const itemRank = rankItem(row.getValue(columnId), value)

    return itemRank.rank >= 3 ? itemRank.passed : false
  }

  const table = useReactTable<T>({
    data: state,
    columns,
    state: {
      ...getPaginationState(),
      ...getSortingState(),
      globalFilter,
    },
    ...getPaginationOptions(),
    ...getSortingOptions(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    meta: {
      loading,
      pageIndex,
      pageSize,
      pageCount,
      onEdit,
      onDelete,
      onRowClick,
      columnCount: columns.length,
      onSortModelChange,
      disablePagination,
      rowHoverCursor,
      disableSort,
      onCopy,
      onHide,
      useDragAndDrop,
      useColumnResizing,
      fetchNextPage,
      sourceData: data,
    },
  })

  const handleSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (onDropChange) {
      setState(prev => onDropChange?.(arrayMove(prev, oldIndex, newIndex)))

      return
    }
    setState(prev => arrayMove(prev, oldIndex, newIndex))
  }

  return (
    <Box width={'100%'}>
      <TableContainer sx={{ width }} table={table}>
        <MUITable
          component='div'
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <TableHead table={table} />
          <TableBody table={table} useDragHandle={!!useDragAndDrop} onSortEnd={handleSortEnd} />
        </MUITable>
      </TableContainer>
      <TableFooter table={table} width={width} />
    </Box>
  )
}

export const ReactTable = <T,>({ ...props }: ReactTableProps<T>) => {
  return (
    <AutoSizer disableHeight>
      {({ width }) => <AutoSizedReactTable<T> {...props} width={props.width ?? width} />}
    </AutoSizer>
  )
}
