import { ItemId, TreeData, TreeItem } from '@atlaskit/tree'

import { OPERATION_TYPE } from '@constants'
import { OptionType } from '@types'

import { TreeBuilder, TreeItemOption } from '../features/TreeBuilder'

const menuTransformation = (
  obj: TreeBuilder,
  options: TreeItemOption[],
  parentItem?: TreeItemOption
) => {
  let parent: null | TreeBuilder = null

  if (parentItem) {
    parent = new TreeBuilder(parentItem.id, parentItem)
  }

  options.forEach(item => {
    if (!parentItem && !item.childrenMenu?.length) {
      obj.withLeaf(item.id, item)

      return
    }

    if (parent) {
      parent.withLeaf(item.id, item)

      if (item.childrenMenu?.length) {
        menuTransformation(parent, item.childrenMenu, item)
      }

      obj.withSubTree(parent)
    }

    if (item.childrenMenu?.length && !parentItem) {
      menuTransformation(obj, item.childrenMenu, item)
    }
  })
}

export const getTree = (options: TreeItemOption[]): TreeData => {
  const tree = new TreeBuilder(0, { id: 0, title: 'tree' })
  menuTransformation(tree, options)

  return tree.build()
}

// Преобразование дерева items в пункты меню
export const transformTreeToMenu = <T>(
  newTree: TreeData,
  isMutation?: boolean,
  operation?: OPERATION_TYPE
): T[] => {
  const treeItems = newTree.items
  const copyTree = []

  // Получение data из tree item без options и title
  const getTreeItemData = (itemId: ItemId) => {
    return treeItems[itemId]
  }
  const result: T[] = []

  // Рекурсивное создание пунктов меню на основе children каждого tree item
  const createOptions = (itemId: ItemId) => {
    const {
      children,
      hasChildren,
      data: { options },
    } = treeItems[itemId]

    if (!hasChildren && !options) {
      return []
    }

    children.forEach(childrenId => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        data: { viewCode, viewType, viewTitle, parentId, ...itemData },
        id,
      } = getTreeItemData(childrenId)

      if (childrenId) {
        createOptions(childrenId)
      }

      result.push({
        ...(operation && { operationType: operation }),
        ...itemData,
        order: parentId
          ? treeItems[parentId]?.children.findIndex(value => value === id)
          : treeItems[0].children.findIndex(value => value === id),
      })
    })
  }

  createOptions(newTree.rootId)

  return result
}

export const transformTreeToMenuForGroups = (
  newTree: TreeData,
  isMutation?: boolean
): OptionType[] => {
  const treeItems = newTree.items

  // Получение data из tree item без options и title
  const getTreeItemData = (itemId: ItemId): TreeItem => {
    return treeItems[itemId]
  }

  // Рекурсивное создание пунктов меню на основе children каждого tree item
  const createOptions = (itemId: ItemId): OptionType[] => {
    const {
      children,
      hasChildren,
      data: { options },
    } = treeItems[itemId]

    if (!hasChildren && !options) {
      return []
    }

    return children.map(childrenId => {
      const {
        data: { viewCode, viewType, viewTitle, parentId, treeItemParentId, order, ...itemData },
        isExpanded,
      } = getTreeItemData(childrenId)

      return {
        ...itemData,
        childrenMenu: createOptions(childrenId),
        ...(isMutation && { expanded: isExpanded }),
      }
    })
  }

  return createOptions(newTree.rootId) || []
}
