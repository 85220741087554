import { MutableRefObject, useEffect } from 'react'
import { GridApiPro } from '@microservices/wiskey-react-components'

export const useScrollDialogContainer = ({
  dialogId,
  apiRef,
  onScrollDraggingDialogContainer,
}: {
  dialogId?: string
  apiRef: MutableRefObject<GridApiPro>
  onScrollDraggingDialogContainer: (event?: Event) => void
}) => {
  useEffect(() => {
    if (!dialogId) return

    const container = document.getElementById(`disable-dragging-container_${dialogId}`)

    if (container && apiRef.current) {
      container.addEventListener('scroll', onScrollDraggingDialogContainer)
    }

    return () => container?.removeEventListener('scroll', onScrollDraggingDialogContainer)
  }, [apiRef])
}
