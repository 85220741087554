import { useMemo } from 'react'

import { ENTITY_COMMAND_TYPE } from '@types'

export const useCommandTypeOptions = () => {
  return useMemo(
    () => Object.values(ENTITY_COMMAND_TYPE).map(type => ({ id: type, label: type })),
    []
  )
}
