import { RouteObject } from 'react-router-dom'
import { ENTITY, ENTITY_PATH, ROUTES } from '@constants'

import { EntityCreateOrEdit } from '@pages/EntityCreateOrEdit'
import { EntityConfiguration } from '@pages/EntityCreateOrEdit/components/EntityConfiguration'

export const generateEntityChildrenRoutes = (type: ENTITY): RouteObject[] => {
  return [
    {
      element: <EntityCreateOrEdit type={type} />,
      path: ROUTES[`CONFIG_${ENTITY_PATH[type]}_CREATE`],
      children: [
        {
          element: <EntityConfiguration type={type} />,
          index: true,
        },
      ],
    },
    {
      element: <EntityCreateOrEdit type={type} />,
      path: ROUTES[`CONFIG_${ENTITY_PATH[type]}_EDIT`],
      children: [
        {
          element: <EntityConfiguration type={type} />,
          index: true,
          path: ROUTES[`CONFIG_${ENTITY_PATH[type]}_EDIT_CODE`],
        },
      ],
    },
  ]
}
