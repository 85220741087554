import { FC, useEffect, useRef, useState } from 'react'
import { Rnd, RndResizeCallback } from 'react-rnd'
import { FORM_CONTAINER_TAB_ID, MIN_HEIGHT_FIELD_DIALOG_WITH_LABEL } from '@constants'
import { Box } from '@mui/material'

import { FormDataEnriched, FormTab } from '../../../../types'
import { LinesWithElements } from '../LinesWithElements/LinesWithElements'

type ContainerTabPanelProps = {
  tab: FormTab<FormDataEnriched>
  initialHeight: number
  tabHeight: number
  countListControls: number
  index: number
}

export const ContainerTabPanel: FC<ContainerTabPanelProps> = ({
  tab,
  initialHeight,
  tabHeight,
  countListControls,
}) => {
  const [resizeHeightTab, setResizeHeightTab] = useState(initialHeight)
  const tabRef = useRef()

  const handleResizeStop: RndResizeCallback = (_e, _direction, ref) => {
    const result = parseInt(ref.style.height, 10)

    if (result >= tabHeight) {
      setResizeHeightTab(result)
    }
  }

  useEffect(() => {
    if (tabRef.current && tabRef.current.scrollTop !== 0) {
      tabRef.current.scrollTop = 0
    }
  }, [tabRef.current])

  const countLinesWithoutLCs =
    tab.lines.length - 1 - countListControls < 0 ? 1 : tab.lines.length - countListControls

  const containerHeightForLCs =
    (resizeHeightTab - countLinesWithoutLCs * MIN_HEIGHT_FIELD_DIALOG_WITH_LABEL) /
    countListControls

  return (
    <Rnd
      disableDragging
      enableResizing={{ bottom: true }}
      minHeight={tabHeight}
      resizeHandleStyles={{ bottom: { bottom: -10 } }}
      size={{ height: resizeHeightTab, width: '100%' }}
      style={{
        position: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        height: `${resizeHeightTab}px`,
        width: '100%',
      }}
      onResizeStop={handleResizeStop}
    >
      <Box
        ref={tabRef}
        id={`${FORM_CONTAINER_TAB_ID}_${tab.id}`}
        sx={{ m: 0, p: 2, minHeight: resizeHeightTab, overflowY: 'auto' }}
      >
        <LinesWithElements
          heigthForListControl={containerHeightForLCs}
          lines={tab.lines}
          tabId={tab.id}
        />
      </Box>
    </Rnd>
  )
}
