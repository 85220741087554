import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const numberRegex = /^\d+$/

/**
 * @param search нужен на случай, если необходимо прокинуть общие search params, чтобы не было конфликтов между хуками.
 * @returns
 */
export const usePagination = (search?: URLSearchParams) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [firstPage] = useState(1)
  const searchParams = search ?? new URLSearchParams(location.search)
  const pageParam = searchParams.get('page')

  const navigateToPage = (page: number) => {
    searchParams.set('page', `${page + 1}`)
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  const getPage = () => {
    return pageParam ? parseInt(pageParam) - 1 : 0
  }

  useEffect(() => {
    if (!pageParam) {
      navigateToPage(0)

      return
    }

    if (!numberRegex.test(pageParam)) {
      navigateToPage(0)

      return
    }

    const pageNumber = parseInt(pageParam) - 1

    if (pageNumber !== firstPage - 1) {
      navigateToPage(pageNumber)
    }
  }, [location.search, pageParam])

  return {
    page: getPage(),
    setPage: navigateToPage,
  }
}
