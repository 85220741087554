import { FC, useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CONFIG_COLUMNS, GENERATOR_INPUT_TYPE, MODAL_TYPE } from '@constants'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Box, Grid } from '@mui/material'
import { AutocompleteOption } from '@types'

import { PageContext } from '@pages/ConfigEntity'

import { EntityOrFormList } from '@components/EntityOrFormList'

type FormValues = {
  searchPattern: string
  objectCode: AutocompleteOption | null
}

const defaultValues = {
  searchPattern: '',
  objectCode: null,
}

export const AllEntities: FC = () => {
  const methods = useForm<FormValues>({ defaultValues })
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    entitiesTables,
    viewsIsLoading,
    onDeleteEntity,
    onShowDialog,
    onEditEntity,
    onSetSorting,
    currentSort,
    objects,
    onSetObjectCode,
    onSetSearchPattern,
    type,
  } = useContext(PageContext)

  const { watch } = methods
  const watchObjectCode = watch('objectCode')
  const watchSearchPattern = watch('searchPattern')

  useEffect(() => {
    onSetObjectCode(watchObjectCode !== null ? (watchObjectCode.id as string) : '')
  }, [watchObjectCode])

  useEffect(() => {
    onSetSearchPattern(watchSearchPattern)
  }, [watchSearchPattern])

  return (
    <>
      <Grid container item alignItems='center' flexWrap='nowrap' gap={1}>
        <Grid item>
          <Button sx={{ mb: 1 }} variant={'contained'} onClick={() => navigate(MODAL_TYPE.CREATE)}>
            {t(`configEntity.${type}.addButton`)}
          </Button>
        </Grid>
        <Grid item flexGrow={1}>
          <FormProvider {...methods}>
            <form>
              <Box>
                <Grid container alignItems='center' justifyContent='center' spacing={0.5}>
                  <Grid item sm={6} xs={6}>
                    <FormInput
                      inputType={GENERATOR_INPUT_TYPE.INPUT}
                      name='searchPattern'
                      placeholder={t(`configEntity.${type}.search`)}
                      size='small'
                    />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <FormInput
                      inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                      name='objectCode'
                      placeholder={t(`configEntity.${type}.object`)}
                      size='small'
                      sx={{ maxWidth: '100%' }}
                      autocompleteOptions={objects?.map(obj => ({
                        id: obj.code,
                        label: obj.title,
                      }))}
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <EntityOrFormList
        columns={CONFIG_COLUMNS}
        currentSort={currentSort}
        loading={viewsIsLoading}
        rows={entitiesTables}
        type={type}
        onCopy={onShowDialog}
        onDelete={onDeleteEntity}
        onEdit={onEditEntity}
        onSetSorting={onSetSorting}
      />
    </>
  )
}
