import { ContentCopy, Delete, Edit } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { CellContext } from '@tanstack/react-table'

type TableActionsCellProps<T> = {
  onEdit?: (props: CellContext<T, unknown>) => void
  onDelete?: (props: CellContext<T, unknown>) => void
  onCopy?: (props: CellContext<T, unknown>) => void
  justifyContent?: string
} & CellContext<T, unknown>

export const TableActionsCell = <T,>({ justifyContent, ...props }: TableActionsCellProps<T>) => {
  const meta = props.table.options.meta
  const onEdit = meta?.onEdit
  const onCopy = meta?.onCopy
  const onDelete = meta?.onDelete

  return (
    <Box sx={{ display: 'flex', justifyContent: justifyContent, alignItems: 'center', height: 52 }}>
      {onCopy && (
        <IconButton
          size={'small'}
          onClick={event => {
            event.stopPropagation()
            onCopy(props)
          }}
        >
          <ContentCopy fontSize={'small'} />
        </IconButton>
      )}
      <IconButton
        size={'small'}
        onClick={event => {
          event.stopPropagation()
          onEdit?.(props)
        }}
      >
        <Edit fontSize={'small'} />
      </IconButton>
      <IconButton
        size={'small'}
        onClick={event => {
          event.stopPropagation()
          onDelete?.(props)
        }}
      >
        <Delete fontSize={'small'} />
      </IconButton>
    </Box>
  )
}
