import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Remove } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption } from '@types'

import { ModalRefPathPicker } from '@gantt/components/GanttCreateOrEdit/components/ModalRefPathPicker'
import { ConfigField } from '@gantt/components/GanttCreateOrEdit/types'

export type Props = {
  field: Record<'id', string>
  index: number
  watchedObject: AutocompleteOption<string> | null
  isHiddenRemove?: boolean
  watchValue: ConfigField
  controllerNameField: string
  controllerNamePathStr: string
  controllerNamePathArray: string
  onRemove: (index: number) => void
  onSaveModalRefPick?: () => void
}

export const VariableItem = ({
  field,
  index,
  watchedObject,
  onRemove,
  onSaveModalRefPick,
  watchValue,
  controllerNameField,
  controllerNamePathStr,
  controllerNamePathArray,
  isHiddenRemove = false,
}: Props) => {
  const { t } = useTranslation()
  const [initialValue, setInitialValue] = useState<ConfigField>()

  useEffect(() => {
    if (!initialValue?.pathStr) {
      const clonedValue = structuredClone(watchValue)
      setInitialValue(clonedValue)
    }

    return () => setInitialValue(undefined)
  }, [watchValue?.pathStr])

  const handleRemoveItem = (index: number) => {
    onRemove(index)
  }

  return (
    <>
      <Grid key={field.id} container columnSpacing={4} xs={12}>
        <Grid item xs={5.5}>
          <FormInput
            inputType={GENERATOR_INPUT_TYPE.INPUT}
            name={controllerNameField}
            placeholder={i18next.t('placeholder.variable')}
          />
        </Grid>
        <Grid item xs={6}>
          <ModalRefPathPicker
            hasClearInInput
            currentValue={initialValue}
            embeddedObjectPickerControllerName={controllerNamePathArray}
            isDisabled={!watchedObject}
            name={controllerNamePathStr}
            watchedObject={watchedObject}
            onSave={onSaveModalRefPick}
          />
        </Grid>
        {!isHiddenRemove && (
          <Grid xs={0.5}>
            <Button onClick={() => handleRemoveItem(index)}>
              <Remove fontSize={'small'} />
              {t('ganttCreate.common.remove')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}
