import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  useCopyVariableMutation,
  useCreateVariableMutation,
  useDeleteVariableMutation,
  useFetchAllVariablesQuery,
  useFetchVariableByIdQuery,
  useUpdateVariableMutation,
} from '@redux/api/variables.api'

import { useDebounce } from '@hooks'
import {
  MODAL_TYPE,
  ROUTES,
  SEARCH_PATTERN_DELAY,
  VALUE_TYPE_OPTIONS,
  VARIABLE_TYPE_OPTIONS,
} from '@constants'
import { POSTCopyVariable } from '@types'

import { VariableForm, VariableRowType } from '../types'

export const useVariables = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation() as unknown as Location & { state?: { id?: string } }
  const { pathname } = useLocation()
  const { id } = useParams()
  const isCreate = ROUTES.VARIABLES_CREATE === location.pathname
  const isEdit = !!id

  const methods = useForm<VariableForm>({
    defaultValues: {
      id: undefined,
      searchPattern: undefined,
      internalId: undefined,
      title: undefined,
      variableType: null,
      valueType: null,
    },
  })
  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = methods
  const searchPattern = watch('searchPattern')
  const debouncedSearchPattern = useDebounce(searchPattern, SEARCH_PATTERN_DELAY)

  const isAllVariables = matchPath('/variables', pathname)

  const [allVariables, setAllVariables] = useState<VariableRowType[]>([])
  const [showCopyDialog, setShowCopyDialog] = useState<boolean>(false)
  const [copyVariableData, setCopyVariableData] = useState<POSTCopyVariable | null>(null)

  const { data: variablesData, isFetching: isLoadingVariables } = useFetchAllVariablesQuery(
    { searchPattern: debouncedSearchPattern ?? undefined },
    {
      skip: isEdit,
    }
  )

  const { data: variablesDataById, isFetching: isLoadingVariablesById } = useFetchVariableByIdQuery(
    Number(id),
    {
      skip: isCreate || Boolean(isAllVariables),
    }
  )

  const [createVariable] = useCreateVariableMutation()
  const [updateVariable] = useUpdateVariableMutation()
  const [copyVariable, { isLoading: isLoadingCopyVariable }] = useCopyVariableMutation()
  const [deleteVariable] = useDeleteVariableMutation()

  const handleCreateVariable = () => navigate(MODAL_TYPE.CREATE)

  const handleEditVariable = (id: number) => navigate(`${ROUTES.VARIABLES_EDIT}/${id}`)

  const handleCopyVariable = (data: POSTCopyVariable) => {
    const { internalId, title, variableId } = data

    copyVariable({ internalId, title, variableId })
      .unwrap()
      .then(() => setShowCopyDialog(false))
  }

  const handleDeleteVariable = (id: number) => deleteVariable(id)

  const handleShowDialog = (data: POSTCopyVariable) => {
    setCopyVariableData(data)
    setShowCopyDialog(true)
  }

  const handleCloseDialog = () => {
    setCopyVariableData(null)
    setShowCopyDialog(false)
  }

  const handleSave = handleSubmit((variableData: any) => {
    const data = {
      id: variableData.id ?? null,
      internalId: variableData.internalId,
      title: variableData.title,
      variableType: variableData.variableType.id,
      valueType: variableData.valueType.id,
    }

    if (variableData.id) {
      updateVariable(data)
    } else {
      createVariable(data)
        .unwrap()
        .then(res => {
          navigate(`${ROUTES.VARIABLES_EDIT}/${res.id}`)
        })
    }
  })

  const handleCancel = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    navigate(ROUTES.VARIABLES)
  }

  useEffect(() => {
    if (variablesData) {
      setAllVariables(variablesData)
    }
  }, [variablesData])

  useEffect(() => {
    if (variablesDataById) {
      reset({
        id: variablesDataById.id,
        internalId: variablesDataById.internalId,
        title: variablesDataById.title,
        variableType: VARIABLE_TYPE_OPTIONS.find(opt => opt.id === variablesDataById.variableType),
        valueType: VALUE_TYPE_OPTIONS.find(opt => opt.id === variablesDataById.valueType),
        searchPattern: undefined,
      })
    }
  }, [variablesDataById])

  return {
    data: {
      methods,
      isDirty,
      isEdit,
      isAllVariables,
      allVariables,
      isLoadingVariables,
      isLoadingVariablesById,
      showCopyDialog,
      copyVariableData,
      isLoadingCopyVariable,
    },
    handlers: {
      handleCreateVariable,
      handleEditVariable,
      handleShowDialog,
      handleCloseDialog,
      handleCopyVariable,
      handleDeleteVariable,
      handleSave,
      handleCancel,
    },
  }
}
