import { v4 as uuidv4 } from 'uuid'

import { ObjectDataRecord } from '../../../types'

// TODO: Костыль. Нужно переходить на курсорную пагинацию
export const getTransformResponse = (data: ObjectDataRecord[]) =>
  data.map(object => ({
    ...object,
    id: uuidv4(),
  }))
