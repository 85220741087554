import { FC, ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Paper, SxProps } from '@mui/material'

import { ErrorFallback } from '@components/ErrorFallback'

type PageContentLayoutProps = {
  children: ReactNode
  hidden?: boolean
  isBreadcrumbs?: boolean
  height?: number
  sx?: SxProps
}

export const PageContentLayout: FC<PageContentLayoutProps> = ({
  children,
  hidden,
  isBreadcrumbs,
  sx,
  ...props
}) => {
  if (hidden) {
    return <>{children}</>
  }

  const height = props.height || (isBreadcrumbs ? 'calc(100% - 32px)' : '100%')

  return (
    <Paper
      sx={{ height, borderRadius: 0, border: theme => theme.palette.border.paper, ...sx }}
      variant='outlined'
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
    </Paper>
  )
}
