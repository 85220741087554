import { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useFetchAllFormQuery, useFetchObjectByCodeNoCacheMutation } from '@redux/api'

import { usePrompt } from '@hooks'
import { MODEL_TYPE } from '@constants'
import { SelectOption } from '@types'

import {
  AutocompleteOption,
  GANTT_BIND_TYPE_LIST,
  GanttActionType,
  PageContext,
} from '@gantt/components/GanttCreateOrEdit'
import {
  getCommandsFormattedRequest,
  getCommandsFormattedResponse,
  getOptionByStr,
} from '@gantt/helpers'

import { getDefaultValue } from '../helpers'

import { useInputs } from './useInputs'

export const useAddGanttActionTimelinesDialog = () => {
  const defaultValues = getDefaultValue()
  const methods = useForm<GanttActionType>({
    defaultValues,
  })
  const [isLoading] = useState(true)
  const [key, setKey] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
  } = methods

  const {
    currentRow,
    onSetModalProperty,
    bindingValuesTimelineAction,
    bindingValuesActionTimelineEvents,
    onSetCurrentActionTimeline,
    currentSectionsList,
    currentBarObject,
  } = useContext(PageContext)

  const [fetchObjectByCode, { data: object }] = useFetchObjectByCodeNoCacheMutation({})

  const watchEventCode = watch('eventCode')
  const watchActionCode = watch('actionCode')

  const objectCode = currentBarObject?.id

  const {
    data: forms,
    isFetching: isFetchingAllForm,
    isLoading: isLoadingAllForm,
  } = useFetchAllFormQuery(
    { objectCode },
    {
      skip: !currentBarObject?.id,
    }
  )

  const loadingAllForm = isFetchingAllForm || isLoadingAllForm

  usePrompt({ when: isDirty })

  useEffect(() => {
    if (currentBarObject?.id) {
      fetchObjectByCode({ code: currentBarObject?.id })
    }
  }, [currentBarObject?.id])

  const formCodeOptions: AutocompleteOption[] = useMemo(
    () =>
      forms?.data.map((form: { code: string; title: string }) => ({
        id: form.code,
        label: form.title,
      })) ?? [],
    [forms]
  )

  const actionTypeOptions: SelectOption[] = useMemo(
    () =>
      bindingValuesTimelineAction?.map(cat => ({
        id: cat.id,
        name: cat.code,
      })) || [],
    [bindingValuesTimelineAction]
  )

  const eventOptions: SelectOption[] = useMemo(
    () =>
      bindingValuesActionTimelineEvents?.map(cat => ({
        id: cat.id,
        name: cat.code,
      })) || [],
    [bindingValuesActionTimelineEvents]
  )

  const targetOptions: SelectOption[] = useMemo(
    () => currentSectionsList?.map(section => getOptionByStr(section.link.systemName)),
    [currentSectionsList]
  )

  const actionInputs = useInputs({
    actionTypeOptions,
    eventOptions,
    watchEventCode,
    targetOptions,
    methods,
    watchActionCode,
  })

  useEffect(() => {
    onSetModalProperty?.(MODEL_TYPE.GANTT_ACTION_TIMELINES)
  }, [])

  useEffect(() => {
    setKey(!key)
  }, [])

  useEffect(() => {
    if (currentRow && !loadingAllForm && actionTypeOptions.length && eventOptions.length) {
      const currentActionRow = structuredClone(currentRow)
      const preparedActionField =
        currentActionRow.actionField.bindType === GANTT_BIND_TYPE_LIST.COMMANDS
          ? {
              ...currentActionRow.actionField,
              commands: getCommandsFormattedResponse(currentActionRow.actionField.commands),
            }
          : currentActionRow.actionField
      currentActionRow.actionField = preparedActionField
      reset(currentActionRow)

      setKey(!key)
    }
  }, [currentRow, loadingAllForm, formCodeOptions, actionTypeOptions, eventOptions])

  const handleSave = handleSubmit((data: GanttActionType) => {
    const { id = Date.now(), code, title, actionCode, eventCode, target, actionField } = data
    const preparedActionField =
      actionField.bindType === GANTT_BIND_TYPE_LIST.COMMANDS
        ? {
            ...actionField,
            commands: getCommandsFormattedRequest(actionField.commands),
          }
        : actionField

    const newData: GanttActionType = {
      id,
      code,
      title,
      actionCode,
      eventCode,
      target,
      actionField: preparedActionField,
    }
    onSetCurrentActionTimeline(newData)
  })

  return {
    isDirty,
    actionInputs,
    methods,
    isLoading,
    handleSave,
    key,
    loadingAllForm,
    watchActionCode,
    formCodeOptions,
  }
}
