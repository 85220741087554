import { EVENT_NAME_OBJECT_MESSAGE } from '@constants'
import { deepCloneObject } from '@helpers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChangedViewRowsState, DeleteRowsPayload, SetViewRowsPayload } from '../../types'

const initialState: ChangedViewRowsState = {
  objectsWithDirtyRecords: {},
}
const changedViewRows = createSlice({
  name: 'changedViewRows',
  initialState,
  reducers: {
    setViewRows(state, action: PayloadAction<SetViewRowsPayload>) {
      const { changedFields, objectCode, recordId, eventName } = action.payload
      const { objectsWithDirtyRecords } = state
      const objectByCode = objectsWithDirtyRecords[objectCode]

      objectsWithDirtyRecords[objectCode] = {
        ...objectByCode,
        [recordId]: eventName === EVENT_NAME_OBJECT_MESSAGE.UPDATE ? changedFields : eventName,
      }
    },
    deleteViewRow(state, action: PayloadAction<DeleteRowsPayload>) {
      const { objectCode, recordId } = action.payload

      // TODO: structuredClone
      const copyStateObjects = deepCloneObject(state.objectsWithDirtyRecords)

      if (copyStateObjects[objectCode]) {
        delete copyStateObjects[objectCode][recordId]

        state.objectsWithDirtyRecords = copyStateObjects
      }
    },
  },
})

export default changedViewRows.reducer
export const { setViewRows, deleteViewRow } = changedViewRows.actions
