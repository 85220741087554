import { useCallback, useEffect } from 'react'
import { ActionData, DataXKeys } from '@microservices/gantt-react-component'

import {
  useMoveGanttBacklogBoxMutation,
  useMoveGanttBoxMutation,
  useResizeGanttBoxMutation,
} from '@redux/api/ganttChart.api'

import { dispatchSelfUpdate } from '../customEvent'
import { AxisDefinedData, GanttActionType } from '../types'

export const useMutateObject = () => {
  const [moveGantBacklogBox, moveBacklogBoxResponse] = useMoveGanttBacklogBoxMutation()
  const [resizeGantBox, resizeBoxResponse] = useResizeGanttBoxMutation()
  const [moveGantBox, moveBoxResponse] = useMoveGanttBoxMutation()

  type Response = typeof moveBacklogBoxResponse

  const isDone = (response: Response) =>
    response?.originalArgs && (response.isSuccess || response.isError)

  useEffect(() => {
    if (isDone(moveBacklogBoxResponse)) {
      dispatchSelfUpdate(moveBacklogBoxResponse.originalArgs)
    }
  }, [moveBacklogBoxResponse?.status])

  useEffect(() => {
    if (isDone(resizeBoxResponse)) {
      dispatchSelfUpdate(resizeBoxResponse.originalArgs)
    }
  }, [resizeBoxResponse?.status])

  useEffect(() => {
    if (isDone(moveBoxResponse)) {
      dispatchSelfUpdate(moveBoxResponse.originalArgs)
    }
  }, [moveBoxResponse?.status])

  return useCallback(
    (action: GanttActionType, actionData: ActionData, definedData: AxisDefinedData) => {
      const changes: Record<string, string | null | undefined> = {}

      Object.values(actionData.changes ?? {})?.forEach(
        item => item.pathStr && (changes[item.pathStr] = item.value)
      )

      const queryData = {
        ...definedData,
        changes,
      }

      if (action.eventCode.includes('move')) {
        if (actionData.metaData?.target === DataXKeys.TimelineData) {
          moveGantBox(queryData)
        } else {
          moveGantBacklogBox(queryData)
        }
      }

      if (Object.keys(changes).length > 1 || action.eventCode.includes('resize')) {
        resizeGantBox(queryData)
      }
    },
    []
  )
}
