import { useMemo } from 'react'

import { useFetchFormQuery } from '@redux/api'

import { FormRow } from '@types'

type UseFormFieldsOptions = {
  formCode: string
}

export const useFormFields = ({ formCode }: UseFormFieldsOptions): FormRow[] => {
  const { data: fetchedForm } = useFetchFormQuery(formCode, {
    skip: !formCode,
  })

  return useMemo(() => fetchedForm?.fields || [], [fetchedForm])
}
