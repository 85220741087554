import { useContext, useEffect, useRef } from 'react'
import isEqual from 'lodash.isequal'

import { useFetchAllViewQuery, useFetchContextViewsByIdQuery } from '@redux/api'

import { PageContext } from '../ContextCreateOrEdit'

export const useViews = () => {
  const { id, isCreate, methods } = useContext(PageContext)
  const { watch, setValue, getValues, reset, formState } = methods
  const searchView = watch('searchView')
  const selectedViews = watch('selectedViews')
  const unselectedViews = watch('unselectedViews')

  const refSearchView = useRef(null)

  const { data: contextViewsById, isLoading: isLoadingContextViewsById } =
    useFetchContextViewsByIdQuery(id, {
      skip: !id,
      refetchOnMountOrArgChange: true,
    })

  const { data: views, isLoading: isLoadingViews } = useFetchAllViewQuery(
    {},
    {
      skip: !isCreate,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (contextViewsById) {
      reset({
        ...getValues(),
        selectedViews: contextViewsById.selectedViews,
        unselectedViews: contextViewsById.unselectedViews,
      })
    }
  }, [contextViewsById])

  useEffect(() => {
    if (views) {
      reset({
        ...getValues(),
        selectedViews: [],
        unselectedViews: views.data,
      })
    }
  }, [views])

  useEffect(() => {
    if (unselectedViews && searchView?.id) {
      handleAddView(Number(searchView.id))
      setValue('searchView', null, { shouldDirty: false })
    }
  }, [searchView])

  const handleAddView = (viewId: number) => {
    const { selectedViews, unselectedViews } = getValues()
    const addedView = unselectedViews.find(view => view.id === viewId)
    const addedViews = addedView ? [addedView, ...selectedViews] : []
    const newUnselectedViews = unselectedViews.filter(view => view.id !== viewId)

    setValue('selectedViews', addedViews, {
      shouldDirty: !isEqual(addedViews, contextViewsById?.selectedViews),
    })
    setValue('unselectedViews', newUnselectedViews, { shouldDirty: true })
  }

  const handleDeleteView = (viewId: number) => {
    const { selectedViews, unselectedViews } = getValues()
    const removedView = selectedViews.find(view => view.id === viewId)
    const addedViews = selectedViews.filter(view => view.id !== viewId)
    const newUnselectedViews = removedView
      ? [...unselectedViews, removedView].sort((a, b) => b.id - a.id)
      : []

    setValue('selectedViews', addedViews, {
      shouldDirty: !isEqual(addedViews, contextViewsById?.selectedViews),
    })
    setValue('unselectedViews', newUnselectedViews, { shouldDirty: true })
  }

  return {
    data: {
      methods,
      isLoadingContextViewsById,
      isLoadingViews,
    },
    state: {
      selectedViews,
      unselectedViews,
      searchView,
      refSearchView,
    },
    handlers: {
      handleAddView,
      handleDeleteView,
    },
  }
}
