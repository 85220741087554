import { FC } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { AutocompleteOption, GETEntityFilter } from 'src/types'
import { FormGenerator } from '@microservices/wiskey-react-components'
import { FormInputsType } from '@microservices/wiskey-react-components/dist/types'
import { Button, Grid, SxProps, Theme } from '@mui/material'

import { ChipType, ScrollableChips } from '@components/ScrollableChips'

type DropDownInputWithFitlersProps = {
  filters: GETEntityFilter[]
  inputs: FormInputsType[]
  onAddFilter: () => void
  onDeleteFilter?: (filter: GETEntityFilter & ChipType, index: number) => void
  onDoubleClickFilter?: (filter: GETEntityFilter & ChipType, index: number) => void
  watchInput?: AutocompleteOption | string | null
  loading?: boolean
  sx?: SxProps<Theme>
}

export const DropDownInputWithFitlers: FC<DropDownInputWithFitlersProps> = ({
  filters,
  inputs,
  watchInput,
  loading,
  sx,
  onAddFilter,
  onDeleteFilter,
  onDoubleClickFilter,
}) => {
  return (
    <>
      <Grid container item columnSpacing={1} sx={sx}>
        <Grid item flex={1}>
          <FormGenerator inputs={inputs} loading={loading} mainGridSpacing={0} />
        </Grid>
        <Grid item>
          <Button size='large' variant='outlined' onClick={onAddFilter}>
            Add filter
          </Button>
        </Grid>
      </Grid>
      <Grid item ml={14}>
        {filters.length > 0 && (
          <AutoSizer disableHeight style={{ width: '100%', marginBottom: '8px' }}>
            {({ width }) => {
              return (
                <ScrollableChips
                  chips={filters}
                  width={width}
                  onDelete={onDeleteFilter}
                  onDoubleClick={onDoubleClickFilter}
                />
              )
            }}
          </AutoSizer>
        )}
      </Grid>
    </>
  )
}

export default DropDownInputWithFitlers
