import { FC, useEffect, useRef, useState } from 'react'
import { ItemId, RenderItemParams } from '@atlaskit/tree'
import { Add, Circle, Delete, Edit, KeyboardArrowDown } from '@mui/icons-material'
import { Box, Grid, IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'

import { usePaletteMode } from '@hooks'
import { useScrollToItem } from '@hooks'
import { MENU_POINT_TYPES } from '@constants'
import { TreeItemMenuPointOptionType } from '@types'

type ItemProps = Omit<RenderItemParams, 'item'> & {
  item: TreeItemMenuPointOptionType
  onDeleteTreeItem: (itemTreeId: ItemId, itemId?: number) => void
  onEditTreeItem?: (treeItem: TreeItemMenuPointOptionType) => void
  onAddTreeItem?: (itemTreeId: ItemId, itemId?: number) => void
}

export const Item: FC<ItemProps> = ({
  item,
  onCollapse,
  onExpand,
  provided,
  onDeleteTreeItem,
  onEditTreeItem,
  onAddTreeItem,
  snapshot,
}) => {
  const mode = usePaletteMode()

  const [showAddedItem, setShowAddedItem] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const collapseRef = useRef<HTMLDivElement>(null)
  const isDivider = item.data.menuPointType === MENU_POINT_TYPES.DIVIDER
  const isHeader = item.data.menuPointType === MENU_POINT_TYPES.HEADER

  useEffect(() => {
    setShowAddedItem(false)
  }, [item])

  const { scrollToItem } = useScrollToItem({ ref: collapseRef, isScroll: showAddedItem })

  const handleAddClick = () => {
    onAddTreeItem?.(item.id)
    handleShowItem(true)
    if (showAddedItem) {
      scrollToItem()
    }
  }

  const handleShowItem = (value: boolean) => {
    setIsEdit(false)
    setShowAddedItem(value)
  }

  const handleEditItem = () => {
    onEditTreeItem?.(item)
    setIsEdit(true)
    setShowAddedItem(true)
  }

  const handleOpen = (): void => {
    if (item.isExpanded) {
      onCollapse(item.id)

      return
    }
    onExpand(item.id)
  }

  const handleDeleteitem = (): void => {
    onDeleteTreeItem(item.id, item.data.id)
  }

  return (
    <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{ py: 0 }}>
      <Box
        {...provided.dragHandleProps}
        sx={{
          transition: '0.1s',
          width: '100%',
          ...(snapshot.isDragging && {
            backgroundColor: mode === 'light' ? grey[300] : blueGrey[700],
          }),
        }}
      >
        <Grid container direction={'column'}>
          <Grid container item alignItems={'center'} sx={{ mb: 0, p: 1 }}>
            <Grid
              container
              alignItems={'center'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              wrap='nowrap'
            >
              <Grid
                container
                item
                alignItems={'center'}
                flex='100%'
                flexDirection={'row'}
                flexWrap={'nowrap'}
                maxWidth={'calc(100% - 110px)'}
              >
                <ListItemIcon sx={{ minWidth: 'fit-content', mr: 1 }}>
                  {item.hasChildren ? (
                    <KeyboardArrowDown
                      sx={
                        item.isExpanded
                          ? { cursor: 'pointer' }
                          : { cursor: 'pointer', transform: 'rotate(-90deg)' }
                      }
                      onClick={handleOpen}
                    />
                  ) : (
                    <Circle sx={{ fontSize: 6, ml: 1, mr: 1 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item.data?.title}
                  secondary={!item.hasChildren ? item.data?.valueTitle : null}
                  sx={{
                    ml: 1,
                    '& .MuiListItemText-primary': {
                      maxWidth: 'fit-content',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </Grid>
              <Grid container item flexWrap={'nowrap'} justifyContent={'flex-end'} width={'auto'}>
                {!isDivider && !isHeader && (
                  <IconButton onClick={handleAddClick}>
                    <ListItemIcon sx={{ minWidth: 'fit-content' }}>
                      <Add fontSize='small' />
                    </ListItemIcon>
                  </IconButton>
                )}

                <IconButton onClick={handleEditItem}>
                  <ListItemIcon sx={{ minWidth: 'fit-content' }}>
                    <Edit fontSize='small' />
                  </ListItemIcon>
                </IconButton>
                <IconButton onClick={handleDeleteitem}>
                  <ListItemIcon sx={{ minWidth: 'fit-content' }}>
                    <Delete fontSize='small' />
                  </ListItemIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  )
}
