import { ELEMENT_TYPE_PANEL } from '@constants'
import { UpdatedElementType } from '@types'

import { ControlPanelLineForm } from '../types'

export const transformUpdatedElementsForRequest = (
  elements: UpdatedElementType[],
  lines: ControlPanelLineForm[],
  panelId?: number
) => {
  return elements.map(({ treeItemId, lineIndex, treeItemParentId, ...element }) => {
    if (element.type === ELEMENT_TYPE_PANEL.ITEM && lineIndex !== undefined && element.data) {
      const { nullableParent, ...itemData } = element.data

      return {
        ...element,
        data: {
          ...itemData,
          panelId,
          order: lines[lineIndex].items.items[
            treeItemParentId || lines[lineIndex].items.rootId
          ].children.findIndex(value => value === treeItemId),
        },
      }
    }

    return element
  })
}
