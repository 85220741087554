import { useContext } from 'react'
import i18next from 'i18next'
import { FormInputProps } from '@microservices/wiskey-react-components'

import { generateDefaultObjectByFields } from '@helpers'
import {
  BIND_TYPE,
  FIELD_VALUE_TYPE,
  GENERATOR_INPUT_TYPE,
  MODAL_TYPE,
  PINNED_COLUMN,
  REGEX,
} from '@constants'
import { GETShareColumn, SelectOption } from '@types'

import { PageContext } from '@gantt/components/GanttCreateOrEdit'

type useInputsParams = {
  watchBinding: BIND_TYPE | undefined
  valueOptions: SelectOption[]
  bindTypeOptions: SelectOption[]
  valueType: FIELD_VALUE_TYPE | null
  isObjectLinkedValue: boolean
  objectValueOptions: SelectOption[]
  watchPinning: boolean
  currentRow?: GETShareColumn | null
}

export const useInputs = ({
  watchBinding,
  valueType,
  isObjectLinkedValue,
  objectValueOptions,
  watchPinning,
  currentRow,
}: useInputsParams) => {
  const { modalType, rawObjectFields, currentColumnsList } = useContext(PageContext)
  const srcObj = generateDefaultObjectByFields(rawObjectFields || [])

  const columnInputs: FormInputProps[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('columnInputs.code.placeholder'),
      label: i18next.t('columnInputs.code.label'),
      disabled: modalType === MODAL_TYPE.EDIT,
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      rules: {
        required: true,
        validate: value => {
          if (currentColumnsList.find(col => col.code === value && currentRow?.code !== value)) {
            return `${value} ${i18next.t('columnInputs.errors.alreadyUsed')}`
          }
        },
      },
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('columnInputs.title.placeholder'),
      label: i18next.t('columnInputs.title.label'),
      rules: { required: true },
    },
  ]

  const otherColumnInputs: FormInputProps[] = [
    ...(watchBinding !== BIND_TYPE.JS && watchBinding !== BIND_TYPE.JSX
      ? [
          {
            name: 'valueType',
            inputType: GENERATOR_INPUT_TYPE.INPUT,
            label: i18next.t('label.valueType'),
            placeholder: i18next.t('placeholder.valueType'),
            disabled: true,
            value: valueType,
          },
        ]
      : []),
    ...(isObjectLinkedValue
      ? [
          {
            name: 'objectValue',
            inputType: GENERATOR_INPUT_TYPE.SELECT,
            placeholder: i18next.t('placeholder.objectValue'),
            label: i18next.t('label.objectValue'),
            selectOptions: objectValueOptions,
            MenuProps: { PaperProps: { sx: { maxHeight: 300 } } },
            disabled: objectValueOptions.length === 0,
            rules: {
              validate: (value: string) => {
                if (objectValueOptions.length === 0) {
                  return i18next.t('error.allObjectValues')
                }
                if (
                  value === i18next.t('placeholder.objectValue') &&
                  objectValueOptions.length !== 0
                ) {
                  return false
                }
              },
              required: true,
            },
          },
        ]
      : []),
    {
      name: 'pinning',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('columnInputs.pinning.label'),
      labelPlacement: 'end',
    },
    {
      name: 'useParameters',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('columnInputs.useParameters.label'),
      labelPlacement: 'end',
    },
    ...(watchPinning
      ? [
          {
            name: 'pinnedColumn',
            inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
            label: i18next.t('columnInputs.pinnedColumn.label'),
            rules: { required: true },
            autocompleteOptions: Object.values(PINNED_COLUMN).map(value => ({
              id: value,
              label: value,
            })),
          },
        ]
      : []),
  ]

  return {
    otherColumnInputs,
    columnInputs,
    srcObj,
  }
}
