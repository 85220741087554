import { ViewRow } from '../../../types'

export const sortByOrder = (array: ViewRow[]) => {
  return array.sort((a, b) => {
    const firstOrderToCompareBy = a?.restrictions?.order ?? a?.order
    const secondOrderToCompareBy = b?.restrictions?.order ?? b?.order

    return (firstOrderToCompareBy || 0) > (secondOrderToCompareBy || 0) ? 1 : -1
  })
}
