import { EVENT_CODE } from '@constants'

import { ViewActionRow } from '../../../types'

export const findActionByEventCode = (
  eventCode: EVENT_CODE,
  actions: ViewActionRow[] | undefined
) => {
  return actions?.find(action => action.eventCode === eventCode)
}
