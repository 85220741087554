import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

export const transformResponseWithPagination = <OutputType>(
  res: OutputType,
  meta: FetchBaseQueryMeta
) => {
  return {
    data: res,
    totalCount: Number(meta?.response?.headers.get('x-page-total-pages')),
    totalCountElements: Number(meta?.response?.headers.get('x-page-total-elements')),
    currentPage: Number(meta?.response?.headers.get('x-page-current')),
    pageSize: Number(meta?.response?.headers.get('x-page-size')),
  }
}
