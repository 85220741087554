import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TreeItem } from '@atlaskit/tree'
import { ConfirmModal, FormGenerator } from '@microservices/wiskey-react-components'
import { Close, Done } from '@mui/icons-material'
import { Button, Grid, IconButton, SxProps } from '@mui/material'

import { transformTreeToMenuForGroups, transliterate } from '@helpers'

import { ENTITY, ENTITY_TYPES_REQUEST, GENERATOR_INPUT_TYPE, REGEX } from '../../../../../constants'
import { AutocompleteOption, POSTOptionGroupType } from '../../../../../types'
import { EntityOrFormGroupListContext } from '../../../EntityOrFormGroupList'

type AddItemProps = {
  treeItem?: TreeItem
  onClose?: () => void
  onSetEdit: (value: boolean) => void
  isEdit?: boolean
  disabled?: boolean
  isGlobalEdit?: boolean
  sx?: SxProps
}

type FormValues = {
  selectElement: AutocompleteOption | null
  groupName?: string
  selectedViewOrForm?: AutocompleteOption | null
}

enum ElementTypeGroup {
  ELEMENT = 1,
  GROUP = 2,
}

export const AddItem: FC<AddItemProps> = ({
  sx,
  onClose,
  treeItem,
  isEdit,
  disabled,
  onSetEdit,
  isGlobalEdit,
}) => {
  const { t } = useTranslation()
  const { tree, onAddTreeItem, updateGroups, allData, onEditTreeItem, isDirty, type, onCancel } =
    useContext(EntityOrFormGroupListContext)
  const ELEMENT_TYPE_GROUP = {
    ELEMENT: { id: 1, label: t(`groups.variant.${type}.element`) },
    GROUP: { id: 2, label: t(`groups.variant.${type}.group`) },
  }
  const [showCancelModal, setShowCancelModal] = useState(false)
  const defaultValues: FormValues = {
    selectElement: (isEdit && treeItem?.hasChildren) || disabled ? ELEMENT_TYPE_GROUP.GROUP : null,
    selectedViewOrForm: null,
    groupName: '',
  }
  const methods = useForm<FormValues>({
    defaultValues,
  })
  const { handleSubmit, reset, watch } = methods
  const watchElementType = watch('selectElement')
  const isEntityType = useMemo(
    () => watchElementType && watchElementType.id === ElementTypeGroup.ELEMENT,
    [watchElementType]
  )
  const viewsAutocompleteOptions = useMemo(
    () => allData.map(item => ({ id: item.id, label: item.code })),
    [allData]
  )
  const treeNames = useMemo(() => Object.values(tree?.items ?? {}).map(op => op.data.title), [tree])

  useEffect(() => {
    reset({ ...defaultValues, selectElement: watchElementType })
  }, [isEntityType])

  const handleSaveOrEdit = () => {
    reset()
    if (!isGlobalEdit) {
      onSetEdit(true)

      return
    }
    onSetEdit(false)
    if (tree) {
      // TODO: привести id к number у типа OptionType
      updateGroups(transformTreeToMenuForGroups(tree, true) as POSTOptionGroupType[])
    }
  }

  useEffect(() => {
    if (isEdit && treeItem) {
      reset({
        selectElement: ELEMENT_TYPE_GROUP.GROUP,
        groupName: treeItem.data.title,
      })

      return
    }
    reset({ ...defaultValues, selectElement: watchElementType })
  }, [isEdit, treeItem, isEntityType])

  const onAdd = ({ selectElement, groupName, selectedViewOrForm }: FormValues): void => {
    if (selectElement) {
      // добавление папки
      if (selectElement.id === ElementTypeGroup.GROUP && groupName) {
        const isCyrillic = REGEX.RUSSIAN_LETTERS.test(groupName)
        const itemCode = isCyrillic ? transliterate(groupName) : groupName

        if (isEdit && treeItem) {
          // обновление загаловка у папки
          onEditTreeItem({ ...treeItem, data: { ...treeItem.data, title: groupName } })
          reset({ ...defaultValues, selectElement })

          return
        }

        const option = {
          title: groupName,
          code: itemCode.replaceAll(' ', '_'),
          hasChild: true,
          childrenMenu: [],
          ...(type === ENTITY.VIEW || type === ENTITY.LIST_CONTROL
            ? {
                viewId: null,
                viewType:
                  type === ENTITY.VIEW
                    ? ENTITY_TYPES_REQUEST.VIEW
                    : ENTITY_TYPES_REQUEST.LIST_CONTROL,
              }
            : { formCode: null }),
        }
        onAddTreeItem(option, treeItem)
        reset({ ...defaultValues, selectElement })

        return
      }
      // добавление
      if (selectElement.id === ElementTypeGroup.ELEMENT && selectedViewOrForm) {
        const option = {
          title: selectedViewOrForm.label,
          code: Date.now().toString(),
          hasChild: false,
          childrenMenu: [],
          ...(type === ENTITY.VIEW || type === ENTITY.LIST_CONTROL
            ? {
                viewId: selectedViewOrForm.id,
                viewType:
                  type === ENTITY.VIEW
                    ? ENTITY_TYPES_REQUEST.VIEW
                    : ENTITY_TYPES_REQUEST.LIST_CONTROL,
              }
            : { formCode: selectedViewOrForm.label }),
        }

        onAddTreeItem(option, treeItem)
        reset({ ...defaultValues, selectElement })
      }
    }
  }

  const handleCancel = () => {
    if (isDirty) {
      setShowCancelModal(true)

      return
    }
    handleClose()
  }

  const handleCloseModal = () => setShowCancelModal(false)

  const handleClose = () => {
    if (treeItem) {
      onClose?.()
      reset()

      return
    }

    reset()
    onCancel()
    onSetEdit(false)
    handleCloseModal()
  }

  const isCurrentItem = (value: string) => isEdit && treeItem && treeItem.data.title === value

  return (
    <Grid container item alignItems='center' direction={'row'} spacing={0.5} sx={sx}>
      {showCancelModal && (
        <ConfirmModal
          actionBtnText={t('modal.cancelBtnText')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showCancelModal}
          text={t('modal.cancelMenuChanges.text')}
          title={t('modal.cancelMenuChanges.title')}
          onClose={handleCloseModal}
          onConfirm={handleClose}
        />
      )}
      <FormProvider {...methods}>
        <Grid item flexGrow={1}>
          <FormGenerator
            inputs={[
              {
                inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                name: 'selectElement',
                disabled:
                  isGlobalEdit === undefined
                    ? (isEdit && !treeItem?.data.viewCode) || disabled
                    : !isGlobalEdit,
                placeholder: t(`groups.placeholder.${type}.selectType`),
                isOptionEqualToValue: (option, value) => option.label === value.label,
                getOptionLabel: option => option.label,
                autocompleteOptions: [ELEMENT_TYPE_GROUP.ELEMENT, ELEMENT_TYPE_GROUP.GROUP],
              },
            ]}
          />
        </Grid>
        <Grid item flexGrow={1}>
          {(isEdit && treeItem) || !isEntityType ? (
            <FormGenerator
              inputs={[
                {
                  inputType: GENERATOR_INPUT_TYPE.INPUT,
                  name: 'groupName',
                  disabled,
                  placeholder: t(`groups.placeholder.${type}.groupName`),
                  rules: {
                    required: true,
                    validate: value => {
                      if (value.trim().length === 0) {
                        return false
                      }
                      if (treeNames.includes(value) && !isCurrentItem(value)) {
                        return `${value} ${t('groups.variant.views.error.alreadyUsed')}`
                      }
                    },
                  },
                },
              ]}
            />
          ) : (
            <FormGenerator
              inputs={[
                {
                  inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                  name: 'selectedViewOrForm',
                  disabled,
                  placeholder: t(`groups.placeholder.${type}.select`),
                  isOptionEqualToValue: (option, value) => option.label === value.label,
                  autocompleteOptions: viewsAutocompleteOptions,
                },
              ]}
            />
          )}
        </Grid>
        <Grid item alignSelf={'baseline'} mr={treeItem ? 0 : 2}>
          <IconButton disabled={!isGlobalEdit && !treeItem} onClick={handleSubmit(onAdd)}>
            <Done color='primary' />
          </IconButton>
          <IconButton disabled={!isGlobalEdit && !treeItem} onClick={handleClose}>
            <Close color='error' />
          </IconButton>
        </Grid>
        {!treeItem && (
          <Grid container ml={0.5}>
            <Grid item mr={0.5}>
              <Button
                disabled={isGlobalEdit && !isDirty}
                variant='contained'
                onClick={handleSaveOrEdit}
              >
                {isGlobalEdit ? t('form.save') : t('form.edit')}
              </Button>
            </Grid>
            {isGlobalEdit && (
              <Grid item>
                <Button onClick={handleCancel}>{t('form.close')}</Button>
              </Grid>
            )}
          </Grid>
        )}
      </FormProvider>
    </Grid>
  )
}
