import { ColumnDef } from '@tanstack/react-table'

import { TableActionsCell } from '../TableActionsCell'

export const getActionsColumn = <T,>(showActionsColumn: boolean) => {
  return showActionsColumn
    ? [
        {
          accessorKey: '_actions',
          header: '',
          enableSorting: false,
          cell: props => <TableActionsCell {...props} justifyContent={'flex-end'} />,
          minSize: 100,
          size: 150,
        } as ColumnDef<T>,
      ]
    : []
}
