import { FC } from 'react'
import { Add as AddIcon } from '@mui/icons-material'
import { Button } from '@mui/material'

type AddTabButtonProps = {
  tabCreationInProgress: boolean
  handleAddTabButtonClick: () => void
}

export const AddTabButton: FC<AddTabButtonProps> = ({
  tabCreationInProgress,
  handleAddTabButtonClick,
}) => {
  return (
    <Button
      variant='outlined'
      sx={{ ml: 0.5, minWidth: 36, minHeight: 36, padding: 0 }}
      onClick={handleAddTabButtonClick}
      disabled={tabCreationInProgress}
    >
      <AddIcon />
    </Button>
  )
}
