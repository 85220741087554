import { FC } from 'react'
import { Pagination as MUIPagination, PaginationProps as MUIPaginationProps } from '@mui/material'

type PaginationType = MUIPaginationProps

export const Pagination: FC<PaginationType> = props => {
  return (
    <MUIPagination {...props} color='primary' siblingCount={4} showFirstButton showLastButton />
  )
}
