import { AVAILABLE_SEARCH_RULES_TYPES, SEARCH_RULE_TYPE } from '@constants'
import { ViewRow } from '@types'

export const getIsAvailableRuleByNames = (column: ViewRow, rule: SEARCH_RULE_TYPE) => {
  return !(
    AVAILABLE_SEARCH_RULES_TYPES[rule].excludeNames.includes(column.value) ||
    (column.objectValue &&
      AVAILABLE_SEARCH_RULES_TYPES[rule].excludeNames.includes(column.objectValue))
  )
}
