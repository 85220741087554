import { ComponentClass, LegacyRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc'
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import { Grid, Skeleton, SxProps, TableBody as MUITableBody, Typography } from '@mui/material'
import { flexRender, Table } from '@tanstack/react-table'

import { TableRowProps } from './TableRow'
import { TableCell, TableRow } from '.'

type ListProps<T> = {
  ref?: LegacyRef<FixedSizeList<T>> | undefined
  children: ({ index, style }: ListChildComponentProps) => JSX.Element
} & FixedSizeListProps<T>

const List: ComponentClass<SortableContainerProps & ListProps<unknown>> = SortableContainer(
  <T,>({ children, ...props }: ListProps<T>) => {
    return (
      <FixedSizeList style={{ overflowX: 'hidden' }} {...props}>
        {children}
      </FixedSizeList>
    )
  }
)

const DragTableRow: ComponentClass<SortableElementProps & TableRowProps> = SortableElement(
  ({ children, ...props }: TableRowProps) => <TableRow {...props}>{children}</TableRow>
)

type TableBodyProps<T> = {
  table: Table<T>
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void
  useDragHandle: boolean
}

export const TableBody = <T,>({ table, onSortEnd, useDragHandle }: TableBodyProps<T>) => {
  const { t } = useTranslation()

  const meta = table.options.meta
  const useDragAndDrop = !!meta?.useDragAndDrop

  const pageIndex = meta?.pageIndex || 0
  const pageSize = meta?.pageSize || 0
  const pageCount = meta?.pageCount || 0
  const hasNexPage = pageIndex < pageCount

  const fetchNextPage = meta?.fetchNextPage

  const rowClick = meta?.onRowClick
  const rowHoverCursor = meta?.rowHoverCursor

  const loadMoreItems = () => {
    fetchNextPage?.()
  }

  const { rows } = table.getRowModel()
  const sourceData = meta?.sourceData || []
  const loading = isFunction(meta?.loading) ? meta.loading(table) : !!meta?.loading
  const isRowsPrepared = sourceData.length > 0 ? rows.length > 0 : !loading
  const isItemLoaded = (index: number) => !!rows[index]

  const VirtualizedRow = ({ index, style }: ListChildComponentProps) => {
    const row = rows[index]
    const isLastRow = rows.length - 1 === index
    const sxTableCell: SxProps = {
      ...(isLastRow && { borderBottom: 0 }),
    }

    return (
      <DragTableRow
        key={row.id}
        disabled={!useDragAndDrop}
        index={index}
        sx={{ cursor: rowHoverCursor, ...style }}
        onClick={() => {
          rowClick?.(row)
        }}
      >
        {row.getVisibleCells().map(cell => {
          const value = cell.getValue()
          const title = isString(value) ? (value as string) : undefined
          const size = cell.column.getSize()

          return (
            <TableCell
              key={cell.id}
              title={title}
              variant='body'
              sx={{
                ...sxTableCell,
                width: size,
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          )
        })}
      </DragTableRow>
    )
  }

  const itemCount = rows.length
  const height = pageSize * 52

  return (
    <MUITableBody component='div' sx={{ width: '100%', height, overflowX: 'hidden' }}>
      {loading &&
        Array.from(Array(pageSize).keys()).map(el => (
          <TableRow key={el}>
            {table.getVisibleLeafColumns().map(column => (
              <TableCell key={column.id} sx={{ width: column.getSize() }}>
                <Skeleton variant={'text'} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      {!loading && rows.length > 0 && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={hasNexPage ? itemCount + 1 : itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <List
              ref={ref}
              height={height}
              itemCount={itemCount}
              itemSize={52}
              overscanCount={10}
              useDragHandle={useDragHandle}
              width={'100%'}
              onItemsRendered={onItemsRendered}
              onSortEnd={onSortEnd}
            >
              {VirtualizedRow}
            </List>
          )}
        </InfiniteLoader>
      )}
      {!loading && isRowsPrepared && rows.length === 0 && (
        <Grid
          container
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ height: '100%', userSelect: 'none' }}
        >
          <Typography>{t('reactTable.noRows')}</Typography>
        </Grid>
      )}
    </MUITableBody>
  )
}
