import { FC } from 'react'
import { RegisterOptions, useController, useFormContext } from 'react-hook-form'
import { AutocompleteOption } from 'src/types'

import { EnumAutocomplete, EnumAutocompleteProps } from '@components/ui/EnumAutocomplete'

type EnumAutocompleteControllerProps<T> = Omit<EnumAutocompleteProps<T>, 'value' | 'onChange'>

export const EnumAutocompleteController: FC<
  EnumAutocompleteControllerProps<AutocompleteOption> & {
    rules?: RegisterOptions
  }
> = ({ ...props }) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value: formValue },
    fieldState: { invalid },
  } = useController({
    name: props.name,
    control,
    rules: props.rules,
  })

  const handleChange = (details: AutocompleteOption) => {
    onChange(details?.id ?? null)
  }

  return <EnumAutocomplete invalid={invalid} value={formValue} onChange={handleChange} {...props} />
}
