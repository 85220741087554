import { AutocompleteOption } from '@types'

import { getEntityAutocompleteOptions } from './getEntityAutocompleteOptions'

export const getEntityAutocompleteOptionsFromType = (
  currentType: string | undefined,
  usageMap: Record<string, Array<any> | undefined>
): Array<AutocompleteOption> => {
  const options = currentType && usageMap ? usageMap[currentType] : []
  return getEntityAutocompleteOptions(options, currentType)
}
