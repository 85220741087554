import qs from 'qs'

export const getEnrichedParams = (names: string[], params: any[] | undefined): string => {
  const values: Record<string, any> = {}

  if (params) {
    names.forEach((name, index) => {
      values[name] = params[index]
    })
  }

  return qs.stringify(values, { encode: false, indices: false })
}
