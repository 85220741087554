import i18next from 'i18next'
import moment from 'moment'

import { checkAvailableVariablesInScript } from './checkAvailableVariablesInScript'

export type ErrorValidJSType =
  | {
      error: true
      message: string | null
    }
  | {
      error: false
      message?: null
    }

export const checkValidJS = (
  value: string,
  data: Record<string, object>,
  variablesByObject?: Record<string, string[]>
): ErrorValidJSType => {
  const notAvailableVarsError = variablesByObject
    ? checkAvailableVariablesInScript(value, variablesByObject)
    : null

  if (notAvailableVarsError) {
    return notAvailableVarsError
  }

  try {
    const render = new Function(...Object.keys(data), 'moment', value)

    render(...Object.values(data), moment)

    return { error: false }
  } catch (e: any) {
    return { error: true, message: e?.message || `${i18next.t('error.valueJS')}` }
  }
}
