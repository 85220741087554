import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

const MAX_SECONDS = 359940
/**
 * Перевод числа(секунд) в строку(формат - HH:mm)
 * @param seconds number
 * @returns string
 */
export const getTimeFromSeconds = (seconds: number) => {
  if (!isNumber(seconds) && (!isString(seconds) || seconds === '')) {
    return ''
  }

  if (seconds.toString().includes('-')) {
    return '00:00'
  }

  if (seconds > MAX_SECONDS) {
    return '99:59+'
  }

  let hours: number | string = Math.floor(seconds / 3600)
  let minutes: number | string = Math.ceil(Number((((seconds / 3600) % 1) * 60).toFixed(3)))

  if (minutes === 60) {
    minutes = 0
    hours += 1
  }

  if (hours < 10) {
    hours = `0${hours}`
  }

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return `${hours}:${minutes}`
}
