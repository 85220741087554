export enum OBJECT_FIELD_TYPE {
  ENUM = 'enum',
  SINGLE = 'single',
  OBJECT_LINK = 'object_link',
}

export enum FIELD_VALUE_FORMAT {
  NUMBER = 'NUMBER',
  TIME = 'TIME',
  BOOLEAN = 'BOOLEAN',
}
