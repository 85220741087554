import { ParameterPropertyType } from 'src/types'

export type ReturnTransformCommonParametersToValues = {
  numberOfWindows: string | null
  timezone: string | null
  pagination: string | null
  ganttChart: string | null
}

export const transformCommonParametersToValues = (
  parameters: ParameterPropertyType[] | undefined
): ReturnTransformCommonParametersToValues => {
  if (!parameters) {
    return { numberOfWindows: null, pagination: null, timezone: null, ganttChart: null }
  }

  const initialData: Record<string, unknown> = {}

  parameters.forEach(({ key, value }) => {
    initialData[key] = value
  })

  return initialData as ReturnTransformCommonParametersToValues
}
