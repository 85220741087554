import { FC, SyntheticEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '@microservices/wiskey-react-components'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { Badge, Box, Button, Grid, Tab, Tabs } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { ICONS_BY_TAB, TAB } from '../constants'

type IconMenu = {
  selectedIcon: string | null | undefined
  isShow: boolean
  onClose: () => void
  onChange: (event: React.SyntheticEvent<EventTarget> | null, value: string | null) => void
}

export const IconMenu: FC<IconMenu> = ({ selectedIcon, isShow, onClose, onChange }) => {
  const { t } = useTranslation()
  const menuRef = useRef<HTMLDivElement>(null)

  const [icon, setIcon] = useState<string | null>(selectedIcon || null)
  const [activeTab, setActiveTab] = useState(TAB.INITIAL)
  const [iconsByTab, setIconsByTab] = useState(ICONS_BY_TAB[TAB.INITIAL])

  const handleChangeIcon = (value: string) => {
    if (value === icon) {
      setIcon(null)

      return
    }

    setIcon(value)
  }

  const handleSave = () => {
    onChange(null, icon)
    onClose()
  }

  const handleChangeTab = (event: SyntheticEvent, value: TAB) => {
    setActiveTab(value)
    setIconsByTab(ICONS_BY_TAB[value])

    menuRef.current?.children[0].scrollTo(0, 0)
  }

  return (
    <ModalWrapper
      disabledSave={selectedIcon === icon}
      isShow={isShow}
      title={t('iconPicker.menu')}
      containerStyle={{
        minWidth: '390px !important',
        maxWidth: '390px !important',
      }}
      onClose={onClose}
      onSave={handleSave}
    >
      <Grid container>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab label={t('iconPicker.tab.initial')} value={TAB.INITIAL} />
          <Tab label={t('iconPicker.tab.common')} value={TAB.COMMON} />
          <Tab label={t('iconPicker.tab.additional')} value={TAB.ADDITIONAL} />
        </Tabs>
      </Grid>
      <Box ref={menuRef}>
        <Grid
          container
          alignItems='center'
          bgcolor={theme => theme.palette.background.main}
          gap={1.5}
          maxHeight={212}
          overflow='auto'
          paddingY={1}
        >
          {Object.values(iconsByTab).map((i, index) => (
            <Grid key={index} item alignItems='center' justifyContent='center'>
              <Badge
                invisible={icon !== i.name}
                badgeContent={
                  <Box sx={{ background: 'white', borderRadius: '50%' }}>
                    <CheckCircle sx={{ height: 14, width: 14, color: 'green' }} />
                  </Box>
                }
              >
                <Button
                  size='small'
                  sx={{ p: '1px', minWidth: 40 }}
                  title={i.name}
                  onClick={() => handleChangeIcon(i.name)}
                >
                  <CustomSvgIcon
                    imgStyle={{ maxHeight: 26, maxWidth: 38 }}
                    src={i.src}
                    sx={{ width: '100%', height: 26 }}
                  />
                </Button>
              </Badge>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ModalWrapper>
  )
}
