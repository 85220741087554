import { ColumnDef } from '@tanstack/react-table'

import { TableDragHandleCell } from '../TableDragHandleCell'

export const getDragHandleColumn = <T,>(useDragAndDrop: boolean) => {
  return useDragAndDrop
    ? [
        {
          accessorKey: '_dragHandle',
          header: '',
          enableSorting: false,
          cell: () => <TableDragHandleCell />,
          size: 50,
          meta: {
            showDivider: false,
          },
        } as ColumnDef<T>,
      ]
    : []
}
