import { DRAGGABLE_FORM_SIZES } from '@constants'

export const getBreabcrumbLinkWidth = (
  windowWidth: number | undefined,
  linksLength: number,
  inlineElementsWidth: number
) => {
  const initialWidth =
    !windowWidth || windowWidth < DRAGGABLE_FORM_SIZES.MIN_WIDTH
      ? DRAGGABLE_FORM_SIZES.MIN_WIDTH
      : windowWidth

  return (initialWidth - inlineElementsWidth) / linksLength
}
