import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Position } from 'react-rnd'
import { TokenObject } from 'prismjs'
import getCaretCoordinates from 'textarea-caret'
import { FormLabel } from '@microservices/wiskey-react-components'
import { Box, Grid, ListItemButton, ListItemText } from '@mui/material'

import { useClickOutside } from '@pages/Parameters/hooks'

import { EditorBase } from '@components/ScriptValueDialog/EditorBase'

import { BIND_TYPE, HOT_KEY } from '@constants'
import { AutocompleteOption } from '@types'

const LINE_HEIGHT_LIST = 15
const LABEL_WIDTH = 120
const TEXTAREA_PADDING = 4

type PrefilterProps = {
  value: string
  onChange: (value: string) => void
  keyWordObj: TokenObject
  keyWordList: AutocompleteOption<number>[]
}

export const Prefilter: FC<PrefilterProps> = ({ value, onChange, keyWordObj, keyWordList }) => {
  const { t } = useTranslation()
  const selectRef = useRef<HTMLDivElement>(null)

  const [isOpenList, setIsOpenList] = useState<boolean>(false)
  const [listPosition, setListPosition] = useState<Position>({ x: 0, y: 0 })
  const [selectedListItemIndex, setSelectedListItemIndex] = useState<number | null>(null)
  const [valueInsertPosition, setValueInsertPosition] = useState<number>(0)
  const [textareaElementEvent, setTextareaElementEvent] = useState<KeyboardEvent | null>(null)

  useClickOutside(selectRef, () => handleCloseList())

  const handleInsertValue = (selectedValue: string) => {
    onChange(
      [value.slice(0, valueInsertPosition), selectedValue, value.slice(valueInsertPosition)].join(
        ''
      )
    )

    textareaElementEvent?.target?.focus()
    setTimeout(() => {
      textareaElementEvent?.target?.setSelectionRange(
        valueInsertPosition + selectedValue.length,
        valueInsertPosition + selectedValue.length
      )
    }, 10)

    handleCloseList()
  }

  const handleCloseList = () => {
    setIsOpenList(false)
    setSelectedListItemIndex(null)
  }

  const handleSelectListItem = (key: string) => {
    const listLength = keyWordList.length
    let index = selectedListItemIndex

    if (key === HOT_KEY.ARROW_DOWN) {
      index = index === null || index === listLength - 1 ? 0 : index + 1
    }

    if (key === HOT_KEY.ARROW_UP) {
      index = index === null || index === 0 ? listLength - 1 : index - 1
    }

    setSelectedListItemIndex(index)
  }

  const handleKeyUp = (event: KeyboardEvent) => {
    if (!keyWordObj.alias) {
      return
    }

    if (event.key === HOT_KEY.DOT) {
      const target = event.target
      const { value, selectionStart } = target
      const index = selectionStart - 1 // -точка

      const isCustomKeyWord =
        value.slice(index - keyWordObj.alias.length, index) === keyWordObj.alias

      if (isCustomKeyWord) {
        const caretPosition = getCaretCoordinates(target, selectionStart)

        setListPosition({ x: caretPosition.left, y: caretPosition.top + LINE_HEIGHT_LIST })
        setValueInsertPosition(selectionStart)
        setIsOpenList(true)
      } else {
        handleCloseList()
      }

      return
    }

    if (isOpenList && (event.key === HOT_KEY.ARROW_DOWN || event.key === HOT_KEY.ARROW_UP)) {
      handleSelectListItem(event.key)

      return
    }

    if (isOpenList && selectedListItemIndex !== null && event.key === HOT_KEY.ENTER) {
      handleInsertValue(keyWordList[selectedListItemIndex].label)

      return
    }

    handleCloseList()
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!textareaElementEvent) {
      setTextareaElementEvent(event)
    }

    if (
      isOpenList &&
      [HOT_KEY.ARROW_UP, HOT_KEY.ARROW_DOWN, HOT_KEY.ENTER].includes(event.key as HOT_KEY)
    ) {
      event.preventDefault()
    }
  }

  return (
    <Box>
      <Box marginLeft={`${LABEL_WIDTH}px`} position='relative' top={TEXTAREA_PADDING}>
        {isOpenList && keyWordList.length ? (
          <Box
            ref={selectRef}
            sx={{
              position: 'absolute',
              zIndex: 10,
              left: listPosition.x,
              top: listPosition.y,
              width: 150,
              minHeight: 15,
              maxHeight: 105,
              overflowY: 'auto',
              border: theme => theme.palette.border.default,
              bgcolor: theme => theme.palette.background.default,
            }}
          >
            {keyWordList.map((item, index) => {
              return (
                <Grid
                  key={item.id}
                  container
                  item
                  sx={{
                    height: 15,
                    bgcolor: theme =>
                      index === selectedListItemIndex
                        ? theme.palette.background.prevSelectedRow
                        : theme.palette.background.default,
                    '&:hover': {
                      bgcolor: theme => theme.palette.background.hovered,
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      p: '0 10px',
                      height: 15,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => handleInsertValue(item.label)}
                  >
                    <ListItemText
                      title={item.label}
                      sx={{
                        m: 0,
                        '& .MuiTypography-root': {
                          fontSize: 12,
                          lineHeight: `${LINE_HEIGHT_LIST}px`,
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          fontFamily: 'Montserrat Regular',
                          color: theme => theme.palette.common.black,
                        },
                      }}
                    >
                      {item.label}
                    </ListItemText>
                  </ListItemButton>
                </Grid>
              )
            })}
          </Box>
        ) : null}
      </Box>
      <Box display='flex'>
        <FormLabel
          label={t('viewsCreate.newModel.preFilter.label')}
          name='prefilter'
          control={
            <Box flexGrow={1}>
              <EditorBase
                noValidate
                autoFocus={false}
                customKeyWord={keyWordObj}
                language={BIND_TYPE.JS}
                placeholder={t('viewsCreate.newModel.preFilter.placeholder')}
                value={value}
                boxSx={{
                  mt: 0.5,
                  maxHeight: 300,
                  overflowY: 'auto',
                  width: '50%',
                }}
                style={{
                  width: '100%',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  transition: 'all .5s ease-in-out',
                  flexGrow: 1,
                }}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              />
            </Box>
          }
        />
      </Box>
    </Box>
  )
}
