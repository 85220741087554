import { ComponentClass, FC, MouseEvent, ReactNode, useCallback, useContext, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { SortableContainer, SortableContainerProps, SortEndHandler } from 'react-sortable-hoc'
import { getContainerById, reorder } from '@helpers'
import { ConfirmModal, TextField } from '@microservices/wiskey-react-components'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  DragHandle as DragHandleIcon,
  ExpandMore as ExpandMoreIcon,
  ManageSearch as ManageSearchIcon,
  WebAsset as WebAssetIcon,
} from '@mui/icons-material'
import {
  Box,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  Typography,
} from '@mui/material'
import { indigo } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

import { TabPanel } from '@components/Tabs'
import CardContainer from '@components/ui/CardContainer/CardContainer'

import {
  useCreateContainerMutation,
  useDeleteContainerMutation,
  useDeleteContainerTabMutation,
  useUpdateContainerMutation,
  useUpdateContainerTabMutation,
} from '@redux/api/container.api'

import { FormContainer } from '../../../../../types'
import { ElementContextMenu } from '../ActionMenu/ElementContextMenu'
import { useActionMenu } from '../ActionMenu/useActionMenu'
import { FieldsConfigContext } from '../FieldsConfig'

import { AddTabButton } from './AddTabButton'
import { ContainerTabPanel } from './ContainerTabPanel'
import { TabButton } from './TabButton'

type DraggableContainerOnFormProps = {
  container: FormContainer
  index: number
}

type ExpandMoreProps = {
  expand: boolean
} & IconButtonProps

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

type TabButtonContainerProps = {
  children: ReactNode
  tabCreationInProgress: boolean
  handleAddTabButtonClick(): void
}

const TabButtonContainer: ComponentClass<SortableContainerProps & TabButtonContainerProps> =
  SortableContainer(
    <T,>({ children, tabCreationInProgress, handleAddTabButtonClick }: TabButtonContainerProps) => {
      return (
        <Grid container alignItems={'center'}>
          {children}
          <Grid item xs={1}>
            <AddTabButton
              tabCreationInProgress={tabCreationInProgress}
              handleAddTabButtonClick={handleAddTabButtonClick}
            />
          </Grid>
        </Grid>
      )
    }
  )

export const DraggableContainerOnForm: FC<DraggableContainerOnFormProps> = ({
  container,
  index,
}) => {
  const { t } = useTranslation()

  const { containers, setContainers, containerCreationInProgress } = useContext(FieldsConfigContext)

  const contentPaddingOffset = 5
  const creationInProgress = Boolean(container.id === -1)
  const [editingInProgress, setContainerEditing] = useState(false)
  const [maxTabsWidth, setTabsMaxWidth] = useState(0)
  const isHistoryContainer = container.history

  const tabCreationInProgress = container.tabs.some(tab => tab.id === -1)

  const tabsWrapperRef = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return
    }

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setTabsMaxWidth(entries[0].contentRect.width - contentPaddingOffset)
    })

    observer.observe(node)
  }, [])

  const [expanded, setExpanded] = useState(false)
  const [isShowDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeletingContainer, setIsDeletingContainer] = useState(false)
  const [deletingTabId, setDeletingTabId] = useState(0)

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [createContainer] = useCreateContainerMutation()
  const [updateContainer] = useUpdateContainerMutation()
  const [updateTab] = useUpdateContainerTabMutation()
  const [deleteContainer] = useDeleteContainerMutation()
  const [deleteTab] = useDeleteContainerTabMutation()

  const handleExpandClick = () => {
    if (isHistoryContainer) {
      return
    }

    !creationInProgress && setExpanded(!expanded)
  }

  const handleShowDeleteModal = (value: boolean) => setShowDeleteModal(value)

  const handleDelete = () => {
    handleShowDeleteModal(false)

    if (isDeletingContainer) {
      deleteContainer(container.id)
      setIsDeletingContainer(false)

      return
    }

    deleteTab(deletingTabId)
  }

  const handleDeleteContainerClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    handleShowDeleteModal(true)
    setIsDeletingContainer(true)
  }

  const handleDeleteTabClick = (tabId: number) => {
    handleShowDeleteModal(true)
    setIsDeletingContainer(false)
    setDeletingTabId(tabId)
  }

  const [newContainerTitle, setNewContainerTitle] = useState('')
  const handleSaveContainerTitle = () => {
    if (newContainerTitle) {
      if (creationInProgress) {
        const { id, tabs, ...body } = { ...container, title: newContainerTitle }
        createContainer(body)
      } else {
        const { tabs, ...body } = { ...container, title: newContainerTitle }
        updateContainer(body)
          .unwrap()
          .then(res => {
            const newContainers = [...containers]
            newContainers[container.order] = res
            setContainers(newContainers)
            setContainerEditing(false)
          })
      }
    }
  }

  const handleCancelContainerCreationOrEdit = () => {
    if (creationInProgress) {
      const newContainers = [...containers].filter(container => container.id !== -1)
      setContainers(newContainers)
    } else {
      setContainerEditing(false)
    }
  }

  const handleDragEndTab: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return
    }

    const newContainers = structuredClone(containers) as FormContainer[]
    const { container: newContainer } = getContainerById(newContainers, container.id)

    if (newContainer?.tabs) {
      const oldTabs = [...newContainer.tabs]

      newContainer.tabs = reorder(newContainer.tabs, oldIndex, newIndex).map((tab, index) => ({
        ...tab,
        order: index,
      }))
      setContainers(newContainers)

      const oldActiveTab = oldTabs[activeTabIndex]
      if (oldActiveTab) {
        const newActiveTabIndex = newContainer.tabs.findIndex(tab => tab.id === oldActiveTab.id)
        if (newActiveTabIndex !== -1) {
          setActiveTabIndex(newActiveTabIndex)
        }
      }

      const { lines, ...body } = newContainer.tabs[newIndex]
      updateTab({ ...body, containerId: newContainer.id })
    }
  }

  const handleAddTabButtonClick = () => {
    const newContainer = { ...container }
    const newTabs = [...newContainer.tabs]
    newTabs.push({
      id: -1,
      title: '',
      order: newTabs.length,
      lines: [],
    })
    newContainer.tabs = newTabs
    const newContainers = [...containers]
    newContainers[index] = newContainer
    setContainers(newContainers)
  }

  const containerActionsDisabled = containerCreationInProgress
  const isEmptyContainer = container.tabs.length === 0

  const {
    menuPosition,
    handleSetMenuPosition,
    handleElementContextClick: handleTitleClick,
    handleCloseMenu: handleTabMenuClose,
  } = useActionMenu({})

  const handleTitleDoubleClick = () => {
    setContainerEditing(true)
    handleSetMenuPosition(null)

    if (container.title) {
      setNewContainerTitle(container.title)
    }
  }

  return (
    <>
      <ConfirmModal
        isShow={isShowDeleteModal}
        onClose={() => handleShowDeleteModal(false)}
        onConfirm={handleDelete}
        title={t('fieldsConfig.delete')}
        text={t('fieldsConfig.deleteText')}
        actionBtnText={t('fieldsConfig.deleteBtnText')}
        cancelBtnText={t('fieldsConfig.cancelDeleteBtnText')}
        containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      />
      <ElementContextMenu
        menuPosition={menuPosition}
        handleFieldMenuClose={handleTabMenuClose}
        handleOpenEdit={handleTitleDoubleClick}
        displayDeleteOption={false}
        displayFieldParamsOption={false}
      />
      <Draggable
        draggableId={`containerId:${container.id}`}
        index={index}
        isDragDisabled={containerActionsDisabled}
      >
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              px: 2,
              py: 1,
            }}
          >
            <CardContainer>
              <CardActions
                disableSpacing
                onClick={handleExpandClick}
                sx={{
                  cursor: isHistoryContainer ? 'default' : 'pointer',
                }}
              >
                <Box {...provided.dragHandleProps} sx={{ mr: 1, display: 'flex' }}>
                  <DragHandleIcon color={containerActionsDisabled ? 'disabled' : 'action'} />
                </Box>
                {isHistoryContainer ? (
                  <ManageSearchIcon sx={{ color: indigo[500], mr: 1 }} />
                ) : (
                  <WebAssetIcon color={'secondary'} sx={{ mr: 1 }} />
                )}
                {creationInProgress || editingInProgress ? (
                  <Box onClick={event => event.stopPropagation()}>
                    <TextField
                      value={newContainerTitle}
                      onChange={event => setNewContainerTitle(event.target.value)}
                      placeholder={t('fieldsConfig.placeholder.title')}
                      size={'small'}
                      variant={'standard'}
                      autoFocus
                    />
                    <IconButton onClick={handleSaveContainerTitle}>
                      <CheckIcon />
                    </IconButton>
                    <IconButton onClick={handleCancelContainerCreationOrEdit}>
                      <ClearIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Typography onContextMenu={handleTitleClick} sx={{ userSelect: 'none' }}>
                    {container.title}
                  </Typography>
                )}
                {!isHistoryContainer && (
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    disabled={containerActionsDisabled}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                )}
                <IconButton
                  onClick={handleDeleteContainerClick}
                  disabled={containerActionsDisabled}
                  sx={{ ...(isHistoryContainer && { ml: 'auto' }) }}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
              {!isHistoryContainer && (
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                  <CardContent sx={{ p: 0 }} ref={tabsWrapperRef}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <TabButtonContainer
                        axis={'xy'}
                        useDragHandle
                        onSortEnd={handleDragEndTab}
                        tabCreationInProgress={tabCreationInProgress}
                        handleAddTabButtonClick={handleAddTabButtonClick}
                      >
                        {container.tabs.map((tab, tabIndex) => (
                          // eslint-disable-next-line react/prop-types
                          <TabButton
                            currentTabButton={activeTabIndex}
                            handleSetCurrentTabButton={(value: number) => {
                              setActiveTabIndex(value)
                            }}
                            containerId={container.id}
                            onDelete={handleDeleteTabClick}
                            key={tab.id}
                            tab={tab}
                            index={tabIndex}
                          />
                        ))}
                      </TabButtonContainer>
                      <Box
                        sx={{
                          minHeight: 56,
                          py: 1.5,
                          boxSizing: 'content-box',
                          background: theme => theme.palette.background.formCardContainer,
                          position: 'relative',
                        }}
                      >
                        {isEmptyContainer && (
                          <Typography
                            color={'gray'}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              transform: 'translate(-50%, -50%)',
                              left: '50%',
                              marginRight: '-50%',
                              userSelect: 'none',
                            }}
                          >
                            {t('fieldsConfig.placeholder.emptyContainer')}
                          </Typography>
                        )}
                        {container.tabs.map((tab, tabIndex) => (
                          <TabPanel
                            // eslint-disable-next-line react/prop-types
                            key={tab.id}
                            value={tabIndex}
                            index={activeTabIndex}
                            contentSx={{
                              p: 0,
                            }}
                          >
                            <ContainerTabPanel
                              tab={tab}
                              index={tabIndex}
                              containerId={container.id}
                            />
                          </TabPanel>
                        ))}
                      </Box>
                    </Box>
                  </CardContent>
                </Collapse>
              )}
            </CardContainer>
          </Box>
        )}
      </Draggable>
    </>
  )
}
