import cloneDeep from 'lodash/cloneDeep'

export const getUpdatedOptions = <T>(
  options: T[],
  current: T,
  findCallback: (value: T, index: number) => unknown
) => {
  const copyOptions = cloneDeep(options)
  const findOption = copyOptions.findIndex(findCallback)

  if (findOption !== -1) {
    copyOptions.splice(findOption, 1)
  } else {
    copyOptions.push(current)
  }

  return copyOptions
}
