// В мутации прокидываются fixedCacheKey для синхронизации состояний isLoading между разными компонентами
import {
  useCreateContainerElementMutation as useCreateContainerElement,
  useCreateContainerMutation as useCreateContainer,
  useCreateContainerTabMutation as useCreateContainerTab,
  useDeleteContainerElementMutation as useDeleteContainerElement,
  useDeleteContainerMutation as useDeleteContainer,
  useDeleteContainerTabMutation as useDeleteContainerTab,
  useUpdateContainerElementMutation as useUpdateContainerElement,
  useUpdateContainerLineMutation as useUpdateContainerLine,
  useUpdateContainerMutation as useUpdateContainer,
  useUpdateContainerTabMutation as useUpdateContainerTab,
} from '@redux/api/container.api'

export const useCreateContainerMutation = () =>
  useCreateContainer({ fixedCacheKey: 'create-container' })

export const useUpdateContainerMutation = () =>
  useUpdateContainer({ fixedCacheKey: 'update-container' })

export const useDeleteContainerMutation = () =>
  useDeleteContainer({ fixedCacheKey: 'delete-container' })

export const useCreateContainerTabMutation = () =>
  useCreateContainerTab({ fixedCacheKey: 'create-container-tab' })

export const useUpdateContainerTabMutation = () =>
  useUpdateContainerTab({ fixedCacheKey: 'update-container-tab' })

export const useDeleteContainerTabMutation = () =>
  useDeleteContainerTab({ fixedCacheKey: 'delete-container-tab' })

export const useUpdateContainerLineMutation = () =>
  useUpdateContainerLine({ fixedCacheKey: 'update-container-line' })

export const useCreateContainerElementMutation = () =>
  useCreateContainerElement({ fixedCacheKey: 'create-container-element' })

export const useUpdateContainerElementMutation = () =>
  useUpdateContainerElement({ fixedCacheKey: 'update-container-element' })

export const useDeleteContainerElementMutation = () =>
  useDeleteContainerElement({ fixedCacheKey: 'delete-container-element' })
