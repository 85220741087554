import { useContext, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box, CircularProgress, Grid } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'

import { ACTION_CODE } from '@constants'
import { SelectOption } from '@types'

import { AccordionConfigExamples } from '@gantt/components/ConfigExamples'
import {
  BIND_TYPE_INPUTS_FORM_TYPE,
  CONTEXT_MENU_BIND_TYPE,
  GANTT_BIND_TYPE_LIST,
  OPEN_MODAL_BIND_TYPE,
  PageContext,
  TOOLTIP_BINDING_TYPE,
} from '@gantt/components/GanttCreateOrEdit'
import { metaDataValues } from '@gantt/constants'
import { checkValidGanttJS, getBindTypeOptionsByEnum, getOptionByStr } from '@gantt/helpers'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

import { useAddGanttActionTimelinesDialog } from './hooks/useAddGanttActionTimelinesDialog'

const getHints = (actionCode: ACTION_CODE) => {
  return {
    json:
      actionCode === ACTION_CODE.OPEN_CONTEXT_MENU
        ? i18next.t('ganttCreate.timelineForm.hint.contextMenuJsonHint')
        : undefined,
  }
}

const tooltipHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.tooltipScriptHint'),
  json: i18next.t('ganttCreate.timelineForm.hint.tooltipScriptHint'),
}

const tooltipPlaceholders = {
  js: i18next.t('ganttCreate.timelineForm.placeholder.tooltipJS'),
}

const commandPlaceholders = {
  command: i18next.t('placeholder.selectCommand'),
}

const formPlaceholders = {
  form: i18next.t('placeholder.objectFormCode'),
}

export const AddGanttActionTimelinesDialog = () => {
  const { showDialog, handleCloseModal, currentBarObject, mainDataAvailableVariables } =
    useContext(PageContext)
  const {
    actionInputs,
    isDirty,
    methods,
    handleSave,
    key,
    loadingAllForm,
    formCodeOptions,
    watchActionCode,
  } = useAddGanttActionTimelinesDialog()
  const { t } = useTranslation()

  const bindings: SelectOption[] = useMemo(() => {
    if (watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU) {
      return getBindTypeOptionsByEnum(CONTEXT_MENU_BIND_TYPE)
    }

    if (watchActionCode === ACTION_CODE.OPEN_FORM_MODEL) {
      return [
        getOptionByStr(
          OPEN_MODAL_BIND_TYPE.FORM,
          i18next.t('ganttCreate.timelineForm.bindTypeForm')
        ),
        getOptionByStr(OPEN_MODAL_BIND_TYPE.JSON),
      ]
    }

    if (watchActionCode === ACTION_CODE.EXECUTE_COMMAND) {
      return [getOptionByStr(GANTT_BIND_TYPE_LIST.COMMANDS)]
    }

    return []
  }, [watchActionCode])

  const handleClose = () => {
    handleCloseModal(isDirty)
  }

  const availableVariables: Record<string, string[]> = useMemo(() => {
    if (watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU) {
      return { mainData: mainDataAvailableVariables, metaData: metaDataValues }
    }

    return { mainData: [], metaData: metaDataValues }
  }, [watchActionCode, mainDataAvailableVariables])

  const funValidator = useMemo(
    () => (value: string) =>
      checkValidGanttJS(value, availableVariables, GANTT_SCRIPT_RETURN_TYPE.ARRAY_OF_OBJECTS),
    [availableVariables]
  )

  return (
    <ModalWrapper
      btnText={t('ganttCreate.common.apply')}
      disabledSave={!isDirty}
      isShow={showDialog}
      onClose={handleClose}
      onSave={handleSave}
    >
      {loadingAllForm ? (
        <Grid container alignItems='center' justifyContent='center' my={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <Box
          key={`AddGanttActionTimelinesDialog_${String(key)}`}
          maxHeight={500}
          overflow='auto'
          pb={2}
          pr={2}
          pt={2}
        >
          <FormProvider {...methods}>
            <form>
              <FormGenerator inputs={actionInputs} />
              {watchActionCode === ACTION_CODE.OPEN_TOOLTIP && (
                <BindTypeInputs
                  bindTypeOptions={getBindTypeOptionsByEnum(TOOLTIP_BINDING_TYPE)}
                  containerName='actionField'
                  formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
                  hintDict={tooltipHints}
                  placeholderDict={tooltipPlaceholders}
                  watchedObject={currentBarObject}
                />
              )}
              {watchActionCode === ACTION_CODE.EXECUTE_COMMAND && (
                <BindTypeInputs
                  showBinding
                  bindTypeOptions={bindings}
                  containerName='actionField'
                  formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
                  placeholderDict={commandPlaceholders}
                  valueInputLabel={i18next.t('ganttCreate.resourceForm.command')}
                  watchedObject={currentBarObject}
                />
              )}
              {[ACTION_CODE.OPEN_CONTEXT_MENU, ACTION_CODE.OPEN_FORM_MODEL].includes(
                watchActionCode
              ) && (
                <BindTypeInputs
                  bindTypeOptions={bindings}
                  containerName='actionField'
                  formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
                  hintDict={getHints(watchActionCode)}
                  placeholderDict={formPlaceholders}
                  showBinding={Boolean(bindings?.length)}
                  validator={funValidator}
                  valueOptions={formCodeOptions}
                  valueInputLabel={
                    watchActionCode === ACTION_CODE.OPEN_FORM_MODEL
                      ? i18next.t('label.objectFormCode')
                      : undefined
                  }
                />
              )}
            </form>
          </FormProvider>
          <AccordionConfigExamples />
        </Box>
      )}
    </ModalWrapper>
  )
}
