import { FC, MouseEventHandler, ReactNode } from 'react'
import { Box, Grid, SxProps } from '@mui/material'

type PageTitleProps = {
  start?: ReactNode
  end: ReactNode
  onClick?: MouseEventHandler<HTMLDivElement>
  sx?: SxProps
}

export const PageTitle: FC<PageTitleProps> = ({ start, end, sx, onClick }) => {
  return (
    <Box sx={{ ...sx }} onClick={onClick}>
      <Grid container item alignItems='center' justifyContent='space-between'>
        <Grid item>{start}</Grid>
        <Grid item>{end}</Grid>
      </Grid>
    </Box>
  )
}
