import isEmpty from 'lodash/isEmpty'

import { ENTITY_COMMAND_TYPE, EntityCommandDTO } from '@types'

import { GanttFormCommands, GanttFormFilledCommands } from '@gantt/components/GanttCreateOrEdit'
import { EMPTY_COMMAND_FORM } from '@gantt/constants'

export const getCommandsFormattedRequest = (commands: GanttFormCommands): EntityCommandDTO[] => {
  return (commands as GanttFormFilledCommands).map(command => ({
    type: command.type?.label as ENTITY_COMMAND_TYPE,
    name: command.name?.label,
  }))
}
export const getCommandsFormattedResponse = (commands: EntityCommandDTO[]): GanttFormCommands => {
  if (isEmpty(commands)) {
    return EMPTY_COMMAND_FORM
  }

  return commands.map(command => ({
    type: { id: command.type, label: command.type },
    name: { id: command.name, label: command.name },
  }))
}
