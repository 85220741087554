import i18next from 'i18next'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { ServerError } from '../types'

export const getResponseMessageText = (
  error: FetchBaseQueryError | undefined,
  errorData: ServerError
) => {
  if (!error) {
    return undefined
  }

  if (!errorData) {
    return i18next.t('notifications.error')
  }

  return `message: ${errorData.message} details: ${errorData.details}`
}
