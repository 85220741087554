import AircraftDefault from '@assets/images/common/aircraft-default.ico'
import AircraftInProgress from '@assets/images/common/aircraft-in-progress.ico'
import AircraftInfo from '@assets/images/common/aircraft-info.ico'
import AircraftNew from '@assets/images/common/aircraft-new.ico'
import AircraftSolved from '@assets/images/common/aircraft-solved.ico'
import AircraftUnread from '@assets/images/common/aircraft-unread.ico'
import AircraftWarning from '@assets/images/common/aircraft-warning.ico'
import ArrivalBlack from '@assets/images/common/arrival-black.ico'
import ArrivalCanceled from '@assets/images/common/arrival-canceled.ico'
import ArrivalDefault from '@assets/images/common/arrival-default.ico'
import ArrivalDiverted from '@assets/images/common/arrival-diverted.ico'
import ArrivalInProgress from '@assets/images/common/arrival-in-progress.ico'
import ArrivalNew from '@assets/images/common/arrival-new.ico'
import ArrivalPrioDefault from '@assets/images/common/arrival-prio-default.ico'
import ArrivalPrioHigh from '@assets/images/common/arrival-prio-high.ico'
import ArrivalPrioLow from '@assets/images/common/arrival-prio-low.ico'
import ArrivalSolved from '@assets/images/common/arrival-solved.ico'
import ArrivalUnread from '@assets/images/common/arrival-unread.ico'
import AttentionSymbolError from '@assets/images/common/attention-symbol-error.png'
import AttentionSymbolInfo from '@assets/images/common/attention-symbol-info.png'
import AttentionSymbolWarning from '@assets/images/common/attention-symbol-warning.png'
import BagDefault from '@assets/images/common/bag-default.ico'
import BagInProgress from '@assets/images/common/bag-in-progress.ico'
import BagLooseHighAmount from '@assets/images/common/bag-loose-high-amount.png'
import BagLooseLowAmount from '@assets/images/common/bag-loose-low-amount.png'
import BagLooseMediumAmount from '@assets/images/common/bag-loose-medium-amount.png'
import BagNew from '@assets/images/common/bag-new.ico'
import BagSolved from '@assets/images/common/bag-solved.ico'
import BagUnread from '@assets/images/common/bag-unread.ico'
import BanBlack from '@assets/images/common/ban-black.png'
import BanOutline from '@assets/images/common/ban-outline.png'
import BanWhite from '@assets/images/common/ban-white.png'
import BarInfo from '@assets/images/common/bar-info.png'
import BarWarning from '@assets/images/common/bar-warning.png'
import BoardingClose from '@assets/images/common/boarding-close.ico'
import BoardingComplete from '@assets/images/common/boarding-complete.ico'
import BoardingEndOverdue from '@assets/images/common/boarding-end-overdue.ico'
import BoardingOverdue from '@assets/images/common/boarding-overdue.ico'
import BoardingProgressCritical from '@assets/images/common/boarding-progress-critical.ico'
import BoardingStarted from '@assets/images/common/boarding-started.ico'
import BoltBlack from '@assets/images/common/bolt-black.png'
import BoltOutline from '@assets/images/common/bolt-outline.png'
import BoltRed from '@assets/images/common/bolt-red.png'
import BoltWhite from '@assets/images/common/bolt-white.png'
import CargoCar from '@assets/images/common/cargo-car.png'
import CargoCool from '@assets/images/common/cargo-cool.png'
import CargoDefault from '@assets/images/common/cargo-default.ico'
import CargoDefaultAlternate from '@assets/images/common/cargo-default-alternate.png'
import CargoDocsHighAmountReverse from '@assets/images/common/cargo-docs-high-amount-reverse.png'
import CargoDocsLowAmountReverse from '@assets/images/common/cargo-docs-low-amount-reverse.png'
import CargoDocsMediumAmountReverse from '@assets/images/common/cargo-docs-medium-amount-reverse.png'
import CargoInProgress from '@assets/images/common/cargo-in-progress.ico'
import CargoLooseHighAmount from '@assets/images/common/cargo-loose-high-amount.png'
import CargoLooseHighAmountReverse from '@assets/images/common/cargo-loose-high-amount-reverse.png'
import CargoLooseLowAmount from '@assets/images/common/cargo-loose-low-amount.png'
import CargoLooseLowAmountReverse from '@assets/images/common/cargo-loose-low-amount-reverse.png'
import CargoLooseMediumAmount from '@assets/images/common/cargo-loose-medium-amount.png'
import CargoLooseMediumAmountReverse from '@assets/images/common/cargo-loose-medium-amount-reverse.png'
import CargoMeat from '@assets/images/common/cargo-meat.png'
import CargoNew from '@assets/images/common/cargo-new.ico'
import CargoNewAlternate from '@assets/images/common/cargo-new-alternate.png'
import CargoNewReverse from '@assets/images/common/cargo-new-reverse.png'
import CargoPill from '@assets/images/common/cargo-pill.png'
import CargoSolved from '@assets/images/common/cargo-solved.ico'
import CargoSolvedAlternate from '@assets/images/common/cargo-solved-alternate.png'
import CargoUnread from '@assets/images/common/cargo-unread.ico'
import CargoXps from '@assets/images/common/cargo-xps.png'
import CargouldHighAmount from '@assets/images/common/cargould-high-amount.png'
import CargouldLowAmount from '@assets/images/common/cargould-low-amount.png'
import CargouldMediumAmount from '@assets/images/common/cargould-medium-amount.png'
import Comment from '@assets/images/common/comment.png'
import CommentBlack from '@assets/images/common/comment-black.png'
import CommentOutline from '@assets/images/common/comment-outline.png'
import CommentWhite from '@assets/images/common/comment-white.png'
import ConnStateConnected from '@assets/images/common/conn-state-connected.ico'
import ConnStateCritical from '@assets/images/common/conn-state-critical.ico'
import ConnStateDefault from '@assets/images/common/conn-state-default.ico'
import ConnStateMisconnected from '@assets/images/common/conn-state-misconnected.ico'
import ConnStateRequested from '@assets/images/common/conn-state-requested.ico'
import ConnStateRequestedDefault from '@assets/images/common/conn-state-requested-default.ico'
import ConnStateRerouted from '@assets/images/common/conn-state-rerouted.ico'
import ConnStateReroutedDefault from '@assets/images/common/conn-state-rerouted-default.ico'
import ConnStateSameAc from '@assets/images/common/conn-state-same-ac.ico'
import ConnStateSameAcDefault from '@assets/images/common/conn-state-same-ac-default.ico'
import ConnStateUnknown from '@assets/images/common/conn-state-unknown.ico'
import ConnStateUnknownDefault from '@assets/images/common/conn-state-unknown-default.ico'
import ConnStateWillWait from '@assets/images/common/conn-state-will-wait.ico'
import ConnStateWillWaitDefault from '@assets/images/common/conn-state-will-wait-default.ico'
import CpmInvalid from '@assets/images/common/cpm-invalid.png'
import CpmRead from '@assets/images/common/cpm-read.png'
import CpmUpdated from '@assets/images/common/cpm-updated.png'
import CustomsRequired from '@assets/images/common/customs-required.png'
import DdoArrivalActive from '@assets/images/common/ddo-arrival-active.png'
import DdoArrivalActiveGantt from '@assets/images/common/ddo-arrival-active-gantt.png'
import DdoArrivalCanceled from '@assets/images/common/ddo-arrival-canceled.png'
import DdoArrivalCanceledGantt from '@assets/images/common/ddo-arrival-canceled-gantt.png'
import DdoDepartureActive from '@assets/images/common/ddo-departure-active.png'
import DdoDepartureActiveGantt from '@assets/images/common/ddo-departure-active-gantt.png'
import DdoDepartureCanceled from '@assets/images/common/ddo-departure-canceled.png'
import DdoDepartureCanceledGantt from '@assets/images/common/ddo-departure-canceled-gantt.png'
import DepartureBlack from '@assets/images/common/departure-black.ico'
import DepartureCanceled from '@assets/images/common/departure-canceled.ico'
import DepartureDefault from '@assets/images/common/departure-default.ico'
import DepartureInProgress from '@assets/images/common/departure-in-progress.ico'
import DepartureNew from '@assets/images/common/departure-new.ico'
import DeparturePrioDefault from '@assets/images/common/departure-prio-default.ico'
import DeparturePrioHigh from '@assets/images/common/departure-prio-high.ico'
import DeparturePrioLow from '@assets/images/common/departure-prio-low.ico'
import DepartureSolved from '@assets/images/common/departure-solved.ico'
import DepartureUnread from '@assets/images/common/departure-unread.ico'
import DomesticFlight from '@assets/images/common/domestic-flight.png'
import EuropeanFlight from '@assets/images/common/european-flight.png'
import ExclamationTriangleBlack from '@assets/images/common/exclamation-triangle-black.png'
import ExclamationTriangleOrange from '@assets/images/common/exclamation-triangle-orange.png'
import ExclamationTriangleOutline from '@assets/images/common/exclamation-triangle-outline.png'
import ExclamationTriangleWhite from '@assets/images/common/exclamation-triangle-white.png'
import Hourglass from '@assets/images/common/hourglass.png'
import HourglassBlack from '@assets/images/common/hourglass-black.png'
import HourglassOutline from '@assets/images/common/hourglass-outline.png'
import HourglassWhite from '@assets/images/common/hourglass-white.png'
import InitialTimeEarly from '@assets/images/common/initial-time-early.png'
import InitialTimeLate from '@assets/images/common/initial-time-late.png'
import IntIrnationalFlight from '@assets/images/common/international-flight.png'
import LinkAnchorLeft from '@assets/images/common/link-anchor-left.png'
import LinkAnchorRight from '@assets/images/common/link-anchor-right.png'
import LocationDefault from '@assets/images/common/location-default.ico'
import LocationInProgress from '@assets/images/common/location-in-progress.ico'
import LocationNew from '@assets/images/common/location-new.ico'
import LocationSolved from '@assets/images/common/location-solved.ico'
import LocationUnread from '@assets/images/common/location-unread.ico'
import LockDefault from '@assets/images/common/lock-default.ico'
import LockLocked from '@assets/images/common/lock-locked.ico'
import MaintenanceDefault from '@assets/images/common/maintenance-default.ico'
import MaintenanceNew from '@assets/images/common/maintenance-new.ico'
import MaintenanceSolved from '@assets/images/common/maintenance-solved.ico'
import MaintenanceUnread from '@assets/images/common/maintenance-unread.ico'
import MelDefault from '@assets/images/common/mel-default.png'
import MelNew from '@assets/images/common/mel-new.png'
import MelSolved from '@assets/images/common/mel-solved.png'
import MelUnread from '@assets/images/common/mel-unread.png'
import MessageDefault from '@assets/images/common/message-default.ico'
import MessageDeleted from '@assets/images/common/message-deleted.ico'
import MessageRead from '@assets/images/common/message-read.ico'
import MessageSolidDefault from '@assets/images/common/message-solid-default.ico'
import MessageSolidRead from '@assets/images/common/message-solid-read.ico'
import MessageSolidUnread from '@assets/images/common/message-solid-unread.ico'
import MessageUnread from '@assets/images/common/message-unread.ico'
import MilesToneLinkAnchor from '@assets/images/common/miles-tone-link-anchor.png'
import MinusBlack from '@assets/images/common/minus-black.png'
import MinusOrange from '@assets/images/common/minus-orange.png'
import MinusOutline from '@assets/images/common/minus-outline.png'
import MinusWhite from '@assets/images/common/minus-white.png'
import MissingEtd from '@assets/images/common/missing-etd.png'
import Nonps from '@assets/images/common/nonps.png'
import OccurrencesDefault from '@assets/images/common/occurrences-default.ico'
import OccurrencesResolved from '@assets/images/common/occurrences-resolved.ico'
import OccurrencesUnread from '@assets/images/common/occurrences-unread.ico'
import OccurrencesUnresolved from '@assets/images/common/occurrences-unresolved.ico'
import Offblock from '@assets/images/common/offblock.png'
import OffblockEarly from '@assets/images/common/offblock-early.png'
import OffblockLate from '@assets/images/common/offblock-late.png'
import OnTime from '@assets/images/common/on-time.png'
import OnblockEarly from '@assets/images/common/onblock-early.png'
import OnblockLate from '@assets/images/common/onblock-late.png'
import OutboundGrey from '@assets/images/common/outbound-grey.png'
import Pause from '@assets/images/common/pause.png'
import PauseBlack from '@assets/images/common/pause-black.png'
import PauseOutline from '@assets/images/common/pause-outline.png'
import PauseWhite from '@assets/images/common/pause-white.png'
import PaxDecisionDefault from '@assets/images/common/pax-decision-default.ico'
import PaxDecisionGreen from '@assets/images/common/pax-decision-green.ico'
import PaxDecisionLime from '@assets/images/common/pax-decision-lime.ico'
import PaxDecisionOrange from '@assets/images/common/pax-decision-orange.ico'
import PaxDecisionRed from '@assets/images/common/pax-decision-red.ico'
import PaxDecisionRedRecheck from '@assets/images/common/pax-decision-red-recheck.ico'
import PaxDefault from '@assets/images/common/pax-default.ico'
import PaxInProgress from '@assets/images/common/pax-in-progress.ico'
import PaxNew from '@assets/images/common/pax-new.ico'
import PaxSolved from '@assets/images/common/pax-solved.ico'
import PaxUnread from '@assets/images/common/pax-unread.ico'
import PilotDefault from '@assets/images/common/pilot-default.ico'
import PilotInProgress from '@assets/images/common/pilot-in-progress.ico'
import PilotNew from '@assets/images/common/pilot-new.ico'
import PilotSolved from '@assets/images/common/pilot-solved.ico'
import PilotUnread from '@assets/images/common/pilot-unread.ico'
import PrioCrewNew from '@assets/images/common/prio-crew-new.ico'
import PrioCrewSolved from '@assets/images/common/prio-crew-solved.ico'
import PrioDefault from '@assets/images/common/prio-default.ico'
import PrioHighNew from '@assets/images/common/prio-high-new.ico'
import PrioHighSolved from '@assets/images/common/prio-high-solved.ico'
import PrioQuickNew from '@assets/images/common/prio-quick-new.ico'
import PrioQuickSolved from '@assets/images/common/prio-quick-solved.ico'
import PrioTransferNew from '@assets/images/common/prio-transfer-new.ico'
import PrioTransferSolved from '@assets/images/common/prio-transfer-solved.ico'
import Progress12BufferTow from '@assets/images/common/progress-12-buffer-tow.png'
import Progress12DepTow from '@assets/images/common/progress-12-dep-tow.png'
import Progress12NoTow from '@assets/images/common/progress-12-no-tow.png'
import Progress25BufferTow from '@assets/images/common/progress-25-buffer-tow.png'
import Progress25DepTow from '@assets/images/common/progress-25-dep-tow.png'
import Progress25NoTow from '@assets/images/common/progress-25-no-tow.png'
import Progress38DepTow from '@assets/images/common/progress-38-dep-tow.png'
import Progress38NoTow from '@assets/images/common/progress-38-no-tow.png'
import Progress50AfterBufferTow from '@assets/images/common/progress-50-after-buffer-tow.png'
import Progress50AfterDepTow from '@assets/images/common/progress-50-after-dep-tow.png'
import Progress50NoTow from '@assets/images/common/progress-50-no-tow.png'
import Progress63NoTow from '@assets/images/common/progress-63-no-tow.png'
import Progress75DepTow from '@assets/images/common/progress-75-dep-tow.png'
import Progress75NoTow from '@assets/images/common/progress-75-no-tow.png'
import Progress87BufferTow from '@assets/images/common/progress-87-buffer-tow.png'
import Progress87NoTow from '@assets/images/common/progress-87-no-tow.png'
import Progress100BufferTow from '@assets/images/common/progress-100-buffer-tow.png'
import Progress100DepTow from '@assets/images/common/progress-100-dep-tow.png'
import Progress100NoTow from '@assets/images/common/progress-100-no-tow.png'
import ProgressAcOnBuffer from '@assets/images/common/progress-ac-on-buffer.png'
import ProgressAcOnPosAfterBufferTow from '@assets/images/common/progress-ac-on-pos-after-buffer-tow.png'
import ProgressAcOnPosAfterDepTow from '@assets/images/common/progress-ac-on-pos-after-dep-tow.png'
import ProgressAirborneBufferTow from '@assets/images/common/progress-airborne-buffer-tow.png'
import ProgressAirborneDepTow from '@assets/images/common/progress-airborne-dep-tow.png'
import ProgressAirborneNoTow from '@assets/images/common/progress-airborne-no-tow.png'
import ProgressBufferTowStarted from '@assets/images/common/progress-buffer-tow-started.png'
import ProgressDefault from '@assets/images/common/progress-default.png'
import ProgressDepTowStarted from '@assets/images/common/progress-dep-tow-started.png'
import ProgressNotOnbBufferTow from '@assets/images/common/progress-not-onb-buffer-tow.png'
import ProgressNotOnbDepTow from '@assets/images/common/progress-not-onb-dep-tow.png'
import ProgressOfbBufferTow from '@assets/images/common/progress-ofb-buffer-tow.png'
import ProgressOfbDepTow from '@assets/images/common/progress-ofb-dep-tow.png'
import ProgressOfbNoTow from '@assets/images/common/progress-ofb-no-tow.png'
import ProgressOfbOverdueBufferTow from '@assets/images/common/progress-ofb-overdue-buffer-tow.png'
import ProgressOfbOverdueDepTow from '@assets/images/common/progress-ofb-overdue-dep-tow.png'
import ProgressOfbOverdueNoTow from '@assets/images/common/progress-ofb-overdue-no-tow.png'
import ProgressOnbBufferTow from '@assets/images/common/progress-onb-buffer-tow.png'
import ProgressOnbDepTow from '@assets/images/common/progress-onb-dep-tow.png'
import ProgressOnbNoTow from '@assets/images/common/progress-onb-no-tow.png'
import ProgressOnbOverdueBufferTow from '@assets/images/common/progress-onb-overdue-buffer-tow.png'
import ProgressOnbOverdueDepTow from '@assets/images/common/progress-onb-overdue-dep-tow.png'
import ProgressOnbOverdueNoTow from '@assets/images/common/progress-onb-overdue-no-tow.png'
import ProgressTaxiingInBufferTow from '@assets/images/common/progress-taxiing-in-buffer-tow.png'
import ProgressTaxiingInDepTow from '@assets/images/common/progress-taxiing-in-dep-tow.png'
import ProgressTaxiingInNoTow from '@assets/images/common/progress-taxiing-in-no-tow.png'
import ProgressTowFromBufferStarted from '@assets/images/common/progress-tow-from-buffer-started.png'
import RecheckDefault from '@assets/images/common/recheck-default.ico'
import RecheckNew from '@assets/images/common/recheck-new.ico'
import StairsDownDefault from '@assets/images/common/stairs-down-default.ico'
import StairsDownInvalid from '@assets/images/common/stairs-down-invalid.ico'
import StairsDownNew from '@assets/images/common/stairs-down-new.ico'
import StairsDownSolved from '@assets/images/common/stairs-down-solved.ico'
import StairsUpDefault from '@assets/images/common/stairs-up-default.ico'
import StairsUpInvalid from '@assets/images/common/stairs-up-invalid.ico'
import StairsUpNew from '@assets/images/common/stairs-up-new.ico'
import StairsUpSolved from '@assets/images/common/stairs-up-solved.ico'
import StatusAirborne from '@assets/images/common/status-airborne.ico'
import StatusAirbornePrev from '@assets/images/common/status-airborne-prev.ico'
import StatusAllDoorsClosed from '@assets/images/common/status-all-doors-closed.ico'
import StatusAllDoorsOpen from '@assets/images/common/status-all-doors-open.ico'
import StatusArrivedAtStand from '@assets/images/common/status-arrived-at-stand.ico'
import StatusCalculated from '@assets/images/common/status-calculated.ico'
import StatusCargoDoor from '@assets/images/common/status-cargo-door.ico'
import StatusCleaningFinished from '@assets/images/common/status-cleaning-finished.ico'
import StatusDeboardingFinished from '@assets/images/common/status-deboarding-finished.ico'
import StatusDeicingFinished from '@assets/images/common/status-deicing-finished.ico'
import StatusDeicingStarted from '@assets/images/common/status-deicing-started.ico'
import StatusEstimated from '@assets/images/common/status-estimated.ico'
import StatusEstimatedGate from '@assets/images/common/status-estimated-gate.ico'
import StatusFinalApproach from '@assets/images/common/status-final-approach.ico'
import StatusFirstBag from '@assets/images/common/status-first-bag.ico'
import StatusFlightCrewOnBoard from '@assets/images/common/status-flight-crew-on-board.ico'
import StatusGateOpen from '@assets/images/common/status-gate-open.ico'
import StatusGateOpenPending from '@assets/images/common/status-gate-open-pending.ico'
import StatusLandedNext from '@assets/images/common/status-landed-next.ico'
import StatusLastBag from '@assets/images/common/status-last-bag.ico'
import StatusLastBus from '@assets/images/common/status-last-bus.ico'
import StatusMinCrewOnBoard from '@assets/images/common/status-min-crew-on-board.ico'
import StatusOnPosition from '@assets/images/common/status-on-position.ico'
import StatusOnblockNext from '@assets/images/common/status-onblock-next.ico'
import StatusPaxDoor from '@assets/images/common/status-pax-door.ico'
import StatusPushBack from '@assets/images/common/status-push-back.ico'
import StatusReady from '@assets/images/common/status-ready.ico'
import StatusScheduled from '@assets/images/common/status-scheduled.ico'
import StatusStandBlocked from '@assets/images/common/status-stand-blocked.ico'
import StatusStarted from '@assets/images/common/status-started.ico'
import StatusStartupApproved from '@assets/images/common/status-startup-approved.ico'
import StatusStartupRequest from '@assets/images/common/status-startup-request.ico'
import StatusTaxiingAfterDeicing from '@assets/images/common/status-taxiing-after-deicing.ico'
import StatusTaxiingIn from '@assets/images/common/status-taxiing-in.ico'
import StatusTaxiingOut from '@assets/images/common/status-taxiing-out.ico'
import StatusTaxiingOutPrev from '@assets/images/common/status-taxiing-out-prev.ico'
import StatusTaxiingToDeicing from '@assets/images/common/status-taxiing-to-deicing.ico'
import StatusTenMilesOut from '@assets/images/common/status-ten-miles-out.ico'
import StatusTowingOffFinished from '@assets/images/common/status-towing-off-finished.ico'
import StatusTowingOffStarted from '@assets/images/common/status-towing-off-started.ico'
import StatusTowingOffTimer from '@assets/images/common/status-towing-off-timer.ico'
import StatusTowingOnFinished from '@assets/images/common/status-towing-on-finished.ico'
import StatusTowingOnStarted from '@assets/images/common/status-towing-on-started.ico'
import StatusTowingOnTimer from '@assets/images/common/status-towing-on-timer.ico'
import StopBlack from '@assets/images/common/stop-black.png'
import StopOutline from '@assets/images/common/stop-outline.png'
import StopRed from '@assets/images/common/stop-red.png'
import StopWhite from '@assets/images/common/stop-white.png'
import SyncBlack from '@assets/images/common/sync-black.png'
import SyncOutline from '@assets/images/common/sync-outline.png'
import SyncWhite from '@assets/images/common/sync-white.png'
import TaxiingToDeicing from '@assets/images/common/taxiing-to-deicing.ico'
import TimerOnblockClose from '@assets/images/common/timer-onblock-close.ico'
import TimerOnblockOverdue from '@assets/images/common/timer-onblock-overdue.ico'
import TimerStopped from '@assets/images/common/timer-stopped.ico'
import TowRequiredNew from '@assets/images/common/tow-required-new.ico'
import TowRequiredSolved from '@assets/images/common/tow-required-solved.ico'
import TrendDownGreen from '@assets/images/common/trend-down-green.png'
import TrendDownRed from '@assets/images/common/trend-down-red.png'
import TrendUpGreen from '@assets/images/common/trend-up-green.png'
import TrendUpRed from '@assets/images/common/trend-up-red.png'
import TurnDefault from '@assets/images/common/turn-default.ico'
import TurnInProgress from '@assets/images/common/turn-in-progress.ico'
import TurnNew from '@assets/images/common/turn-new.ico'
import TurnSolved from '@assets/images/common/turn-solved.ico'
import TurnUnread from '@assets/images/common/turn-unread.ico'
import Unmr from '@assets/images/common/unmr.png'
import UpdateDefault from '@assets/images/common/update-default.png'
import UpdateInfo from '@assets/images/common/update-info.png'
import UpdateWarning from '@assets/images/common/update-warning.png'
import Wrench from '@assets/images/common/wrench.png'
import WrenchBlack from '@assets/images/common/wrench-black.png'
import WrenchOutline from '@assets/images/common/wrench-outline.png'
import WrenchWhite from '@assets/images/common/wrench-white.png'

import { IconType } from '@components/AppBar/components/IconSection'

export const COMMON_ICONS: Record<string, IconType> = {
  AircraftDefault: {
    name: 'AircraftDefault',
    src: AircraftDefault,
  },
  AircraftInProgress: {
    name: 'AircraftInProgress',
    src: AircraftInProgress,
  },
  AircraftInfo: {
    name: 'AircraftInfo',
    src: AircraftInfo,
  },
  AircraftNew: {
    name: 'AircraftNew',
    src: AircraftNew,
  },
  AircraftSolved: {
    name: 'AircraftSolved',
    src: AircraftSolved,
  },
  AircraftUnread: {
    name: 'AircraftUnread',
    src: AircraftUnread,
  },
  AircraftWarning: {
    name: 'AircraftWarning',
    src: AircraftWarning,
  },
  ArrivalBlack: {
    name: 'ArrivalBlack',
    src: ArrivalBlack,
  },
  ArrivalCanceled: {
    name: 'ArrivalCanceled',
    src: ArrivalCanceled,
  },
  ArrivalDefault: {
    name: 'ArrivalDefault',
    src: ArrivalDefault,
  },
  ArrivalDiverted: {
    name: 'ArrivalDiverted',
    src: ArrivalDiverted,
  },
  ArrivalInProgress: {
    name: 'ArrivalInProgress',
    src: ArrivalInProgress,
  },
  ArrivalNew: {
    name: 'ArrivalNew',
    src: ArrivalNew,
  },
  ArrivalPrioDefault: {
    name: 'ArrivalPrioDefault',
    src: ArrivalPrioDefault,
  },
  ArrivalPrioHigh: {
    name: 'ArrivalPrioHigh',
    src: ArrivalPrioHigh,
  },
  ArrivalPrioLow: {
    name: 'ArrivalPrioLow',
    src: ArrivalPrioLow,
  },
  ArrivalSolved: {
    name: 'ArrivalSolved',
    src: ArrivalSolved,
  },
  ArrivalUnread: {
    name: 'ArrivalUnread',
    src: ArrivalUnread,
  },
  AttentionSymbolError: {
    name: 'AttentionSymbolError',
    src: AttentionSymbolError,
  },
  AttentionSymbolInfo: {
    name: 'AttentionSymbolInfo',
    src: AttentionSymbolInfo,
  },
  AttentionSymbolWarning: {
    name: 'AttentionSymbolWarning',
    src: AttentionSymbolWarning,
  },
  BagDefault: {
    name: 'BagDefault',
    src: BagDefault,
  },
  BagInProgress: {
    name: 'BagInProgress',
    src: BagInProgress,
  },
  BagLooseHighAmount: {
    name: 'BagLooseHighAmount',
    src: BagLooseHighAmount,
  },
  BagLooseLowAmount: {
    name: 'BagLooseLowAmount',
    src: BagLooseLowAmount,
  },
  BagLooseMediumAmount: {
    name: 'BagLooseMediumAmount',
    src: BagLooseMediumAmount,
  },
  BagNew: {
    name: 'BagNew',
    src: BagNew,
  },
  BagSolved: {
    name: 'BagSolved',
    src: BagSolved,
  },
  BagUnread: {
    name: 'BagUnread',
    src: BagUnread,
  },
  BanBlack: {
    name: 'BanBlack',
    src: BanBlack,
  },
  BanOutline: {
    name: 'BanOutline',
    src: BanOutline,
  },
  BanWhite: {
    name: 'BanWhite',
    src: BanWhite,
  },
  BarInfo: {
    name: 'BarInfo',
    src: BarInfo,
  },
  BarWarning: {
    name: 'BarWarning',
    src: BarWarning,
  },
  BoardingClose: {
    name: 'BoardingClose',
    src: BoardingClose,
  },
  BoardingComplete: {
    name: 'BoardingComplete',
    src: BoardingComplete,
  },
  BoardingEndOverdue: {
    name: 'BoardingEndOverdue',
    src: BoardingEndOverdue,
  },
  BoardingOverdue: {
    name: 'BoardingOverdue',
    src: BoardingOverdue,
  },
  BoardingProgressCritical: {
    name: 'BoardingProgressCritical',
    src: BoardingProgressCritical,
  },
  BoardingStarted: {
    name: 'BoardingStarted',
    src: BoardingStarted,
  },
  BoltBlack: {
    name: 'BoltBlack',
    src: BoltBlack,
  },
  BoltOutline: {
    name: 'BoltOutline',
    src: BoltOutline,
  },
  BoltRed: {
    name: 'BoltRed',
    src: BoltRed,
  },
  BoltWhite: {
    name: 'BoltWhite',
    src: BoltWhite,
  },
  CargoCar: {
    name: 'CargoCar',
    src: CargoCar,
  },
  CargoCool: {
    name: 'CargoCool',
    src: CargoCool,
  },
  CargoDefault: {
    name: 'CargoDefault',
    src: CargoDefault,
  },
  CargoDefaultAlternate: {
    name: 'CargoDefaultAlternate',
    src: CargoDefaultAlternate,
  },
  CargoDocsHighAmountReverse: {
    name: 'CargoDocsHighAmountReverse',
    src: CargoDocsHighAmountReverse,
  },
  CargoDocsLowAmountReverse: {
    name: 'CargoDocsLowAmountReverse',
    src: CargoDocsLowAmountReverse,
  },
  CargoDocsMediumAmountReverse: {
    name: 'CargoDocsMediumAmountReverse',
    src: CargoDocsMediumAmountReverse,
  },
  CargoInProgress: {
    name: 'CargoInProgress',
    src: CargoInProgress,
  },
  CargoLooseHighAmount: {
    name: 'CargoLooseHighAmount',
    src: CargoLooseHighAmount,
  },
  CargoLooseHighAmountReverse: {
    name: 'CargoLooseHighAmountReverse',
    src: CargoLooseHighAmountReverse,
  },
  CargoLooseLowAmount: {
    name: 'CargoLooseLowAmount',
    src: CargoLooseLowAmount,
  },
  CargoLooseLowAmountReverse: {
    name: 'CargoLooseLowAmountReverse',
    src: CargoLooseLowAmountReverse,
  },
  CargoLooseMediumAmount: {
    name: 'CargoLooseMediumAmount',
    src: CargoLooseMediumAmount,
  },
  CargoLooseMediumAmountReverse: {
    name: 'CargoLooseMediumAmountReverse',
    src: CargoLooseMediumAmountReverse,
  },
  CargoMeat: {
    name: 'CargoMeat',
    src: CargoMeat,
  },
  CargoNew: {
    name: 'CargoNew',
    src: CargoNew,
  },
  CargoNewAlternate: {
    name: 'CargoNewAlternate',
    src: CargoNewAlternate,
  },
  CargoNewReverse: {
    name: 'CargoNewReverse',
    src: CargoNewReverse,
  },
  CargoPill: {
    name: 'CargoPill',
    src: CargoPill,
  },
  CargoSolved: {
    name: 'CargoSolved',
    src: CargoSolved,
  },
  CargoSolvedAlternate: {
    name: 'CargoSolvedAlternate',
    src: CargoSolvedAlternate,
  },
  CargouldHighAmount: {
    name: 'CargouldHighAmount',
    src: CargouldHighAmount,
  },
  CargouldLowAmount: {
    name: 'CargouldLowAmount',
    src: CargouldLowAmount,
  },
  CargouldMediumAmount: {
    name: 'CargouldMediumAmount',
    src: CargouldMediumAmount,
  },
  CargoUnread: {
    name: 'CargoUnread',
    src: CargoUnread,
  },
  CargoXps: {
    name: 'CargoXps',
    src: CargoXps,
  },
  Comment: {
    name: 'Comment',
    src: Comment,
  },
  CommentBlack: {
    name: 'CommentBlack',
    src: CommentBlack,
  },
  CommentOutline: {
    name: 'CommentOutline',
    src: CommentOutline,
  },
  CommentWhite: {
    name: 'CommentWhite',
    src: CommentWhite,
  },
  ConnStateConnected: {
    name: 'ConnStateConnected',
    src: ConnStateConnected,
  },
  ConnStateCritical: {
    name: 'ConnStateCritical',
    src: ConnStateCritical,
  },
  ConnStateDefault: {
    name: 'ConnStateDefault',
    src: ConnStateDefault,
  },
  ConnStateMisconnected: {
    name: 'ConnStateMisconnected',
    src: ConnStateMisconnected,
  },
  ConnStateRequested: {
    name: 'ConnStateRequested',
    src: ConnStateRequested,
  },
  ConnStateRequestedDefault: {
    name: 'ConnStateRequestedDefault',
    src: ConnStateRequestedDefault,
  },
  ConnStateRerouted: {
    name: 'ConnStateRerouted',
    src: ConnStateRerouted,
  },
  ConnStateReroutedDefault: {
    name: 'ConnStateReroutedDefault',
    src: ConnStateReroutedDefault,
  },
  ConnStateSameAc: {
    name: 'ConnStateSameAc',
    src: ConnStateSameAc,
  },
  ConnStateSameAcDefault: {
    name: 'ConnStateSameAcDefault',
    src: ConnStateSameAcDefault,
  },
  ConnStateUnknown: {
    name: 'ConnStateUnknown',
    src: ConnStateUnknown,
  },
  ConnStateUnknownDefault: {
    name: 'ConnStateUnknownDefault',
    src: ConnStateUnknownDefault,
  },
  ConnStateWillWait: {
    name: 'ConnStateWillWait',
    src: ConnStateWillWait,
  },
  ConnStateWillWaitDefault: {
    name: 'ConnStateWillWaitDefault',
    src: ConnStateWillWaitDefault,
  },
  CpmInvalid: {
    name: 'CpmInvalid',
    src: CpmInvalid,
  },
  CpmRead: {
    name: 'CpmRead',
    src: CpmRead,
  },
  CpmUpdated: {
    name: 'CpmUpdated',
    src: CpmUpdated,
  },
  CustomsRequired: {
    name: 'CustomsRequired',
    src: CustomsRequired,
  },
  DdoArrivalActive: {
    name: 'DdoArrivalActive',
    src: DdoArrivalActive,
  },
  DdoArrivalActiveGantt: {
    name: 'DdoArrivalActiveGantt',
    src: DdoArrivalActiveGantt,
  },
  DdoArrivalCanceled: {
    name: 'DdoArrivalCanceled',
    src: DdoArrivalCanceled,
  },
  DdoArrivalCanceledGantt: {
    name: 'DdoArrivalCanceledGantt',
    src: DdoArrivalCanceledGantt,
  },
  DdoDepartureActive: {
    name: 'DdoDepartureActive',
    src: DdoDepartureActive,
  },
  DdoDepartureActiveGantt: {
    name: 'DdoDepartureActiveGantt',
    src: DdoDepartureActiveGantt,
  },
  DdoDepartureCanceled: {
    name: 'DdoDepartureCanceled',
    src: DdoDepartureCanceled,
  },
  DdoDepartureCanceledGantt: {
    name: 'DdoDepartureCanceledGantt',
    src: DdoDepartureCanceledGantt,
  },
  DepartureBlack: {
    name: 'DepartureBlack',
    src: DepartureBlack,
  },
  DepartureCanceled: {
    name: 'DepartureCanceled',
    src: DepartureCanceled,
  },
  DepartureDefault: {
    name: 'DepartureDefault',
    src: DepartureDefault,
  },
  DepartureInProgress: {
    name: 'DepartureInProgress',
    src: DepartureInProgress,
  },
  DepartureNew: {
    name: 'DepartureNew',
    src: DepartureNew,
  },
  DeparturePrioDefault: {
    name: 'DeparturePrioDefault',
    src: DeparturePrioDefault,
  },
  DeparturePrioHigh: {
    name: 'DeparturePrioHigh',
    src: DeparturePrioHigh,
  },
  DeparturePrioLow: {
    name: 'DeparturePrioLow',
    src: DeparturePrioLow,
  },
  DepartureSolved: {
    name: 'DepartureSolved',
    src: DepartureSolved,
  },
  DepartureUnread: {
    name: 'DepartureUnread',
    src: DepartureUnread,
  },
  DomesticFlight: {
    name: 'DomesticFlight',
    src: DomesticFlight,
  },
  EuropeanFlight: {
    name: 'EuropeanFlight',
    src: EuropeanFlight,
  },
  ExclamationTriangleBlack: {
    name: 'ExclamationTriangleBlack',
    src: ExclamationTriangleBlack,
  },
  ExclamationTriangleOrange: {
    name: 'ExclamationTriangleOrange',
    src: ExclamationTriangleOrange,
  },
  ExclamationTriangleOutline: {
    name: 'ExclamationTriangleOutline',
    src: ExclamationTriangleOutline,
  },
  ExclamationTriangleWhite: {
    name: 'ExclamationTriangleWhite',
    src: ExclamationTriangleWhite,
  },
  Hourglass: {
    name: 'Hourglass',
    src: Hourglass,
  },
  HourglassBlack: {
    name: 'HourglassBlack',
    src: HourglassBlack,
  },
  HourglassOutline: {
    name: 'HourglassOutline',
    src: HourglassOutline,
  },
  HourglassWhite: {
    name: 'HourglassWhite',
    src: HourglassWhite,
  },
  InitialTimeEarly: {
    name: 'InitialTimeEarly',
    src: InitialTimeEarly,
  },
  InitialTimeLate: {
    name: 'InitialTimeLate',
    src: InitialTimeLate,
  },
  IntIrnationalFlight: {
    name: 'IntIrnationalFlight',
    src: IntIrnationalFlight,
  },
  LinkAnchorLeft: {
    name: 'LinkAnchorLeft',
    src: LinkAnchorLeft,
  },
  LinkAnchorRight: {
    name: 'LinkAnchorRight',
    src: LinkAnchorRight,
  },
  LocationDefault: {
    name: 'LocationDefault',
    src: LocationDefault,
  },
  LocationInProgress: {
    name: 'LocationInProgress',
    src: LocationInProgress,
  },
  LocationNew: {
    name: 'LocationNew',
    src: LocationNew,
  },
  LocationSolved: {
    name: 'LocationSolved',
    src: LocationSolved,
  },
  LocationUnread: {
    name: 'LocationUnread',
    src: LocationUnread,
  },
  LockDefault: {
    name: 'LockDefault',
    src: LockDefault,
  },
  LockLocked: {
    name: 'LockLocked',
    src: LockLocked,
  },
  MaintenanceDefault: {
    name: 'MaintenanceDefault',
    src: MaintenanceDefault,
  },
  MaintenanceNew: {
    name: 'MaintenanceNew',
    src: MaintenanceNew,
  },
  MaintenanceSolved: {
    name: 'MaintenanceSolved',
    src: MaintenanceSolved,
  },
  MaintenanceUnread: {
    name: 'MaintenanceUnread',
    src: MaintenanceUnread,
  },
  MelDefault: {
    name: 'MelDefault',
    src: MelDefault,
  },
  MelNew: {
    name: 'MelNew',
    src: MelNew,
  },
  MelSolved: {
    name: 'MelSolved',
    src: MelSolved,
  },
  MelUnread: {
    name: 'MelUnread',
    src: MelUnread,
  },
  MessageDefault: {
    name: 'MessageDefault',
    src: MessageDefault,
  },
  MessageDeleted: {
    name: 'MessageDeleted',
    src: MessageDeleted,
  },
  MessageRead: {
    name: 'MessageRead',
    src: MessageRead,
  },
  MessageSolidDefault: {
    name: 'MessageSolidDefault',
    src: MessageSolidDefault,
  },
  MessageSolidRead: {
    name: 'MessageSolidRead',
    src: MessageSolidRead,
  },
  MessageSolidUnread: {
    name: 'MessageSolidUnread',
    src: MessageSolidUnread,
  },
  MessageUnread: {
    name: 'MessageUnread',
    src: MessageUnread,
  },
  MilesToneLinkAnchor: {
    name: 'MilesToneLinkAnchor',
    src: MilesToneLinkAnchor,
  },
  MinusBlack: {
    name: 'MinusBlack',
    src: MinusBlack,
  },
  MinusOrange: {
    name: 'MinusOrange',
    src: MinusOrange,
  },
  MinusOutline: {
    name: 'MinusOutline',
    src: MinusOutline,
  },
  MinusWhite: {
    name: 'MinusWhite',
    src: MinusWhite,
  },
  MissingEtd: {
    name: 'MissingEtd',
    src: MissingEtd,
  },
  Nonps: {
    name: 'Nonps',
    src: Nonps,
  },
  OccurrencesDefault: {
    name: 'OccurrencesDefault',
    src: OccurrencesDefault,
  },
  OccurrencesResolved: {
    name: 'OccurrencesResolved',
    src: OccurrencesResolved,
  },
  OccurrencesUnread: {
    name: 'OccurrencesUnread',
    src: OccurrencesUnread,
  },
  OccurrencesUnresolved: {
    name: 'OccurrencesUnresolved',
    src: OccurrencesUnresolved,
  },
  Offblock: {
    name: 'Offblock',
    src: Offblock,
  },
  OffblockEarly: {
    name: 'OffblockEarly',
    src: OffblockEarly,
  },
  OffblockLate: {
    name: 'OffblockLate',
    src: OffblockLate,
  },
  OnblockEarly: {
    name: 'OnblockEarly',
    src: OnblockEarly,
  },
  OnblockLate: {
    name: 'OnblockLate',
    src: OnblockLate,
  },
  OnTime: {
    name: 'OnTime',
    src: OnTime,
  },
  OutboundGrey: {
    name: 'OutboundGrey',
    src: OutboundGrey,
  },
  Pause: {
    name: 'Pause',
    src: Pause,
  },
  PauseBlack: {
    name: 'PauseBlack',
    src: PauseBlack,
  },
  PauseOutline: {
    name: 'PauseOutline',
    src: PauseOutline,
  },
  PauseWhite: {
    name: 'PauseWhite',
    src: PauseWhite,
  },
  PaxDecisionDefault: {
    name: 'PaxDecisionDefault',
    src: PaxDecisionDefault,
  },
  PaxDecisionGreen: {
    name: 'PaxDecisionGreen',
    src: PaxDecisionGreen,
  },
  PaxDecisionLime: {
    name: 'PaxDecisionLime',
    src: PaxDecisionLime,
  },
  PaxDecisionOrange: {
    name: 'PaxDecisionOrange',
    src: PaxDecisionOrange,
  },
  PaxDecisionRed: {
    name: 'PaxDecisionRed',
    src: PaxDecisionRed,
  },
  PaxDecisionRedRecheck: {
    name: 'PaxDecisionRedRecheck',
    src: PaxDecisionRedRecheck,
  },
  PaxDefault: {
    name: 'PaxDefault',
    src: PaxDefault,
  },
  PaxInProgress: {
    name: 'PaxInProgress',
    src: PaxInProgress,
  },
  PaxNew: {
    name: 'PaxNew',
    src: PaxNew,
  },
  PaxSolved: {
    name: 'PaxSolved',
    src: PaxSolved,
  },
  PaxUnread: {
    name: 'PaxUnread',
    src: PaxUnread,
  },
  PilotDefault: {
    name: 'PilotDefault',
    src: PilotDefault,
  },
  PilotInProgress: {
    name: 'PilotInProgress',
    src: PilotInProgress,
  },
  PilotNew: {
    name: 'PilotNew',
    src: PilotNew,
  },
  PilotSolved: {
    name: 'PilotSolved',
    src: PilotSolved,
  },
  PilotUnread: {
    name: 'PilotUnread',
    src: PilotUnread,
  },
  PrioCrewNew: {
    name: 'PrioCrewNew',
    src: PrioCrewNew,
  },
  PrioCrewSolved: {
    name: 'PrioCrewSolved',
    src: PrioCrewSolved,
  },
  PrioDefault: {
    name: 'PrioDefault',
    src: PrioDefault,
  },
  PrioHighNew: {
    name: 'PrioHighNew',
    src: PrioHighNew,
  },
  PrioHighSolved: {
    name: 'PrioHighSolved',
    src: PrioHighSolved,
  },
  PrioQuickNew: {
    name: 'PrioQuickNew',
    src: PrioQuickNew,
  },
  PrioQuickSolved: {
    name: 'PrioQuickSolved',
    src: PrioQuickSolved,
  },
  PrioTransferNew: {
    name: 'PrioTransferNew',
    src: PrioTransferNew,
  },
  PrioTransferSolved: {
    name: 'PrioTransferSolved',
    src: PrioTransferSolved,
  },
  Progress12BufferTow: {
    name: 'Progress12BufferTow',
    src: Progress12BufferTow,
  },
  Progress12DepTow: {
    name: 'Progress12DepTow',
    src: Progress12DepTow,
  },
  Progress12NoTow: {
    name: 'Progress12NoTow',
    src: Progress12NoTow,
  },
  Progress25BufferTow: {
    name: 'Progress25BufferTow',
    src: Progress25BufferTow,
  },
  Progress25DepTow: {
    name: 'Progress25DepTow',
    src: Progress25DepTow,
  },
  Progress25NoTow: {
    name: 'Progress25NoTow',
    src: Progress25NoTow,
  },
  Progress38DepTow: {
    name: 'Progress38DepTow',
    src: Progress38DepTow,
  },
  Progress38NoTow: {
    name: 'Progress38NoTow',
    src: Progress38NoTow,
  },
  Progress50AfterBufferTow: {
    name: 'Progress50AfterBufferTow',
    src: Progress50AfterBufferTow,
  },
  Progress50AfterDepTow: {
    name: 'Progress50AfterDepTow',
    src: Progress50AfterDepTow,
  },
  Progress50NoTow: {
    name: 'Progress50NoTow',
    src: Progress50NoTow,
  },
  Progress63NoTow: {
    name: 'Progress63NoTow',
    src: Progress63NoTow,
  },
  Progress75DepTow: {
    name: 'Progress75DepTow',
    src: Progress75DepTow,
  },
  Progress75NoTow: {
    name: 'Progress75NoTow',
    src: Progress75NoTow,
  },
  Progress87BufferTow: {
    name: 'Progress87BufferTow',
    src: Progress87BufferTow,
  },
  Progress87NoTow: {
    name: 'Progress87NoTow',
    src: Progress87NoTow,
  },
  Progress100BufferTow: {
    name: 'Progress100BufferTow',
    src: Progress100BufferTow,
  },
  Progress100DepTow: {
    name: 'Progress100DepTow',
    src: Progress100DepTow,
  },
  Progress100NoTow: {
    name: 'Progress100NoTow',
    src: Progress100NoTow,
  },
  ProgressAcOnBuffer: {
    name: 'ProgressAcOnBuffer',
    src: ProgressAcOnBuffer,
  },
  ProgressAcOnPosAfterBufferTow: {
    name: 'ProgressAcOnPosAfterBufferTow',
    src: ProgressAcOnPosAfterBufferTow,
  },
  ProgressAcOnPosAfterDepTow: {
    name: 'ProgressAcOnPosAfterDepTow',
    src: ProgressAcOnPosAfterDepTow,
  },
  ProgressAirborneBufferTow: {
    name: 'ProgressAirborneBufferTow',
    src: ProgressAirborneBufferTow,
  },
  ProgressAirborneDepTow: {
    name: 'ProgressAirborneDepTow',
    src: ProgressAirborneDepTow,
  },
  ProgressAirborneNoTow: {
    name: 'ProgressAirborneNoTow',
    src: ProgressAirborneNoTow,
  },
  ProgressBufferTowStarted: {
    name: 'ProgressBufferTowStarted',
    src: ProgressBufferTowStarted,
  },
  ProgressDefault: {
    name: 'ProgressDefault',
    src: ProgressDefault,
  },
  ProgressDepTowStarted: {
    name: 'ProgressDepTowStarted',
    src: ProgressDepTowStarted,
  },
  ProgressNotOnbBufferTow: {
    name: 'ProgressNotOnbBufferTow',
    src: ProgressNotOnbBufferTow,
  },
  ProgressNotOnbDepTow: {
    name: 'ProgressNotOnbDepTow',
    src: ProgressNotOnbDepTow,
  },
  ProgressOfbBufferTow: {
    name: 'ProgressOfbBufferTow',
    src: ProgressOfbBufferTow,
  },
  ProgressOfbDepTow: {
    name: 'ProgressOfbDepTow',
    src: ProgressOfbDepTow,
  },
  ProgressOfbNoTow: {
    name: 'ProgressOfbNoTow',
    src: ProgressOfbNoTow,
  },
  ProgressOfbOverdueBufferTow: {
    name: 'ProgressOfbOverdueBufferTow',
    src: ProgressOfbOverdueBufferTow,
  },
  ProgressOfbOverdueDepTow: {
    name: 'ProgressOfbOverdueDepTow',
    src: ProgressOfbOverdueDepTow,
  },
  ProgressOfbOverdueNoTow: {
    name: 'ProgressOfbOverdueNoTow',
    src: ProgressOfbOverdueNoTow,
  },
  ProgressOnbBufferTow: {
    name: 'ProgressOnbBufferTow',
    src: ProgressOnbBufferTow,
  },
  ProgressOnbDepTow: {
    name: 'ProgressOnbDepTow',
    src: ProgressOnbDepTow,
  },
  ProgressOnbNoTow: {
    name: 'ProgressOnbNoTow',
    src: ProgressOnbNoTow,
  },
  ProgressOnbOverdueBufferTow: {
    name: 'ProgressOnbOverdueBufferTow',
    src: ProgressOnbOverdueBufferTow,
  },
  ProgressOnbOverdueDepTow: {
    name: 'ProgressOnbOverdueDepTow',
    src: ProgressOnbOverdueDepTow,
  },
  ProgressOnbOverdueNoTow: {
    name: 'ProgressOnbOverdueNoTow',
    src: ProgressOnbOverdueNoTow,
  },
  ProgressTaxiingInBufferTow: {
    name: 'ProgressTaxiingInBufferTow',
    src: ProgressTaxiingInBufferTow,
  },
  ProgressTaxiingInDepTow: {
    name: 'ProgressTaxiingInDepTow',
    src: ProgressTaxiingInDepTow,
  },
  ProgressTaxiingInNoTow: {
    name: 'ProgressTaxiingInNoTow',
    src: ProgressTaxiingInNoTow,
  },
  ProgressTowFromBufferStarted: {
    name: 'ProgressTowFromBufferStarted',
    src: ProgressTowFromBufferStarted,
  },
  RecheckDefault: {
    name: 'RecheckDefault',
    src: RecheckDefault,
  },
  RecheckNew: {
    name: 'RecheckNew',
    src: RecheckNew,
  },
  StairsDownDefault: {
    name: 'StairsDownDefault',
    src: StairsDownDefault,
  },
  StairsDownInvalid: {
    name: 'StairsDownInvalid',
    src: StairsDownInvalid,
  },
  StairsDownNew: {
    name: 'StairsDownNew',
    src: StairsDownNew,
  },
  StairsDownSolved: {
    name: 'StairsDownSolved',
    src: StairsDownSolved,
  },
  StairsUpDefault: {
    name: 'StairsUpDefault',
    src: StairsUpDefault,
  },
  StairsUpInvalid: {
    name: 'StairsUpInvalid',
    src: StairsUpInvalid,
  },
  StairsUpNew: {
    name: 'StairsUpNew',
    src: StairsUpNew,
  },
  StairsUpSolved: {
    name: 'StairsUpSolved',
    src: StairsUpSolved,
  },
  StatusAirborne: {
    name: 'StatusAirborne',
    src: StatusAirborne,
  },
  StatusAirbornePrev: {
    name: 'StatusAirbornePrev',
    src: StatusAirbornePrev,
  },
  StatusAllDoorsClosed: {
    name: 'StatusAllDoorsClosed',
    src: StatusAllDoorsClosed,
  },
  StatusAllDoorsOpen: {
    name: 'StatusAllDoorsOpen',
    src: StatusAllDoorsOpen,
  },
  StatusArrivedAtStand: {
    name: 'StatusArrivedAtStand',
    src: StatusArrivedAtStand,
  },
  StatusCalculated: {
    name: 'StatusCalculated',
    src: StatusCalculated,
  },
  StatusCargoDoor: {
    name: 'StatusCargoDoor',
    src: StatusCargoDoor,
  },
  StatusCleaningFinished: {
    name: 'StatusCleaningFinished',
    src: StatusCleaningFinished,
  },
  StatusDeboardingFinished: {
    name: 'StatusDeboardingFinished',
    src: StatusDeboardingFinished,
  },
  StatusDeicingFinished: {
    name: 'StatusDeicingFinished',
    src: StatusDeicingFinished,
  },
  StatusDeicingStarted: {
    name: 'StatusDeicingStarted',
    src: StatusDeicingStarted,
  },
  StatusEstimated: {
    name: 'StatusEstimated',
    src: StatusEstimated,
  },
  StatusEstimatedGate: {
    name: 'StatusEstimatedGate',
    src: StatusEstimatedGate,
  },
  StatusFinalApproach: {
    name: 'StatusFinalApproach',
    src: StatusFinalApproach,
  },
  StatusFirstBag: {
    name: 'StatusFirstBag',
    src: StatusFirstBag,
  },
  StatusFlightCrewOnBoard: {
    name: 'StatusFlightCrewOnBoard',
    src: StatusFlightCrewOnBoard,
  },
  StatusGateOpen: {
    name: 'StatusGateOpen',
    src: StatusGateOpen,
  },
  StatusGateOpenPending: {
    name: 'StatusGateOpenPending',
    src: StatusGateOpenPending,
  },
  StatusLandedNext: {
    name: 'StatusLandedNext',
    src: StatusLandedNext,
  },
  StatusLastBag: {
    name: 'StatusLastBag',
    src: StatusLastBag,
  },
  StatusLastBus: {
    name: 'StatusLastBus',
    src: StatusLastBus,
  },
  StatusMinCrewOnBoard: {
    name: 'StatusMinCrewOnBoard',
    src: StatusMinCrewOnBoard,
  },
  StatusOnblockNext: {
    name: 'StatusOnblockNext',
    src: StatusOnblockNext,
  },
  StatusOnPosition: {
    name: 'StatusOnPosition',
    src: StatusOnPosition,
  },
  StatusPaxDoor: {
    name: 'StatusPaxDoor',
    src: StatusPaxDoor,
  },
  StatusPushBack: {
    name: 'StatusPushBack',
    src: StatusPushBack,
  },
  StatusReady: {
    name: 'StatusReady',
    src: StatusReady,
  },
  StatusScheduled: {
    name: 'StatusScheduled',
    src: StatusScheduled,
  },
  StatusStandBlocked: {
    name: 'StatusStandBlocked',
    src: StatusStandBlocked,
  },
  StatusStarted: {
    name: 'StatusStarted',
    src: StatusStarted,
  },
  StatusStartupApproved: {
    name: 'StatusStartupApproved',
    src: StatusStartupApproved,
  },
  StatusStartupRequest: {
    name: 'StatusStartupRequest',
    src: StatusStartupRequest,
  },
  StatusTaxiingAfterDeicing: {
    name: 'StatusTaxiingAfterDeicing',
    src: StatusTaxiingAfterDeicing,
  },
  StatusTaxiingIn: {
    name: 'StatusTaxiingIn',
    src: StatusTaxiingIn,
  },
  StatusTaxiingOut: {
    name: 'StatusTaxiingOut',
    src: StatusTaxiingOut,
  },
  StatusTaxiingOutPrev: {
    name: 'StatusTaxiingOutPrev',
    src: StatusTaxiingOutPrev,
  },
  StatusTaxiingToDeicing: {
    name: 'StatusTaxiingToDeicing',
    src: StatusTaxiingToDeicing,
  },
  StatusTenMilesOut: {
    name: 'StatusTenMilesOut',
    src: StatusTenMilesOut,
  },
  StatusTowingOffFinished: {
    name: 'StatusTowingOffFinished',
    src: StatusTowingOffFinished,
  },
  StatusTowingOffStarted: {
    name: 'StatusTowingOffStarted',
    src: StatusTowingOffStarted,
  },
  StatusTowingOffTimer: {
    name: 'StatusTowingOffTimer',
    src: StatusTowingOffTimer,
  },
  StatusTowingOnFinished: {
    name: 'StatusTowingOnFinished',
    src: StatusTowingOnFinished,
  },
  StatusTowingOnStarted: {
    name: 'StatusTowingOnStarted',
    src: StatusTowingOnStarted,
  },
  StatusTowingOnTimer: {
    name: 'StatusTowingOnTimer',
    src: StatusTowingOnTimer,
  },
  StopBlack: {
    name: 'StopBlack',
    src: StopBlack,
  },
  StopOutline: {
    name: 'StopOutline',
    src: StopOutline,
  },
  StopRed: {
    name: 'StopRed',
    src: StopRed,
  },
  StopWhite: {
    name: 'StopWhite',
    src: StopWhite,
  },
  SyncBlack: {
    name: 'SyncBlack',
    src: SyncBlack,
  },
  SyncOutline: {
    name: 'SyncOutline',
    src: SyncOutline,
  },
  SyncWhite: {
    name: 'SyncWhite',
    src: SyncWhite,
  },
  TaxiingToDeicing: {
    name: 'TaxiingToDeicing',
    src: TaxiingToDeicing,
  },
  TimerOnblockClose: {
    name: 'TimerOnblockClose',
    src: TimerOnblockClose,
  },
  TimerOnblockOverdue: {
    name: 'TimerOnblockOverdue',
    src: TimerOnblockOverdue,
  },
  TimerStopped: {
    name: 'TimerStopped',
    src: TimerStopped,
  },
  TowRequiredNew: {
    name: 'TowRequiredNew',
    src: TowRequiredNew,
  },
  TowRequiredSolved: {
    name: 'TowRequiredSolved',
    src: TowRequiredSolved,
  },
  TrendDownGreen: {
    name: 'TrendDownGreen',
    src: TrendDownGreen,
  },
  TrendDownRed: {
    name: 'TrendDownRed',
    src: TrendDownRed,
  },
  TrendUpGreen: {
    name: 'TrendUpGreen',
    src: TrendUpGreen,
  },
  TrendUpRed: {
    name: 'TrendUpRed',
    src: TrendUpRed,
  },
  TurnDefault: {
    name: 'TurnDefault',
    src: TurnDefault,
  },
  TurnInProgress: {
    name: 'TurnInProgress',
    src: TurnInProgress,
  },
  TurnNew: {
    name: 'TurnNew',
    src: TurnNew,
  },
  TurnSolved: {
    name: 'TurnSolved',
    src: TurnSolved,
  },
  TurnUnread: {
    name: 'TurnUnread',
    src: TurnUnread,
  },
  Unmr: {
    name: 'Unmr',
    src: Unmr,
  },
  UpdateDefault: {
    name: 'UpdateDefault',
    src: UpdateDefault,
  },
  UpdateInfo: {
    name: 'UpdateInfo',
    src: UpdateInfo,
  },
  UpdateWarning: {
    name: 'UpdateWarning',
    src: UpdateWarning,
  },
  Wrench: {
    name: 'Wrench',
    src: Wrench,
  },
  WrenchBlack: {
    name: 'WrenchBlack',
    src: WrenchBlack,
  },
  WrenchOutline: {
    name: 'WrenchOutline',
    src: WrenchOutline,
  },
  WrenchWhite: {
    name: 'WrenchWhite',
    src: WrenchWhite,
  },
}
