import { FC, HTMLAttributes, useContext, useMemo } from 'react'

import { PageContext } from '@pages/ConfiguredEntity'

import { useDisplayFormState } from '@components/DisplayForm/hooks'

import { DropDownListLine, DropDownListLineProps } from '../DropDownListLine'

type DropDownListProps = {
  props: HTMLAttributes<HTMLLIElement>
} & Omit<DropDownListLineProps, 'liProps' | 'columns' | 'isDialogWindow'>

export const DropDownList: FC<DropDownListProps> = ({
  combinedData,
  dropDownList,
  props,
  option,
  pkFieldName,
}) => {
  const { isViewDialogWindow } = useContext(PageContext)
  const { isFormDialogWindow } = useDisplayFormState()

  const dropDownListColumns = useMemo(
    () => dropDownList?.columns?.filter(col => col.visibility) || [],
    [dropDownList]
  )

  return (
    <DropDownListLine
      key={option.id}
      columns={dropDownListColumns}
      combinedData={combinedData}
      dropDownList={dropDownList}
      isDialogWindow={isViewDialogWindow || isFormDialogWindow}
      liProps={props}
      option={option}
      pkFieldName={pkFieldName}
    />
  )
}
