import { FormState } from 'src/types'

/**
 * Проверка данных текущей открытой формы в редаксе на наличие в ней открытых контейнеров
 * @param formCode Код текущей открытой формы
 * @param formStates Текущее состояние редакса
 * @returns Результат операции Array.some() над данными контейнеров: true, если хотя бы у одного open === true, иначе false
 */
export const getInitialExpandedContainers = (
  formCode: string | undefined,
  formStates: FormState
): boolean => {
  if (!formCode) {
    return false
  }

  const currentFormDataInRedux = formStates[formCode]

  if (!currentFormDataInRedux) {
    return false
  }

  const currentFormDataContainers = currentFormDataInRedux.containers

  // Описание этой переменной в @returns
  const areThereOpenedContainersInData = Object.keys(currentFormDataContainers).some(
    containerKey => {
      return currentFormDataContainers[containerKey as unknown as number].open === true
    }
  )

  return areThereOpenedContainersInData
}
