import { FC, useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { SearchAssistantConfigContext } from '@pages/ContextCreateOrEdit/components/SearchAssistant/SearchAssistant'
import { SearchFilterValue } from '@pages/ContextCreateOrEdit/components/SearchAssistant/types'

import { useAddSearchFilterDialog } from './hooks'
import { SearchFilterValueFormInputs } from './SearchFilterValueFormInputs'

export type SearchFilterForm = {
  id?: number
  code: string
  title: string
  objectTitle: string
  isPinning: boolean
  values: SearchFilterValue[]
  visibility: boolean
}

type AddSearchFilterDialogProps = {
  isEdit?: boolean
}

export const AddSearchFilterDialog: FC<AddSearchFilterDialogProps> = ({ isEdit }) => {
  const { t } = useTranslation()

  const { isDirty, handleSave, methods, searchFilterInputs } = useAddSearchFilterDialog()

  const { showAddSearchFilterModal, closeAddSearchFilterModal, isLoadingRow } = useContext(
    SearchAssistantConfigContext
  )

  return (
    <ModalWrapper
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={showAddSearchFilterModal}
      loading={isLoadingRow}
      title={t(`searchFilterForm.formTitle.${isEdit ? 'edit' : 'new'}`)}
      onClose={() => closeAddSearchFilterModal(isDirty)}
      onSave={handleSave}
    >
      <Box pb={2} pt={2} sx={{ maxHeight: 500, overflowY: 'auto', pr: 2 }}>
        <FormProvider {...methods}>
          <form>
            <FormGenerator inputs={searchFilterInputs} mainGridSpacing={0} />
            <SearchFilterValueFormInputs />
          </form>
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
