import { Row } from 'src/types'

export function getValueObjectByPath(object: Row, path: string): Row | string {
  const keys = path.split('.')
  let value = object

  keys.forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(value, key)) {
      return undefined
    }
    value = value[key]
  })

  return value
}
