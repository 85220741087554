import { Component, createRef, RefObject } from 'react'
import { connect } from 'react-redux'
import { Position, Rnd } from 'react-rnd'
import i18next from 'i18next'
import {
  AutocompleteOption,
  EntityType,
  UIOverlayCloseActionPayload,
  UIOverlayOpenActionPayload,
  UIOverlayState,
} from 'src/types'
import { v4 as uuid } from 'uuid'
import copy from '@assets/images/copy.svg'
import logo from '@assets/images/logo.svg'
import underline from '@assets/images/underline.svg'
import { Button } from '@microservices/wiskey-react-components'
import { Close as CloseIcon, UnfoldMore as UnfoldMoreIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Stack, Theme, Typography } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { onCloseDialogWindow, onOpenDialogWindow } from '@redux/reducers/uiOverlay.reducer'
import { AppDispatch, RootState } from '@redux/store'

import { Size } from '@features/DialogWindowManager/DialogWindow'
import { observeRndTransform } from '@features/DialogWindowManager/helpers'

import { buttonStylesByMode, getHighestZIndexPriority } from '@helpers'
import {
  BLOCKING_DDW_ZINDEX_INCREASE,
  DRAGGABLE_FORM_SIZES,
  DROP_DOWN_WINDOW_SIZES,
  ENTITY,
} from '@constants'

import { ConfiguredEntityForDDW } from './ConfiguredEntityForDDW'

type DropDownWindowProps = {
  customInitialOffset?: Position
  theme: Theme
  dropDownWindow: EntityType
  onClose: ({ shouldApplyValueToForm }: { shouldApplyValueToForm: boolean }) => void
  selectedPickerOptionValue?: string | AutocompleteOption
  onSelectPickerOption?: (objectId: string) => void
  onMultiSelectPickerOption?: (value: AutocompleteOption[]) => void
  multipleSelectPickerOption?: AutocompleteOption[]
  objectValueName: string
  formObjectId?: string
  formObjectCode?: string
  elementId?: number
  fieldId?: number
  valueId?: number
  isDialogWindow: boolean | undefined
  uiOverlay: UIOverlayState
  onOpenDialogWindow: (uiOverlayPayload: UIOverlayOpenActionPayload) => void
  onCloseDialogWindow: (uiOverlayPayload: UIOverlayCloseActionPayload) => void
  parentDialogId?: string
  readonly?: boolean
}

type DropDownWindowState = {
  isOpen: boolean
  storedHeight: number
  storedWidth: number
  isDragging: boolean
  isFullscreen: boolean
  isOverlayShown: boolean
  positionBeforeFullscreen: Position | null
  sizeBeforeFullscreen: Size | null
  isOpenBeforeFullscreen: boolean
  dialogIdImitation: string
}

class DropDownWindowComponent extends Component<DropDownWindowProps, DropDownWindowState> {
  rnd: Rnd | null | undefined
  wrapperRef: RefObject<HTMLDivElement> = createRef()
  containerRef: RefObject<HTMLDivElement> = createRef()
  windowResizeObserver: ResizeObserver | null = null
  buttonResizeRef: RefObject<HTMLButtonElement> = createRef()

  constructor(props: DropDownWindowProps) {
    super(props)

    this.state = {
      isOpen: true,
      storedHeight: 0,
      storedWidth: 0,
      isDragging: false,
      isFullscreen: false,
      isOverlayShown: false,
      positionBeforeFullscreen: null,
      sizeBeforeFullscreen: null,
      isOpenBeforeFullscreen: true,
      // TODO: Да момент написания этого кода, dropdown window не является dialog window
      dialogIdImitation: '',
    }
  }

  handleInitialOverlayLogic() {
    const uiOverlay = this.props.uiOverlay
    // TODO: Да момент написания этого кода, dropdown window не является dialog window
    const dialogIdImitation = uuid()

    this.setState({ dialogIdImitation })

    this.props.onOpenDialogWindow({
      dialogId: dialogIdImitation,
      parentDialogId: this.props.parentDialogId ? this.props.parentDialogId : null,
      isBlockingWindow: true,
    })
  }

  updateSize() {
    if (!this.rnd) {
      return
    }

    this.rnd.updateSize(this.getDimentions())
  }

  componentDidMount() {
    this.setState(prev => ({
      ...prev,
      storedHeight: DROP_DOWN_WINDOW_SIZES.OPENED_HEIGHT,
      storedWidth: DROP_DOWN_WINDOW_SIZES.OPENED_WIDTH,
    }))

    // Обработка возможного блюра окна rnd (см. комментарий хелпера)
    observeRndTransform(this.rnd)

    this.updateSize()
    this.handleSetBodyOverflow('hidden')
    setTimeout(() => {
      this.wrapperRef.current?.focus()
    }, 100)

    this.handleInitialOverlayLogic()
  }

  componentWillUnmount() {
    this.props.onCloseDialogWindow({ dialogId: this.state.dialogIdImitation })
  }

  componentDidUpdate(prevProps: DropDownWindowProps, prevState: DropDownWindowState) {
    this.updateSize()
  }

  getDimentions() {
    return {
      width: this.state.isOpen
        ? this.state.storedWidth
          ? this.state.storedWidth
          : DROP_DOWN_WINDOW_SIZES.OPENED_WIDTH
        : DROP_DOWN_WINDOW_SIZES.CLOSED_WIDTH,
      height: this.state.isOpen
        ? this.state.storedHeight
          ? this.state.storedHeight
          : DROP_DOWN_WINDOW_SIZES.OPENED_HEIGHT
        : DROP_DOWN_WINDOW_SIZES.CLOSED_HEIGHT,
    }
  }

  getWindowSize(): Size | null {
    const bounding = this.rnd?.getSelfElement()?.getBoundingClientRect()
    if (bounding) {
      return { width: bounding.width, height: bounding.height }
    }

    return null
  }

  handleSetBodyOverflow(value: 'visible' | 'hidden') {
    const body = document.querySelector('body') as HTMLElement

    body.style.overflow = value
  }

  handleWindowResizeWhenFullscreen() {
    this.rnd?.updateSize({ width: window.innerWidth, height: window.innerHeight })
    this.setState(prev => ({
      ...prev,
      storedHeight: window.innerHeight,
      storedWidth: window.innerWidth,
    }))
  }

  handleToggleFullscreen(value: boolean) {
    const body = document.querySelector('body') as HTMLElement
    const positionBeforeFullscreen = this.rnd?.getDraggablePosition() as Position

    if (!this.windowResizeObserver) {
      this.windowResizeObserver = new ResizeObserver(() => this.handleWindowResizeWhenFullscreen())
    }

    this.windowResizeObserver.observe(body)

    if (value) {
      this.rnd?.updatePosition({ x: 0, y: 0 })
      window.scrollTo(0, 0)
      this.handleSetBodyOverflow('hidden')
    } else {
      if (this.state.positionBeforeFullscreen) {
        this.rnd?.updatePosition(this.state.positionBeforeFullscreen)
      }

      if (this.state.sizeBeforeFullscreen) {
        this.rnd?.updateSize(this.state.sizeBeforeFullscreen)
      }
      this.handleSetBodyOverflow('visible')

      this.windowResizeObserver && this.windowResizeObserver.unobserve(body)
    }
    // Запоминаем sizeBeforeFullscreen при сворачивании окна
    const sizeBeforeFullscreen = !this.state.isOpen
      ? this.state.sizeBeforeFullscreen
      : this.getWindowSize()

    this.setState(prev => ({
      ...prev,
      storedHeight: value
        ? window.innerHeight
        : this.state.sizeBeforeFullscreen?.height ?? DROP_DOWN_WINDOW_SIZES.OPENED_HEIGHT,
      storedWidth: value
        ? window.innerWidth
        : this.state.sizeBeforeFullscreen?.width ?? DROP_DOWN_WINDOW_SIZES.OPENED_WIDTH,
      positionBeforeFullscreen: value ? positionBeforeFullscreen : null,
      sizeBeforeFullscreen: value ? sizeBeforeFullscreen : null,
      isOpenBeforeFullscreen: this.state.isOpen,
      isOpen: value || this.state.isOpenBeforeFullscreen,
      isFullscreen: value,
      isOverlayShown: value,
    }))
    this.buttonResizeRef?.current?.blur()
  }

  handleMinimize() {
    this.handleSetBodyOverflow('visible')

    this.setState({ isOpen: false, isOverlayShown: false })
  }

  handleOpen() {
    this.setState({ isOpen: true })

    if (this.state.isFullscreen) {
      this.rnd?.updatePosition({ x: 0, y: 0 })
      window.scrollTo(0, 0)
      this.handleSetBodyOverflow('hidden')
      this.setState({ isOverlayShown: true })
    }
  }

  handleDragStart() {
    this.setState({ isDragging: true })
  }

  handleDragStop() {
    this.setState({ isDragging: false })
  }

  handleResizeStop(ref: HTMLElement) {
    this.setState({
      storedWidth: Number(ref.style.width.substring(0, ref.style.width.length - 2)),
      storedHeight: Number(ref.style.height.substring(0, ref.style.height.length - 2)),
    })
  }

  handleContainerScroll() {
    const documentActiveElement = document.activeElement as HTMLElement

    if (documentActiveElement.classList.contains('MuiAutocomplete-input')) {
      documentActiveElement.blur()
    }
  }

  handleClose(shouldApplyValueToForm: boolean) {
    this.handleSetBodyOverflow('visible')
    this.props.onClose({ shouldApplyValueToForm })
  }

  handleGetShouldApplyBlockingZIndex() {
    const uiOverlay = this.props.uiOverlay

    const currentDialogDataFromOverlayTree = uiOverlay.dialogTreeData[this.state.dialogIdImitation]

    if (!currentDialogDataFromOverlayTree) {
      return false
    }

    const { isBlockingWindow, zIndexPriority } = currentDialogDataFromOverlayTree
    const highestZIndexPriority = getHighestZIndexPriority(uiOverlay.dialogTreeData)

    return isBlockingWindow && zIndexPriority === highestZIndexPriority
  }

  render() {
    const shouldApplyBlockingZIndex = this.handleGetShouldApplyBlockingZIndex()

    return (
      <>
        {/** Оверлей, чтобы в режиме полного экрана при задержке при изменении масштаба страницы
         * не было видно содержимого страницы вне dropdownwindow */}
        {this.state.isOverlayShown && (
          <Paper
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              zIndex: theme => theme.zIndex.fab,
            }}
          />
        )}
        <Box
          ref={this.wrapperRef}
          tabIndex={0}
          sx={{
            '.draggable-window__handler': {
              cursor: this.state.isOverlayShown ? 'default' : 'move !important',
            },
            '.MuiButtonBase-root, .MuiBadge-root': {
              visibility: 'inherit !important',
            },
          }}
        >
          <Rnd
            ref={c => {
              this.rnd = c
            }}
            bounds='window'
            cancel='.disable-dragging-window-container'
            dragHandlerClassName='.draggable-window__handler'
            enableResizing={this.state.isOpen && !this.state.isFullscreen}
            default={{
              x: this.props.customInitialOffset?.x ?? 0,
              y: this.props.customInitialOffset?.y ?? 0,
              ...this.getDimentions(),
            }}
            minHeight={
              this.state.isOpen
                ? DROP_DOWN_WINDOW_SIZES.OPENED_MIN_HEIGHT
                : DROP_DOWN_WINDOW_SIZES.CLOSED_HEIGHT
            }
            style={{
              boxShadow: '6px 6px 6px 0px #00000040',
              overflow: 'hidden',
              cursor: 'default !important',
              zIndex: shouldApplyBlockingZIndex
                ? this.props.theme.zIndex.modal + BLOCKING_DDW_ZINDEX_INCREASE
                : this.props.theme.zIndex.modal,
            }}
            onDragStart={() => this.handleDragStart()}
            onDragStop={() => this.handleDragStop()}
            onResizeStop={(e, direction, ref, delta, position) => this.handleResizeStop(ref)}
          >
            <Stack sx={{ width: '100%', height: '100%' }}>
              <Box
                sx={{
                  color: this.props.theme.palette.color.formWindowHeader,
                  background: this.props.theme.palette.primary.main,
                  height: !this.state.isOpen ? '100%' : 'auto',
                  pl: 2,
                  pr: 1,
                  py: 0.1,
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  className='draggable-window__handler'
                  justifyContent='space-between'
                >
                  <Grid
                    container
                    item
                    sx={{
                      alignItems: 'center',
                      gap: '20px',
                      textOverflow: this.state.isOpen ? 'auto' : 'ellipsis',
                      width: this.state.isOpen ? 'auto' : '162px',
                      overflowX: 'hidden',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Grid item pt='5px'>
                      <CustomSvgIcon src={logo} sx={{ width: 24, height: 26 }} />
                    </Grid>
                    <Typography
                      noWrap
                      aria-label={this.props.dropDownWindow.title}
                      fontSize='24px'
                      title={this.props.dropDownWindow.title}
                    >
                      {this.props.dropDownWindow.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.state.isOpen ? (
                      <>
                        <IconButton
                          onClick={() => {
                            this.handleMinimize()
                          }}
                        >
                          <CustomSvgIcon src={underline} sx={{ width: 20, height: 20 }} />
                        </IconButton>

                        {this.state.isFullscreen ? (
                          <IconButton
                            onClick={() => {
                              this.handleToggleFullscreen(false)
                            }}
                          >
                            <CustomSvgIcon src={copy} sx={{ width: 20, height: 20 }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            ref={this.buttonResizeRef}
                            onClick={() => {
                              this.handleToggleFullscreen(true)
                            }}
                          >
                            <CustomSvgIcon src={copy} sx={{ width: 20, height: 20 }} />
                          </IconButton>
                        )}
                      </>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => {
                            this.handleOpen()
                          }}
                        >
                          <UnfoldMoreIcon
                            fontSize='small'
                            sx={{
                              transform: 'rotateZ(45deg)',
                              color: this.props.theme.palette.color.formWindowHeader,
                            }}
                          />
                        </IconButton>
                        <IconButton
                          ref={this.buttonResizeRef}
                          onClick={() => {
                            this.handleToggleFullscreen(true)
                          }}
                        >
                          <CustomSvgIcon src={copy} sx={{ width: 20, height: 20 }} />
                        </IconButton>
                      </>
                    )}

                    <IconButton
                      onClick={() => {
                        this.handleClose(false)
                      }}
                    >
                      <CloseIcon
                        fontSize='small'
                        sx={{ color: this.props.theme.palette.color.formWindowHeader }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box
                ref={this.containerRef}
                className='disable-dragging-container'
                sx={{
                  overflowY: 'auto',
                  height: '100%',
                  cursor: 'default !important',
                  border: theme => `2px solid ${theme.palette.primary.main}`,
                  borderTop: 0,
                  background: theme => theme.palette.background.paper,
                }}
                onMouseDown={e => e.stopPropagation()}
                onScroll={() => this.handleContainerScroll()}
              >
                <Box
                  sx={{
                    height: '100%',
                    ...(!this.state.isOpen && { display: 'none' }),
                    minWidth: DRAGGABLE_FORM_SIZES.MIN_WIDTH,
                  }}
                >
                  {this.state.isOpen && (
                    <Box>
                      <Box sx={{ px: 1 }}>
                        <ConfiguredEntityForDDW
                          entityCode={this.props.dropDownWindow.code}
                          entityId={this.props.dropDownWindow.id}
                          fieldId={this.props.fieldId}
                          formElementId={this.props.elementId}
                          formObjectCode={this.props.formObjectCode}
                          formObjectId={this.props.formObjectId}
                          isDropDownDialogWindow={this.props.isDialogWindow}
                          multipleSelectPickerOption={this.props.multipleSelectPickerOption}
                          objectValueNameOption={this.props.objectValueName}
                          path={this.props.dropDownWindow.code}
                          readonly={this.props.readonly}
                          selectedPickerOptionValue={this.props.selectedPickerOptionValue}
                          title={this.props.dropDownWindow.title}
                          type={ENTITY.DROP_DOWN_ENTITY}
                          valueId={this.props.valueId}
                          windowHeight={this.state.storedHeight}
                          onMultiSelectPickerOption={this.props.onMultiSelectPickerOption}
                          onSelectPickerOption={this.props.onSelectPickerOption}
                        />
                      </Box>
                      <Grid
                        container
                        item
                        alignItems='center'
                        justifyContent='flex-end'
                        sx={{ borderTop: theme => theme.palette.border.default, paddingY: '2px' }}
                      >
                        <Grid item mb='3px' mt='1px'>
                          <Button
                            disabled={this.props.readonly}
                            sx={theme => ({
                              ...buttonStylesByMode(theme, true),
                              mr: 1.5,
                            })}
                            onClick={() => {
                              this.handleClose(true)
                            }}
                          >
                            <>{i18next.t('form.save')}</>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            </Stack>
          </Rnd>
        </Box>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onOpenDialogWindow: (uiOverlayPayload: UIOverlayOpenActionPayload) => {
      dispatch(onOpenDialogWindow(uiOverlayPayload))
    },
    onCloseDialogWindow: (uiOverlayPayload: UIOverlayCloseActionPayload) => {
      dispatch(onCloseDialogWindow(uiOverlayPayload))
    },
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    uiOverlay: state.uiOverlay,
  }
}

export const DropDownWindow = connect(mapStateToProps, mapDispatchToProps)(DropDownWindowComponent)
