import { useCallback, useContext, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { ObjectFieldDTO } from '@types'

import { PageContext, SectionType, SegmentType } from '@gantt/components/GanttCreateOrEdit'

import { getGanttSegmentDefaultValue } from './helpers/getGanttSegmentDefaultValue'
import { useAddGanttSegmentDialog } from './hooks/useAddGanttSegmentDialog'
import { SegmentItem } from './SegmentItem'

type SegmentsArrayProps = {
  type?: SegmentType
  rawObjectFields?: ObjectFieldDTO[]
}

export const AddSegmentDialog = ({
  type = SegmentType.MULTI,
  rawObjectFields,
}: SegmentsArrayProps) => {
  const methods = useForm<SectionType>({
    defaultValues: getGanttSegmentDefaultValue(),
  })
  const {
    handleSubmit,
    formState: { isDirty },
    reset,
    setError,
    clearErrors,
  } = methods

  const handleDuplicateError = (error: boolean) => {
    if (error) {
      setError('link.systemName', { type: 'custom', message: t('error.duplicatedSystemName') })

      return
    }
    clearErrors('link.systemName')
  }

  const { showDialog, handleCloseModal, modalType } = useContext(PageContext)
  const { t } = useTranslation()
  const { handleSave } = useAddGanttSegmentDialog({
    reset,
    onDuplicateError: handleDuplicateError,
    modalType,
  })

  const handleOnCloseModal = useCallback(() => {
    handleCloseModal(isDirty)
  }, [isDirty])

  const getModalTitle = useMemo(() => {
    return modalType === 'edit'
      ? t('ganttCreate.timelineForm.segmentDialogEdit')
      : t('ganttCreate.timelineForm.segmentDialogCreate')
  }, [modalType])

  return (
    <FormProvider {...methods}>
      {showDialog && (
        <ModalWrapper
          btnText={t('ganttCreate.common.apply')}
          disabledSave={!isDirty}
          isShow={showDialog}
          title={getModalTitle}
          onClose={handleOnCloseModal}
          onSave={handleSubmit(handleSave)}
        >
          <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh/1.5)', width: 'calc(100vw/1.5)' }}>
            <Box mb={1}>
              <SegmentItem objectFieldsForValidation={rawObjectFields} type={type} />
            </Box>
          </Box>
        </ModalWrapper>
      )}
    </FormProvider>
  )
}
