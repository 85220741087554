import { ParameterPropertyType } from 'src/types'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

export type ReturnTransformCommonParameters = {
  numberOfWindows: AutocompleteOption | null
  timezone: AutocompleteOption | null
  pagination: AutocompleteOption | null
  ganttChart: AutocompleteOption | null
}

export const transformCommonParameters = (
  parameters: ParameterPropertyType[] | undefined
): ReturnTransformCommonParameters => {
  if (!parameters) {
    return { numberOfWindows: null, pagination: null, timezone: null, ganttChart: null }
  }

  const initialData: Record<string, unknown> = {}

  parameters.forEach(({ key, value }) => {
    initialData[key] = value ? { id: value?.toString(), label: value?.toString() } : null
  })

  return initialData as ReturnTransformCommonParameters
}
