import { PathMatch } from 'react-router-dom'
import { GridSortModel } from '@mui/x-data-grid'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'

import {
  ConfigRowsType,
  CopyModelDataType,
  DataOfCopiedModelType,
  EntityType,
  ExtraOptions,
  GETActionView,
  MutationType,
  ObjectShortDTO,
  PayloadCreateAction,
  POSTOrderColumnType,
  POSTVisibilityColumnType,
} from '@types'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit/types'

type ContextMutations = {
  createAction: MutationType<PayloadCreateAction, GETActionView>
  editAction: MutationType<PayloadCreateAction, GETActionView>
  updateColumnOrder: (columns: POSTOrderColumnType[]) => void
  updateVisibilityColumn: (column: POSTVisibilityColumnType) => void
}

type PageContextTypeHandlers = {
  onDeleteGantt: (id: string | number) => void
  onDeleteGanttByCode: (code: string | number) => void
  onShowDialog: (data: DataOfCopiedModelType) => void
  onCopyGantt: (data: CopyModelDataType) => void
  onEditGantt: (id: string) => void
  onSetSorting: (value: GridSortModel | null) => void
  onSetObjectCode: (code: string) => void
  onSetSearchPattern: (pattern: string) => void
  onSetAllGantt: (value: ConfigRowsType[]) => void
  onSetCurrentSort: (value: GridSortModel) => void
}

export type GanttConfig = {
  id: string | number
  resourceObjectCode: string
  timelineObjectCode: string
  title: string
}

export type GanttContextValue = {
  isAllGantt?: PathMatch<string> | null
  objects?: ObjectShortDTO[]
  isLoadingGanttList?: boolean
  ganttConfigList?: GanttConfig[]
} & PageContextTypeHandlers

export type deleteGanttType = MutationTrigger<
  MutationDefinition<
    string | number,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptions>,
    string,
    EntityType,
    'commonApi'
  >
>
export type deleteGanttByCodeType = MutationTrigger<
  MutationDefinition<
    string | number,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptions>,
    string,
    EntityType,
    'commonApi'
  >
>

export enum GANTT_SCRIPT_RETURN_TYPE {
  STRING = 'string',
  ARRAY_OF_OBJECTS = 'arrayOfObjects',
}

export type createGanttType = MutationTrigger<MutationDefinition<GanttFormValues, any, any, any>>
