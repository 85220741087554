import { ADDITIONAL_ICONS } from './additional_icons'
import { COMMON_ICONS } from './common_icons'
import { INITIAL_ICONS } from './initial_icons'

export const enum TAB {
  INITIAL = 'INITIAL',
  COMMON = 'COMMON',
  ADDITIONAL = 'ADDITIONAL',
}

export const ICONS_BY_TAB = {
  [TAB.INITIAL]: INITIAL_ICONS,
  [TAB.COMMON]: COMMON_ICONS,
  [TAB.ADDITIONAL]: ADDITIONAL_ICONS,
}
