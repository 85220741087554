import { FC, MouseEventHandler } from 'react'
import { NavOptionType } from 'src/types/menu'
import { Button } from '@microservices/wiskey-react-components'
import {
  Box,
  Divider,
  ListItemButton as MUIListItemButton,
  ListItemText,
  styled,
  SxProps,
  Theme,
} from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { MENU_TYPE } from '@constants'

export const ListItemButton = styled(MUIListItemButton)<{ isChild?: boolean }>(({ isChild }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  padding: `${isChild ? 0 : 1}px 11px`,
  height: '28px',
  minWidth: 40,
}))

export type ListItemProps = {
  onClick: MouseEventHandler<HTMLDivElement | HTMLButtonElement>
  option: NavOptionType
  icon?: string
  sxIcon?: SxProps<Theme>
  isChild?: boolean
  selected?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
}

export const ListItem: FC<ListItemProps> = ({
  onClick,
  option,
  icon,
  isChild,
  selected,
  disabled,
  sxIcon,
  sx,
}) => {
  const isIcon = option.menuType === MENU_TYPE.ICON
  const isDivider = option.menuType === MENU_TYPE.DIVIDER
  const isListItem = option.showTitle || !isIcon || isChild || !icon

  if (isDivider) {
    if (!isChild) return null

    return <Divider sx={{ ml: '40px', mr: '4px', borderColor: 'black' }} />
  }

  const handleClick: MouseEventHandler<HTMLDivElement | HTMLButtonElement> = event => {
    onClick(event)
  }

  return (
    <>
      {isListItem ? (
        <ListItemButton
          key={option.id}
          disabled={disabled}
          isChild={isChild}
          selected={selected}
          title={option.title}
          sx={{
            ...(isChild
              ? {
                  '&:hover': {
                    bgcolor: theme => theme.palette.background.hovered,
                  },
                }
              : {
                  '&': {
                    boxSizing: 'border-box',
                    border: '1px solid transparent',
                    alignItems: 'center',
                  },
                  '&:hover': {
                    bgcolor: theme => theme.palette.background.hovered,
                    border: theme => `1px solid ${theme.palette.common.black}`,
                    boxShadow: theme => theme.palette.boxShadow.paper,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                  '&.Mui-selected': {
                    bgcolor: theme => theme.palette.background.hovered,
                    border: theme => `1px solid ${theme.palette.common.black}`,
                    boxShadow: theme => theme.palette.boxShadow.paper,
                  },
                }),
            ...sx,
          }}
          onClick={handleClick}
        >
          {isChild && !icon && <Box sx={{ mr: '15px', width: 20, height: 20, minWidth: 0 }} />}
          {icon && isIcon && (
            <CustomSvgIcon src={icon} sx={{ mr: '15px', display: 'flex', height: 26, ...sxIcon }} />
          )}
          {((isIcon && option.showTitle) || !isIcon) && (
            <ListItemText
              title={option.title}
              sx={{
                m: 0,
                '& .MuiTypography-root': {
                  fontSize: 16,
                  lineHeight: '24px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  fontFamily: 'Montserrat Regular',
                },
              }}
            >
              {option.title}
            </ListItemText>
          )}
        </ListItemButton>
      ) : (
        <Button
          disabled={disabled}
          form=''
          size='small'
          title={option.title}
          sx={{
            p: '1px 5px',
            borderRadius: 0,
            minWidth: 0,
            ...(disabled && { opacity: 0.25 }),
            // ml: '6px',
            transition: 'none',
            ...(isChild
              ? {
                  '&:hover': {
                    bgcolor: theme => theme.palette.background.hovered,
                  },
                }
              : {
                  '&': {
                    boxSizing: 'border-box',
                    border: '1px solid transparent',
                    alignItems: 'center',
                  },
                  '&:hover': {
                    bgcolor: theme => theme.palette.background.hovered,
                    border: theme => `1px solid ${theme.palette.common.black}`,
                    boxShadow: theme => theme.palette.boxShadow.paper,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                  '&.Mui-selected': {
                    bgcolor: theme => theme.palette.background.hovered,
                    border: theme => `1px solid ${theme.palette.common.black}`,
                    boxShadow: theme => theme.palette.boxShadow.paper,
                  },
                }),
            ...sx,
          }}
          onClick={handleClick}
        >
          {icon && isIcon && (
            <CustomSvgIcon
              imgStyle={{ maxHeight: 26, maxWidth: 38 }}
              src={icon}
              sx={{ width: '100%', minWidth: 40, height: 26 }}
            />
          )}
        </Button>
      )}
    </>
  )
}
