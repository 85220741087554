import { FC, useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BIND_TYPE, MODAL_TYPE } from '@constants'
import {
  Button,
  ConfirmModal,
  FormGenerator,
  ModalWrapper,
} from '@microservices/wiskey-react-components'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'

import { PageContext } from '@pages/EntityCreateOrEdit'

import { ScriptValueDialog } from '@components/ScriptValueDialog'
import { TextSettings } from '@components/TextSettings'

import { useAddColumnDialog } from './hooks/useAddColumnDialog'

export const AddColumnDialog: FC = () => {
  const { t } = useTranslation()

  const { onCloseModal, showDialog, modalType, rawObjectFields } = useContext(PageContext)

  const {
    isDirty,
    handleSave: onSave,
    isLoading,
    isShowScriptValueDialog,
    toggleOpenScriptValueDialog,
    methods,
    columnInputs,
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    watchUseParameters,
    handleHeaderExternalDirty,
    handleTextExternalDirty,
    handleClickReset,
    handleResetParams,
    handleSetShowResetConfirmModal,
    isShowResetConfirmModal,
  } = useAddColumnDialog()

  const { setValue, watch } = methods

  const watchedBindType = watch('bindType')
  const watchedValue = watch('value')

  const handleSave = () => {
    onSave(
      columnHeaderSettingsRef.current?.getTextParams(),
      columnTextSettingsRef.current?.getTextParams()
    )
  }

  return (
    <ModalWrapper
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={showDialog}
      title={`${t(`addDialog.${modalType}`)} Column`}
      onSave={handleSave}
      onClose={() => {
        onCloseModal(isDirty)
      }}
    >
      {isLoading && modalType === MODAL_TYPE.EDIT ? (
        <Grid container alignItems={'center'} justifyContent={'center'} my={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <Box maxHeight={500} overflow={'auto'} pb={0.25} pr={1} pt={1}>
          <ConfirmModal
            actionBtnText={t('parameters.resetModal.actionBtnText')}
            containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
            isShow={isShowResetConfirmModal}
            text={t('parameters.resetModal.text')}
            title={t('parameters.resetModal.title')}
            onClose={() => handleSetShowResetConfirmModal(false)}
            onConfirm={handleResetParams}
          />
          <FormProvider {...methods}>
            {isShowScriptValueDialog && (
              <ScriptValueDialog
                windowBoundaries
                isJsx={watchedBindType === BIND_TYPE.JSX}
                isShow={isShowScriptValueDialog}
                modalType={modalType}
                objectFields={rawObjectFields}
                value={watchedValue}
                onClose={() => toggleOpenScriptValueDialog(false)}
                onSave={value => setValue('value', value, { shouldDirty: true })}
              />
            )}
            <form>
              <FormGenerator inputs={columnInputs} mainGridSpacing={0} />
              <Typography variant='h6'>{t('addDialog.parameters')}</Typography>
              <TextSettings
                ref={columnHeaderSettingsRef}
                backgroundSettings={false}
                borderSettings={false}
                initialTextState={initialColumnHeaderStyles ? initialColumnHeaderStyles : undefined}
                isEdit={watchUseParameters}
                labelPlacement='top'
                labelText={t('parameters.visual.columnHeader')}
                onSetExternalDirty={handleHeaderExternalDirty}
              />
              <TextSettings
                ref={columnTextSettingsRef}
                borderSettings={false}
                initialTextState={initialColumnTextStyles ? initialColumnTextStyles : undefined}
                isEdit={watchUseParameters}
                labelPlacement='top'
                labelText={t('parameters.visual.columnText')}
                onSetExternalDirty={handleTextExternalDirty}
              />
              <Grid container item justifyContent={'flex-end'} mt={0.5}>
                <Button variant='outlined' onClick={handleClickReset}>
                  {t('parameters.resetButton')}
                </Button>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      )}
    </ModalWrapper>
  )
}
