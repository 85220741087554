import { Position } from 'react-rnd'

import { FavoritesFiltersTableMode } from '@pages/ConfiguredEntity/components/SearchAssistant/components/SavedFilterWindow'

import { Size } from '@features/DialogWindowManager/DialogWindow'

import { ENTITY, FORM_TYPE } from '@constants'

import { POSTCreateSearchType } from '../api'

export enum DIALOG_WINDOW_TYPE {
  // DROP_DOWN_WINDOW = 'DROP_DOWN_WINDOW',
  FORM = 'FORM',
  VIEW = 'VIEW',
  SEARCH_ASSISTANT = 'SEARCH_ASSISTANT',
  SAVED_FILTERS = 'SAVED_FILTERS',
  STRING_EDIT = 'STRING_EDIT',
}

export type ViewDialogWindowMeta = {
  viewId: number
  viewCode: string | null
  title: string
  path: string
  menuId: number
  parentId?: number
}

export type StringEditDialogWindowMeta = {
  text?: string
  setOuterValue?: ((value: string) => void) | undefined
  readonly?: boolean
}

export type FormDialogWindowMeta = {
  // rowId?: string
  // id?: number
  formId: number
  formCode: string
  objectCode: string
  objectId?: string
  viewCode: string
  viewId?: number | string
  path: string
  event: FORM_TYPE
  globalId: string
  entityType: ENTITY
  parentFormId?: string
  isIndependentForm?: boolean
  // variant?: PASSED_FORM_DATA_VARIANT
  // openedContainers: OpenedFormContainer[]
}

export type SavedFiltersWindowMeta = {
  entityId: string
  mode: FavoritesFiltersTableMode
  newSearch?: POSTCreateSearchType
  entityObjectCode: string | undefined
  searchAssistantId: number | string | undefined
}

export type DialogWindowType<T = Record<string, unknown>> = {
  id: string
  parentDialogId: string | null
  title: string | null
  type: DIALOG_WINDOW_TYPE
  initialPosition?: Position | null
  initialSize?: Size | null
  isFullScreen?: boolean
  hidden?: boolean
  minWidth?: number
  icon?: string
  meta: T
}

export type DialogWindowManagerState = {
  selectedId: string | null
  dialogWindows: DialogWindowType[]
}
