import { FIELD_VALUE_FORMAT, FIELD_VALUE_TYPE } from '@constants'

export const mapValueTypeToValueFormat = (
  valueType: FIELD_VALUE_TYPE | undefined,
  asCheckbox: boolean | undefined,
  asDuration: boolean | undefined
) => {
  if (valueType === FIELD_VALUE_TYPE.INTEGER) {
    if (asCheckbox) {
      return FIELD_VALUE_FORMAT.NUMBER
    }

    if (asDuration) {
      return FIELD_VALUE_FORMAT.TIME
    }
  }

  if (valueType === FIELD_VALUE_TYPE.BOOLEAN && asCheckbox) {
    return FIELD_VALUE_FORMAT.BOOLEAN
  }

  return null
}
