import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'

import { ParametersContext } from '@pages/Parameters/Parameters'

import { TextSettings } from '@components/TextSettings'

export const VisualParameters: FC = () => {
  const { t } = useTranslation()

  const {
    isEdit,
    initialGlobalColumnHeaderStyles,
    columnHeaderSettingsRef,
    initialGlobalColumnTextStyles,
    columnTextSettingsRef,
    onTextExternalDirty,
    onHeaderExternalDirty,
  } = useContext(ParametersContext)

  return (
    <Grid flexDirection='column' container>
      <Typography variant='h6'>{t('parameters.visual.title')}</Typography>
      <TextSettings
        labelText={t('parameters.visual.columnHeader')}
        isEdit={isEdit}
        backgroundSettings={false}
        borderSettings={false}
        onSetExternalDirty={onHeaderExternalDirty}
        initialTextState={
          initialGlobalColumnHeaderStyles ? initialGlobalColumnHeaderStyles : undefined
        }
        ref={columnHeaderSettingsRef}
      />
      <TextSettings
        labelText={t('parameters.visual.columnText')}
        isEdit={isEdit}
        initialTextState={initialGlobalColumnTextStyles ? initialGlobalColumnTextStyles : undefined}
        onSetExternalDirty={onTextExternalDirty}
        ref={columnTextSettingsRef}
      />
    </Grid>
  )
}
