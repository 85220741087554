import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ExpandMore } from '@mui/icons-material'
import { Avatar, Grid, IconButton, ListItemButton, Menu, Typography } from '@mui/material'

import { useAppSelector, useAuth } from '@hooks'
import { getLocalStorageContext } from '@helpers'
import { ROUTES } from '@constants'

export const Profile = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { username, doLogout } = useAuth()
  const navigate = useNavigate()

  const { dialogWindows } = useAppSelector(state => state.dialogWindowManager)

  const context = getLocalStorageContext()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)

  const handleCloseMenu = () => setAnchorEl(null)

  const chooseContext = () => {
    if (dialogWindows.length > 0 && !confirm(t('notifications.changeContext'))) {
      return
    }

    window.location.href = ROUTES.CHOOSE_CONTEXT
  }

  const logOut = () => {
    localStorage.removeItem('context')
    doLogout()
  }

  return (
    <Grid container alignItems={'center'} flexWrap={'nowrap'}>
      <Grid container item flexDirection={'column'} mr={1} width='400px'>
        <Grid item>
          <Typography sx={{ fontSize: 14, lineHeight: '20px', textAlign: 'right' }}>
            {username}
          </Typography>
        </Grid>
        <Grid item>
          {context && (
            <Typography
              title={context.label}
              sx={{
                fontSize: 14,
                lineHeight: '20px',
                textAlign: 'right',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: 400,
              }}
            >
              {context.label}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Avatar
          variant='circular'
          sx={{
            width: 36,
            height: 36,
            borderRadius: 0,
            background: theme => theme.palette.grey[400],
          }}
        />
      </Grid>
      <Grid item alignSelf={'flex-end'}>
        <IconButton sx={{ color: theme => theme.palette.common.white }} onClick={handleClick}>
          <ExpandMore sx={{ transition: '0.1s', cursor: 'pointer' }} />
        </IconButton>
      </Grid>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        anchorReference='anchorEl'
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <ListItemButton onClick={chooseContext}>{t('contexts.chooseContext')}</ListItemButton>
        <ListItemButton onClick={logOut}>{t('logout')}</ListItemButton>
      </Menu>
    </Grid>
  )
}
