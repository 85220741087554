import { DraggableLocation } from 'react-beautiful-dnd'

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
export const move = <T>(
  source: T[],
  destination: T[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result: Record<string, T[]> = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

export const add = <T>(item: T, destination: T[], droppableDestination: DraggableLocation) => {
  const destClone = Array.from(destination)

  destClone.splice(droppableDestination.index, 0, item)

  const result: Record<string, T[]> = {}
  result[droppableDestination.droppableId] = destClone

  return result
}
