import { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { VISIBLE_HIDDEN } from '@constants'
import { Box, Tab, Tabs, Typography } from '@mui/material'

import { CopyDialog } from '@components/CopyDialog'
import { PageTitle } from '@components/PageTitle'
import { TabPanel } from '@components/Tabs'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { AllForms } from './components/AllForms'
import { FormGroupList } from './components/FormGroupList'
import { useConfigForms } from './hooks'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const ConfigForms = () => {
  const { t } = useTranslation()

  const { state, data, handlers } = useConfigForms()

  const { currentSort, allForms, currentTab, copyFormData, showCopyDialog, isLoadingCopyForm } =
    state

  const { isFetchingForms, objects, isAllForms } = data

  const {
    handleDeleteForm,
    handleDeleteFormByCode,
    handleCopyForm,
    handleEditForm,
    handleSetObjectCode,
    handleSetSearchPattern,
    handleSetSorting,
    handleRefreshFormTablesData,
    handleTabChange,
    handleSetDirty,
    handleSetAllForms,
    handleCloseDialog,
    handleShowDialog,
  } = handlers

  const contextValue = {
    formTables: allForms,
    formsIsLoading: isFetchingForms,
    currentSort,
    objects,
    onDeleteForm: handleDeleteForm,
    onDeleteFormByCode: handleDeleteFormByCode,
    onCopyForm: handleCopyForm,
    onEditForm: handleEditForm,
    onSetObjectCode: handleSetObjectCode,
    onSetSearchPattern: handleSetSearchPattern,
    onSetSorting: handleSetSorting,
    onShowDialog: handleShowDialog,
  }

  return (
    <PageContext.Provider value={contextValue}>
      {showCopyDialog && copyFormData && (
        <CopyDialog
          isShow={showCopyDialog}
          onClose={handleCloseDialog}
          copyData={copyFormData}
          onCopy={handleCopyForm}
          models={allForms.map(form => form.code)}
          dialogTitle={t('copying.form.dialogTitle')}
          loading={isLoadingCopyForm}
        />
      )}
      <Outlet
        context={{
          setFormTables: handleSetAllForms,
          refreshFormTablesData: handleRefreshFormTablesData,
        }}
      />
      <Box
        sx={{
          ...(!isAllForms && VISIBLE_HIDDEN),
        }}
      >
        <PageTitle
          start={
            <>
              <Typography display={'inline'} variant={'h6'} sx={{ mr: 1, ml: 1 }}>
                {t('pageTitle.models')}
              </Typography>
              <Typography display={'inline'} variant={'subtitle2'}>
                {t('pageTitle.modelsControl')}
              </Typography>
            </>
          }
          end={false}
        />
        <PageContentLayout key={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab label={t('configViews.all')} />
              <Tab label={t('configViews.groups')} />
            </Tabs>
          </Box>
          <TabPanel
            value={currentTab}
            index={0}
            isAlwaysRendered
            hidden={false}
            sx={{ ...(currentTab !== 0 && VISIBLE_HIDDEN) }} // Пришлось кастомизировать стили, т.к display none немного ломает отображение DataGrid
          >
            <AllForms />
          </TabPanel>
          <TabPanel
            value={currentTab}
            index={1}
            hidden={false}
            sx={{ ...(currentTab !== 1 && VISIBLE_HIDDEN) }}
          >
            <FormGroupList onSetDirty={handleSetDirty} />
          </TabPanel>
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}
