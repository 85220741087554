import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { POSTCreateSearchType } from 'src/types'
import close from '@assets/images/close.svg'
import pencil from '@assets/images/pencil.svg'
import sortArrowUp from '@assets/images/sort-arrow-up.svg'
import { Button, TextField, TextFieldProps } from '@microservices/wiskey-react-components'
import {
  Box,
  Divider,
  Grid,
  ListItemButton,
  ListItemText,
  styled,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'

import { AppLoader } from '@components/AppLoader'
import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { buttonStylesByMode } from '@helpers'

import { useSaveFilterWindow } from './hooks'

export enum FavoritesFiltersTableMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}

type SavedFilterPageProps = {
  dialogId: string
  newSearch?: POSTCreateSearchType
  mode: FavoritesFiltersTableMode
  entityObjectCode: string | undefined
  searchAssistantId: number | string | undefined
  onChangeDirty?: (value: boolean) => void
}

export const StyledButton = styled(Button)<{ theme: Theme }>(({ theme, style }) => ({
  ...buttonStylesByMode(theme),
  boxShadow: theme.palette.boxShadow.filterButton,
  fontFamily: 'Montserrat Regular',
  fontSize: 11,
  lineHeight: '16px',
  padding: '0.5px 7.5px',
  '& .MuiButton-startIcon ': {
    pl: '7.5px',
  },
  height: 17,
  marginRight: 4,
  ...style,
}))

export const appLoaderSx: SxProps<Theme> = {
  position: 'absolute',
  width: 'fit-content',
  height: 'fit-content',
  right: '10px',
}

const rowSx = {
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
}

const EditTextField: FC<TextFieldProps> = props => {
  return (
    <TextField
      fullWidth
      size='small'
      sx={{
        borderRadius: 0,
        backgroundColor: theme => theme.palette.common.white,
        '& > .MuiInputBase-root': {
          color: theme => theme.palette.color.field,
          border: theme => theme.palette.border.default,
          borderRadius: 0,
          fontSize: 13,
          lineHeight: '28px',
          height: 28,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontFamily: 'Montserrat Regular',
          '*::before': {
            borderBottom: 'none !important',
          },
          '& > .MuiInputBase-input': {
            padding: '4px 8px',
            '-webkit-text-fill-color': theme => theme.palette.color.field,
          },
          '& input:-webkit-autofill': {
            '-webkit-box-shadow': theme => `0 0 0 100px ${theme.palette.common.black} inset`,
          },
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
      }}
      {...props}
    />
  )
}

export const SavedFilterWindow: FC<SavedFilterPageProps> = ({
  dialogId,
  newSearch,
  mode = FavoritesFiltersTableMode.VIEW,
  entityObjectCode,
  searchAssistantId,
  onChangeDirty,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { handlers, state } = useSaveFilterWindow({
    dialogId,
    mode,
    newSearch,
    entityObjectCode,
    searchAssistantId,
    onChangeDirty,
  })

  const {
    handleSortChange,
    handleChangeEditTitle,
    handleSelect,
    handleEditMode,
    handleDelete,
    handleCloseDialog,
    handleSaveOrRename,
    handleCancel,
  } = handlers

  const {
    sort,
    loadingSavedSearch,
    tableMode,
    createLoading,
    editTitle,
    searchData,
    selectedSearch,
    deleteLoading,
    updateSearchLoading,
    isDirty,
  } = state

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        minHeight: 140,
        p: '4px 6px 8px 6px',
      }}
    >
      <Box
        sx={{
          border: theme => `1px solid ${theme.palette.color.divider}`,
          height: 'calc(100% - 90px)',
          position: 'relative',
        }}
      >
        <Box
          p={'0 38px 0 21px !important'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px  !important',
            boxShadow: '0px 4px 4px 0px #00000040, 0px 4px 4px 0px #00000040',
            height: 24,
            mb: '8px  !important',
            cursor: 'pointer',
            userSelect: 'none',
            '&:hover > .MuiIcon-root': {
              opacity: sort ? 1 : 0.5,
            },
          }}
          onClick={handleSortChange}
        >
          <Typography
            sx={{
              fontFamily: 'Montserrat Regular',
              fontWeight: 600,
              fontSize: 13,
              lineHeight: '24px',
              color: theme => theme.palette.common.black,
            }}
          >
            {t('filter.favorites.dialog.columnName')}
          </Typography>
          <CustomSvgIcon
            src={sortArrowUp}
            sx={{
              height: '100%',
              display: 'flex',
              width: '10px',
              opacity: sort ? 1 : 0,
              transform: sort === 'desc' ? 'rotate(180deg)' : undefined,
            }}
          />
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            height: 'calc(100% - 32px)',
          }}
        >
          {loadingSavedSearch ? (
            <AppLoader size='1.5rem' />
          ) : (
            <Grid container alignContent={'flex-start'}>
              <Grid container item>
                {tableMode === FavoritesFiltersTableMode.CREATE && (
                  <Grid
                    container
                    sx={{
                      ...rowSx,
                      backgroundColor: theme => theme.palette.background.prevSelectedRow,
                      p: '0 50px 0 10px  !important',
                    }}
                  >
                    <EditTextField
                      disabled={createLoading}
                      value={editTitle}
                      onChange={handleChangeEditTitle}
                    />
                    {createLoading && <AppLoader size='1rem' sx={appLoaderSx} />}
                  </Grid>
                )}
                {searchData?.map(search => {
                  const isSelected = search.id === selectedSearch?.id

                  return (
                    <Grid
                      key={search.id}
                      container
                      item
                      sx={{
                        ...rowSx,
                        height: 28,
                        '&:hover': {
                          bgcolor: theme => theme.palette.background.hovered,
                        },
                        ...(isSelected &&
                          tableMode === FavoritesFiltersTableMode.EDIT && {
                            p: '0 50px 0 10px',
                            backgroundColor: theme => theme.palette.background.prevSelectedRow,
                          }),
                      }}
                    >
                      {tableMode === FavoritesFiltersTableMode.EDIT && isSelected ? (
                        <EditTextField
                          disabled={updateSearchLoading}
                          value={editTitle}
                          onChange={handleChangeEditTitle}
                        />
                      ) : (
                        <ListItemButton
                          selected={isSelected}
                          sx={{
                            p: '0 50px 0 10px',
                            height: 28,
                            display: 'flex',
                            justifyContent: 'space-between',
                            '&.Mui-selected': {
                              bgcolor: theme => theme.palette.background.prevSelectedRow,
                            },
                          }}
                          onClick={() => handleSelect(search)}
                        >
                          <ListItemText
                            sx={{
                              m: 0,
                              '& .MuiTypography-root': {
                                fontSize: 13,
                                lineHeight: '24px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                fontFamily: 'Montserrat Regular',
                                color: theme => theme.palette.common.black,
                              },
                            }}
                          >
                            {search.title}
                          </ListItemText>
                        </ListItemButton>
                      )}
                      {isSelected && (deleteLoading || updateSearchLoading) && (
                        <AppLoader size='1rem' sx={appLoaderSx} />
                      )}
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      {tableMode === FavoritesFiltersTableMode.VIEW && (
        <Grid container columnSpacing={'13px'} justifyContent={'flex-end'} mt={2}>
          <Grid item>
            <StyledButton
              disabled={!selectedSearch}
              theme={theme}
              startIcon={
                <CustomSvgIcon
                  src={pencil}
                  sx={{
                    opacity: !selectedSearch ? 0.5 : 1,
                    height: '100%',
                    fontSize: '15px !important',
                  }}
                />
              }
              onClick={handleEditMode}
            >
              {t('filter.favorites.dialog.rename')}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              disabled={!selectedSearch || deleteLoading}
              theme={theme}
              startIcon={
                <CustomSvgIcon
                  src={close}
                  sx={{
                    opacity: !selectedSearch || deleteLoading ? 0.5 : 1,
                    height: '100%',
                    fontSize: '10px !important',
                  }}
                />
              }
              onClick={handleDelete}
            >
              {t('filter.favorites.dialog.delete')}
            </StyledButton>
          </Grid>
        </Grid>
      )}
      <Grid item sx={{ my: '14px' }}>
        <Divider sx={{ borderColor: theme => theme.palette.color.divider }} />
      </Grid>
      <Grid container item gap={3} justifyContent={'flex-end'}>
        {tableMode === FavoritesFiltersTableMode.VIEW ? (
          <StyledButton theme={theme} onClick={handleCloseDialog}>
            {t('filter.favorites.dialog.close')}
          </StyledButton>
        ) : (
          <>
            <Grid item>
              <StyledButton
                disabled={!isDirty || updateSearchLoading}
                theme={theme}
                onClick={handleSaveOrRename}
              >
                {t('filter.favorites.dialog.ok')}
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton theme={theme} onClick={handleCancel}>
                {t('filter.favorites.dialog.cancel')}
              </StyledButton>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}
