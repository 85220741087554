import { useEffect, useRef } from 'react'

export const useStateless = <T>(value: T) => {
  const store = useRef(value)

  useEffect(() => {
    store.current = value
  }, [value])

  return store
}
