import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ConfirmModal, FormInput, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box, Button, Grid, Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { EntityOrFormList } from '@components/EntityOrFormList'
import { PageTitle } from '@components/PageTitle'

import {
  useCopyContextMenuMutation,
  useDeleteContextMenuMutation,
  useFetchContextMenuListQuery,
} from '@redux/api/contextMenu.api'

import { useDebounce } from '@hooks'
import { getSortParamRequest } from '@helpers'
import {
  CONFIG_CONTEXT_MENU_COLUMNS,
  GENERATOR_INPUT_TYPE,
  ROUTES,
  SEARCH_PATTERN_DELAY,
  VISIBLE_HIDDEN,
} from '@constants'
import { CopyContextMenu, GETContextMenu } from '@types'

import { CopyDialog } from './components/CopyDialog'

export const ConfigContextMenu = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isAllContextMenu = matchPath(ROUTES.CONFIG_CONTEXT_MENU, pathname)

  const methods = useForm<{ searchPattern: string }>({ defaultValues: { searchPattern: '' } })

  const { watch } = methods

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showHasObjectsModal, setShowHasObjectsModal] = useState<boolean>(false)
  const [deletedMenu, setDeletedMenu] = useState<null | GETContextMenu>(null)

  const [currentSort, setCurrentSort] = useState<GridSortModel>([])
  const [copyContextMenuData, setCopyContextMenuData] = useState<CopyContextMenu | null>(null)
  const watchSearchPattern = watch('searchPattern')

  const debouncedSearchPattern = useDebounce(watchSearchPattern, SEARCH_PATTERN_DELAY)

  const {
    data: contextMenus,
    isLoading,
    isFetching,
  } = useFetchContextMenuListQuery(
    {
      sort: getSortParamRequest(currentSort) || undefined,
      searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
    },
    { refetchOnMountOrArgChange: true }
  )
  const loading = isLoading || isFetching

  const [copyContextMenu, { isLoading: isLoadingCopyContextMenu }] = useCopyContextMenuMutation()
  const [deleteContextMenu] = useDeleteContextMenuMutation()

  const handleCreate = () => navigate(ROUTES.CONFIG_CONTEXT_MENU_CREATE)

  const handleSetSorting = (value: GridSortModel | null) => {
    setCurrentSort(value ? value : [])
  }

  const handleEdit = (_code: string, id: string | number) =>
    navigate(`${ROUTES.CONFIG_CONTEXT_MENU}/edit/${id}`)

  const handleCopy = (contextMenu: GETContextMenu) => {
    const { id, title } = contextMenu
    setCopyContextMenuData({ contextMenuId: id, title })
  }
  const handleCloseCopyDialog = () => setCopyContextMenuData(null)

  const handleCopyContextMenu = (data: CopyContextMenu) => {
    copyContextMenu(data)
      .unwrap()
      .then(res => {
        setCopyContextMenuData(null)
        navigate(`${ROUTES.CONFIG_CONTEXT_MENU_EDIT}/${res.id}`)
      })
  }

  const handleClickDelete = (id: string | number) => {
    const findMenu = contextMenus?.find(menu => menu.id === id)
    setDeletedMenu(findMenu || null)

    if (findMenu?.hasObjects) {
      setShowHasObjectsModal(true)
    } else {
      setShowDeleteModal(true)
    }
  }

  const handleDeleteDialog = () => {
    if (deletedMenu) {
      deleteContextMenu?.(deletedMenu.id).then(() => {
        setShowDeleteModal(false)
        setDeletedMenu(null)
      })
    }
  }

  const handleCloseDeleteDialog = () => {
    setShowDeleteModal(false)
    setDeletedMenu(null)
  }

  const handleCloseHasObjectDialog = () => {
    setShowHasObjectsModal(false)
    setDeletedMenu(null)
  }

  const handleSaveHasObjectDialog = () => {
    setShowHasObjectsModal(false)
    setShowDeleteModal(true)
  }

  return (
    <>
      <Outlet />
      {showHasObjectsModal && (
        <ModalWrapper
          btnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          disabledSave={loading}
          isShow={showHasObjectsModal}
          title={t('modal.delete.title')}
          onClose={handleCloseHasObjectDialog}
          onSave={handleSaveHasObjectDialog}
        >
          <Box my={1}>
            <Typography mb={1}>{t('configContextMenu.hasObjects')}</Typography>
            {deletedMenu?.objects.map(obj => (
              <Typography key={obj.id}>{`${obj.type}: ${obj.title}`}</Typography>
            ))}
          </Box>
        </ModalWrapper>
      )}
      {showDeleteModal && (
        <ConfirmModal
          actionBtnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showDeleteModal}
          text={t('modal.delete.warning')}
          title={t('modal.delete.title')}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleDeleteDialog}
        />
      )}
      {copyContextMenuData && (
        <CopyDialog
          copyData={copyContextMenuData}
          dialogTitle={t('copying.contextMenu.dialogTitle')}
          loading={isLoadingCopyContextMenu}
          onClose={handleCloseCopyDialog}
          onCopy={handleCopyContextMenu}
        />
      )}
      <Box
        sx={{
          ...(!isAllContextMenu && VISIBLE_HIDDEN),
        }}
      >
        <PageTitle
          end={false}
          start={
            <>
              <Typography display={'inline'} sx={{ mr: 1, ml: 1 }} variant={'h6'}>
                {t('pageTitle.contextMenu')}
              </Typography>
            </>
          }
        />
        <PageContentLayout sx={{ p: 1 }}>
          <Grid container alignItems='center' flexWrap='nowrap' gap={1}>
            <Grid item>
              <Button sx={{ mb: 1, width: 190 }} variant={'contained'} onClick={handleCreate}>
                {t(`configContextMenu.add`)}
              </Button>
            </Grid>
            <Grid container item flexGrow={1} flexWrap='nowrap' spacing={2}>
              <FormProvider {...methods}>
                <Grid item flexGrow={1}>
                  <FormInput
                    inputType={GENERATOR_INPUT_TYPE.INPUT}
                    name='searchPattern'
                    placeholder={t('configViews.allTab.search')}
                    size='small'
                  />
                </Grid>
              </FormProvider>
            </Grid>
          </Grid>
          <EntityOrFormList
            columns={CONFIG_CONTEXT_MENU_COLUMNS}
            currentSort={currentSort}
            loading={loading}
            rows={contextMenus || []}
            onCopy={handleCopy}
            onCustomDelete={handleClickDelete}
            onEdit={handleEdit}
            onSetSorting={handleSetSorting}
          />
        </PageContentLayout>
      </Box>
    </>
  )
}
