import { ENTITY, ENTITY_TYPES_REQUEST } from '@constants'

export const getEntityTypeObjectForRequest = (
  type: ENTITY
): { viewType: ENTITY_TYPES_REQUEST } | null => {
  switch (type) {
    case ENTITY.VIEW:
      return { viewType: ENTITY_TYPES_REQUEST.VIEW }
    case ENTITY.LIST_CONTROL:
      return { viewType: ENTITY_TYPES_REQUEST.LIST_CONTROL }
    case ENTITY.DROP_DOWN_ENTITY:
      return { viewType: ENTITY_TYPES_REQUEST.DROP_DOWN_ENTITY }
    default:
      return null
  }
}

export const getEntityTypeForRequest = (type: ENTITY): ENTITY_TYPES_REQUEST => {
  switch (type) {
    case ENTITY.VIEW:
      return ENTITY_TYPES_REQUEST.VIEW
    case ENTITY.LIST_CONTROL:
      return ENTITY_TYPES_REQUEST.LIST_CONTROL
    case ENTITY.DROP_DOWN_ENTITY:
      return ENTITY_TYPES_REQUEST.DROP_DOWN_ENTITY
    case ENTITY.GANTT_CHART:
      return ENTITY_TYPES_REQUEST.GANTT_CHART
    default:
      return ENTITY_TYPES_REQUEST.VIEW
  }
}
