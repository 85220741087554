import { SEARCH_RULE_TYPE } from './selectOptionsForRulesQuickSearch'

export enum RULES_EXCEPTIONS {
  DEFAULT = 'default',
  /** Применяется только для одной колонки */
  SINGLE = 'single',
  /** Применяется только для колонок с числовыми значениями */
  NUMERIC = 'numeric',
}

export enum RULES_SEARCH {
  /** записи, в которые входят введенные в строку поиска символы */
  CONTAIN = 'contain',
  /** записи, в которые не входят введенные в строку поиска символы */
  NOT_CONTAIN = 'not contain',
  /** записи, которые полностью совпадают с введенными в строку поиска символами */
  EQUAL = 'equal',
  /** записи, которые полностью не совпадают с введенными в строку поиска символами */
  NOT_EQUAL = 'not equal',
  /** записи, которые начинаются с введенных в строку поиска символов */
  STARTS_WITH = 'starts with',
  /** записи, которые заканчивается на введенные в строку поиска символы */
  ENDS_WITH = 'ends with',
  /** записи, не содержащие символы */
  EMPTY = 'empty',
  /** записи, содержащие символы */
  NOT_EMPTY = 'not empty',
  /** записи, значение в которых больше заданного */
  MORE = 'more',
  /** записи, значение в которых меньше заданного */
  LESS = 'less',
  /** записи, значение в которых больше или равно заданному */
  MORE_OR_EQUAL = 'More or equal',
  /** записи, значение в которых меньше или равно заданному */
  LESS_OR_EQUAL = 'Less or equal',
}

export type QuickRuleType = {
  name: SEARCH_RULE_TYPE
  exception: RULES_EXCEPTIONS
}
