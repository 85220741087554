import { useState } from 'react'

import {
  useCreateGanttMutation,
  useFetchAllObjectQuery,
  useFetchDictionariesByCategoryCodeQuery,
  useFetchFieldMutation,
  useFetchObjectByCodeNoCacheMutation,
  useUpdateGanttMutation,
} from '@redux/api'

import { CATEGORIES, MODEL_TYPE } from '@constants'
import { ObjectFieldDTO } from '@types'

import { useHandlers } from './useHandlers'

export const useGanttCreateOrEdit = () => {
  const [objectFields, setObjectFields] = useState<ObjectFieldDTO[]>([]) //todo actual?
  const { data: objects } = useFetchAllObjectQuery()
  const [fetchField, { data: row }] = useFetchFieldMutation()
  const [createGantt, { isSuccess: isSuccessCreate, isLoading: ganttCreateLoading }] =
    useCreateGanttMutation()
  const [updateGantt, { isSuccess: isSuccessUpdate, isLoading: ganttUpdateLoading }] =
    useUpdateGanttMutation()

  const { state, handlers } = useHandlers({
    fetchField,
    createGantt,
    updateGantt,
  })
  const { handleSetCurrentRow } = handlers
  const { objectCodeResource, objectCodeTimelines, modalProperty } = state

  const [, { data: objectResource }] = useFetchObjectByCodeNoCacheMutation({
    fixedCacheKey: 'resource-object',
  })

  const [, { data: objectTimelines }] = useFetchObjectByCodeNoCacheMutation({
    fixedCacheKey: 'timeline-object',
  })

  const { data: bindingValuesColumns } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_COLUMN
  )

  const { data: bindingValuesActionResourceEvents } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_EVENTS_RESOURCE_ACTION
  )

  const { data: bindingValuesActionTimelineEvents } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_EVENTS_TIMELINE_ACTION
  )

  const { data: bindingValuesResourceAction } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_RESOURCE_ACTION,
    {
      skip: modalProperty !== MODEL_TYPE.GANTT_ACTION_RESOURCE,
    }
  )

  const { data: bindingValuesTimelineAction } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_TIMELINE_ACTION,
    {
      skip: modalProperty !== MODEL_TYPE.GANTT_ACTION_TIMELINES,
    }
  )

  return {
    state: {
      ...state,
      objectFields,
    },
    data: {
      objects,
      objectResource,
      objectTimelines,
      bindingValuesColumns: bindingValuesColumns || [],
      bindingValuesActionResourceEvents: bindingValuesActionResourceEvents || [],
      bindingValuesActionTimelineEvents: bindingValuesActionTimelineEvents || [],
      bindingValuesResourceAction: bindingValuesResourceAction || [],
      bindingValuesTimelineAction: bindingValuesTimelineAction || [],
    },
    handlers: {
      ...handlers,
    },
  }
}
