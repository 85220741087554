import { AutocompleteOption, GETControlPanelLineItem } from 'src/types'
import { ELEMENT_TYPE } from '@constants'
import { getOptionRoute } from '@helpers'

export const getGanttChartOptionsFromMenu = (
  menu: GETControlPanelLineItem[]
): AutocompleteOption[] => {
  return menu
    .map(option => {
      if (option.elementType === ELEMENT_TYPE.GANTT_CHART && option.title) {
        // в id нужно указывать код, чтобы потом по дефолту делать на него роутинг
        return { id: getOptionRoute(option), label: option.title }
      }

      if (option.childrenMenu.length) {
        return getGanttChartOptionsFromMenu(option.childrenMenu)
      }
      return null
    })
    .flatMap(value => {
      return value ? value : []
    })
}
