import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import arrowOpen from '@assets/images/arrow-open.svg'
import { ManageSearch as ManageSearchIcon, WebAsset as WebAssetIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Typography,
} from '@mui/material'
import { indigo } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { ObjectFieldChangesTable } from '@components/DisplayForm/components/ObjectFieldChangesTable'
import { getAllHeightTabs } from '@components/DisplayForm/helpers'
import { useDisplayFormContext, useDisplayFormState } from '@components/DisplayForm/hooks'

import { setOpenedContainerForm, setTabIndexInContainer } from '@redux/reducers/formStates.reducer'

import { useAppDispatch, useAppSelector, useSubscribeEvent } from '@hooks'
import {
  FORM_ELEMENT_TYPE,
  MIN_HEIGHT_FIELD_DIALOG_WITH_LABEL,
  MIN_HEIGHT_FIELD_WITH_LABEL,
} from '@constants'

import { EVENT_NAME, publish } from '../../../../events'
import { FormContainerEnriched, FormDataEnriched, FormTab } from '../../../../types'
import { TabPanel } from '../../../Tabs'
import CardContainer from '../../../ui/CardContainer/CardContainer'
import { ContainerTabPanel } from '../ContainerTabPanel'

type DraggableContainerOnFormProps = {
  container: FormContainerEnriched
  index: number
}

type ExpandMoreProps = {
  expand: boolean
} & IconButtonProps

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props

  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  marginRight: '20px',
  '& > .MuiIcon-root': {
    background: expand ? theme.palette.background.paper : 'transparent',
    boxShadow: !expand ? '2px 2px 6px 0px #000000' : '-2px -2px 6px 0px #000000',
  },
}))

type TabButtonProps = {
  tab: FormTab<FormDataEnriched>
  index: number
}

export const ContainerOnForm: FC<DraggableContainerOnFormProps> = ({ container, index }) => {
  const contentPaddingOffset = 5
  const { t } = useTranslation()
  const formContainers = useAppSelector(state => state.formStates)
  const dispatch = useAppDispatch()
  const currentContainerHistory = formContainers?.[container.formCode]?.containers?.[container.id]

  const {
    methods,
    auditFieldHistory,
    formDataId,
    withPassedParams,
    objectId,
    onSetOpenedContainersInStaticForms,
    samePageFormsData,
    selectedRowIds,
    dialogId,
    isLoadingRow,
  } = useDisplayFormContext()

  const { isFormDialogWindow } = useDisplayFormState()

  const [expanded, setExpanded] = useState(Boolean(currentContainerHistory?.open))
  const [currentTabButton, setCurrentTabButton] = useState(
    currentContainerHistory?.currentTabIndex || 0
  )
  const [maxTabsWidth, setTabsMaxWidth] = useState(0)
  const isHistoryContainer = container.history

  const tabsHeight = useMemo(
    () => getAllHeightTabs(container.tabs, isFormDialogWindow),
    [container]
  )
  const maxHeight = Math.max(...tabsHeight)
  const minHeight = isFormDialogWindow
    ? MIN_HEIGHT_FIELD_DIALOG_WITH_LABEL
    : MIN_HEIGHT_FIELD_WITH_LABEL

  // Эффект для установки состояния expanded и currentTabButton из данных статических форм
  useEffect(() => {
    // TODO скорее всего нужно убрать зависимость от samePageFormsData, это должно храниться где то вне формы
    if (!samePageFormsData) {
      return
    }

    const currentOpenedSamePageForm = samePageFormsData.find(data => data.objectId === objectId)

    if (!currentOpenedSamePageForm) {
      return
    }

    const currentContainerInData = currentOpenedSamePageForm.openedContainers.find(
      containerData => containerData.containerIndex === index
    )

    if (currentContainerInData !== undefined) {
      setExpanded(true)
      setCurrentTabButton(currentContainerInData.openedTabIndex)
    }
  }, [samePageFormsData, selectedRowIds])

  useSubscribeEvent(EVENT_NAME.CONFIGURED_FORM_TOGGLE_OPENED_CONTAINERS, data => {
    if (!isFormDialogWindow) {
      setExpanded(data.detail.expanded)

      return
    }

    if (data.detail.dialogId === dialogId) {
      setExpanded(data.detail.expanded)
    }
  })

  const tabsWrapperRef = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return
    }

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setTabsMaxWidth(entries[0].contentRect.width - contentPaddingOffset)
    })

    observer.observe(node)
  }, [])

  const handleSetSamePageFormsData = (containerExpanded: boolean, openedTabIndex: number) => {
    if (withPassedParams && onSetOpenedContainersInStaticForms) {
      onSetOpenedContainersInStaticForms({
        formDataId: formDataId as number,
        containerExpanded,
        containerIndex: index,
        openedTabIndex,
      })
    }
  }

  const handleExpandClick = (expanded: boolean) => {
    setExpanded(!expanded)
    dispatch(
      setOpenedContainerForm({
        containerId: container.id,
        formCode: container.formCode,
        open: !expanded,
      })
    )
    handleSetSamePageFormsData(!expanded, currentTabButton)
    publish(EVENT_NAME.CONFIGURED_FORM_HAS_OPENED_CONTAINER, !expanded)
  }

  const TabButton: FC<TabButtonProps> = ({ tab, index }) => {
    const handleTabButtonClick = () => {
      setCurrentTabButton(() => {
        handleSetSamePageFormsData(expanded, index)
        dispatch(
          setTabIndexInContainer({
            containerId: container.id,
            formCode: container.formCode,
            tabIndex: index,
          })
        )

        return index
      })
    }

    return (
      <Button
        //variant={currentTabButton === index ? 'contained' : 'outlined'}
        sx={{
          height: isFormDialogWindow ? '25px' : '36px',
          color: theme => theme.palette.color?.formCardTab,
          background: theme =>
            currentTabButton === index
              ? theme.palette.background.formCardSelectedTab
              : theme.palette.background.formCardTab,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          mr: 0.5,
          ':hover': {
            background: theme => theme.palette.background.formCardSelectedTab,
          },
        }}
        onClick={handleTabButtonClick}
      >
        <Typography
          sx={{ textTransform: 'none' }}
          onDoubleClick={event => {
            event.stopPropagation()
          }}
        >
          {tab.title}
        </Typography>
      </Button>
    )
  }

  return (
    <Box sx={{ pb: isFormDialogWindow ? 0.5 : 1 }}>
      <CardContainer>
        <CardActions
          disableSpacing
          sx={{
            padding: isFormDialogWindow ? 0 : 1,
            height: isFormDialogWindow ? '25px' : '42px',
            cursor: 'pointer',
          }}
          onClick={() => handleExpandClick(expanded)}
        >
          <ExpandMore expand={expanded} onClick={() => handleExpandClick(expanded)}>
            <CustomSvgIcon fontSize={isFormDialogWindow ? 'small' : 'medium'} src={arrowOpen} />
          </ExpandMore>
          {isHistoryContainer ? (
            <ManageSearchIcon sx={{ color: indigo[500], mr: 1 }} />
          ) : (
            <WebAssetIcon color={'secondary'} sx={{ mr: 1 }} />
          )}
          <Typography
            sx={theme => ({
              userSelect: 'none',
              cursor: 'pointer',
              color: theme.palette.color.formCardTab,
              fontWeight: 600,
            })}
          >
            {container.title}
          </Typography>
        </CardActions>
        <Collapse unmountOnExit in={expanded} timeout={0}>
          <CardContent ref={tabsWrapperRef} sx={{ p: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', pb: 1 }}>
              {isHistoryContainer && auditFieldHistory && <ObjectFieldChangesTable />}
              {!isHistoryContainer && (
                <Box
                  sx={{
                    paddingTop: isFormDialogWindow ? 0.5 : 1,
                    background: theme => theme.palette.background.formCardBehindTab,
                    display: 'flex',
                    alignItems: 'end',
                    //maxWidth: maxTabsWidth,
                    height: 'auto',
                    flexWrap: 'wrap',
                    gap: '5px 0',
                  }}
                >
                  {container.tabs.map((tab, tabIndex) => (
                    // eslint-disable-next-line react/prop-types
                    <TabButton key={tab.id} index={tabIndex} tab={tab} />
                  ))}
                </Box>
              )}
              {!isHistoryContainer && (
                <Box
                  sx={theme => ({
                    background: theme.palette.background.formCardContainerContent,
                    minHeight: 'fit-content',
                  })}
                >
                  {container.tabs.map((tab, tabIndex) => {
                    // eslint-disable-next-line react/prop-types
                    const containsListControl = tab.lines.some(({ elements }) =>
                      elements.some(({ type }) => type === FORM_ELEMENT_TYPE.VIEW)
                    )

                    // eslint-disable-next-line react/prop-types
                    const countListControls = tab.lines.reduce(
                      (count, { elements }) =>
                        elements.some(({ type }) => type === FORM_ELEMENT_TYPE.VIEW)
                          ? count + 1
                          : count,
                      0
                    )

                    return (
                      <TabPanel
                        // eslint-disable-next-line react/prop-types
                        key={tab.id}
                        index={currentTabButton}
                        value={tabIndex}
                        contentSx={{
                          p: 0,
                        }}
                      >
                        <FormProvider {...methods}>
                          <ContainerTabPanel
                            countListControls={countListControls}
                            index={tabIndex}
                            tab={tab}
                            tabHeight={tabsHeight[tabIndex]}
                            initialHeight={
                              minHeight * 5 > maxHeight && containsListControl
                                ? minHeight * 5
                                : maxHeight
                            }
                          />
                        </FormProvider>
                      </TabPanel>
                    )
                  })}
                </Box>
              )}
            </Box>
          </CardContent>
        </Collapse>
      </CardContainer>
    </Box>
  )
}
