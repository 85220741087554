import { Checkbox } from '@microservices/wiskey-react-components'
import { CellContext, ColumnDef } from '@tanstack/react-table'

export const getVisibilityColumn = <T,>(
  showVisibilityColumn: boolean,
  disabledVisibilityColumn: boolean
) => {
  // const onHide = table.options.meta?.onHide
  return showVisibilityColumn
    ? [
        {
          accessorKey: 'visibility',
          header: 'Visibility',
          enableSorting: false,
          cell: (props: CellContext<T & { visibility: boolean }, unknown>) => (
            <Checkbox
              checked={props.row.original.visibility}
              disabled={disabledVisibilityColumn}
              onChange={(_e, checked) => props.table.options.meta?.onHide?.(props, checked)}
            />
          ),
          minSize: 100,
          size: 100,
        } as ColumnDef<T>,
      ]
    : []
}
