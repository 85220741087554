import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { DRAWER_WIDTH } from '@constants'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, Drawer } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

import { NavOptionProps } from '../../types'
import { NavList } from '../NavList'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

type SidebarProps = {
  children: ReactNode
  options: NavOptionProps[]
  onSelected: (item: NavOptionProps) => void
  open: boolean
  setOpen: (sidebarOpen: boolean) => void
}

export const Sidebar: FC<SidebarProps> = ({ children, options, onSelected, open, setOpen }) => {
  const { t } = useTranslation()

  const handleDrawerClose = (): void => setOpen(!open)

  return (
    <Main
      open={open}
      sx={{
        overflow: 'hidden',
      }}
    >
      <Box display={'flex'} height={'100%'}>
        <Drawer
          anchor='left'
          open={open}
          variant='persistent'
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
            },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <NavList
            isChild={false}
            onSelected={onSelected}
            title={t('navigation')}
            // hideText={!open}
            options={options}
          />
        </Drawer>
        <Box bgcolor={theme => theme.palette.background.main} flexGrow={1} minWidth={0}>
          {children}
        </Box>
      </Box>
    </Main>
  )
}
