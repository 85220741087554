import { GETAllObject } from 'src/types/api/objects.api'
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'

import { transformResponseWithPagination } from '@helpers'
import {
  GETEnumForObject,
  GETObjectDataRecordParams,
  GETObjectEnumParams,
  GETObjectFieldChanges,
  GETObjectFieldChangesParams,
  GETObjectModel,
  ObjectDataRecord,
  ObjectFieldChangesDTO,
} from '@types'

import { commonAPI } from './common.api'

export const objectAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllObject: build.query<GETAllObject, void>({
      query: () => ({
        url: 'router/models/object/list',
      }),
    }),
    fetchObjectByCode: build.query<GETObjectModel, string | void>({
      query: code => ({
        url: `router/models/object/code/${code}`,
      }),
    }),
    fetchEnumForObject: build.query<GETEnumForObject[], GETObjectEnumParams>({
      query: ({ code, name }) => ({
        url: `router/models/object/code/${code}/field/code/${name}/enums`,
      }),
    }),
    // TODO Устарело, проверить
    fetchObjectData: build.mutation<ObjectDataRecord, GETObjectDataRecordParams>({
      query: ({ objectCode, objectId, expands }) => ({
        url: `router/storage/object/${objectCode}/id/${objectId}`,
        params: { expands },
      }),
    }),
    fetchObjectFieldChanges: build.mutation<GETObjectFieldChanges, GETObjectFieldChangesParams>({
      query: ({ objectCode, objectId, page, size, sort, filter }) => ({
        url: `router/audit/object/${objectCode}/id/${objectId}`,
        params: {
          page,
          size,
          sort,
          filter,
        },
      }),
      // providesTags: ['AuditData'],
      transformResponse: (output: ObjectFieldChangesDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectFieldChangesDTO[]>(output, meta),
    }),
    fetchObjectByCodeNoCache: build.mutation<GETObjectModel, { code: string }>({
      query: ({ code }) => ({
        url: `router/models/object/code/${code}`,
      }),
    }),
  }),
})

export const {
  useFetchAllObjectQuery,
  useFetchObjectByCodeQuery,
  useFetchEnumForObjectQuery,
  useFetchObjectFieldChangesMutation,
  useFetchObjectDataMutation,
  useFetchObjectByCodeNoCacheMutation,
} = objectAPI
