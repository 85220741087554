import { useEffect, useState } from 'react'

import { AutocompleteOption, ObjectFieldDTO } from '../../../../../../../types'

type ObjectFieldOfSelectedValueOptions = {
  watchedValueName: AutocompleteOption | null
  objectFields: ObjectFieldDTO[] | undefined
}

export const useObjectFieldOfSelectedValue = ({
  objectFields,
  watchedValueName,
}: ObjectFieldOfSelectedValueOptions) => {
  const [objectField, setObjectField] = useState<ObjectFieldDTO>()

  useEffect(() => {
    const field = objectFields?.find(
      field => field.name === watchedValueName?.label || field.id === watchedValueName?.id
    )
    setObjectField(field ? field : undefined)
  }, [objectFields, watchedValueName])

  return { objectField }
}
