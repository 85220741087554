import { FIELD_VALUE_FORMAT } from '@constants'

import { checkIfOuterValueIsInvalid } from './checkIfOuterValueIsInvalid'

export const mapOuterValueToChecked = (
  outerValue: number | boolean,
  valueFormat: FIELD_VALUE_FORMAT
) => {
  if (checkIfOuterValueIsInvalid(outerValue, valueFormat)) {
    return false
  }

  if (valueFormat === FIELD_VALUE_FORMAT.BOOLEAN) {
    return Boolean(outerValue) // возможно Boolean() не нужен
  }

  return Boolean(outerValue)
}
