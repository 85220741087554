import { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import Tree, {
  ItemId,
  moveItemOnTree,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeDestinationPosition,
  TreeSourcePosition,
} from '@atlaskit/tree'
import { Box, List } from '@mui/material'

import { MENU_TYPE } from '@constants'
import { TreeItemControlPanelOptionType } from '@types'

export type MenuListProps = {
  tree: TreeData | null
  setTree: Dispatch<SetStateAction<TreeData | null>>
  renderItem?: (item: RenderItemParams) => ReactNode
  onDragEnd?: (item: TreeItemControlPanelOptionType, order: number) => void
}

export const MenuList: FC<MenuListProps> = ({ tree, renderItem, setTree, onDragEnd }) => {
  const onExpand = (itemId: ItemId): void => {
    if (tree) {
      setTree(mutateTree(tree, itemId, { isExpanded: true }))
    }
  }

  const onCollapse = (itemId: ItemId): void => {
    if (tree) {
      setTree(mutateTree(tree, itemId, { isExpanded: false }))
    }
  }

  const handleDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    if (!destination || !tree) {
      return
    }

    const draggedItem = tree.items[tree.items[source.parentId].children[source.index]]
    const newTree = moveItemOnTree(tree, source, destination)
    const destinationParentItem = newTree.items[destination.parentId]
    const newOrder = destinationParentItem.children.findIndex(value => value === draggedItem.id)

    if (destinationParentItem.data.menuType === MENU_TYPE.DIVIDER) {
      return
    }

    onDragEnd?.(
      {
        ...draggedItem,
        data: {
          ...draggedItem.data,
          treeItemParentId: destination.parentId,
          ...(destinationParentItem.data.uuid && { parentUuid: destinationParentItem.data.uuid }),
          parentId: destinationParentItem.data.id || null,
        },
      },
      newOrder
    )

    setTree(
      mutateTree(newTree, draggedItem.id, {
        data: {
          ...draggedItem.data,
          parentUuid: destinationParentItem.data.uuid || null,
          treeItemParentId: destination.parentId,
        },
      })
    )
  }

  return (
    <Box sx={{ overflow: 'auto', height: '35vh' }}>
      <List>
        {tree && (
          <Tree
            isDragEnabled
            isNestingEnabled
            renderItem={renderItem}
            tree={tree}
            onCollapse={onCollapse}
            onDragEnd={handleDragEnd}
            onExpand={onExpand}
          />
        )}
      </List>
    </Box>
  )
}
