import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@microservices/wiskey-react-components'

type SearchInTableType = {
  searchValue: string
  setGlobalFilter: (value: string) => void
}

export const SearchInTable: FC<SearchInTableType> = ({ searchValue, setGlobalFilter }) => {
  const { t } = useTranslation()

  return (
    <TextField
      size='small'
      value={searchValue ?? ''}
      onChange={e => {
        setGlobalFilter(String(e?.currentTarget?.value))
      }}
      placeholder={t('configViews.allTab.search')}
    />
  )
}
