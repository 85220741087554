import { FC, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { THEME_MODE } from '@constants'
import { ExpandMore } from '@mui/icons-material'
import { Box, Collapse, Link, ListItemButton, ListItemText, SxProps, Theme } from '@mui/material'

import { NavOptionProps } from '../../types'

import { NavList } from './NavList'

type NavItemProps = {
  isChild?: boolean
  pl?: number
  onClick?: (op: NavOptionProps) => void
  onSelected?: (item: NavOptionProps) => void
  hideText?: boolean
  item: NavOptionProps
}

// Проверка списка на выбранных детей
const checkedOpen = (option: NavOptionProps): boolean | undefined => {
  return option.options?.some(item => {
    if (item.options?.length) {
      return checkedOpen(item)
    }
    if (item.isSelected) {
      return true
    }
  })
}

const textStyles: SxProps<Theme> = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: theme =>
    theme.palette.mode === THEME_MODE.LIGHT ? theme.palette.grey[900] : theme.palette.text.primary,
  textDecoration: 'none',
}

export const NavItem: FC<NavItemProps> = ({ item, isChild, pl, onClick, hideText, onSelected }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const paddingLeft = pl ? pl * 1.5 : 4

  useEffect(() => {
    if (checkedOpen(item)) {
      setIsOpen(true)
    }
  }, [item])

  const handleClick = () => {
    if (item.options?.length) {
      setIsOpen(!isOpen)
    }

    if (onClick) {
      onClick(item)
    }

    // if (onSelected && !item.options?.length && !item.isSelected) {
    //   onSelected(item)
    // }
  }

  return (
    <Link sx={textStyles} component={item.options?.length ? Box : RouterLink} to={item.code}>
      <ListItemButton
        onClick={handleClick}
        sx={{ pl: isChild ? pl : {} }}
        selected={item.isSelected}
      >
        <ListItemText sx={{ opacity: hideText ? 0 : 1 }}>{item.title}</ListItemText>
        {item.options?.length && !hideText ? (
          <ExpandMore sx={{ transition: '0.1s', transform: !isOpen ? 'rotate(-90deg)' : {} }} />
        ) : null}
      </ListItemButton>
      {item.options?.length ? (
        <Collapse in={isOpen} timeout='auto' unmountOnExit>
          <NavList
            options={item.options}
            isChild={true}
            pl={paddingLeft}
            onClick={onClick}
            onSelected={onSelected}
            hideText={hideText}
          />
        </Collapse>
      ) : null}
    </Link>
  )
}
