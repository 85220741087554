import { MouseEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Position } from 'css-box-model'
import { t } from 'i18next'
import { v4 as uuid } from 'uuid'
import { COMMON_PARAMETERS, OBJECT_TYPES_FOR_MENU_POINT_VALUE } from '@constants'
import { getOptionRoute, transformCommonParametersToValues } from '@helpers'
import { DIALOG_WINDOW_TYPE, MenuPointOptionType } from '@types'

import { CursorPosition } from '@components/NestedMenu'

import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import { setAvailableGantt } from '@redux/reducers/availableGantts.reducer'
import { openViewDialog } from '@redux/reducers/dialogWindowManager.reducer'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch, useAppSelector } from './redux'

export const useContextMenu = ({
  dialogId,
  variantPosition = 'cursor',
}: {
  dialogId?: string
  variantPosition?: 'cursor' | 'element'
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [position, setPosition] = useState<null | CursorPosition>(null)
  const { dialogWindows, selectedId } = useAppSelector(state => state.dialogWindowManager)

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  const handleOpenContextMenu = (event: MouseEvent<HTMLElement>) => {
    switch (variantPosition) {
      case 'cursor':
        setPosition({ left: event.clientX, top: event.clientY })
        break
      case 'element': {
        const { left, bottom } = event.currentTarget.getBoundingClientRect()
        setPosition({ left: left - 4, top: bottom + 3 })
        return
      }
      default:
        setPosition({ left: event.clientX, top: event.clientY })
    }
  }

  const handleCloseContextMenu = () => setPosition(null)

  const handleOpenView = (item: MenuPointOptionType, position?: Position) => {
    if (item.value) {
      const openedFormsCount = dialogWindows.filter(
        dialog => [DIALOG_WINDOW_TYPE.VIEW].includes(dialog.type) && !dialog.hidden
      ).length

      const maxOpenedFormsCount = Number(initialCommonParameters.numberOfWindows)

      if (openedFormsCount === maxOpenedFormsCount) {
        dispatch(
          showMessage({
            type: 'info',
            text: t('error.dynamicFormsExceeded', { count: maxOpenedFormsCount }),
          })
        )

        return
      }

      dispatch(
        openViewDialog({
          id: uuid(),
          parentDialogId: dialogId || null,
          type: DIALOG_WINDOW_TYPE.VIEW,
          title: item.title,
          meta: {
            viewId: Number(item.value),
            viewCode: item.value,
            title: item.valueTitle || '',
            path: item.id.toString(),
            menuId: item.id,
          },
          initialPosition: position,
        })
      )
    }
  }

  const handleOpenGanttChart = (item: MenuPointOptionType) => {
    if (item.value) {
      const path = getOptionRoute(item)
      dispatch(
        setAvailableGantt({
          id: Number(item.value),
          title: item.valueTitle || item.title,
          path,
        })
      )

      navigate(path)
    }
  }

  const handleClickLink = (item: MenuPointOptionType, position?: Position) => {
    switch (item.objectType) {
      case OBJECT_TYPES_FOR_MENU_POINT_VALUE.VIEW:
        handleOpenView(item, position)
        break
      case OBJECT_TYPES_FOR_MENU_POINT_VALUE.GANTT_CHART:
        handleOpenGanttChart(item)
    }
  }

  return {
    position,
    onOpenContextMenu: handleOpenContextMenu,
    onClickLink: handleClickLink,
    onCloseContextMenu: handleCloseContextMenu,
  }
}
