import { ParameterPropertyType } from 'src/types'

import { TextSettingsStateType } from '@components/TextSettings'

export const transformTextParamsData = (
  textParams: ParameterPropertyType[] | undefined
): TextSettingsStateType | null => {
  if (!textParams || textParams?.length === 0) {
    return null
  }

  const initialData: Record<string, unknown> = {}

  textParams.forEach(param => {
    initialData[param.key] = param.value
  })

  const { isBold, isItalic, ...rest } = initialData

  return {
    ...rest,
    formats: [
      initialData.isBold === 'true' ? 'bold' : '',
      initialData.isItalic === 'true' ? 'italic' : '',
    ].filter(format => format !== ''),
    fontFamily: {
      id: initialData.fontFamily,
      label: initialData.fontFamily,
    },
  } as TextSettingsStateType
}
