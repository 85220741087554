import { FC, useContext, useEffect, useRef, useState } from 'react'
import { ItemId, RenderItemParams, TreeItem } from '@atlaskit/tree'
import { ENTITY, THEME_MODE } from '@constants'
import { usePaletteMode, useScrollToItem } from '@hooks'
import { Circle, DragIndicator, Folder, KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'

import { OptionType } from '../../../../../types'
import { EntityOrFormGroupListContext } from '../../../EntityOrFormGroupList'
import { IconButtons } from '../../../IconButtons'
import { AddItem } from '../AddItem'

type ItemProps = RenderItemParams & {
  addTreeItem?: (option: OptionType, treeItem?: TreeItem) => void
  deleteTreeItem: (itemId: ItemId) => void
  isEditGlobal: boolean
}

export const Item: FC<ItemProps> = ({
  item,
  onCollapse,
  onExpand,
  provided,
  isEditGlobal,
  deleteTreeItem,
  snapshot,
}) => {
  const mode = usePaletteMode()
  const [showAddedItem, setShowAddedItem] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const collapseRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { onCopy, onEdit, onDelete, type, allData } = useContext(EntityOrFormGroupListContext)
  const isEntity = type === ENTITY.VIEW || type === ENTITY.LIST_CONTROL
  const code =
    type === ENTITY.VIEW || type === ENTITY.LIST_CONTROL ? item.data.viewId : item.data.formCode
  const isFolder = item.hasChildren || !code
  const handleMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (actionType: 'copy' | 'edit' | 'delete') => {
    // TODO: Нужен рефактор
    const model = allData.find(data => data.id === code || data.code === code)

    if (model) {
      switch (actionType) {
        case 'copy':
          onCopy({
            id: model.id,
            objectCode: model.objectCode,
            objectTitle: model.objectTitle,
            title: model.title,
          })
          break
        case 'edit':
          onEdit(model.code)
          break
        case 'delete':
          if (isEntity) {
            onDelete(model.id)
            return
          }
          onDelete(model.code)
          break
        default:
          break
      }
    }

    setAnchorEl(null)
  }

  const { scrollToItem } = useScrollToItem({ ref: collapseRef, isScroll: showAddedItem })

  useEffect(() => {
    setShowAddedItem(false)
  }, [item])

  const handleAddClick = () => {
    handleShowItem(true)
    if (showAddedItem) {
      scrollToItem()
    }
  }

  const handleShowItem = (value: boolean) => {
    setIsEdit(false)
    setShowAddedItem(value)
  }

  const handleEditItem = () => {
    setIsEdit(true)
    setShowAddedItem(true)
  }

  const handleOpen = (): void => {
    if (item.isExpanded) {
      onCollapse(item.id)
      return
    }
    onExpand(item.id)
  }

  const handleDeleteItem = (): void => {
    deleteTreeItem(item.id)
  }

  return (
    <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{ py: 0 }}>
      <Box
        {...provided.dragHandleProps}
        sx={{
          transition: '0.1s',
          width: '100%',
          ...(snapshot.isDragging && {
            backgroundColor: mode === THEME_MODE.LIGHT ? grey[300] : blueGrey[700],
          }),
        }}
      >
        <Grid container direction={'column'}>
          <Grid container item sx={{ mb: 0, p: 1 }} alignItems={'center'}>
            <Grid
              container
              flexDirection={'row'}
              wrap='nowrap'
              justifyContent={'space-between'}
              alignItems={'center'}
              py={!isEditGlobal && item.hasChildren ? '2px' : 0}
            >
              <Grid container item flexDirection={'row'} alignItems={'center'}>
                <ListItemIcon sx={{ minWidth: 'fit-content', mr: 1 }}>
                  {isFolder ? (
                    <Grid container alignItems={'center'}>
                      <IconButton disabled={!item.hasChildren} size={'small'}>
                        <KeyboardArrowDown
                          onClick={handleOpen}
                          sx={{
                            cursor: 'pointer',
                            ...(!item.isExpanded && { transform: 'rotate(-90deg)' }),
                          }}
                        />
                      </IconButton>
                      <Folder />
                    </Grid>
                  ) : isEditGlobal ? (
                    <DragIndicator fontSize='small' sx={{ ml: 0.5 }} />
                  ) : (
                    <Circle sx={{ fontSize: 6, ml: 1, mr: 1 }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={item.data?.title} />
              </Grid>
              {isEditGlobal ? (
                isFolder ? (
                  <IconButtons
                    onAdd={handleAddClick}
                    onEdit={handleEditItem}
                    onDelete={handleDeleteItem}
                  />
                ) : (
                  <IconButtons onDelete={handleDeleteItem} />
                )
              ) : (
                !item.hasChildren && code && <IconButtons onMore={handleMore} />
              )}
            </Grid>
            <Menu
              id='view-item-context-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <IconButtons
                onCopy={() => handleClose('copy')}
                onEdit={() => handleClose('edit')}
                onDelete={() => handleClose('delete')}
              />
            </Menu>
          </Grid>
          <Grid item>
            <Collapse in={showAddedItem} ref={collapseRef}>
              <Box
                sx={{
                  mx: 2,
                  my: 1,
                }}
              >
                <AddItem
                  treeItem={item}
                  onSetEdit={handleShowItem}
                  isEdit={isEdit}
                  onClose={() => handleShowItem(false)}
                />
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  )
}
