import { ParameterPropertyType, POSTLocalParameterPropertyType } from 'src/types'

import { isTextParam, TextParams } from '@components/TextSettings'

type GetInitialPropertiesForUpdateParams = {
  textData: TextParams
  paramId: number
}

export const getPropertiesForUpdate = (
  textData: TextParams,
  propertiesData: ParameterPropertyType[],
  paramId: number
): POSTLocalParameterPropertyType[] => {
  const propertiesForUpdate: POSTLocalParameterPropertyType[] = []

  propertiesData.forEach(property => {
    const correspondingHeaderDataKey = Object.keys(textData).find(key => key === property.key)

    if (!correspondingHeaderDataKey) {
      return
    }

    if (property.value == textData[correspondingHeaderDataKey as keyof TextParams]) {
      return
    }

    const { paramCode, ...restProperty } = property

    propertiesForUpdate.push({
      ...restProperty,
      value: textData[correspondingHeaderDataKey as keyof TextParams] as string,
      paramId,
    })
  })

  // Используется при добавлении новых параметров старым вью, в которых они отсутствуют
  if (Object.keys(textData).length > propertiesData.length && propertiesData.length > 0) {
    const newProperties = Object.keys(textData).filter(
      propertyKey => !propertiesData.find(property => property.key === propertyKey)
    )

    const maxId = Math.max(...propertiesData.map(property => property.id || -1))
    const maxNum = Math.max(...propertiesData.map(property => property.num || -1))

    newProperties.map((property, index) => {
      if (isTextParam(property)) {
        propertiesForUpdate.push({
          id: maxId + index + 1,
          num: maxNum + index + 1,
          key: property,
          value: textData[property as keyof TextParams] as string,
          paramId,
        })
      }
    })
  }

  return propertiesForUpdate
}

export const getInitialPropertiesForRequest = ({
  textData,
  paramId,
}: GetInitialPropertiesForUpdateParams) => {
  return Object.keys(textData).map((key, index) => {
    return {
      num: index,
      key,
      value: textData[key as keyof TextParams] as string,
      paramId,
    }
  })
}
