import { lazy, Suspense } from 'react'
import { CircularProgress } from '@mui/material'

const GanttConfigLazy = lazy(() =>
  import('./GanttChart').then(({ GanttChart }) => ({
    default: GanttChart,
  }))
)

const GanttCreateOrEditLazy = lazy(() =>
  import('./components/GanttCreateOrEdit').then(({ GanttCreateOrEdit }) => ({
    default: GanttCreateOrEdit,
  }))
)

const GanttConfigurationLazy = lazy(() =>
  import('./components/GanttCreateOrEdit/components/GanttConfiguration').then(
    ({ GanttConfiguration }) => ({
      default: GanttConfiguration,
    })
  )
)

export default function GanttChart() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <GanttConfigLazy />
    </Suspense>
  )
}

export function GanttCreateOrEdit() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <GanttCreateOrEditLazy />
    </Suspense>
  )
}

export function GanttConfiguration() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <GanttConfigurationLazy />
    </Suspense>
  )
}
