import { FC, FocusEventHandler, useRef } from 'react'
import moment from 'moment'
import { generalFieldStylesByMode } from '@helpers'
import { DateRangeValue, DateTimePicker, TextField } from '@microservices/wiskey-react-components'
import { Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import Divider from '@mui/material/Divider'

type RelativeDatePickerProps = {
  onChange: (value: [DateRangeValue, string, string]) => void
  onChangeInput: (value: [DateRangeValue, string, string]) => void
  onBlur: (value: [DateRangeValue, string, string]) => void
  beforeText?: string
  afterText?: string
  value: [DateRangeValue, string, string]
  width?: number
  disabled?: boolean
  popoverDateShiftMode?: boolean
  placeholder?: string
  isDialogWindow?: boolean
}

export type RelativeDatePickerForm = {
  value: string | null
  before: string
  after: string
}

export type RelativeType = [DateRangeValue, string, string]

const MIN_WIDTH_FOR_LABEL = 500

const LabelText: FC<{ text?: string; hidden?: boolean }> = ({ text, hidden }) => {
  return (
    <Typography
      sx={{
        mr: 1,
        color: grey[700],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {!hidden ? text : null}
    </Typography>
  )
}

export const RelativeDatePicker: FC<RelativeDatePickerProps> = ({
  onChange,
  value,
  onChangeInput,
  width,
  beforeText,
  afterText,
  disabled = false,
  popoverDateShiftMode,
  placeholder,
  isDialogWindow,
}) => {
  const dateRef = useRef<HTMLInputElement>(null)
  const beforeRef = useRef<HTMLInputElement>(null)
  const afterRef = useRef<HTMLInputElement>(null)

  const handleChange = (value: string) => {
    const newRelativeValue: RelativeType = [
      value,
      beforeRef.current?.value || '',
      afterRef.current?.value || '',
    ]

    onChange?.(newRelativeValue)
  }

  const handleBlurBefore: FocusEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    onChange?.([
      moment(dateRef.current?.value).toISOString() || null,
      value,
      afterRef.current?.value || '',
    ])
  }

  const handleChangeBefore: FocusEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    onChangeInput?.([
      moment(dateRef.current?.value).toISOString() || null,
      value,
      afterRef.current?.value || '',
    ])
  }

  const handleChangeAfter: FocusEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    onChangeInput?.([
      moment(dateRef.current?.value).toISOString() || null,
      beforeRef.current?.value || '',
      value,
    ])
  }

  const handleBlurAfter: FocusEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    onChange?.([
      moment(dateRef.current?.value).toISOString() || null,
      beforeRef.current?.value || '',
      value,
    ])
  }

  return (
    <Grid container item flexWrap={'nowrap'}>
      <Grid item flex={1}>
        <DateTimePicker
          fullWidth
          disabled={disabled}
          inputRef={dateRef}
          placeholder={placeholder}
          popoverDateShiftMode={popoverDateShiftMode}
          value={value[0]}
          sx={theme => ({
            ...generalFieldStylesByMode(theme, undefined, undefined, undefined, isDialogWindow),
          })}
          onChange={handleChange}
        />
      </Grid>
      <Grid container item alignItems={'center'} flex={1} flexWrap={'nowrap'} ml={1}>
        <Grid item width={'100%'}>
          <TextField
            fullWidth
            disabled={disabled}
            inputRef={beforeRef}
            placeholder={beforeText}
            type={'number'}
            value={value[1]}
            sx={theme => ({
              ...generalFieldStylesByMode(theme, undefined, undefined, undefined, isDialogWindow),
            })}
            onBlur={handleBlurBefore}
            onChange={handleChangeBefore}
          />
        </Grid>
      </Grid>
      <Grid container item alignItems={'center'} flex={1} flexWrap={'nowrap'} ml={1}>
        <Grid item flex={1} width={'100%'}>
          <Divider sx={{ width: 10, mr: 1 }} />
        </Grid>
        <Grid item width={'100%'}>
          <TextField
            fullWidth
            disabled={disabled}
            inputRef={afterRef}
            placeholder={afterText}
            type={'number'}
            value={value[2]}
            sx={theme => ({
              ...generalFieldStylesByMode(theme, undefined, undefined, undefined, isDialogWindow),
            })}
            onBlur={handleBlurAfter}
            onChange={handleChangeAfter}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
