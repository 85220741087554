import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { AutocompleteOption, ObjectFieldDTO } from '../../../../../../../types'

type useResetGroupRelatedCheckboxOnValueNameChangeOptions = {
  watchedValueName: AutocompleteOption | null
  objectFields: ObjectFieldDTO[] | undefined
  groupRelatedInputName: string
  isGroupRelatedEnabled: boolean
  objectField: ObjectFieldDTO | undefined
  isLinkedField: boolean
}

export const useResetGroupRelatedCheckboxOnValueNameChange = ({
  watchedValueName,
  objectFields,
  groupRelatedInputName,
  isGroupRelatedEnabled,
  objectField,
  isLinkedField,
}: useResetGroupRelatedCheckboxOnValueNameChangeOptions) => {
  const { setValue } = useFormContext()

  useEffect(() => {
    if (!objectFields || !objectField) {
      return
    }

    if (!isGroupRelatedEnabled) {
      setValue(groupRelatedInputName, false)
    }
  }, [watchedValueName, objectFields, isGroupRelatedEnabled])
}
