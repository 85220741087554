import { FC, useContext, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isString from 'lodash/isString'
import { FormGenerator } from '@microservices/wiskey-react-components'
import { Grid, useTheme } from '@mui/material'

import { useFetchContextViewsByIdQuery, useFetchDefaultViewSAQuery } from '@redux/api'

import { generalFieldStylesByMode } from '@helpers'
import { GENERATOR_INPUT_TYPE } from '@constants'

import { AutocompleteOption } from '../../../../../../types'
import { SearchAssistantContext } from '../../SearchAssistant'

type ViewSelectorType = {
  entityId: number | string | null
  onClear: () => void
  onSetEntityId: (value: string | number | null) => void
  onSetEntityCode: (value: string | null) => void
  contextId: string | number | undefined
}

export const ViewSelector: FC<ViewSelectorType> = ({
  entityId,
  onClear,
  onSetEntityCode,
  onSetEntityId,
  contextId,
}) => {
  const { data: defaultView } = useFetchDefaultViewSAQuery(
    {
      contextId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const { data: contextViewsById } = useFetchContextViewsByIdQuery(contextId, {
    skip: !contextId,
    refetchOnMountOrArgChange: true,
  })

  const methods = useForm<{
    viewId: AutocompleteOption | null
  }>({
    defaultValues: { viewId: null },
  })

  const { isViewDialogWindow } = useContext(SearchAssistantContext)
  const { t } = useTranslation()
  const theme = useTheme()

  const { watch, setValue } = methods
  const watchViewId = watch('viewId')

  const viewOptions = useMemo(
    () => contextViewsById?.selectedViews?.map(view => ({ id: view.id, label: view.code })),
    [contextViewsById]
  )

  useEffect(() => {
    setValue(
      'viewId',
      viewOptions
        ? (viewOptions?.find(option => option.id === entityId) as AutocompleteOption)
        : null
    )
  }, [])

  useEffect(() => {
    onClear()

    if (watchViewId && isString(watchViewId.label)) {
      onSetEntityCode(watchViewId.label)
      onSetEntityId(watchViewId.id)

      return
    }

    onSetEntityId(null)
  }, [watchViewId])

  useEffect(() => {
    setValue('viewId', defaultView ? { id: defaultView.id, label: defaultView.code } : null)
  }, [defaultView])

  return (
    <FormProvider {...methods}>
      <form>
        <Grid container alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
          <Grid container item pl={'40px'} pr={'5px'}>
            <FormGenerator
              mainGridSpacing={0}
              inputs={[
                {
                  name: 'viewId',
                  inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                  placeholder: t('searchAssistant.filtration.view.placeholder'),
                  autocompleteOptions: viewOptions,
                  label: t('searchAssistant.filtration.view.label'),
                  sx: {
                    '& > .MuiFormControl-root': {
                      ...generalFieldStylesByMode(
                        theme,
                        undefined,
                        undefined,
                        undefined,
                        isViewDialogWindow
                      ),
                    },
                    '& .MuiTypography-root': {
                      minWidth: 90,
                      width: 90,
                    },
                    // ml: 20.5,
                  },
                  labelSx: { width: 90, minWidth: 90 },
                },
              ]}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
