export enum GanttEvents {
  SELF_UPDATED = 'SELF_UPDATED',
}

export type DetailSelfUpdate = {
  objectCode: string
  objectId: string
}

export function dispatchSelfUpdate(data: any) {
  window.dispatchEvent(
    new CustomEvent<DetailSelfUpdate>(GanttEvents.SELF_UPDATED, {
      detail: data,
    })
  )
}
