export const enum MENU_POINT_TYPES {
  COMMAND = 'COMMAND',
  LINK = 'LINK',
  HEADER = 'HEADER',
  DIVIDER = 'DIVIDER',
  GROUP = 'GROUP',
}

export const enum OBJECT_TYPES_FOR_MENU_POINT_LABEL {
  FORM = 'FORM',
  GANTT_CHART = 'GANTT CHART',
  LIST_CONTROL = 'LIST CONTROL',
  VIEW = 'VIEW',
}

export const enum OBJECT_TYPES_FOR_MENU_POINT_VALUE {
  GANTT_CHART = 'GANTT_CHART',
  VIEW = 'VIEW',
}

export const enum CONTEXT_MENU_TABS {
  MENU_POINT = 0,
  VARIABLES = 1,
}

export const enum ELEMENT_TYPE_CONTEXT_MENU {
  CONTEXT = 'CONTEXT',
  POINT = 'POINT',
  VARIABLE = 'VARIABLE',
}
