import { FC, SyntheticEvent } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { SxProps, TabProps, useTheme } from '@mui/material'

import { Tab } from '@components/Tabs'

type FormsTabProps = {
  onIconClick: () => void
} & TabProps

export const FormsTab: FC<FormsTabProps> = ({ onIconClick, ...props }) => {
  const theme = useTheme()

  const styles: SxProps = {
    minHeight: '30px',
    height: '30px',
    mr: 1,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    border: `1px solid ${theme.palette.grey['300']}`,
    background: theme.palette.grey['100'],
    color: theme.palette.common.black,
    textTransform: 'capitalize',
    '&.Mui-selected': {
      border: 'none',
      background: theme.palette.primary.main,
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    },
  }

  const clickIconHandler = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onIconClick()
  }

  return (
    <Tab
      disableRipple
      icon={<CloseIcon onClick={clickIconHandler} sx={{ width: '20px', height: '20px' }} />}
      iconPosition='end'
      sx={styles}
      {...props}
    />
  )
}
