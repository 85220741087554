import JsxParser from 'react-jsx-parser'

export const renderJSXValueCell = (
  // Содержимое самого скрипта
  value: string,
  srcObj: Record<string, unknown>
) => {
  // eslint-disable-next-line
  // @ts-ignore
  return <JsxParser bindings={{ srcObj }} jsx={value} />
}
