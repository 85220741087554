import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { t } from 'i18next'

import { PageContext } from '@pages/EntityCreateOrEdit'
import { transformTextParamsData } from '@pages/Parameters/helpers'

import { GetTextParamsHandle } from '@components/TextSettings'

import { useFetchObjectByCodeQuery } from '@redux/api'

import { usePrompt } from '@hooks'
import { getColumnParametersFromArray } from '@helpers'
import { createAutocompleteOptions } from '@helpers'
import { BIND_TYPE, FIELD_VALUE_FORMAT, FIELD_VALUE_TYPE, OBJECT_FIELD_TYPE } from '@constants'
import { ObjectFieldDTO, ObjectFieldWithUsed } from '@types'

import { getDefaultValue } from '../helpers'

import { useHandlers } from './useHandlers'
import { ColumnForm, useInputs } from './useInputs'

export type ObjectValueOptionsType = {
  id: number
  name: string
  isAlreadyUsed: boolean
  fieldType: OBJECT_FIELD_TYPE
  isPk?: boolean
  isObjectLevelRequired?: boolean
}

export const useAddColumnDialog = () => {
  const defaultValues: ColumnForm = getDefaultValue()
  const methods = useForm<ColumnForm>({ defaultValues })
  const columnHeaderSettingsRef = useRef<GetTextParamsHandle>(null)
  const columnTextSettingsRef = useRef<GetTextParamsHandle>(null)
  const [isLoading, setLoading] = useState(true)
  const [objectField, setObjectField] = useState<ObjectFieldDTO>()

  const {
    currentRow,
    onSetModalProperty,
    objectCode: viewObjectCode,
    rawObjectFields,
    objectCommands = [],
    bindingValuesColumns,
    mainTable: { rows: columns },
  } = useContext(PageContext)

  const {
    handleSubmit,
    reset,
    formState: { isDirty: isDirtyBasicParameters },
    getValues,
    setValue,
    clearErrors,
    trigger,
    watch,
  } = methods

  const watchBinding = watch('bindType')
  const watchObjectValue = watch('objectValue')
  const watchValue = watch('value')
  const watchPinning = watch('pinning')
  const watchUseParameters = watch('useParameters')
  const watchAsCheckbox = watch('asCheckbox')
  const watchAsDuration = watch('asDuration')

  const fieldType = objectField?.type ?? null
  const valueType = objectField?.valueType || null
  const objectCode = objectField?.model ?? ''
  const isObjectLinkedValue = objectField?.type === 'object_link'

  const { data: objectForField } = useFetchObjectByCodeQuery(objectCode, { skip: !objectCode })

  const { headerProperties, textProperties } = getColumnParametersFromArray(
    currentRow ? currentRow.parameters : []
  )

  const initialColumnHeaderStyles = useMemo(() => {
    return transformTextParamsData(headerProperties)
  }, [currentRow])
  const initialColumnTextStyles = useMemo(() => {
    return transformTextParamsData(textProperties)
  }, [currentRow])

  const filterObjectValueOptions = useCallback(
    (fields: ObjectFieldDTO[]) =>
      fields.map(field => {
        const isAlreadyUsed = Boolean(
          columns?.find(column => {
            return column?.value === watchValue && column.objectValue === field.name
          })
        )

        return { id: field.id, name: field.name, isAlreadyUsed }
      }),
    [columns, watchValue, currentRow]
  )

  const objectValueOptions = useMemo(
    () => filterObjectValueOptions(objectForField?.fields || []),
    [filterObjectValueOptions, objectForField?.fields]
  )

  const commandOptions = useMemo(
    () => createAutocompleteOptions(objectCommands, { id: 'code', label: 'code' }),
    [objectCommands]
  )

  const filterObjectFields = (fields?: ObjectFieldDTO[]): ObjectFieldWithUsed[] =>
    fields?.map(field => {
      const isAlreadyUsed = Boolean(columns?.find(column => column.code === field.name))

      return {
        ...field,
        isAlreadyUsed,
      }
    }) || []

  const filteredObjectFields = useMemo(
    () => filterObjectFields(rawObjectFields),
    [rawObjectFields, columns, objectValueOptions]
  )

  const valueOptions: ObjectValueOptionsType[] = useMemo(
    () =>
      filteredObjectFields.map(({ id, name, isAlreadyUsed, type, isPk }) => ({
        id,
        name,
        isAlreadyUsed,
        fieldType: type,
        isPk,
      })),
    [filteredObjectFields]
  )

  const bindTypeOptions = useMemo(
    () =>
      bindingValuesColumns
        ?.map(cat => ({
          id: cat.id,
          name: cat.code,
        }))
        .filter(cat => !(cat.name === BIND_TYPE.FIELD && valueOptions.length === 0)) || [],
    [bindingValuesColumns, valueOptions]
  )

  // TODO дубликат
  useEffect(() => {
    const field = rawObjectFields?.find(field => field.name === watchValue)
    setObjectField(field ? field : undefined)
    clearErrors()

    if (currentRow && field && currentRow?.value === field?.name) {
      setValue('objectValue', currentRow?.objectValue)

      return
    }

    if (watchBinding !== BIND_TYPE.JS && watchBinding !== BIND_TYPE.JSX) {
      setValue('objectValue', t('placeholder.objectValue'))
    }
  }, [rawObjectFields, watchValue, currentRow, objectCode])

  const triggerValidation = async () => await trigger('objectValue')

  useEffect(() => {
    if (watchValue && objectValueOptions.length === 0) {
      triggerValidation()
    }
  }, [objectValueOptions])

  // Когда поля asСheckbox и asDuration скрываются, нужно ресетить их значения, чтобы случайно не отправилось true
  // при иных valueType
  useEffect(() => {
    if (objectField?.type === OBJECT_FIELD_TYPE.ENUM) {
      return
    }

    if (valueType !== FIELD_VALUE_TYPE.INTEGER && valueType !== FIELD_VALUE_TYPE.BOOLEAN) {
      if (watchAsCheckbox && isDirty) {
        setValue('asCheckbox', false)
      }
    }

    if (valueType !== FIELD_VALUE_TYPE.INTEGER) {
      if (watchAsDuration && isDirty) {
        setValue('asDuration', false)
      }
    }
  }, [valueType])

  useEffect(() => {
    if (currentRow) {
      reset({
        ...currentRow,
        valueType,
        order: currentRow.order?.toString(),
        columnToSort: currentRow.columnToSort ? { id: 0, label: currentRow.columnToSort } : null,
        pinnedColumn: currentRow.pinnedColumn
          ? { id: currentRow.pinnedColumn, label: currentRow.pinnedColumn }
          : null,
        pinning: !!currentRow.pinnedColumn,
        useParameters: Boolean(currentRow?.useLocalParameters),
        asCheckbox:
          currentRow.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
          currentRow.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN,
        asDuration: currentRow.valueFormat === FIELD_VALUE_FORMAT.TIME,
      })

      setLoading(false)
    }
  }, [currentRow])

  useEffect(() => {
    onSetModalProperty?.('column')
  }, [])

  useEffect(() => {
    if (watchBinding === BIND_TYPE.JS || watchBinding === BIND_TYPE.JSX) {
      const value =
        currentRow?.value &&
        (currentRow.bindType === BIND_TYPE.JS || currentRow?.bindType === BIND_TYPE.JSX)
          ? currentRow.value
          : ''

      setValue('value', value)
    }

    if (watchBinding === BIND_TYPE.FIELD && !watchValue) {
      setValue('value', t('placeholder.value'))
    }
  }, [watchBinding])

  const { state, handlers } = useHandlers({
    objectField,
    watchBinding,
    watchUseParameters,
    setValue,
    isDirty: isDirtyBasicParameters,
    isObjectLinkedValue,
    reset,
    handleSubmit,
    viewObjectCode,
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
  })

  const { isTextParamsDirty, isShowResetConfirmModal, isShowScriptValueDialog } = state
  // TODO: Привести к нормальному виду хуков
  const {
    handleChangeValue,
    toggleOpenScriptValueDialog,
    handleChangeBinding,
    handleSave,
    handleTextExternalDirty,
    handleHeaderExternalDirty,
    handleSetValuesColumnStyles,
    handleClickReset,
    handleResetParams,
    handleSetShowResetConfirmModal,
  } = handlers

  const isDirty = isDirtyBasicParameters || isTextParamsDirty

  usePrompt({ when: isDirty })

  const columnInputs = useInputs({
    watchBinding,
    watchValue,
    watchObjectValue,
    handleChangeValue,
    valueOptions,
    bindTypeOptions,
    handleChangeBinding,
    valueType,
    toggleOpenScriptValueDialog,
    isObjectLinkedValue,
    objectValueOptions,
    watchPinning,
    fieldType,
    watchAsCheckbox,
    watchAsDuration,
  })

  return {
    isDirty,
    handleSave,
    isLoading,
    isShowScriptValueDialog,
    toggleOpenScriptValueDialog,
    methods,
    columnInputs,
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    watchUseParameters,
    isShowResetConfirmModal,
    handleTextExternalDirty,
    handleHeaderExternalDirty,
    handleSetValuesColumnStyles,
    handleClickReset,
    handleResetParams,
    handleSetShowResetConfirmModal,
  }
}
