import { SyntheticEvent, useState } from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { t } from 'i18next'

import { setModelCode } from '@redux/reducers/stepper.reducer'

import { useAppDispatch } from '@hooks'
import { MODAL_TYPE, ROUTES } from '@constants'

import {
  AutocompleteOption,
  ENTITY_COMMAND_TYPE,
  FormRow,
  FormValues,
  GETForm,
  ModalType,
  ObjectShortDTO,
  PayloadCreateField,
  POSTForm,
  ShareColumnType,
} from '../../../types'
import {
  CreateFieldMutationType,
  CreateFormMutationType,
  DeleteFieldMutationType,
  FetchFieldMutationType,
  OutletContext,
  UpdateFieldMutationType,
  UpdateFormMutationType,
} from '../types'

type useHandlersParams = {
  createForm: CreateFormMutationType
  updateForm: UpdateFormMutationType
  createField: CreateFieldMutationType
  deleteField: DeleteFieldMutationType
  updateField: UpdateFieldMutationType
  fetchField: FetchFieldMutationType
  objects: ObjectShortDTO[] | undefined
  code: string | undefined
  copyCode: string | undefined
  handleSubmit: UseFormHandleSubmit<FormValues>
}

export const useHandlers = ({
  createForm,
  updateForm,
  createField,
  deleteField,
  updateField,
  fetchField,
  objects,
  code,
  copyCode,
  handleSubmit,
}: useHandlersParams) => {
  const [modalProperty, setModalProperty] = useState<ShareColumnType>()
  const [objectCode, setObjectCode] = useState<string>()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [currentRow, setCurrentRow] = useState<FormRow | null>()
  const [modalType, setModalType] = useState<ModalType>(MODAL_TYPE.CREATE)
  const [currentTab, setCurrentTab] = useState(0)
  const [currentForm, setCurrentForm] = useState<GETForm>()
  const [isShowScriptValueDialog, setScriptValueDialog] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { refreshFormTablesData } = useOutletContext<OutletContext>()

  const handleSetCurrentForm = (value: GETForm) => setCurrentForm(value)

  const handleSetCurrentRow = (value: FormRow | null): void => setCurrentRow(value)

  const handleSetModalProperty = (type: ShareColumnType) => setModalProperty(type)

  const handleSetObjectCode = (value: string) => setObjectCode(value)

  const handleAddForm = async (form: POSTForm) => {
    if (!form.id) {
      const res = await createForm(form).unwrap()

      refreshFormTablesData()

      if (!code) {
        navigate(`${ROUTES.CONFIG_FORMS_EDIT}/${res.code}`, { state: null })
      }

      return
    }

    return updateForm(form).unwrap()
  }

  const handleAddField = (row: PayloadCreateField) => createField(row)

  const handleDeleteField = (id: number | string) => deleteField(id)

  const handleEditField = (row: PayloadCreateField) => updateField(row)

  const handleCancel = () => navigate(ROUTES.CONFIG_FORMS)

  const handleCloseModal = (isDirty?: boolean) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setShowDialog(false)
      if (currentRow) setCurrentRow(null)

      return
    }
    if (!isDirty) {
      setShowDialog(false)
      if (currentRow) setCurrentRow(null)
    }
  }

  const handleOpenDialog = async (type: ModalType, id?: number | string) => {
    if (type === MODAL_TYPE.EDIT && id) {
      fetchField(id).unwrap()
    }
    setModalType(type)
    setShowDialog(true)
  }

  const toggleOpenScriptValueDialog = (open: boolean) => setScriptValueDialog(open)

  const handleSetModelCode = (value: string) => dispatch(setModelCode(value))

  const handleTabChange = (_e: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleEdit = (id?: number | string, isAction?: boolean) => {
    if (id) {
      handleOpenDialog(MODAL_TYPE.EDIT, id)
    }
  }

  const handleSave = handleSubmit((data: FormValues) => {
    const {
      code,
      title,
      object,
      commands: commandOptions,
      id,
      viewIds,
      isJsTitle,
      titleJsValue,
      contextMenu,
    } = data

    const commands = commandOptions
      .filter(command => command.type && command.code)
      .map(command => ({
        type: (command.type as AutocompleteOption<ENTITY_COMMAND_TYPE>).id,
        name: (command.code as AutocompleteOption<string>).id,
      }))

    handleAddForm({
      id,
      code,
      title,
      commands,
      viewIds: viewIds.map(viewData => Number(viewData.id)),
      objectCode: object?.id || '',
      objectTitle: object?.label || '',
      isJsTitle,
      titleJsValue,
      contextMenuId: contextMenu?.id || null,
    }).catch(() => {
      handleSetModelCode('')
    })
    handleSetModelCode(code)
  })

  return {
    state: {
      modalProperty,
      objectCode,
      showDialog,
      currentRow,
      modalType,
      currentTab,
      currentForm,
      isShowScriptValueDialog,
    },
    handlers: {
      handleSetModalProperty,
      handleSetObjectCode,
      handleAddForm,
      handleAddField,
      handleDeleteField,
      handleEditField,
      handleCancel,
      handleCloseModal,
      handleOpenDialog,
      handleSetModelCode,
      handleTabChange,
      handleEdit,
      handleSave,
      handleSetCurrentForm,
      handleSetCurrentRow,
      toggleOpenScriptValueDialog,
    },
  }
}
