import { AVAILABLE_SEARCH_RULES_TYPES, FIELD_VALUE_TYPE, SEARCH_RULE_TYPE } from '@constants'
import { ViewRow } from '@types'

export const getIsAvailableRuleByType = (column: ViewRow, rule: SEARCH_RULE_TYPE) => {
  return (
    column.valueType &&
    column.fieldType &&
    AVAILABLE_SEARCH_RULES_TYPES[rule].fieldValueTypes.includes(column.valueType) &&
    AVAILABLE_SEARCH_RULES_TYPES[rule].objectFieldTypes.includes(column.fieldType)
  )
}

export const getIsAvailableRuleByTypeForValueType = (
  valueType: FIELD_VALUE_TYPE,
  rule: SEARCH_RULE_TYPE
) => {
  return AVAILABLE_SEARCH_RULES_TYPES[rule].fieldValueTypes.includes(valueType)
}
