import { Navigate } from 'react-router-dom'
import { ROUTES } from '@constants'
import { ReturnTransformCommonParametersToValues } from '@helpers'

export const SwitchRouter = (initialCommonParameters: ReturnTransformCommonParametersToValues) => {
  const context = localStorage.getItem('context')

  if (context === null) {
    return <Navigate replace to={ROUTES.CHOOSE_CONTEXT} />
  }

  if (initialCommonParameters.ganttChart) {
    return <Navigate replace to={initialCommonParameters.ganttChart} />
  }

  return <Navigate replace to={'/'} />
}
