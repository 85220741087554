import { REGEX } from '@constants'

export const getParsedFields = (str: string) => {
  const match = str.match(REGEX.FIELD_NAMES)

  const parentFieldName = match?.[1] || null
  const fieldName = match?.[2] || ''

  return { parentFieldName, fieldName }
}
