import { FC } from 'react'
// import {
//   GridToolbarColumnsButton,
//   GridToolbarContainer,
// } from '@microservices/wiskey-react-components/DataGrid'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@microservices/wiskey-react-components'

type CustomToolbarComponentProps = {
  showColumnsButton: boolean
  isAssistantSearch: boolean
}

export const CustomToolbarComponent: FC<CustomToolbarComponentProps> = ({
  showColumnsButton,
  isAssistantSearch,
}) => {
  return (
    <GridToolbarContainer>
      {showColumnsButton && !isAssistantSearch && <GridToolbarColumnsButton title='title' />}
    </GridToolbarContainer>
  )
}
