import { FC } from 'react'
import { CORRECT_HEIGHT_STRING_EDIT_INPUT } from '@constants'
import { TextField } from '@microservices/wiskey-react-components'
import { Box, Grid } from '@mui/material'

import { GeneralButtons } from '@components/GeneralButtons'
import { useStringEditDialog } from '@components/StringEditDialog/hooks/useStringEditDialog'

type StringEditDialogType = {
  id?: string | undefined
  text?: string | undefined
  heightDialog?: number | undefined
  widthDialog?: number | undefined
  setOuterValue?: ((value: string) => void) | undefined
  readonly?: boolean
}

export const StringEditDialog: FC<StringEditDialogType> = ({
  id,
  setOuterValue,
  text = '',
  readonly = true,
  widthDialog = 600,
  heightDialog = 800,
}) => {
  const { state, handlers, data } = useStringEditDialog({ id, text, setOuterValue })

  const { value, isDirty } = state
  const { handleChangeValue, handleCancel, handleSave } = handlers
  const { t } = data

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        multiline
        disabled={readonly}
        sx={{ m: 1 }}
        value={value}
        inputProps={{
          style: {
            minHeight: heightDialog - CORRECT_HEIGHT_STRING_EDIT_INPUT,
          },
        }}
        onChange={e => handleChangeValue(e)}
      />
      <Grid container justifyContent={'flex-end'} sx={{ mb: 1 }}>
        <GeneralButtons
          cancelText={t(`stringEdit.buttons.cancel`)}
          saveDisabled={!isDirty}
          saveText={t(`stringEdit.buttons.save`)}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      </Grid>
    </Box>
  )
}
