import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithNotifications } from './config'

export const refetchApi = createApi({
  reducerPath: 'refetchApi',
  baseQuery: baseQueryWithNotifications,
  endpoints: build => ({}),
})

// export const {} = refetchApi
