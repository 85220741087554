import { EVENT_NAME_OBJECT_MESSAGE } from '@constants'
import { deepCloneObject } from '@helpers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChangedFormFieldsState, DeleteFormFieldsPayload, SetFormFieldsPayload } from '../../types'

const initialState: ChangedFormFieldsState = {
  objectsWithDirtyRecords: {},
}
const changedFormFields = createSlice({
  name: 'changedFormFields',
  initialState,
  reducers: {
    setFormFields(state, action: PayloadAction<SetFormFieldsPayload>) {
      const { changedFields, objectCode, recordId, eventName } = action.payload
      const { objectsWithDirtyRecords } = state
      const objectByCode = objectsWithDirtyRecords[objectCode]

      objectsWithDirtyRecords[objectCode] = {
        ...objectByCode,
        [recordId]:
          eventName === EVENT_NAME_OBJECT_MESSAGE.UPDATE
            ? changedFields
            : EVENT_NAME_OBJECT_MESSAGE.CREATE,
      }
    },
    deleteFormField(state, action: PayloadAction<DeleteFormFieldsPayload>) {
      const { objectCode, recordId } = action.payload

      // TODO: structuredClone
      const copyStateObjects = deepCloneObject(state.objectsWithDirtyRecords)

      if (copyStateObjects[objectCode]) {
        delete copyStateObjects[objectCode][recordId]

        state.objectsWithDirtyRecords = copyStateObjects
      }
    },
  },
})

export default changedFormFields.reducer
export const { setFormFields, deleteFormField } = changedFormFields.actions
