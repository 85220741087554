import { useCallback, useEffect } from 'react'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import { ActionData } from '@microservices/gantt-react-component'

import { useExecuteCommandMutation } from '@redux/api/ganttChart.api'

import { getParamsStringSafe } from '@helpers'

import { dispatchSelfUpdate } from '../customEvent'
import { AxisDefinedData, GanttActionType, GanttFormValues } from '../types'

export const useExecuteCommand = (ganttConfig: GanttFormValues) => {
  const [executeCommand, response] = useExecuteCommandMutation()

  useEffect(() => {
    // todo неизвестный originalArgs, ожидается id, для обновления инф на гантте
    if (response?.originalArgs && ['rejected', 'fulfilled'].includes(response.status)) {
      dispatchSelfUpdate(response.originalArgs)
    }
  }, [response?.status])

  return useCallback(
    (action: GanttActionType, actionData: ActionData, definedData: AxisDefinedData) => {
      const command = getParamsStringSafe(action.actionField)

      if (!isEmpty(command) && isArray(command)) {
        command.map(item =>
          executeCommand({
            ...definedData,
            command: item.name,
          })
        )

        return true
      }

      executeCommand({
        ...definedData,
        command,
      })

      return true
    },
    [ganttConfig?.code]
  )
}
