import { useContext } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import i18next from 'i18next'
import { ACTION_CODE, EVENT_CODE, GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { FormInputProps, SelectOption } from '@microservices/wiskey-react-components'
import { AutocompleteOption } from '@types'

import { PageContext } from '@pages/EntityCreateOrEdit'

export type ActionForm = {
  id?: number | string
  code: string
  title: string
  actionCode?: ACTION_CODE
  actionTitle?: string
  eventCode?: EVENT_CODE
  eventTitle?: string
  params?: string
  columnToSort?: AutocompleteOption | null
  order?: string | number
  json?: boolean
  objectFormCode?: AutocompleteOption | null
  targetColumn?: AutocompleteOption | null
  contextMenu?: AutocompleteOption<number> | null
}

type useInputsParams = {
  setValue: UseFormSetValue<ActionForm>
  actionTypeOptions: SelectOption[]
  contextMenusOptions: AutocompleteOption[]
  targetColumnsOptions: AutocompleteOption[]
  eventOptions: SelectOption[]
  watchJson?: boolean
  watchEvent?: EVENT_CODE
  watchActionCode?: ACTION_CODE
  formCodeOptions: AutocompleteOption[]
  loadingAllForm?: boolean
  loadingBindingValuesEvents?: boolean
}

export const useInputs = ({
  setValue,
  actionTypeOptions,
  eventOptions,
  watchJson,
  watchEvent,
  watchActionCode,
  formCodeOptions,
  loadingAllForm,
  targetColumnsOptions,
  contextMenusOptions,
  loadingBindingValuesEvents,
}: useInputsParams) => {
  const { modalType } = useContext(PageContext)

  const isRightColumnClick = watchEvent === EVENT_CODE.ON_COLUMN_RIGHT_CLICK
  const isContextMenu = watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU
  const isEmptyActionCode = watchActionCode === i18next.t('placeholder.actionType')

  const targetInput = isRightColumnClick
    ? [
        {
          name: 'targetColumn',
          inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
          placeholder: i18next.t('entityActionDialog.targetColumn.placeholder'),
          label: i18next.t('entityActionDialog.targetColumn.label'),
          autocompleteOptions: targetColumnsOptions,
          loading: loadingAllForm,
          rules: { required: true },
        },
      ]
    : []

  const actionInputs: FormInputProps[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('actionInputs.code.placeholder'),
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      label: i18next.t('actionInputs.code.label'),
      disabled: modalType === 'edit',
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('actionInputs.title.placeholder'),
      label: i18next.t('actionInputs.title.label'),
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'actionCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('actionInputs.actionCode.label'),
      placeholder: i18next.t('placeholder.actionType'),
      selectOptions: actionTypeOptions,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.actionType') },
      onChangeSelect: value => {
        if (value !== watchActionCode) {
          setValue('eventCode', i18next.t('placeholder.event'), { shouldDirty: true })
        }
      },
    },
    {
      name: 'eventCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('actionInputs.eventCode.label'),
      placeholder: i18next.t('placeholder.event'),
      selectOptions: isEmptyActionCode ? [] : eventOptions,
      loading: loadingBindingValuesEvents,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.event') },
    },
    ...(isContextMenu
      ? [
          ...targetInput,
          {
            name: 'contextMenu',
            inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
            placeholder: i18next.t('entityActionDialog.contextMenu.placeholder'),
            label: i18next.t('entityActionDialog.contextMenu.label'),
            autocompleteOptions: contextMenusOptions,
            loading: loadingAllForm,
            rules: { required: true },
          },
        ]
      : [
          {
            name: 'formCodeRow',
            inputs: [
              ...(watchJson
                ? [
                    {
                      name: 'params',
                      inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
                      label: i18next.t('actionInputs.params.label'),
                      placeholder: i18next.t('actionInputs.params.placeholder'),
                      rules: { required: true, validate: value => value.trim().length !== 0 },
                    },
                  ]
                : [
                    {
                      name: 'objectFormCode',
                      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                      placeholder: i18next.t('placeholder.objectFormCode'),
                      label: i18next.t('label.objectFormCode'),
                      autocompleteOptions: formCodeOptions,
                      loading: loadingAllForm,
                      rules: { required: true },
                    },
                  ]),
              {
                name: 'json',
                inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                label: i18next.t('label.json'),
                labelPlacement: 'end',
              },
            ],
          },
        ]),
  ]

  return actionInputs
}
