import { AutocompleteOption } from '@types'

export const createAutocompleteOptions = <T>(
  array: T[],
  keys: Record<'id' | 'label', keyof T>
): AutocompleteOption[] => {
  const { id, label } = keys

  return array.map(item => ({ id: item[id], label: item[label] } as AutocompleteOption))
}
