import { createContext, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { usePrompt } from '@hooks'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Button, Grid, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { useContextCreateOrEdit } from './hooks/useContextCreateOrEdit'
import { ContextTabs } from './components'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const ContextCreateOrEdit = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const {
    data: { methods, id, isCreate, isEdit, contextById },
    state: { currentTab, isNewTitle, isLoading },
    handlers: { handleTabChange, handleCreateModel, handleCancel, handleSave },
  } = useContextCreateOrEdit()

  const {
    formState: { dirtyFields },
  } = methods

  const { searchGanttChart, searchView, ...dirtyFieldsCommon } = dirtyFields

  const dirty = useMemo(() => Object.values(dirtyFieldsCommon).some(Boolean), [dirtyFieldsCommon])

  const isLeaveEdit = dirty && !isLoading && !isCreate && !!contextById
  const isLeaveCreate = dirty && isCreate && !isEdit && !isLoading

  usePrompt({ when: isLeaveEdit || isLeaveCreate })

  const contextValue: PageContextType = {
    methods,
    id,
    isCreate,
  }

  const modelType = isCreate ? 'create' : 'edit'

  return (
    <PageContext.Provider value={contextValue}>
      <PageContentLayout>
        <Box sx={{ width: '100%' }}>
          <form>
            <FormProvider {...methods}>
              <Box p={1} pb={0}>
                <Grid container sx={{ pb: 2 }}>
                  <Typography variant='h5'>{t(`contexts.title.${modelType}`)}</Typography>
                </Grid>
                <FormInput
                  inputType={GENERATOR_INPUT_TYPE.INPUT}
                  label={t(`contexts.title.context`)}
                  name='title'
                  size='small'
                  sx={{ pt: 1 }}
                  rules={{
                    required: true,
                  }}
                />
              </Box>
              <ContextTabs currentTab={currentTab} handleTabChange={handleTabChange} />
              <Grid
                container
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                p='5px'
              >
                <Box></Box>
                <Box>
                  <Button
                    disabled={!dirty && !isNewTitle}
                    sx={{ mr: '15px' }}
                    variant='contained'
                    onClick={handleSave}
                  >
                    {t('contexts.saveButton')}
                  </Button>
                  <Button sx={{ mr: '3px' }} variant='outlined' onClick={handleCancel}>
                    {t('contexts.cancelButton')}
                  </Button>
                </Box>
              </Grid>
            </FormProvider>
          </form>
        </Box>
      </PageContentLayout>
    </PageContext.Provider>
  )
}
