import { FC } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { AVAILABLE_FORM_ELEMENT_TYPE } from '@constants'
import { DragIndicator } from '@mui/icons-material'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import SpaceBarIcon from '@mui/icons-material/SpaceBar'
import TableViewIcon from '@mui/icons-material/TableView'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import WebAssetIcon from '@mui/icons-material/WebAsset'
import { Box, Typography } from '@mui/material'
import { grey, indigo } from '@mui/material/colors'

import { DraggableFormElement } from '@pages/FormCreateOrEdit/types'

type DraggableElementProps = {
  element: DraggableFormElement
  title: string
  index: number
}

const itemIcon = {
  [AVAILABLE_FORM_ELEMENT_TYPE.VIEW]: <TableViewIcon color={'warning'} />,
  [AVAILABLE_FORM_ELEMENT_TYPE.TEXT]: <TextFieldsIcon color={'primary'} />,
  [AVAILABLE_FORM_ELEMENT_TYPE.CONTAINER]: <WebAssetIcon color={'secondary'} />,
  [AVAILABLE_FORM_ELEMENT_TYPE.SPACE]: <SpaceBarIcon color={'success'} />,
  [AVAILABLE_FORM_ELEMENT_TYPE.HISTORY]: <ManageSearchIcon sx={{ color: indigo[500] }} />,
}

export const DraggableElement: FC<DraggableElementProps> = ({ element, title, index }) => {
  return (
    <Draggable key={element.id} draggableId={`${element.id}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 2,
            px: 2,
            gap: '16px',
          }}
          title={element.type}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <DragIndicator
              sx={{
                color: theme => (theme.palette.mode === 'light' ? grey[600] : grey[300]),
              }}
              fontSize={'small'}
            />
            {element.type !== AVAILABLE_FORM_ELEMENT_TYPE.FIELD && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>{itemIcon[element.type]}</Box>
            )}
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      )}
    </Draggable>
  )
}
