import { ColumnDef } from '@tanstack/react-table'

export const calculatedColumnsWidth = <T>(
  columns: ColumnDef<T>[],
  tableWidth: number
): ColumnDef<T>[] => {
  const notFlexColumns = columns.filter(column => !column.meta?.flex)
  const widthOfNotFlexColumns =
    notFlexColumns.length !== 0
      ? notFlexColumns.map(column => column.size || 150).reduce((a, b) => a + b)
      : 0

  const availableWidth = tableWidth - widthOfNotFlexColumns
  const flexColumns = columns.filter(column => !!column.meta?.flex)
  const allFlexCount =
    flexColumns.length !== 0
      ? flexColumns.map(column => column.meta?.flex || 0).reduce((a, b) => a + b)
      : 0
  const widthForFlexColumns =
    availableWidth >= flexColumns.length * 150 ? availableWidth : flexColumns.length * 150

  const oneFlexWidth = widthForFlexColumns / allFlexCount

  return columns.map(column => ({
    ...column,
    size: column.meta?.flex ? column.meta.flex * oneFlexWidth : column.size,
  }))
}
