import { useEffect, useState } from 'react'
import { DragStart } from 'react-beautiful-dnd'
import { useSubscribeEvent } from '@hooks'

import { EVENT_NAME } from '../../../events'

type UseSubscribeMouseOver = {
  currentDragged?: DragStart | undefined
  isEmptyLine: boolean
}
// Подписка на кастомные события mouse enter/leave на drag handler для линии
// Необходимо для того, чтобы слушать события напрямую (без состояния) в пустой линии и управлять её отображением
export const useSubscribeLineDragHandlerMouseOver = ({
  currentDragged,
  isEmptyLine,
}: UseSubscribeMouseOver) => {
  const [isMouseOver, setIsMouseOver] = useState(false)

  const [renderEmptyLine, setRenderEmptyLine] = useState(true)

  useEffect(() => {
    if (currentDragged) {
      return
    }
    if (isMouseOver) {
      setRenderEmptyLine(false)
    } else {
      setRenderEmptyLine(true)
    }
  }, [currentDragged, isMouseOver])

  useSubscribeEvent(EVENT_NAME.CONFIGURED_FORM_ELEMENTS_MOUSE_ENTER, () => {
    if (isEmptyLine) {
      setIsMouseOver(true)
    }
  })
  useSubscribeEvent(EVENT_NAME.CONFIGURED_FORM_ELEMENTS_MOUSE_LEAVE, () => {
    if (isEmptyLine) {
      setIsMouseOver(false)
    }
  })

  return { renderEmptyLine }
}
