export const enum ENTITY {
  VIEW = 'view',
  GANTT_CHART = 'gantChart',
  LIST_CONTROL = 'listControl',
  DROP_DOWN_ENTITY = 'dropdownEntity',
}

export const ENTITY_PATH: {
  view: 'VIEWS'
  listControl: 'LIST_CONTROLS'
  dropdownEntity: 'DROP_DOWN_ENTITY'
} = {
  view: 'VIEWS',
  listControl: 'LIST_CONTROLS',
  dropdownEntity: 'DROP_DOWN_ENTITY',
}

export enum ENTITY_TYPES_REQUEST {
  LIST_CONTROL = 'LIST_CONTROL',
  VIEW = 'DEFAULT',
  DROP_DOWN_ENTITY = 'DROP_DOWN_ENTITY',
  GANTT_CHART = 'GANTT_CHART',
  MENU = 'MENU',
}

export enum DROPDOWN_FILTER_TYPES {
  WINDOW = 'WINDOW',
  LIST = 'LIST',
}

export enum CLICK_EVENT_TYPE {
  EDIT_CLICK = 'EDIT_CLICK',
  CREATE_CLICK = 'CREATE_CLICK',
  ROW_CLICK = 'ROW_CLICK',
  CELL_CLICK = 'CELL_CLICK',
}
