import { ViewRow } from 'src/types'

import { isObjectValueType } from '@helpers'
import { BIND_TYPE } from '@constants'

export const getColumnFieldName = (column: ViewRow) => {
  return column.code
}

// TODO Переделать сортировку с value на code (нужен бэк)
export const getColumnFieldNameForSort = (column: ViewRow) => {
  // Колонки с js/jsx value
  if (column.bindType !== BIND_TYPE.FIELD) {
    return column.columnToSort
  }
  // Колонки, имеющие связь с другим объектом
  if (isObjectValueType(column.valueType) && column.objectValue) {
    return `${column.value}.${column.objectValue}`
  }

  return column.columnToSort || column.value
}
