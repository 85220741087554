import { SortType } from '@hooks'

export const MAX_NUMBER_INPUTS_IN_ROW = 3

export enum ARROW_DIRECTION {
  left = 'left',
  right = 'right',
}

export const SORT_BY_ORDER_AND_DEFAULT: SortType[] = [{ field: 'order', sort: 'asc' }]
