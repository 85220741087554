import { ParameterPropertyType, POSTParameterPropertyType } from 'src/types'

import { TextParams } from '@components/TextSettings'

export const getGlobalPropertiesForUpdate = (
  textData: TextParams,
  propertiesData: ParameterPropertyType[],
  paramCode: string
): POSTParameterPropertyType[] => {
  const propertiesForUpdate: POSTParameterPropertyType[] = []

  propertiesData.forEach(property => {
    const correspondingHeaderDataKey = Object.keys(textData).find(key => key === property.key)

    if (!correspondingHeaderDataKey) {
      return
    }

    if (property.value == textData[correspondingHeaderDataKey as keyof TextParams]) {
      return
    }

    propertiesForUpdate.push({
      ...property,
      value: textData[correspondingHeaderDataKey as keyof TextParams] as string,
      paramCode,
    })
  })

  return propertiesForUpdate
}
