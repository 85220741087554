import { useEffect, useState } from 'react'

import { useUpdateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useUpdateObjectDataRecordWithFixedKey'
import { Row } from '@components/DisplayForm/types'

export const useCurrentRow = (globalId: string) => {
  const [currentRow, setCurrentRow] = useState<Row>()

  const [, { data: updatedRow }] = useUpdateObjectDataRecordWithFixedKey(globalId)

  useEffect(() => {
    if (updatedRow) {
      setCurrentRow(updatedRow)
    }
  }, [updatedRow])

  return { currentRow, setCurrentRow }
}
