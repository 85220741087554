import isObject from 'lodash/isObject'

export const deepCloneObject = (obj: any) => {
  if (obj === null) return null
  const clone = { ...obj }
  Object.keys(clone).forEach(
    key => (clone[key] = isObject(obj[key]) ? deepCloneObject(obj[key]) : obj[key])
  )

  return Array.isArray(obj) && obj.length
    ? (clone.length = obj.length) && Array.from(clone)
    : Array.isArray(obj)
    ? Array.from(obj)
    : clone
}
