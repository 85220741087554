import { MouseEventWithView } from 'src/types'
import { DRAGGABLE_FORM_SIZES } from '@constants'

export const getInitialPositionWindow = (
  event: MouseEventWithView,
  customSize: { height: number; width: number } = {
    height: DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
    width: DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
  }
) => {
  const innerWidth = event.view.innerWidth || document.documentElement.offsetWidth
  const innerHeight = event.view.innerHeight || document.documentElement.offsetHeight
  const scrollHeight = event.view.scrollY || 0
  const scrollWidth = event.view.scrollX || 0

  const isWindowWitdhAvailableLeft = innerWidth - event.clientX > customSize.width
  const isWindowHeighthAvailableLeft = innerHeight - event.clientY > customSize.height
  const isClientYLessAvailableHeight = event.clientY > innerHeight - customSize.height

  return {
    x: isWindowWitdhAvailableLeft
      ? event.clientX + scrollWidth
      : event.clientX + scrollWidth - customSize.width,
    y: isWindowHeighthAvailableLeft
      ? isClientYLessAvailableHeight
        ? (event.clientY + scrollHeight) / 2
        : event.clientY + scrollHeight
      : Math.max(0 + scrollHeight, event.clientY - customSize.height + scrollHeight),
  }
}
