import { DialogWindowType, NavOptionType, ViewDialogWindowMeta } from 'src/types'

const containsInNestedOption = (options: NavOptionType[], parentId: number): boolean => {
  return options.some(option => {
    if (option.id === parentId) {
      return true
    }

    if (option.options?.length) {
      return containsInNestedOption(option.options, parentId)
    }
  })
}

export const containsOption = (
  option: NavOptionType,
  selectedOption: DialogWindowType<ViewDialogWindowMeta>
) => {
  if (option.id === selectedOption.meta.menuId) {
    return true
  }

  if (selectedOption.meta.parentId) {
    if (option.id === selectedOption.meta.parentId) {
      return true
    }

    if (option.options?.length) {
      return containsInNestedOption(option.options, selectedOption.meta.parentId)
    }
  }

  return false
}
