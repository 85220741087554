import { FC, ReactNode } from 'react'
import { Card } from '@mui/material'

type CardContainerProps = {
  children: ReactNode
}

const CardContainer: FC<CardContainerProps> = ({ children }) => {
  return (
    <Card
      sx={{
        background: theme => theme.palette.background.formCardContainer,
        border: theme => theme.palette.border.formCardContainer,
        boxShadow: 'none',
        borderRadius: 0,
        '.MuiCardContent-root:last-child': { p: 0 },
      }}
    >
      {children}
    </Card>
  )
}

export default CardContainer
