import { FORM_TYPE, ROUTES } from '../constants'

export type GetUrlFormProps = {
  formCode: string
  id: string
  objectCode: string
  objectId?: string
  viewCode: string
  event: FORM_TYPE
  path: string
  search?: string
}

export const getUrlForm = ({ formCode, search, ...params }: GetUrlFormProps) => {
  const searchParams = new URLSearchParams()
  const paramKeys: (keyof Omit<GetUrlFormProps, 'formCode' | 'search'>)[] = [
    'objectCode',
    'objectId',
    'viewCode',
    'event',
    'path',
    'id',
  ]

  paramKeys.forEach(
    param =>
      params[param] &&
      searchParams.append(
        param,
        param === 'path' && search
          ? ((params[param] + search) as string)
          : (params[param] as string)
      )
  )

  return `/${params.path}${ROUTES.FORMS}/${formCode}?${searchParams.toString()}`
}
