import { createContext, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { DragDropContext, DraggableLocation, DragStart, DropResult } from 'react-beautiful-dnd'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  AVAILABLE_FORM_ELEMENT_TYPE,
  FORM_DROPPABLE_TYPE,
  FORM_ELEMENT_TYPE,
  FORM_FIELD_LABEL_POSITION,
  GRID_SIZE,
} from '@constants'
import {
  add,
  getElementPathByDroppableId,
  getLineById,
  getLinePathByDroppableId,
  move,
  reorder,
} from '@helpers'
import { useAppDispatch, useTrimming } from '@hooks'
import { Box, Divider, SxProps, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { DraggableFormElement } from '@pages/FormCreateOrEdit/types'

import {
  useFetchUndefinedFieldsByFormCodeQuery,
  useFetchUndefinedViewsByFormCodeQuery,
} from '@redux/api'
import { useFetchContainersQuery } from '@redux/api/container.api'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { EntityType, FormContainer, FormElement, FormRow } from '../../../../types'
import {
  useCreateContainerElementMutation,
  useUpdateContainerElementMutation,
  useUpdateContainerLineMutation,
  useUpdateContainerMutation,
} from '../../hooks/mutations'

import { AvailableFormElements } from './AvailableFormElements'
import { ConfiguredFormElements } from './ConfiguredFormElements'

export const Title: FC<{ title: string; sx?: SxProps }> = ({ title, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 250,
        padding: '16px 16px 16px 24px',
        gap: '16px',
        pl: 0,
        ...sx,
      }}
    >
      <Typography variant={'caption'} fontWeight={700} sx={{ color: grey[600] }}>
        {title}
      </Typography>
    </Box>
  )
}

export type UnallocatedFields = FormRow[]
export type AllocatedFields = Array<FormRow[]>

type FieldsConfigProps = {
  formCode: string | undefined
}

type FieldsConfigContextType = {
  containers: FormContainer[]
  isLoadingContainers: boolean
  setContainers: Dispatch<SetStateAction<FormContainer[]>>
  containerCreationInProgress: boolean
  availableFormElements: DraggableFormElement[]
  availableFormFields: DraggableFormElement[]
  availableViews: DraggableFormElement[]
  isLoadingAvailableFields: boolean
  currentDragged?: DragStart
  changeFieldsFilter: (filter: string | undefined) => void
}

export const FieldsConfigContext = createContext<FieldsConfigContextType>(
  {} as FieldsConfigContextType
)

export const FieldsConfig: FC<FieldsConfigProps> = ({ formCode = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [fieldsFilterValue, setFieldsFilterValue] = useState<string>()
  const { data: views } = useFetchUndefinedViewsByFormCodeQuery(formCode, { skip: !formCode })

  const { data: undefinedFields, isLoading: isLoadingAvailableFields } =
    useFetchUndefinedFieldsByFormCodeQuery(
      { formCode, filter: fieldsFilterValue },
      {
        skip: !formCode,
      }
    )
  const { data: fetchedContainers, isLoading: isLoadingContainers } = useFetchContainersQuery(
    formCode,
    {
      skip: !formCode,
      refetchOnMountOrArgChange: true,
    }
  )

  const [updateContainer] = useUpdateContainerMutation()
  const [updateLine] = useUpdateContainerLineMutation()
  const [createElement] = useCreateContainerElementMutation()
  const [updateElement] = useUpdateContainerElementMutation()

  const [unallocatedFields, setUnallocatedFields] = useState<UnallocatedFields>([])
  const [unallocatedViews, setUnallocatedViews] = useState<EntityType[]>([])
  const [containers, setContainers] = useState<FormContainer[]>([])

  const hasHistoryContainer = useMemo(
    () => containers.some(container => container.history),
    [containers]
  )

  useEffect(() => {
    setUnallocatedFields(undefinedFields || [])
  }, [undefinedFields])

  useEffect(() => {
    setUnallocatedViews(views || [])
  }, [views])

  useEffect(() => {
    setContainers(fetchedContainers || [])
  }, [fetchedContainers])

  const availableFormElements = useMemo(
    (): DraggableFormElement[] => [
      {
        id: 1,
        type: AVAILABLE_FORM_ELEMENT_TYPE.TEXT,
        code: AVAILABLE_FORM_ELEMENT_TYPE.TEXT,
        title: AVAILABLE_FORM_ELEMENT_TYPE.TEXT,
      },
      {
        id: 2,
        type: AVAILABLE_FORM_ELEMENT_TYPE.CONTAINER,
        code: AVAILABLE_FORM_ELEMENT_TYPE.CONTAINER,
        title: AVAILABLE_FORM_ELEMENT_TYPE.CONTAINER,
      },
      {
        id: 3,
        type: AVAILABLE_FORM_ELEMENT_TYPE.SPACE,
        code: AVAILABLE_FORM_ELEMENT_TYPE.SPACE,
        title: AVAILABLE_FORM_ELEMENT_TYPE.SPACE,
      },
      ...(hasHistoryContainer
        ? []
        : [
            {
              id: 4,
              type: AVAILABLE_FORM_ELEMENT_TYPE.HISTORY,
              code: AVAILABLE_FORM_ELEMENT_TYPE.HISTORY,
              title: AVAILABLE_FORM_ELEMENT_TYPE.HISTORY,
            },
          ]),
    ],
    [hasHistoryContainer]
  )

  const availableFormFields = useMemo(
    (): DraggableFormElement[] =>
      unallocatedFields.map(field => ({
        id: field.id,
        type: AVAILABLE_FORM_ELEMENT_TYPE.FIELD,
        code: field.code,
        title: field.title,
        labelPosition: FORM_FIELD_LABEL_POSITION.TOP,
        titleHidden: false,
      })),
    [unallocatedFields]
  )

  const availableViews = useMemo(
    (): DraggableFormElement[] =>
      unallocatedViews.map(view => ({
        id: view.id as string | number,
        type: AVAILABLE_FORM_ELEMENT_TYPE.VIEW,
        code: view.code,
        title: view.title,
      })),
    [unallocatedViews]
  )

  const showFullRowMessage = () => {
    dispatch(showMessage({ type: 'info', text: t('notifications.rowIsFull') }))
  }

  const showCannotFitViewMessage = () => {
    dispatch(showMessage({ type: 'info', text: t('notifications.cannotFitView') }))
  }

  const [currentDragged, setCurrentDragged] = useState<DragStart>()
  const handleDragStart = (initial: DragStart) => {
    setCurrentDragged(initial)
  }

  const changeFieldsFilter = (filter: string | undefined) => {
    setFieldsFilterValue(filter)
  }

  const { onBeforeCapture } = useTrimming()

  const addNewContainer = (destination: DraggableLocation, isHistoryContainer = false) => {
    const newContainer: FormContainer = {
      id: -1,
      order: destination.index,
      title: '',
      formCode: formCode,
      tabs: [],
      history: isHistoryContainer,
    }
    const result = add(newContainer, containers, destination)
    const newContainers = result.formContainers
    setContainers(newContainers)
  }

  const reorderContainers = (source: DraggableLocation, destination: DraggableLocation) => {
    const { tabs, ...reorderedContainer } = containers[source.index]
    const newContainers = reorder(containers, source.index, destination.index)
    setContainers(newContainers)
    updateContainer({ ...reorderedContainer, order: destination.index })
  }

  const addNewElement = (source: DraggableLocation, destination: DraggableLocation) => {
    const [containerId, tabId, lineId] = getElementPathByDroppableId(destination.droppableId)
    const newContainers = structuredClone(containers) as FormContainer[]
    const { line } = getLineById(newContainers, containerId, tabId, lineId)
    if (!line) {
      return
    }

    let newElement = {} as Omit<FormElement, 'id'>

    const rowSize = line.elements.reduce((a, b) => a + b.size, 0)
    const elementSize = rowSize === 11 ? 1 : 2

    if (source.droppableId.includes(AVAILABLE_FORM_ELEMENT_TYPE.VIEW)) {
      if (rowSize > 1) {
        showCannotFitViewMessage()

        return
      }

      const draggedView = availableViews[source.index]

      const newViews = [...unallocatedViews].filter(({ id }) => id !== draggedView.id)
      // Принудительно вызываем ререндер списка, иначе dragging item мерцает, возвращаясь в исходный список
      flushSync(() => {
        setUnallocatedViews(newViews)
      })

      newElement = {
        order: destination.index,
        size: 12,
        type: FORM_ELEMENT_TYPE.VIEW,
        data: draggedView.id,
        title: draggedView.title,
      }
    }

    if (rowSize === 12) {
      showFullRowMessage()

      return
    }

    if (source.droppableId.includes(AVAILABLE_FORM_ELEMENT_TYPE.TEXT)) {
      newElement = {
        order: destination.index,
        size: elementSize,
        type: FORM_ELEMENT_TYPE.TEXT,
        title: '',
      }
    }

    if (source.droppableId.includes(AVAILABLE_FORM_ELEMENT_TYPE.SPACE)) {
      newElement = {
        order: destination.index,
        size: elementSize,
        type: FORM_ELEMENT_TYPE.SPACE,
        title: '',
      }
    }

    if (source.droppableId.includes(AVAILABLE_FORM_ELEMENT_TYPE.FIELD)) {
      const draggedField = availableFormFields[source.index]

      const newFields = [...unallocatedFields].filter(({ id }) => id !== draggedField.id)
      // Принудительно вызываем ререндер списка, иначе dragging item мерцает, возвращаясь в исходный список
      flushSync(() => {
        setUnallocatedFields(newFields)
      })

      newElement = {
        order: destination.index,
        size: elementSize,
        type: FORM_ELEMENT_TYPE.FIELD,
        data: draggedField.id,
        title: draggedField.title,
        labelPosition: draggedField.labelPosition,
        titleHidden: draggedField.titleHidden,
      }
    }

    line.elements = add(
      {
        id: -1,
        ...newElement,
      },
      line.elements,
      destination
    )[destination.droppableId]

    setContainers(newContainers)

    // Сохранение текстового элемента произойдёт внутри DraggableFieldOnForm
    if (!source.droppableId.includes(AVAILABLE_FORM_ELEMENT_TYPE.TEXT)) {
      createElement({
        ...newElement,
        lineId: line.id,
      })
    }
  }

  const reorderElements = (source: DraggableLocation, destination: DraggableLocation) => {
    const [containerId, tabId, lineId] = getElementPathByDroppableId(source.droppableId)
    const newContainers = structuredClone(containers) as FormContainer[]
    const { line } = getLineById(newContainers, containerId, tabId, lineId)

    if (!line) {
      return
    }

    const newElements = reorder(line.elements, source.index, destination.index).map(
      (element, index) => ({
        ...element,
        order: index,
      })
    )
    line.elements = newElements
    setContainers(newContainers)

    updateElement({
      ...newElements[destination.index],
      lineId: line.id,
    })
  }

  const moveElements = (source: DraggableLocation, destination: DraggableLocation) => {
    const [sContainerId, sTabId, sLineId] = getElementPathByDroppableId(source.droppableId)
    const [dContainerId, dTabId, dLineId] = getElementPathByDroppableId(destination.droppableId)

    const newContainers = structuredClone(containers) as FormContainer[]

    if (sContainerId && dContainerId) {
      const { line: sLine } = getLineById(newContainers, sContainerId, sTabId, sLineId)
      const { line: dLine } = getLineById(newContainers, dContainerId, dTabId, dLineId)

      const countSizeRow = dLine?.elements.reduce((a, b) => a + b.size, 0)
      const sourseIsView =
        sLine?.elements.length === 1 && sLine.elements[0].type === FORM_ELEMENT_TYPE.VIEW

      if (countSizeRow === 12) {
        showFullRowMessage()

        return
      }

      // Если перетаскиваемый элемент больше чем свободное место в строке
      if (sLine && countSizeRow && sLine?.elements[source.index].size > GRID_SIZE - countSizeRow) {
        showFullRowMessage()

        return
      }

      if (countSizeRow && sourseIsView && countSizeRow > 1) {
        showCannotFitViewMessage()

        return
      }

      if (!sLine || !dLine) {
        return
      }

      const moveResult = move(sLine.elements, dLine.elements, source, destination)
      sLine.elements = moveResult[source.droppableId].map((element, index) => ({
        ...element,
        order: index,
      }))
      dLine.elements = moveResult[destination.droppableId].map((element, index) => ({
        ...element,
        order: index,
      }))
      setContainers(newContainers)

      updateElement({ ...dLine.elements[destination.index], lineId: dLine.id })
    }
  }

  const reorderLines = (source: DraggableLocation, destination: DraggableLocation) => {
    const [sContainerId, sTabId] = getLinePathByDroppableId(source.droppableId)
    const [dContainerId, dTabId] = getLinePathByDroppableId(destination.droppableId)

    const newContainers = structuredClone(containers) as FormContainer[]
    const sContainer = newContainers.find(item => item.id === sContainerId)
    const dContainer = newContainers.find(item => item.id === dContainerId)

    if (!sContainer || !dContainer) {
      return
    }

    const sTab = sContainer.tabs.find(item => item.id === sTabId)
    const dTab = dContainer.tabs.find(item => item.id === dTabId)

    if (!sTab || !dTab) {
      return
    }

    if (sTab === dTab) {
      dTab.lines = reorder(dTab.lines, source.index, destination.index).map((line, index) => ({
        ...line,
        order: index,
      }))
    } else {
      const moveResult = move(sTab.lines, dTab.lines, source, destination)
      sTab.lines = moveResult[source.droppableId].map((line, index) => ({ ...line, order: index }))
      dTab.lines = moveResult[destination.droppableId].map((line, index) => ({
        ...line,
        order: index,
      }))
    }
    setContainers(newContainers)
    const { elements, ...body } = dTab.lines[destination.index]
    updateLine({ ...body, tabId: dTab.id })

    // const { tabs, ...reorderedContainer } = containers[source.index]
    // const newContainers = reorder(containers, source.index, destination.index)
    // setContainers(newContainers)
    // updateContainer({ ...reorderedContainer, order: destination.index })
  }

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result

    setCurrentDragged(undefined)

    if (!destination) {
      return
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return
    }

    const sInd = source.droppableId
    const dInd = destination.droppableId

    if (result.type === FORM_DROPPABLE_TYPE.CONTAINERS) {
      if (sInd.includes(AVAILABLE_FORM_ELEMENT_TYPE.CONTAINER)) {
        addNewContainer(destination)
      } else if (sInd.includes(AVAILABLE_FORM_ELEMENT_TYPE.HISTORY)) {
        addNewContainer(destination, true)
      } else {
        reorderContainers(source, destination)
      }
      return
    }

    if (result.type === FORM_DROPPABLE_TYPE.LINES) {
      reorderLines(source, destination)
      return
    }

    if (result.type === FORM_DROPPABLE_TYPE.ELEMENTS) {
      if (sInd.match(/(FIELD|VIEW|TEXT|SPACE):/g)) {
        addNewElement(source, destination)
        return
      }
      if (sInd === dInd) {
        reorderElements(source, destination)
      } else {
        moveElements(source, destination)
      }
      return
    }
  }

  const containerCreationInProgress = useMemo(
    () => Boolean(containers.find(container => container.id === -1)),
    [containers]
  )

  return (
    <Box sx={{ display: 'flex', paddingTop: '16px' }}>
      <FieldsConfigContext.Provider
        value={{
          containers,
          isLoadingContainers,
          setContainers,
          containerCreationInProgress,
          availableFormElements,
          availableFormFields,
          availableViews,
          isLoadingAvailableFields,
          currentDragged,
          changeFieldsFilter,
        }}
      >
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <AvailableFormElements />
          <Divider orientation={'vertical'} flexItem />
          <ConfiguredFormElements />
        </DragDropContext>
      </FieldsConfigContext.Provider>
    </Box>
  )
}
