import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

type ResetValuesOnGroupRelatedCheckboxChangeOptions = {
  watchedGroupRelated: boolean
  fieldTypeInputName: string
  objectValueInputName: string
  linkedObjectCodeInputName: string
  defaultPlaceholderInputName: string
  placeholderValueInputName: string
  placeholderStartInputName: string
  placeholderEndInputName: string
  dropDownListCodeInputName: string
  dropDownWindowInputName: string
  dropDownListFiltersInputName: string
  dropDownWindowFiltersInputName: string
}

// При выборе чекбокса 'Group related' все поля, кроме value, должны сброситься, в этом суть
// данного эффекта
export const useResetValuesOnGroupRelatedCheckboxChange = ({
  watchedGroupRelated,
  fieldTypeInputName,
  objectValueInputName,
  linkedObjectCodeInputName,
  defaultPlaceholderInputName,
  placeholderValueInputName,
  placeholderStartInputName,
  placeholderEndInputName,
  dropDownListCodeInputName,
  dropDownWindowInputName,
  dropDownListFiltersInputName,
  dropDownWindowFiltersInputName,
}: ResetValuesOnGroupRelatedCheckboxChangeOptions) => {
  const {
    setValue,
    formState: { isDirty },
  } = useFormContext()

  useEffect(() => {
    if (isDirty && watchedGroupRelated) {
      setValue(fieldTypeInputName, null)
      setValue(objectValueInputName, null)
      setValue(defaultPlaceholderInputName, true)
      setValue(placeholderValueInputName, '')
      setValue(placeholderStartInputName, '')
      setValue(placeholderEndInputName, '')
      setValue(dropDownListCodeInputName, null)
      setValue(dropDownWindowInputName, null)
      setValue(dropDownListFiltersInputName, [])
      setValue(dropDownWindowFiltersInputName, [])
    }
  }, [watchedGroupRelated])
}
