import { Position } from 'react-rnd'

export const DRAWER_WIDTH = 240
export const VIEW_CREATE_STEPS_AMOUNT = 2
export const SNACK_LIFETIME = 5000
export const INPUT_STANDARD_LENGTH = 255
export const STYLED_PINNED_COLUMN_CLASSNAME = 'custom-column-pinned-column-styles'
export const STYLED_COLUMN_HEADER_CLASSNAME = 'custom-column-header-styles'
export const STYLED_COLUMN_TEXT_CLASSNAME = 'custom-column-text-styles'
export const DEFAULT_HEADER_STYLES_CLASSNAME = 'custom-default-column-header-styles'
export const DATAGRID_HEADER_HEIGHT = 32
export const DATAGRID_HEADER_WINDOW_HEIGHT = 24
export const DATAGRID_ROW_WINDOW_HEIGHT = 24
export const SELECTED_ROW_CLASSNAME = 'row-selected-by-user'
export const PREV_SELECTED_ROW_CLASSNAME = 'row-previous-selected-by-user'
export const UPDATES_ROW_CLASSNAME = 'update-row'
export const DELETED_ROW_CLASSNAME = 'delete-row'
export const FOUND_SEARCH_VALUE_CLASSNAME = 'found-search-value-row'
export const FAVORITES_FILTERS_BUTTON_CLASSNAME = 'favorites_filters_button'
export const MIN_HEIGHT_FIELD = 58
export const MIN_HEIGHT_FIELD_DIALOG = 38
export const MIN_HEIGHT_FIELD_WITH_LABEL = 94
export const MIN_HEIGHT_FIELD_DIALOG_WITH_LABEL = 75
export const MIN_AUTOCOMPLETE_POPPER_WIDTH = 100
export const MIN_FORM_CONTAINER_WIDTH = 900
export const MIN_VIEW_COLUMN_WIDTH = 100
export const WIDTH_ARROW_BUTTONS = 48
export const WIDTH_GRID_SPACING = 8
export const WIDTH_DEFAULT_CRITERIAS_MODAL = 620
export const BOTTOM_PADDING_CONTAINER = 20
export const SEARCH_ASSISTANT_VIEW = 30
export const QUICK_SEARCH_DDW = 40
export const DIALOG_WINDOW_HEADER_HEIGHT = 38
export const DIALOG_WINDOW_BOTTOM_ACTION_BAR = 10
export const BLOCKING_DDW_ZINDEX_INCREASE = 100
export const RIGHT_SHADOW_LIST_ITEM = 4
export const TOP_SHADOW_LIST_ITEM = 1
export const WIDTH_FORM_NAVIGATION_BUTTONS = 136
export const WIDTH_FORM_CONTEXT_MENU_BUTTON = 60
export const SPACE_BETWEEN_BREADCRUMBS = 20
// export const CORRECT_WIDTH_STRING_EDIT_INPUT = 64
export const CORRECT_HEIGHT_STRING_EDIT_INPUT = 143

export const DYNAMIC_WINDOWS_LIMIT = 5

export const GRID_SIZE = 12

/**
 * для поднятия нижней стрелки скролла
 */
export const SCROLL_OFFSET = 42
export const SCROLL_WIDTH = 13
export const SCROLL_HEIGHT = 13

export const DATAGRID_SCROLL_WIDTH = 10

export enum DRAGGABLE_FORM_SIZES {
  OPENED_HEIGHT = 600,
  OPENED_WIDTH = 800,
  CLOSED_HEIGHT = 40,
  CLOSED_WIDTH = 300,
  MIN_WIDTH = 700,
}

export enum DROP_DOWN_WINDOW_SIZES {
  OPENED_HEIGHT = 615,
  OPENED_MIN_HEIGHT = 340,
  OPENED_WIDTH = 900,
  CLOSED_HEIGHT = 40,
  CLOSED_WIDTH = 300,
}

export const DROP_DOWN_WINDOW_OFFSET_STATIC_FORMS: Position = {
  x: 360,
  y: -1650,
}

export const DROP_DOWN_WINDOW_OFFSET_NORMAL_FORMS: Position = {
  x: 360,
  y: -700,
}

export enum PASSED_FORM_DATA_VARIANT {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

export enum CLICK_TYPE {
  SINGLE = 'click',
  DOUBLE = 'dblclick',
}

export const GOLDEN_COLOR_FOR_PINNED_COLUMN = '#ffd700'

export const DATAGRID_HEADERS_SHADOW = `0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)`
export const DATAGRID_HEADERS_MARGIN = 2

export const FORM_CONTAINER_TAB_ID = 'form-container-tab-id'

export const DROPDOWN_LIST_CLASSNAME = 'dropdown-list'

export enum SCRIPT_DIALOG_WINDOW_OFFSET {
  WIDTH = 170,
  HEIGHT = 200,
}
