import { useEffect, useState } from 'react'
import moment from 'moment'
import { Box, Skeleton } from '@mui/material'

const getTime = () => moment().format('DD.MM.YYYY HH:mm')

export const AppFooter = () => {
  const [now, setNow] = useState<string>()
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    !isLoading && setInterval(() => setNow(getTime()), 60_000)
  }, [isLoading])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setNow(getTime())
    }, 500) // для установки кастомной таймзоны в роуте
  }, [])

  return (
    <Box
      alignItems={'center'}
      bgcolor={theme => theme.palette.background.footer}
      display={'flex'}
      height={30}
      justifyContent={'end'}
      pr={2}
    >
      {isLoading ? (
        <Skeleton height={20} variant='rounded' width={150} />
      ) : (
        <span title={moment().format('Z')}>{now}</span>
      )}
    </Box>
  )
}
