import { useContext, useEffect, useRef } from 'react'
import { messageCallbackType, StompHeaders } from '@stomp/stompjs'

import { StompContext } from '@containers/StompSessionProvider/StompSessionProvider'

// TODO На текущий момент ws локально не работают
const isDev = false
// const isDev = process.env.NODE_ENV === 'development'

export const useSubscription = isDev
  ? () => {
      useEffect(() => {
        console.log('Subscription not working in development mode')
      }, [])
    }
  : (
      destinations: string | string[],
      onMessage: messageCallbackType,
      headers: StompHeaders = {}
    ) => {
      const stompContext = useContext(StompContext)
      if (stompContext === undefined) {
        throw new Error(
          'There must be a StompSessionProvider as Ancestor of all Stomp Hooks and HOCs'
        )
      }
      const callbackRef = useRef<messageCallbackType>(onMessage)
      const _destinations = Array.isArray(destinations) ? destinations : [destinations]

      callbackRef.current = onMessage
      useEffect(() => {
        const cleanUpFunctions: (() => void)[] = []

        _destinations.forEach(_destination =>
          cleanUpFunctions.push(
            stompContext.subscribe(
              _destination,
              message => {
                callbackRef.current(message)
              },
              headers
            )
          )
        )

        return () => {
          cleanUpFunctions.forEach(_cleanUpFunction => {
            _cleanUpFunction()
          })
        }
      }, [Object.values(_destinations).toString(), Object.values(headers).toString()])
    }
