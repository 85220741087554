export * from '@gantt/components/GanttCreateOrEdit/types'

export enum COMMAND {
  SORT = 'sort',
  UNSORT = 'unsort',
}

export type AxisDefinedData = {
  objectCode: string
  objectId: string
}
