import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Typography } from '@mui/material'

import { useObjectOptions } from '@components/DataSource/useObjectOptions'
import { useSelectedObjectCode } from '@components/DataSource/useSelectedObjectCode'
import { CommandPickerController } from '@components/hookFormControllers/CommandPickerController'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { ObjectShortDTO } from '@types'

type DataSourceProps = {
  objects?: ObjectShortDTO[]
  isDisabledObject: boolean
  isLoading: boolean
}

export const DataSource: FC<DataSourceProps> = ({ objects = [], isDisabledObject, isLoading }) => {
  const { t } = useTranslation()

  const objectOptions = useObjectOptions({ objects })
  const selectedObjectCode = useSelectedObjectCode()

  return (
    <>
      <Box>
        <Typography my={0.5} variant='h5'>
          {t('viewsCreate.datasource')}
        </Typography>
      </Box>
      <FormInput
        autocompleteOptions={objectOptions}
        disabled={isDisabledObject}
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={i18next.t('label.objectTitle')}
        loading={isLoading}
        name={'object'}
        placeholder={i18next.t('placeholder.selectObject')}
        rules={{
          required: true,
        }}
      />
      <CommandPickerController objectCode={selectedObjectCode} />
    </>
  )
}
