import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash.isequal'
import { ItemId, mutateTree, RenderItemParams, TreeData } from '@atlaskit/tree'
import { ELEMENT_TYPE_PANEL, OPERATION_TYPE } from '@constants'
import { transformTreeToMenu } from '@helpers'
import { useDraggableTree } from '@hooks'
import { Button } from '@microservices/wiskey-react-components'
import { Remove } from '@mui/icons-material'
import { Divider, Grid } from '@mui/material'
import {
  NoIdControlPanelMenuOptionType,
  TreeItemControlPanelOptionType,
  UpdatedElementType,
} from '@types'

import { AddItem } from '../AddItem'
import { Item } from '../Item'
import { MenuList } from '../MenuList'

type MenuTabPanelContextType = {
  isDirty: boolean
  tree: TreeData | null
  handleAddTreeItem: (
    option: NoIdControlPanelMenuOptionType,
    treeItem?: TreeItemControlPanelOptionType
  ) => void
  handleEditTreeItem: (treeItem: TreeItemControlPanelOptionType) => void
  deleteTreeItem: (itemId: ItemId) => void
  resetTree: (tree?: TreeData) => void
  setTree: Dispatch<SetStateAction<TreeData | null>>
}

export const MenuTabPanelContext = createContext<MenuTabPanelContextType>(
  {} as MenuTabPanelContextType
)

type MenuTabPanelType = {
  index: number
  lineUuid: string
  lineId?: number
  menu: TreeData
  updatedElements: UpdatedElementType[]
  onRemoveLine: (index: number) => void
  onUpdateMenu: (index: number, menu: TreeData) => void
  onSetUpdatedElement: (element: UpdatedElementType) => void
  onSetUpdatedElements: (element: UpdatedElementType[]) => void
}

export const MenuTabPanel: FC<MenuTabPanelType> = ({
  index,
  lineId,
  lineUuid,
  menu,
  updatedElements,
  onRemoveLine,
  onUpdateMenu,
  onSetUpdatedElement,
  onSetUpdatedElements,
}) => {
  const { t } = useTranslation()
  const { tree, setTree, deleteTreeItem, addTreeItem } = useDraggableTree(menu)

  const [firstTree] = useState<TreeData | null>(menu)

  const transformFirstTree = useMemo(() => firstTree && transformTreeToMenu(firstTree), [firstTree])
  const transformTree = useMemo(() => tree && transformTreeToMenu(tree), [tree])
  const isDirty = !isEqual(transformTree, transformFirstTree)

  useEffect(() => {
    if (tree) {
      onUpdateMenu(index, tree)
    }
  }, [tree])

  const resetTree = (tree?: TreeData) => setTree(tree || firstTree)

  const handleClick = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }
    onRemoveLine(index)
  }

  const handleEditTreeItem = (treeItem: TreeItemControlPanelOptionType) => {
    if (tree) {
      const newTree: TreeData = { ...tree }
      newTree.items[treeItem.id] = { ...treeItem }
      setTree(newTree)

      if (lineId || lineUuid) {
        const { childrenMenu, treeItemParentId, ...itemData } = treeItem.data

        onSetUpdatedElement({
          lineIndex: index,
          treeItemParentId,
          treeItemId: treeItem.id,
          data: { ...itemData, ...(lineId ? { lineId } : { lineUuid }) },
          type: ELEMENT_TYPE_PANEL.ITEM,
          operationType: itemData.id ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
        })
      }
    }
  }

  const handleHideIconTitle = (itemId: ItemId, value: boolean) => {
    if (tree) {
      const newTree = mutateTree(tree, itemId, {
        data: { ...tree?.items[itemId].data, showTitle: value },
      })
      setTree(mutateTree(tree, itemId, { data: { ...tree?.items[itemId].data, showTitle: value } }))

      if (lineId || lineUuid) {
        const { childrenMenu, treeItemParentId, ...itemData } = (
          newTree.items[itemId] as TreeItemControlPanelOptionType
        ).data

        onSetUpdatedElement({
          lineIndex: index,
          treeItemId: itemId,
          treeItemParentId,
          data: { ...itemData, ...(lineId ? { lineId } : { lineUuid }) },
          type: ELEMENT_TYPE_PANEL.ITEM,
          operationType: itemData.id ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
        })
      }
    }
  }

  const handleChangeIconName = (itemId: ItemId, iconName: string | null) => {
    if (tree) {
      const newTree = mutateTree(tree, itemId, { data: { ...tree?.items[itemId].data, iconName } })

      setTree(newTree)

      if (lineId || lineUuid) {
        const { childrenMenu, treeItemParentId, ...itemData } = (
          newTree.items[itemId] as TreeItemControlPanelOptionType
        ).data

        onSetUpdatedElement({
          lineIndex: index,
          treeItemId: itemId,
          treeItemParentId,
          data: { ...itemData, ...(lineId ? { lineId } : { lineUuid }) },
          type: ELEMENT_TYPE_PANEL.ITEM,
          operationType: itemData.id ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
        })
      }
    }
  }

  const handleDeleteTreeItem = (treeItemId: ItemId, itemId?: number) => {
    deleteTreeItem(treeItemId)
    if (lineId || lineUuid) {
      if (itemId) {
        onSetUpdatedElement({
          lineIndex: index,
          treeItemId,
          data: { id: itemId },
          type: ELEMENT_TYPE_PANEL.ITEM,
          operationType: OPERATION_TYPE.DELETE,
        })
      } else {
        onSetUpdatedElements(updatedElements.filter(el => el.treeItemId !== treeItemId))
      }
    }
  }

  const handleAddTreeItem = (
    option: NoIdControlPanelMenuOptionType,
    treeItem?: TreeItemControlPanelOptionType
  ) => {
    const addedItem = addTreeItem(option, treeItem) as TreeItemControlPanelOptionType

    if ((lineId || lineUuid) && addedItem) {
      const { childrenMenu, treeItemParentId, ...itemData } = addedItem.data

      onSetUpdatedElement({
        treeItemParentId: treeItemParentId,
        lineIndex: index,
        treeItemId: addedItem.id,
        data: { ...itemData, ...(lineId ? { lineId } : { lineUuid }) },
        type: ELEMENT_TYPE_PANEL.ITEM,
        operationType: OPERATION_TYPE.CREATE,
      })
    }
  }

  const handleDragEnd = (treeItem: TreeItemControlPanelOptionType, _order: number) => {
    if (lineId || lineUuid) {
      const { childrenMenu, treeItemParentId, ...itemData } = treeItem.data

      onSetUpdatedElement({
        treeItemParentId,
        lineIndex: index,
        treeItemId: treeItem.id,
        data: { ...itemData, ...(lineId ? { lineId } : { lineUuid }) },
        type: ELEMENT_TYPE_PANEL.ITEM,
        operationType: treeItem.data.id ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
      })
    }
  }

  const renderItem = ({ item, ...props }: RenderItemParams): ReactNode => {
    return (
      <Item
        {...props}
        item={item as TreeItemControlPanelOptionType}
        onChangeIconName={handleChangeIconName}
        onDeleteTreeItem={handleDeleteTreeItem}
        onHideIconTitle={handleHideIconTitle}
      />
    )
  }

  return (
    <MenuTabPanelContext.Provider
      value={{
        isDirty,
        tree,
        setTree,
        resetTree,
        deleteTreeItem,
        handleAddTreeItem,
        handleEditTreeItem,
      }}
    >
      <MenuList renderItem={renderItem} setTree={setTree} tree={tree} onDragEnd={handleDragEnd} />
      <Grid container justifyContent={'flex-end'} width={'100%'}>
        <Button onClick={handleClick}>
          <Remove fontSize='small' />
          {t('controlPanelCreate.form.removeLineButton')}
        </Button>
      </Grid>
      <Divider sx={{ mb: 1 }} />
      <AddItem />
    </MenuTabPanelContext.Provider>
  )
}
