export const renderObjectEmbeddedCell = (
  code: string,
  // objectValue: string,
  srcObj: Record<string, any>
) => {
  // const linkedObject = srcObj[`${code}_enriched`] || {}
  // const valueByObjectValue = linkedObject[objectValue]

  // return valueByObjectValue || ''

  return srcObj[code] || ''
}
