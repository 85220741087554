import Keycloak from 'keycloak-js'

declare global {
  interface Window {
    env: Record<string, string>
  }
}

const isDev = process.env.NODE_ENV === 'development'
// console.log('window', window)
const config = isDev
  ? {
      realm: 'wiskey',
      url: process.env.AUTH_SERVER_URL,
      clientId: 'wiskey-user-front',
    }
  : {
      realm: window.env.REACT_APP_KEYCLOAK_REALM,
      url: window.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
      clientId: window.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    }

const _kc = new Keycloak(config)

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const init = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: 'login-required',
      // redirectUri: 'localhost:3000',
      // onLoad: 'check-sso',
      // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      // pkceMethod: 'S256',
    })
    .then((authenticated: boolean) => {
      if (!authenticated) {
        console.log('user is not authenticated..!')
      }
      onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = (): string => _kc.tokenParsed?.preferred_username

const hasRole = (roles: string[]) => roles.some(role => _kc.hasRealmRole(role))

_kc.onTokenExpired = () => {
  console.log('token expired')
  updateToken(() => {
    console.log('successfully get a new token')
  })
}

const isTokenExpired = _kc.isTokenExpired

const AuthService = {
  init,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  isTokenExpired,
  updateToken,
  getUsername,
  hasRole,
}

export default AuthService
