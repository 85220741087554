export enum EVENT_NAME {
  CONFIGURED_FORM_ELEMENTS_MOUSE_ENTER = 'CONFIGURED_FORM_ELEMENTS_MOUSE_ENTER',
  CONFIGURED_FORM_ELEMENTS_MOUSE_LEAVE = 'CONFIGURED_FORM_ELEMENTS_MOUSE_LEAVE',
  CONFIGURED_FORM_TOGGLE_OPENED_CONTAINERS = 'CONFIGURED_FORM_TOGGLE_OPENED_CONTAINERS',
  CONFIGURED_FORM_HAS_OPENED_CONTAINER = 'CONFIGURED_FORM_HAS_OPENED_CONTAINER',
  DIALOG_WINDOW_CHANGE_POSITION = 'DIALOG_WINDOW_CHANGE_POSITION',
  DIALOG_WINDOW_CHANGE_SIZE = 'DIALOG_WINDOW_CHANGE_SIZE',
  DIALOG_WINDOW_CHANGE_FULLSCREEN_MODE = 'DIALOG_WINDOW_CHANGE_FULLSCREEN_MODE',
  CHECKBOX_IN_COLUMN_FOR_SEARCHING = 'CHECKBOX_IN_COLUMN_FOR_SEARCHING',
  GLOBAL_CHECKBOX_IN_COLUMN = 'GLOBAL_CHECKBOX_IN_COLUMN',
  ALL_CHECKBOXES_IN_COLUMN = 'ALL_CHECKBOXES_IN_COLUMN',
}

export type EventListener = (data?: any) => void

type EventData = unknown

export const subscribe = (eventName: EVENT_NAME, listener: EventListener) => {
  document.addEventListener(eventName, listener)
}

export const unsubscribe = (eventName: EVENT_NAME, listener: EventListener) => {
  document.removeEventListener(eventName, listener)
}

export const publish = (eventName: EVENT_NAME, data?: EventData) => {
  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}
