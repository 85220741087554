import { Box, Paper, SxProps, Theme } from '@mui/material'
import { Table } from '@tanstack/react-table'

import { Pagination } from '../../Pagination'

type TableFooterProps<T> = {
  table: Table<T>
  width: number
}

const sxPaper: SxProps<Theme> = {
  borderColor: theme => theme.palette.border.table,
  borderWidth: 1,
  borderStyle: 'solid',
  boxShadow: 0,
  borderTop: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  '.MuiTableContainer-root': { boxShadow: 0 },
}

export const TableFooter = <T,>({ table, width }: TableFooterProps<T>) => {
  const meta = table.options.meta
  const disablePagination = meta?.disablePagination
  if (disablePagination) {
    return <></>
  }
  return (
    <Box
      component={Paper}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: 56,
        background: theme => theme.palette.background.paper,
        width: width,
        ...sxPaper,
      }}
    >
      <Pagination
        page={table.getState().pagination.pageIndex + 1}
        onChange={(event, page) => table.setPageIndex(page - 1)}
        count={table.getPageCount()}
      />
    </Box>
  )
}
