import { useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  ItemId,
  moveItemOnTree,
  TreeData,
  TreeDestinationPosition,
  TreeItem,
  TreeSourcePosition,
} from '@atlaskit/tree'

export const useDraggableTree = (initialTree?: TreeData | null) => {
  const [tree, setTree] = useState<TreeData | null>(initialTree || null)

  const deleteTreeItem = (itemId: ItemId) => {
    if (tree) {
      const newTree = cloneDeep(tree)
      let parentId: ItemId = ''

      delete newTree.items[itemId]
      for (const value of Object.values(tree.items)) {
        if (value.children.includes(itemId)) {
          parentId = value.id
        }
      }
      if (parentId) {
        const parent = newTree.items[parentId]
        const root = newTree.items[newTree.rootId]
        const childrenParent = parent.children.filter(id => id !== itemId)
        const childrenRoot = root.children.filter(id => id !== itemId)
        newTree.items[parentId] = {
          ...parent,
          children: childrenParent,
          hasChildren: childrenParent.length > 0,
        }
        newTree.items[newTree.rootId] = {
          ...root,
          children: childrenRoot,
          hasChildren: childrenRoot.length > 0,
        }
      }

      setTree(newTree)
    }
  }

  const addTreeItem = <T extends { id?: number; code?: string; uuid?: string; title: string }>(
    option: T,
    treeItem?: TreeItem
  ) => {
    if (tree && option) {
      const itemId = `0-${option.code || option.id || option.uuid}`
      const newTree: TreeData = { ...tree }
      newTree.items[tree.rootId].children.push(itemId)
      newTree.items[itemId] = {
        id: itemId,
        children: [],
        data: {
          ...option,
          title: option.title,
          treeItemParentId: treeItem ? treeItem.id : tree.rootId,
        },
        hasChildren: false,
        isChildrenLoading: false,
        isExpanded: false,
      }
      const source: TreeSourcePosition = {
        index: newTree.items[tree.rootId].children.length - 1,
        parentId: tree.rootId,
      }
      const destination: TreeDestinationPosition = {
        parentId: treeItem ? treeItem.id : tree.rootId,
      }

      setTree(moveItemOnTree(newTree, source, destination))

      return newTree.items[itemId]
    }

    return null
  }

  return {
    tree,
    setTree,
    deleteTreeItem,
    addTreeItem,
  }
}
