import { useTranslation } from 'react-i18next'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Typography } from '@mui/material'

import { TableWithActions } from '@pages/Contexts/components/TableWithActions'

import { VIEW_COLUMNS } from '../constants/columns'
import { useViews } from '../hooks'

export const Views = () => {
  const { t } = useTranslation()

  const {
    data: { isLoadingContextViewsById, isLoadingViews },
    state: { selectedViews, unselectedViews, refSearchView },
    handlers: { handleAddView, handleDeleteView },
  } = useViews()

  return (
    <Box>
      <FormInput
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={t('contexts.title.view')}
        loading={isLoadingContextViewsById || isLoadingViews}
        name='searchView'
        placeholder={t('contexts.placeholder.selectView')}
        autocompleteOptions={
          unselectedViews
            ? unselectedViews.map(view => ({
                id: view.id,
                label: view.title,
              }))
            : []
        }
      />
      <Box mt={2}>
        <Typography fontWeight='bold' variant='subtitle1'>
          {t(`contexts.addedViews`)}
        </Typography>
        <TableWithActions
          columns={VIEW_COLUMNS}
          headerNameActions={`${t('contexts.tableColumns.actions.delete')}`}
          loading={isLoadingContextViewsById}
          rows={selectedViews || []}
          skipDeleteModal={true}
          tableHeight={300}
          onDelete={handleDeleteView}
        />
      </Box>
      <Box mt={2}>
        <Typography fontWeight='bold' variant='subtitle1'>
          {t(`contexts.notAddedViews`)}
        </Typography>
        <TableWithActions
          columns={VIEW_COLUMNS}
          headerNameActions={`${t('contexts.tableColumns.actions.add')}`}
          loading={isLoadingContextViewsById || isLoadingViews}
          rows={unselectedViews || []}
          tableHeight={300}
          onAdd={handleAddView}
        />
      </Box>
    </Box>
  )
}
