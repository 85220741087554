import isUndefined from 'lodash/isUndefined'

/**
 * проверка является ли значение пустотой
 * @param value
 * @returns boolean
 */
export function isNotEmpty(value: unknown) {
  if (isUndefined(value) || value === null || value === '') {
    return false
  }

  return true
}
