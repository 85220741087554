import { FC, ReactNode } from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Grid, List, ListItem, ListItemText } from '@mui/material'
import { grey } from '@mui/material/colors'

type AssistantTypeProps = {
  title: string
  onOpen?: () => void
  isActive: boolean
  isCollapse?: boolean
  icon?: ReactNode
  disabled?: boolean
}

export const AssistantType: FC<AssistantTypeProps> = ({
  title,
  onOpen,
  isActive,
  icon,
  isCollapse,
  disabled,
}) => {
  return (
    <List sx={{ p: 0 }}>
      <ListItem
        disabled={disabled}
        sx={{ color: grey[600], cursor: 'pointer', p: 0 }}
        onClick={onOpen}
      >
        <Grid container alignItems={'flex-end'}>
          <Grid item mb={-0.2} mr={1}>
            {icon}
          </Grid>
          <Grid item>
            <ListItemText primary={title} sx={{ '.MuiTypography-root': { fontSize: 15 } }} />
          </Grid>
          {onOpen && isCollapse && (
            <Grid item mb={-0.5}>
              <KeyboardArrowDown
                sx={{
                  transition: 'transform 0.2s ease-in',
                  transform: isActive ? 'rotate(-180deg)' : 'rotate(0deg)',
                }}
              />
            </Grid>
          )}
        </Grid>
      </ListItem>
    </List>
  )
}
