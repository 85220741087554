import { CriteriaType } from '@constants'
import { checkDirtyStoredValue } from '@helpers'

import { POSTObjectDataFilter } from '../../../../../types'

import { getRelativeDateWithEmptyDate } from './getRelativeDateWithEmptyDate'

/**
 * Преобразование и фильтрация текущего массива с критериями в массив с заполенными значениями для тела запроса
 * @param filters - массив с текущими критериями в ассистенте
 * @returns отфильтрованный массив значений для тела запроса поиска
 */
export const transformFiltersToValuesPOST = (filters: CriteriaType[]): POSTObjectDataFilter => {
  return filters
    .map(filter =>
      filter.storedValues.map(storedValue =>
        checkDirtyStoredValue(storedValue.value, storedValue.searchRule)
          ? {
              value: {
                JSONValue: getRelativeDateWithEmptyDate(storedValue),
              },
              valueName: storedValue.name,
              valueType: storedValue.valueType,
              fieldType: storedValue.fieldType,
              objectValue: storedValue.objectValue,
              searchRule: storedValue.searchRule,
              linkedObjectCode: storedValue.linkedObjectCode,
              isGroupRelated: Boolean(storedValue.isGroupRelated),
            }
          : undefined
      )
    )
    .flat()
    .filter(Boolean)
}
