import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
// import {
//   GridColumnMenuContainer,
//   GridColumnMenuProps,
//   GridColumnPinningMenuItems,
//   GridColumnsMenuItem,
//   HideGridColMenuItem,
// } from '@microservices/wiskey-react-components/DataGrid'
import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridColumnPinningMenuItems,
  GridColumnsMenuItem,
  HideGridColMenuItem,
} from '@microservices/wiskey-react-components'
import { MenuItem } from '@mui/material'

import { PageContext } from '@pages/ConfiguredEntity'

import { RESTRICTION_TYPE } from '../../../../types'

type CustomColumnMenuComponentProps = {
  onShowConfig: (value: boolean) => void
} & GridColumnMenuProps

export const CustomColumnMenuComponent: FC<CustomColumnMenuComponentProps> = props => {
  const { currentColumn, hideMenu, onShowConfig } = props
  const { t } = useTranslation()
  const { onDeleteViewRestrictionsByType, type } = useContext(PageContext)

  return (
    <GridColumnMenuContainer {...props}>
      <HideGridColMenuItem
        column={currentColumn}
        onClick={e => {
          hideMenu(e)
        }}
      />
      <GridColumnsMenuItem
        column={currentColumn}
        onClick={e => {
          hideMenu(e)
          onShowConfig(true)
        }}
      />
      <GridColumnPinningMenuItems
        column={currentColumn}
        onClick={e => {
          hideMenu(e)
        }}
      />
      <MenuItem
        onClick={e => {
          onDeleteViewRestrictionsByType(RESTRICTION_TYPE.ORDER)
          hideMenu(e)
        }}
      >
        {t('configEntity.view.resetOrder')}
      </MenuItem>
    </GridColumnMenuContainer>
  )
}
