import { CriteriaStoredValue } from '@constants'

import { POSTStoredValueType } from '../../../../../types'

/**
 * преобразование массива с заполненными значениями для сохранения поиска
 * @param storedValues массив с заполненными значениями у критерий
 * @returns {POSTStoredValueType[]} - преобразованный массив для отправки
 */
export const transformStoredValuesForSaving = (
  storedValues: CriteriaStoredValue[]
): POSTStoredValueType[] =>
  storedValues.map(({ id, value, searchRule }) => ({
    valueId: id,
    value: JSON.stringify(value),
    searchRule,
  }))
