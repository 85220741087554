import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@microservices/wiskey-react-components'
import { Close as CloseIcon } from '@mui/icons-material'
import { Box, IconButton, SxProps, Theme, useTheme } from '@mui/material'

import { FIELD_VALUE_FORMAT } from '@constants'

import {
  checkIfOuterValueIsInvalid,
  mapCheckedToOuterValue,
  mapOuterValueToChecked,
} from './helpers'

export type MappedCheckboxProps = {
  valueFormat: FIELD_VALUE_FORMAT
  catchOuterValueChanges?: boolean
  checkboxWrapperSx?: SxProps<Theme>
  checkboxSx?: SxProps<Theme>
  disabled?: boolean
  viewOnly?: boolean
  resetByButton?: boolean
  onReset?: () => void
  outerValue: number | boolean
  setOuterValue?: (value: number | boolean) => void
  ignoreErrorStyles?: boolean
}

export const MappedCheckbox: FC<MappedCheckboxProps> = ({
  valueFormat,
  checkboxWrapperSx = {},
  checkboxSx: checkboxSxFromProps = {},
  disabled = false,
  resetByButton = false,
  catchOuterValueChanges = false,
  viewOnly = false,
  onReset,
  outerValue,
  setOuterValue,
  // Этот пропс нужен для отображения состояния ошибок, когда мы во вью или dde. В ассистенте эта логика со стилями не нужна
  // и конфликтует с его функционалом
  ignoreErrorStyles = false,
}) => {
  const [checked, setChecked] = useState(mapOuterValueToChecked(outerValue, valueFormat))
  const [isError, setError] = useState(false)

  const [isClickedAtLeastOnce, setClickedAtLeastOnce] = useState(false)
  const [isReset, setReset] = useState(() => {
    if (resetByButton && !outerValue) {
      return true
    }

    return false
  })

  const { t } = useTranslation()
  const theme = useTheme()

  const title = useMemo(() => {
    return isError
      ? t('error.mappedCheckboxInvalidValue', { outerValue: outerValue === '' ? "''" : outerValue })
      : ''
  }, [isError, outerValue])

  const handleOuterValueChange = (outerValue: number | boolean) => {
    setChecked(mapOuterValueToChecked(outerValue, valueFormat))
    setReset(true)
    setClickedAtLeastOnce(false)
  }

  useEffect(() => {
    // Проверка на пустые значения: null, undefined, ''
    if (checkIfOuterValueIsInvalid(outerValue, valueFormat)) {
      setError(true)

      return
    }

    // Проверка на то, если outerValue = boolean, но по какой-то причине пришел не boolean
    if (
      valueFormat === FIELD_VALUE_FORMAT.BOOLEAN &&
      checkIfOuterValueIsInvalid(outerValue, valueFormat)
    ) {
      setError(true)

      return
    }

    // Проверка на то, если outerValue = number, но по какой-то причине пришел не number
    if (checkIfOuterValueIsInvalid(Number(outerValue), valueFormat)) {
      setError(true)

      return
    }

    setError(false)
  }, [outerValue])

  useEffect(() => {
    if (catchOuterValueChanges) {
      if (valueFormat === FIELD_VALUE_FORMAT.BOOLEAN) {
        handleOuterValueChange(outerValue)

        return
      }

      handleOuterValueChange(Number(outerValue))

      return
    }

    if (!resetByButton) {
      return
    }

    // Приходится смотреть на outerValue как на пустую строку, потому что именно в таком виде значение критерия хранится в ассистенте
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (outerValue === '') {
      handleOuterValueChange(outerValue)
    }
  }, [outerValue])

  useEffect(() => {
    if (!isClickedAtLeastOnce) {
      return
    }

    setOuterValue?.(mapCheckedToOuterValue(checked, valueFormat))
  }, [checked])

  const handleCheckboxChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (viewOnly) {
      return
    }

    setClickedAtLeastOnce(true)
    setChecked(checked)
    setReset(false)
  }

  const handleResetByButton = () => {
    setClickedAtLeastOnce(false)
    setChecked(false)
    setReset(true)

    onReset?.()
  }

  const errorStyles: SxProps<Theme> = isError
    ? {
        color: `${theme.palette.background.fieldError} !important`,
      }
    : {}

  const resetStyles: SxProps<Theme> =
    resetByButton && isReset
      ? {
          color: `${theme.palette.grey['400']} !important`,
        }
      : {}

  const checkboxSxBaseStyles: SxProps<Theme> = {
    width: '14px',
    height: '14px',
    pointerEvents: viewOnly ? 'none' : 'auto',
    padding: 0,
    position: 'relative',
    top: -3,
    left: -3,
    color: disabled ? theme.palette.grey['400'] : theme.palette.grey['800'],
    '&.Mui-checked': {
      color: disabled ? theme.palette.grey['400'] : theme.palette.grey['800'],
    },
  }

  const checkboxSx: SxProps<Theme> = ignoreErrorStyles
    ? {
        ...errorStyles,
        ...resetStyles,
        ...checkboxSxBaseStyles,
        ...checkboxSxFromProps,
      }
    : {
        // Разница в порядке стилей
        ...checkboxSxBaseStyles,
        ...errorStyles,
        ...resetStyles,
        ...checkboxSxFromProps,
      }

  return (
    <Box sx={{ pl: 0.5, ...checkboxWrapperSx }} title={isError && !ignoreErrorStyles ? title : ''}>
      <Checkbox
        checked={checked}
        disabled={disabled}
        size='small'
        sx={checkboxSx}
        onChange={handleCheckboxChange}
      />
      {resetByButton && (
        <IconButton size='small' sx={{ m: 0, p: 0 }} onClick={handleResetByButton}>
          <CloseIcon fontSize='small' />
        </IconButton>
      )}
    </Box>
  )
}
