import { createContext, FC, ReactNode } from 'react'

import AuthService from '@services/AuthService'

type AuthContextType = typeof AuthService
export const AuthContext = createContext<AuthContextType>(AuthService)

type AuthProviderProps = {
  children: ReactNode
}
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  return <AuthContext.Provider value={AuthService}>{children}</AuthContext.Provider>
}
