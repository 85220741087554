import { Dispatch, SetStateAction, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

type ResetValuesOnObjectTitleChangeOptions = {
  valueNameInputName: string
  objectValueInputName: string
  fieldTypeInputName: string
  setSelectedValueType: Dispatch<SetStateAction<string>>
}

export const useResetValuesOnObjectTitleChange = ({
  valueNameInputName,
  objectValueInputName,
  fieldTypeInputName,
  setSelectedValueType,
}: ResetValuesOnObjectTitleChangeOptions) => {
  const {
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext()

  const watchedObjectTitle = watch('objectTitle')

  useEffect(() => {
    if (isDirty) {
      setValue(valueNameInputName, null)
      setValue(objectValueInputName, null)
      setValue(fieldTypeInputName, null)
      setSelectedValueType('')
    }
  }, [watchedObjectTitle])
}
