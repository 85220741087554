import { Dispatch, ReactNode, SetStateAction } from 'react'
import Tree, {
  ItemId,
  moveItemOnTree,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeDestinationPosition,
  TreeItem,
  TreeSourcePosition,
} from '@atlaskit/tree'
import { MENU_POINT_TYPES } from '@constants'
import { Box, List } from '@mui/material'

export type MenuListProps<T> = {
  tree: TreeData | null
  setTree: Dispatch<SetStateAction<TreeData | null>>
  renderItem?: (item: RenderItemParams) => ReactNode
  onDragEnd?: (item: T, order: number) => void
}

export const MenuList = <T extends TreeItem>({
  tree,
  renderItem,
  setTree,
  onDragEnd,
}: MenuListProps<T>) => {
  const onExpand = (itemId: ItemId): void => {
    if (tree) {
      setTree(mutateTree(tree, itemId, { isExpanded: true }))
    }
  }

  const onCollapse = (itemId: ItemId): void => {
    if (tree) {
      setTree(mutateTree(tree, itemId, { isExpanded: false }))
    }
  }

  const handleDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    if (!destination || !tree) {
      return
    }

    const draggedItem = tree.items[
      tree.items[source.parentId].children[source.index]
    ] as unknown as T
    const newTree = moveItemOnTree(tree, source, destination)
    const destinationParentItem = newTree.items[destination.parentId]
    const newOrder = destinationParentItem.children.findIndex(value => value === draggedItem.id)

    if (
      destinationParentItem.data.menuPointType === MENU_POINT_TYPES.DIVIDER ||
      destinationParentItem.data.menuPointType === MENU_POINT_TYPES.HEADER
    ) {
      return
    }

    onDragEnd?.(
      {
        ...draggedItem,
        data: {
          ...draggedItem.data,
          treeItemParentId: destination.parentId,
          parentId: destinationParentItem.data.id || null,
          ...(destinationParentItem.data.uuid && { parentUuid: destinationParentItem.data.uuid }),
        },
      },
      newOrder
    )

    setTree(
      mutateTree(newTree, draggedItem.id, {
        data: {
          ...draggedItem.data,
          parentUuid: destinationParentItem.data.uuid || null,
          treeItemParentId: destination.parentId,
        },
      })
    )
  }

  return (
    <Box sx={{ overflow: 'auto', height: '55vh' }}>
      <List>
        {tree && (
          <Tree
            isDragEnabled
            isNestingEnabled
            renderItem={renderItem}
            tree={tree}
            onCollapse={onCollapse}
            onDragEnd={handleDragEnd}
            onExpand={onExpand}
          />
        )}
      </List>
    </Box>
  )
}
