// import { GridPinnedColumns } from '@microservices/wiskey-react-components/DataGrid'
import { GridPinnedColumns } from '@microservices/wiskey-react-components'
import { CutRestrictionDTO, RESTRICTION_TYPE, RestrictionDTO } from '@types'

import { PinnedColumnPosition } from '../types'

// Хелпер для поиска значений в втором массиве, которых нет в первом и для преобразования их в RestrictionDTO
export const findPinnedColumnsDifferences = (
  fixRestrictions: CutRestrictionDTO[],
  viewId: string,
  firstColumns: GridPinnedColumns,
  secondColumns: GridPinnedColumns,
  restrictionValue?: unknown,
  newPinnedColumns?: RestrictionDTO[]
) => {
  const output: RestrictionDTO[] = []

  Object.keys(firstColumns).forEach(columnPosition => {
    const typedPosition = columnPosition as PinnedColumnPosition

    secondColumns[typedPosition]?.forEach(pinnedColumnFromHandler => {
      const columnIsNotPresent = !firstColumns[typedPosition]?.includes(pinnedColumnFromHandler)

      if (columnIsNotPresent) {
        const existingRestriction = fixRestrictions.find(
          restriction => restriction.fieldCode === pinnedColumnFromHandler
        )

        if (
          pinnedColumnFromHandler !== 'actions' &&
          pinnedColumnFromHandler !== ' ' &&
          !newPinnedColumns?.find(
            pinnedColumn => pinnedColumn.fieldCode === pinnedColumnFromHandler
          )
        ) {
          output.push({
            ...(existingRestriction ? { id: existingRestriction.id } : {}),
            parentFieldName: null,
            fieldName: pinnedColumnFromHandler,
            fieldCode: pinnedColumnFromHandler,
            viewId,
            restrictionType: RESTRICTION_TYPE.FIX,
            value: restrictionValue || restrictionValue === null ? restrictionValue : typedPosition,
          })
        }
      }
    })
  })

  return output
}
