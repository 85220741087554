import { SortType } from '@hooks'
import { ObjectDataRecord } from '@types'

import { compareObjectDataRecords } from './compareObjectDataRecords'

export const binarySearchFirstOccurrence = (
  items: ObjectDataRecord[],
  item: ObjectDataRecord,
  sort: SortType[]
): number => {
  let low = 0
  let high = items.length - 1
  let result = -1

  while (low <= high) {
    const mid = Math.floor((low + high) / 2)
    const comparison = compareObjectDataRecords(items[mid], item, sort)
    if (comparison >= 0) {
      result = mid
      high = mid - 1 // Переходим к левой половине
    } else {
      low = mid + 1
    }
  }

  return result
}
