import {
  contextMenuDefaultJson,
  tooltipDefaultGroupJson,
  tooltipDefaultTextJson,
} from '@gantt/constants'

export default {
  'Context header menu for Y with Sort': JSON.stringify(contextMenuDefaultJson, null, '\t'),
  'Simple tooltip': JSON.stringify(tooltipDefaultTextJson, null, '\t'),
  'Group tooltip': JSON.stringify(tooltipDefaultGroupJson, null, '\t'),
}
