import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { AutocompleteOption } from '@types'

import { PickerItem } from './PickerItem'

type PickerFieldArrayControllerProps = {
  name: string
  disabled: boolean
  isFlatOptions: boolean
  watchedObject?: AutocompleteOption<string> | null
  label: string
  prefix?: string
}

export const PickerFieldArrayController = ({
  name,
  disabled = false,
  isFlatOptions,
  watchedObject,
  label,
  prefix,
}: PickerFieldArrayControllerProps) => {
  const { control, watch, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({ control, name })
  const { t } = useTranslation()

  const handleAppend = () => {
    append({ pathStr: '', pathArray: [], field: '' })
  }

  useEffect(() => {
    if (fields.length === 0) {
      setValue(name, [{ pathStr: '', pathArray: [], field: '' }])
    }
  }, [])

  const handleRemove = (index: number) => {
    if (fields.length > 1) {
      remove(index)
    }
  }

  return (
    <>
      {fields.map((field, index) => {
        return (
          <PickerItem
            key={field.id}
            embeddedObjectPickerControllerName={`${name}.${index}.pathArray`}
            field={field}
            fieldName={`${name}.${index}.field`}
            index={index}
            isDisabled={disabled}
            isFlatOptions={isFlatOptions}
            label={label}
            name={`${name}.${index}.pathStr`}
            prefix={prefix}
            watchValue={watch(`${name}.${index}`)}
            watchedObject={watchedObject}
            onRemove={() => handleRemove(index)}
          />
        )
      })}
      <Button onClick={handleAppend}>
        <Add fontSize='small' />
        {t('ganttCreate.common.add')}
      </Button>
    </>
  )
}
