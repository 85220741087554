import { FILTERS_SA_TYPE } from '@constants'

export const getSecondsArrayForSearchAssistantFilter = (
  seconds: number,
  searchRule: FILTERS_SA_TYPE
) => {
  const values = [seconds]

  while (values.length !== 60 && seconds !== 0) {
    const nextValue = values[values.length - 1] - 1
    values.push(nextValue)
  }

  switch (searchRule) {
    case FILTERS_SA_TYPE.MORE:
      return [values[0] + 1]
    case FILTERS_SA_TYPE.MORE_OR_EQUAL:
      return [values[values.length - 1]]
    case FILTERS_SA_TYPE.LESS:
      return seconds !== 0 ? [values[values.length - 1] - 1] : [0]
    case FILTERS_SA_TYPE.LESS_OR_EQUAL:
      return seconds !== 0 ? [values[0]] : [0]
    default:
      return values
  }
}
