import { useTranslation } from 'react-i18next'
import { DEFAULT_HEADER_STYLES_CLASSNAME, GENERATOR_INPUT_TYPE } from '@constants'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Typography } from '@mui/material'

import { TableWithActions } from '@pages/Contexts/components/TableWithActions'

import { GANTT_CHART_COLUMNS } from '../constants/columns'
import { useGanttCharts } from '../hooks'

export const GanttCharts = () => {
  const { t } = useTranslation()

  const {
    data: { isLoadingContextGanttChartsById, isLoadingGanttCharts },
    state: { selectedGanttCharts, unselectedGanttCharts, defaultGanttChartId },
    handlers: { handleAddGanttChart, handleDeleteGanttChart, handleChangeDefaultGanttChart },
  } = useGanttCharts()

  const disabledActions = selectedGanttCharts.length === 1

  return (
    <Box>
      <FormInput
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={t('contexts.title.ganttChart')}
        loading={isLoadingContextGanttChartsById || isLoadingGanttCharts}
        name='searchGanttChart'
        placeholder={t('contexts.placeholder.selectGantt')}
        autocompleteOptions={
          unselectedGanttCharts
            ? unselectedGanttCharts.map(view => ({
                id: view.id,
                label: view.title,
              }))
            : []
        }
      />
      <Box mt={2}>
        <Typography fontWeight='bold' variant='subtitle1'>
          {t(`contexts.addedGanttCharts`)}
        </Typography>
        <TableWithActions
          columns={GANTT_CHART_COLUMNS}
          defaultGanttChartId={defaultGanttChartId}
          disabledChangeDefault={disabledActions}
          disabledDelete={disabledActions}
          loading={isLoadingContextGanttChartsById}
          minWidthActions={160}
          rows={selectedGanttCharts || []}
          skipDeleteModal={true}
          tableHeight={300}
          headerNameActions={`${t('contexts.tableColumns.actions.delete')} ${t(
            'contexts.tableColumns.actions.default'
          )}`}
          sxGrid={{
            '.MuiDataGrid-actionsCell': {
              gridGap: 31,
            },
            [`.${DEFAULT_HEADER_STYLES_CLASSNAME} .MuiDataGrid-columnHeaderTitle`]: {
              wordSpacing: 8,
            },
          }}
          onChangeDefault={handleChangeDefaultGanttChart}
          onDelete={handleDeleteGanttChart}
        />
      </Box>
      <Box mt={2}>
        <Typography fontWeight='bold' variant='subtitle1'>
          {t(`contexts.notAddedGanttCharts`)}
        </Typography>
        <TableWithActions
          columns={GANTT_CHART_COLUMNS}
          headerNameActions={`${t('contexts.tableColumns.actions.add')}`}
          loading={isLoadingContextGanttChartsById || isLoadingGanttCharts}
          rows={unselectedGanttCharts || []}
          tableHeight={300}
          onAdd={handleAddGanttChart}
        />
      </Box>
    </Box>
  )
}
