import { FieldObjectParams } from 'src/types'

export type TransformExpandsParams = {
  field: string
  value: string
  expandRequest: string
  expandField: string
  formCode: string
}

export const transformParamsToExpands = (object: FieldObjectParams) => {
  const result: TransformExpandsParams[] = []

  if (Array.isArray(object.formCodeByFieldValue)) {
    object.formCodeByFieldValue.forEach(item => {
      // получаем массив всех полей
      const fieldArray = item.field?.split('.')
      // вытаскиваем последнее поле, как запрашиваемое
      const requestedField = fieldArray?.pop()
      // получаем строку вложенных полей через .
      const key = fieldArray?.join('.')

      if (key && requestedField && item.value && item.formCode && item.field) {
        result.push({
          expandRequest: `${key}:${key}`,
          field: requestedField,
          value: item.value,
          formCode: item.formCode,
          expandField: item.field,
        })
      }
    })
  }

  return result
}
