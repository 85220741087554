import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  currentStep: 0,
  modelCode: '',
}

const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },
    setModelCode: (state, action: PayloadAction<string>) => {
      state.modelCode = action.payload
    },
  },
})

export default stepperSlice.reducer
export const { setStep, setModelCode } = stepperSlice.actions
