import { POSTDefaultCriteria } from 'src/types'

import { useAddDefaultCriteriaMutation } from '@redux/api'

export const useHandlers = () => {
  const [addDefaultCriterias] = useAddDefaultCriteriaMutation()

  const handleAddDefaultCriterias = (values: POSTDefaultCriteria[]) => addDefaultCriterias(values)

  return {
    handlers: {
      handleAddDefaultCriterias,
    },
  }
}
