import { TextParams, TextSettingsStateType } from '@components/TextSettings'

import { TIMEZONES } from './timezone'

export enum GLOBAL_PARAMETER_CODE {
  GLOBAL_COLUMN_HEADER_STYLES = 'global_column_header_styles',
  GLOBAL_COLUMN_TEXT_STYLES = 'global_column_text_styles',
  GLOBAL_FONT_VARIANTS = 'global_font_variants',
}

export enum COMMON_PARAMETERS {
  DATA = 'common_parameters_data',
  STATE = 'common_parameters',
}

export enum PARAMETER_STATE {
  DISABLE = 'DISABLE',
  ACTIVE = 'ACTIVE',
}

export enum PARAMETER_KEY {
  HEADER = 'HEADER',
  TEXT = 'TEXT',
}

export const DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS: TextParams = {
  fontFamily: 'Arial',
  fontSize: '14',
  fontColor: '#000',
  alignment: 'left',
  isBold: 'false',
  isItalic: 'false',
  bgFontColor: 'none',
  borderColor: 'none',
}

export const { bgFontColor, ...DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS } =
  DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS

export const DEFAULT_COMMON_PARAMETERS = {
  numberOfWindows: { id: '5', label: '5' },
  timezone: { id: TIMEZONES.MOSCOW, label: TIMEZONES.MOSCOW },
  pagination: { id: '20', label: '20' },
}

export const DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS: TextSettingsStateType = {
  alignment: 'left',
  bgFontColor: 'none',
  fontFamily: {
    id: 'Arial',
    label: 'Arial',
  },
  fontColor: '#000',
  fontSize: '14',
  formats: [],
  borderColor: 'none',
}

export const DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS =
  DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS

export const DEFAULT_BACKGROUND_PINNED_COLUMN = {
  backgroundColor: '#dfdfdf',
  backgroundImage: 'linear-gradient(#ffffff12, #ffffff12)',
}
