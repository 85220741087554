// import 'react-virtualized/styles.css'
import {
  Paper,
  SxProps,
  TableContainer as MUITableContainer,
  TableContainerProps as MUITableContainerProps,
  Theme,
} from '@mui/material'
import { Table } from '@tanstack/react-table'

type TableContainerProps<T> = { table: Table<T> } & MUITableContainerProps

export const TableContainer = <T,>({ children, table, sx, ...props }: TableContainerProps<T>) => {
  const meta = table.options.meta
  const disablePagination = meta?.disablePagination

  const sxPaper: SxProps<Theme> = {
    borderColor: theme => theme.palette.border.table,
    background: theme => theme.palette.background.emptyRow,
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: 0,
    ...(!disablePagination && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
    '.MuiTableContainer-root': { boxShadow: 0 },
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // <AutoSizer disableHeight>
    //   {({ width }) => (
    <MUITableContainer
      component={Paper}
      sx={{
        display: 'block',
        flex: 1,
        ...sxPaper,
        ...sx,
      }}
      {...props}
    >
      {children}
    </MUITableContainer>
    //   )}
    // </AutoSizer>
  )
}
