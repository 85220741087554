import { PARAMETER_KEY, PARAMETER_STATE } from '@constants'

export const getParametersForCreation = (columnHeaderCode: string, columnTextCode: string) => {
  return [
    {
      paramKey: PARAMETER_KEY.HEADER,
      param: {
        code: columnHeaderCode,
        title: 'Column Header Styles',
        type: 'variable',
        propertiesType: 'string',
        description: '',
        state: PARAMETER_STATE.ACTIVE,
      },
    },
    {
      paramKey: PARAMETER_KEY.TEXT,
      param: {
        code: columnTextCode,
        title: 'Column Text Styles',
        type: 'variable',
        propertiesType: 'string',
        description: '',
        state: PARAMETER_STATE.ACTIVE,
      },
    },
  ]
}
