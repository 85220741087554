import { Rnd } from 'react-rnd'

// Хелпер необходим для обработки случая, при котором translateY у обертки rnd сетится с дробью
// (к примеру 172.2). На некоторых устройствах это вызывает некрасивый блюр всего окна
// (дефект https://jiradc.utg.group/browse/WF-475)
export const observeRndTransform = (rnd: Rnd | null | undefined) => {
  // Для этой самой обертки создается MutationObserver, который нативно позволяет отслеживать
  // изменения атрибутов DOM элемента.
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      // В колбэке фиксируется изменение атрибута style (доп проверка, само условие #TUT)
      if (mutation.attributeName === 'style') {
        const target = mutation.target as HTMLElement

        // Из него (атрибута style) берется transform
        const matrix = new DOMMatrixReadOnly(getComputedStyle(target).transform)

        // Если окно скрыто, то применяется display none
        // В этот момент координаты становятся {0;0}
        if (matrix.m41 === 0 && matrix.m42 === 0) {
          return
        }

        // значения которого округляются
        const translateX = Math.round(matrix.m41)
        const translateY = Math.round(matrix.m42)
        target.style.transform = `translate(${translateX}px, ${translateY}px)`
      }
    })
  })

  const observerConfig = {
    attributes: true,
    // #TUT
    attributeFilter: ['style'],
  }

  observer.observe(rnd?.getSelfElement() as Node, observerConfig)
}
