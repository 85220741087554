import {
  useCreateContainerElementMutation,
  useCreateContainerMutation,
  useCreateContainerTabMutation,
  useDeleteContainerElementMutation,
  useDeleteContainerMutation,
  useDeleteContainerTabMutation,
  useUpdateContainerElementMutation,
  useUpdateContainerLineMutation,
  useUpdateContainerMutation,
  useUpdateContainerTabMutation,
} from './mutations'

export const useMutationsLoading = () => {
  const [, { isLoading: isLoadingCreateContainer }] = useCreateContainerMutation()
  const [, { isLoading: isLoadingUpdateContainer }] = useUpdateContainerMutation()
  const [, { isLoading: isLoadingDeleteContainer }] = useDeleteContainerMutation()
  const [, { isLoading: isLoadingCreateContainerTab }] = useCreateContainerTabMutation()
  const [, { isLoading: isLoadingUpdateContainerTab }] = useUpdateContainerTabMutation()
  const [, { isLoading: isLoadingDeleteContainerTab }] = useDeleteContainerTabMutation()
  const [, { isLoading: isLoadingUpdateContainerLine }] = useUpdateContainerLineMutation()
  const [, { isLoading: isLoadingCreateContainerElement }] = useCreateContainerElementMutation()
  const [, { isLoading: isLoadingUpdateContainerElement }] = useUpdateContainerElementMutation()
  const [, { isLoading: isLoadingDeleteContainerElement }] = useDeleteContainerElementMutation()

  return (
    isLoadingCreateContainer ||
    isLoadingUpdateContainer ||
    isLoadingDeleteContainer ||
    isLoadingCreateContainerTab ||
    isLoadingUpdateContainerTab ||
    isLoadingDeleteContainerTab ||
    isLoadingUpdateContainerLine ||
    isLoadingCreateContainerElement ||
    isLoadingUpdateContainerElement ||
    isLoadingDeleteContainerElement
  )
}
