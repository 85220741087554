import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { COMMON_PARAMETERS, GLOBAL_PARAMETER_CODE } from '@constants'
import { getLocalStorageContext } from '@helpers'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

import { useFetchContextControlPanelByIdQuery } from '@redux/api'
import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'

import {
  getGanttChartOptionsFromMenu,
  transformCommonParameters,
  transformCommonParametersData,
  transformTextParamsData,
} from '../helpers'

import { useHandlers } from './useHandlers'

export type CommonParametersFormType = {
  numberOfWindows: AutocompleteOption | null
  timezone: AutocompleteOption | null
  pagination: AutocompleteOption | null
  ganttChart: AutocompleteOption | null
}

export const useParameters = () => {
  const methods = useForm<CommonParametersFormType>({
    defaultValues: { numberOfWindows: null, pagination: null, timezone: null, ganttChart: null },
  })
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = methods

  const { data: globalColumnHeaderStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_HEADER_STYLES,
  })

  const { data: globalColumnTextStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_TEXT_STYLES,
  })

  const { data: commonParametersData } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.DATA,
  })

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const context = getLocalStorageContext()

  const { data: menu } = useFetchContextControlPanelByIdQuery(context?.id, {
    skip: !context,
  })

  const initialGlobalColumnHeaderStyles = useMemo(
    () => transformTextParamsData(globalColumnHeaderStyles),
    [globalColumnHeaderStyles]
  )
  const initialGlobalColumnTextStyles = useMemo(
    () => transformTextParamsData(globalColumnTextStyles),
    [globalColumnTextStyles]
  )

  const initialCommonParameters = useMemo(
    () => transformCommonParameters(commonParameters),
    [commonParameters]
  )

  const ganttChartAutocompleteOptions = useMemo(
    () => getGanttChartOptionsFromMenu(menu?.panel.lines.flatMap(line => line.items) || []),
    [menu]
  )

  const initialCommonParametersData = useMemo(
    () => ({
      ...transformCommonParametersData(commonParametersData || []),
      ganttChart: ganttChartAutocompleteOptions,
    }),
    [commonParametersData, ganttChartAutocompleteOptions]
  )

  useEffect(() => {
    if (initialCommonParameters) {
      const ganttChart =
        ganttChartAutocompleteOptions.find(
          ({ id }) => String(id) === initialCommonParameters.ganttChart?.id
        ) || null

      reset({ ...initialCommonParameters, ganttChart })
    }
  }, [initialCommonParameters, ganttChartAutocompleteOptions])

  const { handlers, state, data } = useHandlers({
    onSubmitCommonForm: handleSubmit,
    commonParameters,
    reset,
    isDirtyCommonParameters: isDirty,
    initialGlobalColumnHeaderStyles,
    initialGlobalColumnTextStyles,
    onSetValueCommonParameters: setValue,
  })

  return {
    data: {
      ...data,
      initialGlobalColumnHeaderStyles,
      initialGlobalColumnTextStyles,
      initialCommonParametersData,
      initialCommonParameters,
    },
    state: {
      ...state,
      methods,
    },
    handlers,
  }
}
