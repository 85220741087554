import { Theme } from '@mui/material'

export const getAutoCompletePopperSxByDisplayMethod = (
  isDialogWindow: boolean | undefined,
  readOnly?: boolean | undefined,
  theme?: Theme
) => {
  const hovered = `${theme?.palette.background.hovered} !important`
  const paper = `${theme?.palette.background.paper} !important`

  return {
    '.MuiAutocomplete-listbox': {
      maxHeight: isDialogWindow ? '30vh' : '40vh',
    },
    '.MuiAutocomplete-option': {
      height: isDialogWindow ? '25px' : '36px',
      '&[aria-selected="true"], &.Mui-focused, & :hover': {
        ...(readOnly && { backgroundColor: paper }),
      },
      '&[aria-selected="false"]': {
        ...(readOnly && { backgroundColor: hovered }),
      },
    },
    '.MuiAutocomplete-noOptions': {
      paddingY: isDialogWindow ? 0 : 1,
      paddingX: 1,
    },
  }
}
