import i18next from 'i18next'
import * as yup from 'yup'

export const ganttParametersFormSchema = yup.object().shape({
  rowHeight: yup
    .mixed()
    .test('min', i18next.t('error.ganttParametersForm.rowHeightMinMax'), value => {
      if (!value && value !== 0) {
        return true
      }
      return !(value < 20)
    }),
  backlogHeight: yup
    .mixed()
    .test('min', i18next.t('error.ganttParametersForm.backlogHeightMin'), value => {
      if (!value && value !== 0) {
        return true
      }
      return !(value < 50)
    }),
  yAxisWidth: yup
    .mixed()
    .test('min', i18next.t('error.ganttParametersForm.backlogHeightMin'), value => {
      if (!value && value !== 0) {
        return true
      }
      return !(value < 20)
    }),
  axisX: yup.object().shape({
    realtimeLineWidth: yup.mixed().when('hasRealtimeLine', {
      is: true,
      then: shema => {
        return shema.test(
          'val',
          i18next.t('error.ganttParametersForm.realtimeLineWidth'),
          value => {
            if (!value && value !== 0) {
              return true
            }
            return /^([1-9]{1}(\.5|\.0)?|0.5?|10(\.0)?)$/.test(value as string)
          }
        )
      },
    }),
  }),
})
