import i18next from 'i18next'
import { GridColumns } from '@mui/x-data-grid'

import { ConfigRowsType } from '@types'

export const VIEW_COLUMNS: GridColumns<ConfigRowsType> = [
  {
    field: 'title',
    headerName: i18next.t('contexts.tableColumns.title'),
    flex: 1,
  },
  {
    field: 'objectTitle',
    headerName: i18next.t('contexts.tableColumns.object'),
    flex: 1,
  },
]

export const GANTT_CHART_COLUMNS: GridColumns<ConfigRowsType> = [
  {
    field: 'title',
    headerName: i18next.t('contexts.tableColumns.title'),
    flex: 1,
  },
  {
    field: 'axisYObject',
    headerName: i18next.t('contexts.tableColumns.axisY'),
    flex: 1,
  },
  {
    field: 'axisXObject',
    headerName: i18next.t('contexts.tableColumns.axisX'),
    flex: 1,
  },
]

export const CONTROL_PANEL_COLUMNS: GridColumns<ConfigRowsType> = [
  {
    field: 'title',
    headerName: i18next.t('contexts.tableColumns.title'),
    flex: 1,
  },
]

export const VARIABLE_COLUMNS: any = (props: any) => {
  return [
    {
      field: 'variableTitle',
      headerName: i18next.t('contexts.tableColumns.variable'),
      flex: 1,
    },
    {
      field: 'values',
      headerName: i18next.t('contexts.tableColumns.value'),
      flex: 1,
      editable: true,
      ...props,
    },
  ]
}

export const SEARCH_ASSISTANT_COLUMNS = VIEW_COLUMNS
