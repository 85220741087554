import { GETTabDTO, POSTOrderTabType, POSTTabDTO } from '../../types'

import { commonAPI } from './common.api'

export const tabsAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchTabs: build.query<GETTabDTO[], void>({
      query: () => ({
        url: 'ui/tabs',
      }),
    }),
    fetchTabsByViewCode: build.query<GETTabDTO[], string | void>({
      query: viewCode => ({
        url: `ui/tabs/viewCode/${viewCode}`,
      }),
      providesTags: ['SingleView', 'Tabs'],
    }),
    createTab: build.mutation<POSTTabDTO, POSTTabDTO>({
      query: tab => ({
        url: 'ui/tabs',
        method: 'POST',
        body: tab,
      }),
      invalidatesTags: ['Tabs', 'SingleView'],
    }),
    deleteTab: build.mutation<POSTTabDTO, number>({
      query: id => ({
        url: `ui/tabs/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tabs', 'SingleView'],
    }),
    updateOrderTabs: build.mutation<void, POSTOrderTabType[]>({
      query: tabs => ({
        url: '/ui/tabs/order',
        method: 'POST',
        body: tabs,
      }),
      invalidatesTags: ['SingleView', 'Tabs'],
    }),
  }),
})

export const {
  useFetchTabsQuery,
  useCreateTabMutation,
  useFetchTabsByViewCodeQuery,
  useDeleteTabMutation,
  useUpdateOrderTabsMutation,
} = tabsAPI
