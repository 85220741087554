import { ReactNode } from 'react'
import { AutoComplete, GridCellParams, TextField } from '@microservices/wiskey-react-components'
import { Chip } from '@mui/material'

import { AutocompleteOption } from '@types'

type RenderCellValue = {
  params: GridCellParams
  values: string[]
}

export const RenderCellValue = ({ params, values = [] }: RenderCellValue) => {
  return (
    <AutoComplete
      freeSolo
      fullWidth
      multiple
      readOnly
      inputValue=''
      options={[]}
      value={values}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant='filled'
            sx={{
              '.MuiInputBase-root': {
                '& input': {
                  padding: 0,
                  cursor: 'default',
                },
                maxHeight: 41,
                overflowY: 'auto',
                paddingTop: 0,
                paddingRight: 0,
                paddingLeft: 0,
                background: 'transparent !important',
              },
              '*::before, *::after': {
                borderBottom: 'none !important',
              },
            }}
          />
        )
      }}
      renderTags={(value: AutocompleteOption<string | number>[], getTagProps) =>
        value.map((option: AutocompleteOption, index: number) => {
          const { key, ...tagProps } = getTagProps({ index })

          return (
            <Chip
              key={key}
              label={option as unknown as ReactNode}
              size='small'
              variant='outlined'
              sx={{
                height: '23px',
                ml: 1,
                color: theme => theme.palette.color.default,
                bgcolor: theme => theme.palette.background.chip,
                border: theme => theme.palette.border.field,
                '&.MuiChip-deleteIcon': {
                  color: theme => theme.palette.color.default,
                },
                '&.MuiChip-root:hover': {
                  bgcolor: theme => theme.palette.background.hoveredChip,
                },
              }}
              {...tagProps}
            />
          )
        })
      }
    />
  )
}
