import { BindTypeField, ConfigField } from '@gantt/components/GanttCreateOrEdit'

const skip = ['additionalFields', 'actions', 'pathStr', 'pathArray']

export const getAvailableVariablesObject = (timelineData: Record<string, any>) => {
  const availableVariablesObject: Record<string, ConfigField> = {}
  function getAvailableVariables(item: Record<string, any>) {
    for (const property in item) {
      if (!skip.includes(property)) {
        if (item[property]?.field?.pathStr) {
          availableVariablesObject[item[property].field.field] = item[property].field
        } else if (item[property]?.field_array?.length) {
          item[property]?.field_array?.map((item: ConfigField) => {
            availableVariablesObject[item?.field] = item
          })
        } else {
          if (Array.isArray(item[property])) {
            item[property].map((item: BindTypeField) => {
              getAvailableVariables(item)
            })
          }
        }
      }
    }
  }

  getAvailableVariables(timelineData)

  return availableVariablesObject
}
