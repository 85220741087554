import { FC, MouseEvent, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { v4 as uuid } from 'uuid'
import { CLICK_EVENT_TYPE, COMMON_PARAMETERS, ENTITY } from '@constants'
import { getInitialPositionWindow, transformCommonParametersToValues } from '@helpers'
import { getUrlForm } from '@helpers'
import { useAppDispatch, useAppSelector } from '@hooks'
// import { MuiEvent } from '@microservices/wiskey-react-components/DataGrid'
import { MuiEvent } from '@microservices/wiskey-react-components'
import { DIALOG_WINDOW_TYPE, EntityType } from '@types'

import { OpenFormProps } from '@pages/ConfiguredEntity/types'
import { ConfiguredEntityWithSearchAssistant } from '@pages/ConfiguredEntityWithSearchAssistant'

import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import { openFormDialog } from '@redux/reducers/dialogWindowManager.reducer'
import { showMessage } from '@redux/reducers/snackbar.reducer'

type ConfiguredEntityPageProps = {
  entityId?: number | string | null
  entityCode?: string | null
  type: ENTITY
  path: string
  title: string
}

export const ConfiguredEntityPage: FC<ConfiguredEntityPageProps> = ({
  entityId,
  entityCode,
  type,
  path,
  title,
}) => {
  const [entity, setEntity] = useState<EntityType | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { search = '', pathname = '' } = useLocation()
  const { dialogWindows } = useAppSelector(state => state.dialogWindowManager)
  const id = uuid()

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  const handleSetEntity = (value: EntityType | null) => setEntity(value)

  const handleOpenForm = (
    { entityType, ...formProps }: OpenFormProps,
    muiEvent: MouseEvent<HTMLButtonElement> | MuiEvent<MouseEvent>,
    type: CLICK_EVENT_TYPE
  ) => {
    if (type === CLICK_EVENT_TYPE.ROW_CLICK && muiEvent.shiftKey) {
      const { id, formCode, objectCode, viewCode, objectId, path, event } = formProps
      const initialPosition = getInitialPositionWindow(muiEvent)
      const openedFormsCount = dialogWindows.filter(
        dialog =>
          [DIALOG_WINDOW_TYPE.FORM, DIALOG_WINDOW_TYPE.VIEW].includes(dialog.type) && !dialog.hidden
      ).length
      const maxOpenedFormsCount = Number(initialCommonParameters.numberOfWindows)

      if (openedFormsCount === maxOpenedFormsCount) {
        dispatch(
          showMessage({
            type: 'info',
            text: t('error.dynamicFormsExceeded', { count: maxOpenedFormsCount }),
          })
        )

        return
      }

      dispatch(
        openFormDialog({
          id: id,
          parentDialogId: null,
          type: DIALOG_WINDOW_TYPE.FORM,
          title: null,
          meta: {
            formCode,
            objectCode,
            objectId,
            viewCode,
            path,
            event,
            globalId: id,
            entityType,
          },
          initialPosition,
        })
      )

      return
    }
    navigate(getUrlForm({ ...formProps }))
  }

  return (
    <ConfiguredEntityWithSearchAssistant
      enableStaticForms
      dialogId={id}
      entityCode={entityCode}
      entityId={entityId}
      handleOpenForm={handleOpenForm}
      path={path}
      pathname={pathname}
      search={search}
      title={title}
      type={type}
    />
  )
}
