import { FIELD_VALUE_TYPE } from '@constants'

export const getDefaultValueByType = (
  valueType: FIELD_VALUE_TYPE | null,
  valueName: string,
  value?: unknown
) => {
  switch (valueType) {
    case FIELD_VALUE_TYPE.DATETIME:
    case FIELD_VALUE_TYPE.OBJ_EMBEDDED:
    case FIELD_VALUE_TYPE.OBJ_PK_LINK:
    case FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK:
      return [valueName, value ?? null]
    default:
      return [valueName, value ?? '']
  }
}
