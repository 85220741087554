import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmModal,
  DataGrid,
  GridCellParams,
  GridColumns,
  GridEditMode,
  GridEditModes,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  useGridApiRef,
} from '@microservices/wiskey-react-components'
import { Grid, SxProps, Theme } from '@mui/material'

import { generateDataGridActionsColumnForContext } from '@pages/ContextCreateOrEdit/helpers'

import { ObjectDataRecord } from '@types'

type TableWithActionsProps<T> = {
  columns: GridColumns<any>
  rows: T[]
  onSave?: (id: number) => void
  onCancel?: (id: number) => void
  onDelete?: (id: number) => void
  onCopy?: (data: any) => void
  onEdit?: (id: number | null, focus?: boolean) => void
  onChangeDefault?: (data: any) => void
  onAdd?: (data: any) => void
  loading?: boolean
  tableHeight?: number
  skipDeleteModal?: boolean
  sx?: SxProps<Theme>
  sxGrid?: SxProps<Theme>
  headerNameActions?: string
  minWidthActions?: number
  disabledDelete?: boolean
  disabledChangeDefault?: boolean
  defaultGanttChartId?: number
  editMode?: GridEditMode
  editRowId?: string | number | null
}

export const TableWithActions = <T extends { id: string | number }>({
  columns,
  rows,
  onSave,
  onCancel,
  onDelete,
  onCopy,
  onEdit,
  onChangeDefault,
  onAdd,
  loading,
  tableHeight,
  skipDeleteModal,
  sx,
  sxGrid,
  headerNameActions,
  minWidthActions,
  disabledDelete,
  disabledChangeDefault,
  defaultGanttChartId,
  editMode,
  editRowId,
}: TableWithActionsProps<T>) => {
  const { t } = useTranslation()
  const apiRef = useGridApiRef()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [currentRowId, setCurrentRowId] = useState<number>()
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  const handleStopRowEditing: GridEventListener<'rowEditStop'> = ({ reason, row: oldRow }) => {
    if (reason === GridRowEditStopReasons.enterKeyDown) {
      setViewRowModeModel(oldRow.id)
      onSave?.(oldRow.id)
    }

    if (
      reason === GridRowEditStopReasons.escapeKeyDown ||
      reason === GridRowEditStopReasons.rowFocusOut
    ) {
      setViewRowModeModel(oldRow.id)
      onCancel?.(oldRow.id)
    }

    onEdit?.(null)
  }

  const handleProcessRowUpdate = (
    _newRow: GridRowModel<ObjectDataRecord>,
    oldRow: GridRowModel<ObjectDataRecord>
  ) => oldRow

  const setViewRowModeModel = (id: string | number) =>
    setRowModesModel({
      [id]: {
        mode: GridRowModes.View,
        ignoreModifications: true,
      },
    })

  const handleSave = (params: GridCellParams<T>) => {
    onSave?.(params.row.id)
    setViewRowModeModel(params.row.id)
    onEdit?.(null)
  }

  const handleCancel = (params: GridCellParams<T>) => {
    setViewRowModeModel(params.row.id)
    onEdit?.(null)
    onCancel?.(params.row.id)
  }

  const handleCopy = (params: GridCellParams<T>) => {
    const { id, title } = params.row
    onCopy?.({ id, title })
  }

  const handleEdit = (params: GridCellParams<T>, event: any) => {
    if (editMode === GridEditModes.Row) {
      setRowModesModel({
        [params.row.id]: {
          mode: GridRowModes.Edit,
          fieldToFocus: 'value',
        },
      })
      onEdit?.(params.row.id, true)

      return
    }

    onEdit?.(params.row.id)
  }

  const handleClickDelete = (params: GridCellParams<T>) => {
    const id = params.row.id

    if (id) {
      if (skipDeleteModal) {
        onDelete?.(id)

        return
      }

      setShowDeleteModal(true)
      setCurrentRowId(id)
    }
  }

  const handleDelete = () => {
    if (currentRowId) {
      onDelete?.(currentRowId)
      setShowDeleteModal(false)
    }
  }

  const handleChangeDefault = (params: GridCellParams<T>) => onChangeDefault?.(params.row.id)

  const handleAdd = (params: GridCellParams<T>) => onAdd?.(params.row.id)

  const handleClose = () => setShowDeleteModal(false)

  const columnsWithActions = useMemo(
    () => [
      ...columns,
      generateDataGridActionsColumnForContext({
        headerName: headerNameActions,
        minWidth: minWidthActions,
        disabledDelete,
        disabledChangeDefault,
        defaultGanttChartId,
        editRowId,
        onAdd: onAdd ? handleAdd : undefined,
        onEdit: onEdit ? handleEdit : undefined,
        onCopy: onCopy ? handleCopy : undefined,
        onDelete: onDelete ? handleClickDelete : undefined,
        onSave: onSave ? handleSave : undefined,
        onCancel: onCancel ? handleCancel : undefined,
        onChangeDefault: onChangeDefault ? handleChangeDefault : undefined,
      }),
    ],
    [columns, defaultGanttChartId, disabledDelete, disabledChangeDefault, editRowId]
  )

  return (
    <Grid container sx={sx}>
      {showDeleteModal && (
        <ConfirmModal
          actionBtnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showDeleteModal}
          text={t('modal.delete.warning')}
          title={t('modal.delete.title')}
          onClose={handleClose}
          onConfirm={handleDelete}
        />
      )}
      <Grid container item>
        <DataGrid
          {...(editMode && { editMode })}
          hideFooter
          apiRef={apiRef}
          columns={columnsWithActions}
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          processRowUpdate={handleProcessRowUpdate}
          rows={rows}
          sx={{ ...sxGrid, borderRadius: 0 }}
          tableHeight={tableHeight}
          rowModesModel={rowModesModel}
          //onRowEditStart={handleStartRowEditing}
          onRowEditStop={handleStopRowEditing}
        />
      </Grid>
    </Grid>
  )
}
