import { isObjectValueType } from '@helpers'

import { AutocompleteOption, ObjectFieldDTO } from '../../../../../../../types'

export const compareValueType = (
  objectFields: ObjectFieldDTO[] | undefined,
  value: AutocompleteOption
) => {
  const valueType = objectFields?.find(field => value && value.label === field.name)?.valueType

  return isObjectValueType(valueType)
}
