import { SortType } from '@hooks'
import { ViewRow } from '@types'

import { getColumnFieldName, getColumnFieldNameForSort } from './getColumnFieldName'

export const getSortByColumns = (columns: ViewRow[]) => {
  // Колонки с ограничением SORT, выстроенные по хранимому порядку сортировки priority
  const columnsWithSortOrderedByPriority = columns
    .filter(column => column?.restrictions?.sort)
    .sort((firstColumn, secondColumn) => {
      const isBigger = Boolean(
        Number(firstColumn?.restrictions?.sort?.priority) >
          Number(secondColumn?.restrictions?.sort?.priority)
      )

      const isSmaller = Boolean(
        Number(firstColumn?.restrictions?.sort?.priority) <
          Number(secondColumn?.restrictions?.sort?.priority)
      )

      if (isBigger) {
        return 1
      }

      if (isSmaller) {
        return -1
      }

      return 0
    })

  return columnsWithSortOrderedByPriority.map(column => {
    const sortKey = getColumnFieldNameForSort(column) as string

    return {
      sortKey,
      field: getColumnFieldName(column),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sort: column?.restrictions?.sort.sortDirection,
    } as SortType
  })
}
