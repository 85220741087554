import { EnumCell } from '@components/EnumCell'

import { EnumCellType } from '../../types'

export const renderEnumCell = (dataEnumCell: EnumCellType) => {
  const { isDialogWindow, objectCode, name, valueType, value } = dataEnumCell

  return (
    <EnumCell
      isDialogWindow={isDialogWindow}
      name={name}
      objectCode={objectCode}
      value={value}
      valueType={valueType}
    />
  )
}
