export const contextMenuDefaultJson = [
  {
    name: 'Sorting',
    isHeader: true,
  },
  {
    name: 'Sort by ASC',
    command: 'sort',
    additionalData: {
      order: 'asc',
    },
  },
  {
    name: 'Sort by DESC',
    command: 'sort',
    additionalData: {
      order: 'desc',
    },
  },
  {
    isDivider: true,
  },
  {
    name: 'Unsort',
    command: 'unsort',
  },
  {
    name: 'Get all',
    commands: [{ type: 'GET_ALL', name: 'object_read_all' }],
  },
]

export const tooltipDefaultTextJson = [
  {
    name: { bindType: 'static', static: 'text' },
    data: { bindType: 'static', static: 'value' },
    filler: 'value',
    parameters: [],
    itemType: 'text',
  },
]

export const tooltipDefaultGroupJson = [
  {
    name: { bindType: 'static', static: 'group' },
    filler: 'value',
    parameters: [],
    itemType: 'group',
    items: [tooltipDefaultTextJson],
  },
]
