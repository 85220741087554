import { FC, useState } from 'react'
import { EntityType } from '@types'

import { ConfiguredEntity, ConfiguredEntityProps } from '@pages/ConfiguredEntity'

export const ConfiguredEntityForDDW: FC<
  Omit<ConfiguredEntityProps, 'entity' | 'onSetEntity'>
> = props => {
  const [entity, setEntity] = useState<EntityType | null>(null)

  const handleSetEntity = (value: EntityType | null) => setEntity(value)

  return <ConfiguredEntity {...props} entity={entity} onSetEntity={handleSetEntity} />
}
