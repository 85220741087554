import { useEffect } from 'react'
import { FORM_TYPE } from '@constants'
import { getVerifyFormCode } from '@helpers'

import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'

import { EntityType } from '../../../types'

type UseVerifyFormCodeParams = {
  // row: ObjectDataRecord | undefined
  newEvent: FORM_TYPE | null
  view: EntityType | undefined
  isSuccessView: boolean
  withPassedParams: boolean
  formCode: string
  newFormCode: string
  handleChangeFormCode: (formCode: string, verifyFormCode: string) => void
  globalId: string
}

export const useVerifyFormCode = ({
  newEvent,
  view,
  isSuccessView,
  withPassedParams,
  formCode,
  newFormCode,
  handleChangeFormCode,
  globalId,
}: UseVerifyFormCodeParams) => {
  const [, { data: row }] = useFetchObjectDataWithFixedKey(globalId)

  const verifyFormCode =
    newEvent === FORM_TYPE.CREATE
      ? getVerifyFormCode({
          type: newEvent,
          row,
          actions: view?.actions ?? [],
        })
      : row &&
        newEvent &&
        getVerifyFormCode({
          type: newEvent,
          row,
          actions: view?.actions ?? [],
        })

  useEffect(() => {
    // Проверка на undefined
    if (!view || !verifyFormCode) {
      return
    }

    // Проверка на наличие id у passedParams
    if (withPassedParams) {
      return
    }

    // TODO например, если для bound 0 и bound 1 есть разные формы, а мы, находясь в одной из них редактируем поле bound,
    //  то раньше был редирект на дефолт страницу
    //  Это не работает для динамических форм, после изменения bound форма остаётся прежней
    // Редирект на форму из actions в случае несоответствия формы из url и actions
    // (См хелпер getVerifyFormCode())
    if (verifyFormCode !== formCode && !newFormCode) {
      // TODO вроде как применимо для формы на отдельной странице
      handleChangeFormCode(formCode, verifyFormCode)
      // const newPathname = location.pathname.replace(formCode, verifyFormCode)
      // navigate(`${newPathname}${location.search}`)
    }
  }, [verifyFormCode, isSuccessView, row])
}
