import { ParameterPropertyType } from 'src/types'

import { transformTextParamsData } from '@pages/Parameters/helpers'

export const getColumnParameterStyles = (params: ParameterPropertyType[]) => {
  const transformedParams = transformTextParamsData(params)
  const columnParameterStyles = {
    styles: {
      fontFamily: transformedParams?.fontFamily.label,
      fontSize: `${transformedParams?.fontSize}px`,
      fontWeight: transformedParams?.formats.find(format => format === 'bold') ? 'bold' : 'normal',
      fontStyle: transformedParams?.formats.find(format => format === 'italic')
        ? 'italic'
        : 'normal',
      color: transformedParams?.fontColor,
      background: transformedParams?.bgFontColor ?? 'inherit',
      borderColor: transformedParams?.borderColor ?? 'none',
    },
    transformedParams,
  }

  return columnParameterStyles
}
