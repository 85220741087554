import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DialogUIOverlayData,
  UIOverlayCloseActionPayload,
  UIOverlayOpenActionPayload,
  UIOverlayState,
} from '@types'

const initialState: UIOverlayState = {
  isOverlayShown: false,
  dialogTreeData: {},
}

const uiOverlaySlice = createSlice({
  name: 'uiOverlay',
  initialState,
  reducers: {
    onSetDialogWindowData: (state, action: PayloadAction<UIOverlayOpenActionPayload>) => {
      const { parentDialogId, dialogId, isBlockingWindow } = action.payload
      let zIndexPriority = 1

      if (!state.dialogTreeData[dialogId]) {
        return
      }

      state.dialogTreeData[dialogId].parentDialogId = parentDialogId
      state.dialogTreeData[dialogId].isBlockingWindow = isBlockingWindow

      if (parentDialogId !== null) {
        const parentData = state.dialogTreeData[parentDialogId]

        zIndexPriority = parentData.isBlockingWindow ? parentData.zIndexPriority + 1 : 1
      }

      state.dialogTreeData[dialogId] = { ...state.dialogTreeData[dialogId], zIndexPriority }

      if (isBlockingWindow && !state.isOverlayShown) {
        state.isOverlayShown = true
      } else {
        state.isOverlayShown = isBlockingWindow
      }
    },
    onOpenDialogWindow: (state, action: PayloadAction<UIOverlayOpenActionPayload>) => {
      const { parentDialogId, dialogId, isBlockingWindow } = action.payload
      let zIndexPriority = 1

      if (parentDialogId !== null) {
        const parentData = state.dialogTreeData[parentDialogId]

        zIndexPriority = parentData.isBlockingWindow ? parentData.zIndexPriority + 1 : 1
      }

      state.dialogTreeData[dialogId] = {
        isBlockingWindow,
        parentDialogId,
        zIndexPriority,
      }

      if (isBlockingWindow && !state.isOverlayShown) {
        state.isOverlayShown = true
      }
    },
    onCloseDialogWindow: (state, action: PayloadAction<UIOverlayCloseActionPayload>) => {
      const { dialogId } = action.payload

      const currentDialog = state.dialogTreeData[dialogId]

      if (currentDialog.parentDialogId && state.dialogTreeData[currentDialog.parentDialogId]) {
        state.isOverlayShown = state.dialogTreeData[currentDialog.parentDialogId].isBlockingWindow
      } else {
        state.isOverlayShown = false
      }

      state.dialogTreeData = Object.keys(state.dialogTreeData)
        .filter(treeDialogId => treeDialogId !== dialogId)
        .reduce((newDialogTreeData, key) => {
          newDialogTreeData[key] = state.dialogTreeData[key]
          return newDialogTreeData
        }, {} as Record<string, DialogUIOverlayData>)
    },
  },
})

export default uiOverlaySlice.reducer
export const { onOpenDialogWindow, onCloseDialogWindow, onSetDialogWindowData } =
  uiOverlaySlice.actions
