import { createContext, FC, useEffect, useRef, useState } from 'react'
import SockJS from 'sockjs-client'
import { useAuth } from '@hooks'
import { Client, IStompSocket, messageCallbackType, StompHeaders } from '@stomp/stompjs'

import AuthService from '@services/AuthService'

import {
  StompSessionProviderContextType,
  StompSessionProviderPropsType,
  StompSessionSubscriptionType,
} from '../../types'

export const StompContext = createContext<StompSessionProviderContextType | undefined>(undefined)

// TODO На текущий момент ws локально не работают
const isDev = false
// const isDev = process.env.NODE_ENV === 'development'

export const StompSessionProvider: FC<StompSessionProviderPropsType> = isDev
  ? ({ children }) => <>{children}</>
  : ({ url, children, stompClientOptions, ...stompOptions }) => {
      if (stompClientOptions) {
        stompOptions = stompClientOptions
      }
      const [client, setClient] = useState<Client | undefined>(undefined)
      const subscriptionRequests = useRef(new Map())
      const { username } = useAuth()
      useEffect(() => {
        const _client = new Client(stompOptions)
        if (username) {
          stompOptions.connectHeaders = {
            Authorization: AuthService.getToken() || '',
            token: AuthService.getToken() || '',
          }
          stompOptions.disconnectHeaders = {
            Authorization: AuthService.getToken() || '',
            token: AuthService.getToken() || '',
          }

          if (!stompOptions.brokerURL && !stompOptions.webSocketFactory) {
            _client.webSocketFactory = function () {
              const parsedUrl = new URL(url, window?.location?.href)
              if (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') {
                return new SockJS(url) as IStompSocket
              } else if (parsedUrl.protocol === 'ws:' || parsedUrl.protocol === 'wss:') {
                return new WebSocket(url) as IStompSocket
              } else throw new Error('Protocol not supported')
            }
          }
          _client.onConnect = function (frame) {
            if (stompOptions.onConnect) {
              stompOptions.onConnect(frame)
            }

            subscriptionRequests.current.forEach(value => {
              value.subscription = _client.subscribe(
                value.destination,
                value.callback,
                value.headers
              )
            })

            setClient(_client)
          }
          _client.onWebSocketClose = function (event) {
            if (stompOptions.onWebSocketClose) stompOptions.onWebSocketClose(event)

            setClient(undefined)
          }

          if (!stompOptions.onStompError) {
            _client.onStompError = function (frame) {
              throw frame
            }
          }

          _client.activate()
        } else {
          _client?.deactivate()
          setClient(undefined)
        }
        return () => {
          _client?.deactivate()
        }
      }, [username, ...Object.values(stompOptions)])

      const subscribe = (
        destination: string,
        callback: messageCallbackType,
        headers: StompHeaders = {}
      ) => {
        const subscriptionId = Math.random().toString(36).substr(2, 9)
        const subscriptionRequest: StompSessionSubscriptionType = {
          destination,
          callback,
          headers,
        }

        subscriptionRequests.current.set(subscriptionId, subscriptionRequest)

        if (client && client.connected) {
          subscriptionRequest.subscription = client.subscribe(destination, callback, headers)
        }

        return () => {
          const subscriptionData = subscriptionRequests.current.get(subscriptionId)

          if (subscriptionData.subscription) {
            subscriptionData.subscription.unsubscribe()
          }

          subscriptionRequests.current.delete(subscriptionId)
        }
      }

      return (
        <StompContext.Provider
          value={{
            client,
            subscribe,
          }}
        >
          {children}
        </StompContext.Provider>
      )
    }
