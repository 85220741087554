import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Button, Grid, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { GENERATOR_INPUT_TYPE, VALUE_TYPE_OPTIONS, VARIABLE_TYPE_OPTIONS } from '@constants'

import { useVariables } from '../../hooks/useVariables'

export const VariableCreateOrEdit = () => {
  const { t } = useTranslation()

  const {
    data: { methods, isDirty, isEdit, isLoadingVariablesById },
    handlers: { handleSave, handleCancel },
  } = useVariables()

  const modelType = isEdit ? 'edit' : 'create'

  return (
    <PageContentLayout>
      <Box sx={{ width: '100%' }}>
        <form>
          <FormProvider {...methods}>
            <Box p={1} pb={0}>
              <Grid container sx={{ pb: 2 }}>
                <Typography variant='h5'>{t(`variables.title.${modelType}`)}</Typography>
              </Grid>
              <FormInput
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                label={t(`variables.label.internalId`)}
                labelSx={{ width: 150 }}
                loading={isLoadingVariablesById}
                name='internalId'
                placeholder={t(`variables.label.internalId`)}
                size='small'
                sx={{ pt: 1 }}
                rules={{
                  required: true,
                  validate: value => value.trim().length !== 0,
                }}
              />
              <FormInput
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                label={t(`variables.label.title`)}
                labelSx={{ width: 150 }}
                loading={isLoadingVariablesById}
                name='title'
                placeholder={t(`variables.label.title`)}
                size='small'
                rules={{
                  required: true,
                  validate: value => value.trim().length !== 0,
                }}
              />
              <FormInput
                autocompleteOptions={VARIABLE_TYPE_OPTIONS}
                inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                label={t(`variables.label.variableType`)}
                labelSx={{ width: 150 }}
                loading={isLoadingVariablesById}
                name='variableType'
                placeholder={t(`variables.label.variableType`)}
                rules={{ required: true }}
                size='small'
              />
              <FormInput
                autocompleteOptions={VALUE_TYPE_OPTIONS}
                inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                label={t(`variables.label.valueType`)}
                labelSx={{ width: 150 }}
                loading={isLoadingVariablesById}
                name='valueType'
                placeholder={t(`variables.label.valueType`)}
                rules={{ required: true }}
                size='small'
              />
            </Box>
            <Grid
              container
              display='flex'
              flexDirection='row'
              justifyContent='flex-end'
              p='15px 5px'
            >
              <Box>
                <Button
                  disabled={!isDirty}
                  sx={{ mr: '15px' }}
                  variant='contained'
                  onClick={handleSave}
                >
                  {t('variables.saveButton')}
                </Button>
                <Button sx={{ mr: '3px' }} variant='outlined' onClick={handleCancel}>
                  {t('variables.cancelButton')}
                </Button>
              </Box>
            </Grid>
          </FormProvider>
        </form>
      </Box>
    </PageContentLayout>
  )
}
