import { FIELD_VALUE_FORMAT } from '@constants'
import { GridRenderCellParams } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'
import { ObjectDataRecord } from '@types'

import { MappedCheckbox } from '@components/MappedCheckbox'

export const renderMappedCheckboxCell = (
  columnName: string,
  valueFormat: FIELD_VALUE_FORMAT,
  params: GridRenderCellParams<unknown, ObjectDataRecord, unknown>
) => {
  const value = params.row[columnName]

  let outerValue = undefined

  if (value === 'false' && valueFormat === FIELD_VALUE_FORMAT.BOOLEAN) {
    outerValue = false
  }

  if (value === 'true' && valueFormat === FIELD_VALUE_FORMAT.BOOLEAN) {
    outerValue = true
  }

  return (
    <Box mt={0.6}>
      <MappedCheckbox
        catchOuterValueChanges
        viewOnly
        outerValue={outerValue ?? value}
        valueFormat={valueFormat}
      />
    </Box>
  )
}
