import { Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowDropDown } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'

import { useKeyPress } from '@hooks'
import { buttonStylesByMode, isNotEmpty } from '@helpers'
import {
  CriteriaType,
  FAVORITES_FILTERS_BUTTON_CLASSNAME,
  HOT_KEY,
  SEARCH_FIELD_TYPE,
  ValueAddType,
} from '@constants'

import { GETCriteriaType, GETSearchType } from '../../../../../../types'
import { transformFiltersToStoredSearchFilters } from '../../helpers'
import { transformFiltersToValuesPOST } from '../../helpers/transformFiltersToPOSTValues'
import { SearchAssistantContext } from '../../SearchAssistant'
import { RelativeType } from '../RelativeDatePicker'
import { RenderCriterias } from '../RenderCriterias'
import { SavedSearchDropdown } from '../SavedSearchDropdown'

type FilterCreateProps = {
  addValue: (value: ValueAddType) => void
  onClean: () => void
  isDirty: boolean
  criterias: GETCriteriaType[]
  pinnedCriterias: CriteriaType[]
  assistantCriterias: CriteriaType[]
  currentCriterias: CriteriaType[]
  onSearch: (search: CriteriaType[]) => void
  setCurrentCriteria: Dispatch<SetStateAction<CriteriaType[]>>
  setPinnedCriterias: Dispatch<SetStateAction<CriteriaType[]>>
}

export const FilterCreate: FC<FilterCreateProps> = ({
  addValue,
  currentCriterias,
  onClean,
  criterias,
  isDirty,
  setCurrentCriteria,
  assistantCriterias,
  pinnedCriterias,
  setPinnedCriterias,
  onSearch,
}) => {
  const { t } = useTranslation()
  const {
    isViewDialogWindow,
    onSearchFilter,
    entityCode,
    searchAssistantId,
    entityObjectCode,
    isDropDownWindowOpen,
  } = useContext(SearchAssistantContext)
  const [open, setOpen] = useState(false)
  const [currentSearch, setCurrentSearch] = useState<GETSearchType | null>(null)

  const filterDisabled = useMemo(() => !entityCode, [entityCode])

  const handleSearch = () => {
    const searchData = transformFiltersToValuesPOST(assistantCriterias)

    assistantCriterias.forEach(data => {
      data.storedValues.forEach(storedValue => {
        const value = storedValue.value

        if (
          isNotEmpty(value) &&
          storedValue.fieldType === SEARCH_FIELD_TYPE.RELATIVE_VALUE &&
          Array.isArray(value) &&
          !value[0]
        ) {
          addValue({
            codeCriteria: data.codeCriteria,
            value: [new Date().toISOString(), ...value.slice(1)] as RelativeType,
            valueId: storedValue.id,
            searchRule: storedValue.searchRule,
          })
        }
      })
    })

    onSearchFilter(searchData)
  }

  const handleSelectSavedSearch = (search: GETSearchType) => {
    setCurrentSearch(search)
    setOpen(false)
  }

  const handleReset = () => {
    setCurrentSearch(null)
    onClean()
  }

  const { keyPressed: startSearch } = useKeyPress(HOT_KEY.ENTER, undefined, isDropDownWindowOpen)
  const { keyPressed: resetFilters } = useKeyPress(HOT_KEY.ESCAPE, undefined, isDropDownWindowOpen)

  useEffect(() => {
    const isSearchData = transformFiltersToValuesPOST(assistantCriterias).length

    if (startSearch && isSearchData) {
      handleSearch()
    }
  }, [startSearch])

  useEffect(() => {
    if (resetFilters && isDirty) {
      onClean()
    }
  }, [resetFilters])

  return (
    <Grid
      container
      bgcolor={theme => theme.palette.background.filter}
      direction={'column'}
      pb={1}
      pr={0}
      px={0.5}
    >
      {pinnedCriterias.length > 0 ? (
        <RenderCriterias
          key={`${currentSearch?.id}_pinned`}
          addValue={addValue}
          criterias={assistantCriterias.filter(({ isPinning }) => isPinning)}
          currentCriterias={pinnedCriterias}
          filterDisabled={filterDisabled}
          setCurrentCriterias={setPinnedCriterias}
          title={t('filter.pinned')}
        />
      ) : null}
      <RenderCriterias
        key={`${currentSearch?.id}_unpinned`}
        addValue={addValue}
        criterias={assistantCriterias.filter(({ isPinning }) => !isPinning)}
        currentCriterias={currentCriterias}
        filterDisabled={filterDisabled}
        options={assistantCriterias.filter(({ isPinning }) => !isPinning)}
        setCurrentCriterias={setCurrentCriteria}
        title={t('filter.chipsDesc')}
      />
      <Grid container item gap={2} justifyContent='flex-end' mt={0.5} pr={3.7}>
        <Grid item flexDirection={'column'} sx={{ position: 'relative' }}>
          <Button
            className={FAVORITES_FILTERS_BUTTON_CLASSNAME}
            endIcon={<ArrowDropDown />}
            sx={theme => buttonStylesByMode(theme, isViewDialogWindow)}
            variant='text'
            onClick={() => setOpen(true)}
          >
            {t('filter.favorites.button')}
          </Button>
          {open && (
            <SavedSearchDropdown
              criteriasDirty={isDirty}
              currentSearchTitle={currentSearch?.title || null}
              newSearch={{
                title: currentSearch?.title || '',
                searchAssistantId: searchAssistantId || '',
                objectCode: entityObjectCode,
                storedSearchFilters: transformFiltersToStoredSearchFilters(assistantCriterias),
              }}
              onClose={() => setOpen(false)}
              onSearch={onSearch}
              onSelected={handleSelectSavedSearch}
            />
          )}
        </Grid>
        <Grid item>
          <Button
            disabled={!isDirty}
            sx={theme => buttonStylesByMode(theme, isViewDialogWindow)}
            variant='text'
            onClick={handleReset}
          >
            {t('filter.reset')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!entityCode}
            sx={theme => buttonStylesByMode(theme, isViewDialogWindow)}
            variant='text'
            onClick={handleSearch}
          >
            {t('filter.search')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
