import { Theme } from '@mui/system'

export const generalFieldStylesByMode = (
  theme: Theme,
  dirtyFields?: Partial<Readonly<{ [x: string]: boolean }>>,
  field?: string,
  updateField?: boolean,
  isDialogWindow?: boolean
) => {
  const isDirty = dirtyFields && field && !!dirtyFields[field]

  return {
    '& > .MuiInputBase-root': {
      color: theme.palette.color.field,
      border: theme.palette.border.field,
      borderRadius: 0,
      paddingLeft: '6px',
      background:
        isDirty || updateField
          ? `${theme.palette.background.fieldUpdated} !important`
          : theme.palette.background.searchAssistantLabel,
      '*::before': {
        borderBottom: 'none !important',
      },
      '& > .MuiInputBase-input': {
        padding: isDialogWindow ? '0px 8px' : '7.5px 8px',
        '-webkit-text-fill-color': theme.palette.color.field,
      },
      '& input:-webkit-autofill': {
        '-webkit-box-shadow': `0 0 0 100px ${theme.palette.background.fieldUpdated} inset`,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '> .Mui-disabled': {
      background: theme.palette.background.fieldDisabled,
    },
    '& > .MuiTypography-root': {
      color: theme.palette.color.default,
    },
    '& .Mui-error': {
      background: `${theme.palette.background.fieldError} !important`,
    },
    '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      paddingY: '0px !important',
      '& .MuiAutocomplete-input': {
        padding: isDialogWindow ? '0px 11px 0px 8px !important' : '7.5px 11px 7.5px 8px !important',
      },
    },
    '.MuiAutocomplete-popupIndicator': {
      padding: isDialogWindow ? 0 : '2px',
    },
  }
}
