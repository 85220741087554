import { FormRow, GETCriteriaValueType } from 'src/types'
import { DEFAULT_FORMAT_DATE, FIELD_VALUE_TYPE } from '@constants'

export const getPlaceholderByField = (field: GETCriteriaValueType | FormRow): string => {
  if (field?.isDefaultPlaceholder) {
    return ''
  }

  if (field?.placeholderValue) {
    return field.placeholderValue
  }

  if (field?.valueType === FIELD_VALUE_TYPE.DATETIME) {
    return field.formatDate || DEFAULT_FORMAT_DATE
  }

  return (field as GETCriteriaValueType)?.name || (field as FormRow)?.title
}
