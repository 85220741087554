import { MouseEvent } from 'react'
import { DEFAULT_HEADER_STYLES_CLASSNAME, PINNED_COLUMN } from '@constants'
// import {
//   GridActionsCellItem,
//   GridCellParams,
//   GridEnrichedColDef,
// } from '@microservices/wiskey-react-components/DataGrid'
import {
  GridActionsCellItem,
  GridCellParams,
  GridEnrichedColDef,
} from '@microservices/wiskey-react-components'
import { ContentCopy, Delete, Edit } from '@mui/icons-material'

type HandlerActionClick = (
  params: GridCellParams<any, any, any>,
  event: MouseEvent<HTMLButtonElement>
) => void

type DataGridActionsColumnParams = {
  onCopy?: HandlerActionClick
  onEdit?: HandlerActionClick
  onDelete?: HandlerActionClick
  columnDef?: GridEnrichedColDef
}

export const generateDataGridActionsColumn = ({
  onCopy,
  onEdit,
  onDelete,
  columnDef,
}: DataGridActionsColumnParams): GridEnrichedColDef => {
  return {
    field: 'actions',
    type: 'actions',
    align: 'right',
    minWidth: onCopy ? 150 : 100,
    resizable: false,
    hideable: false,
    headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
    pinnedColumn: PINNED_COLUMN.RIGHT, // Для промежуточных расчётов. Не влияет на реальное закрепление.
    getActions: (params: GridCellParams) => [
      ...(onCopy
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<ContentCopy />}
              label={'Copy'}
              showInMenu={false}
              onClick={event => onCopy?.(params, event)}
            />,
          ]
        : []),
      <GridActionsCellItem
        key={params.id}
        icon={<Edit />}
        label={'Edit'}
        showInMenu={false}
        onClick={event => onEdit?.(params, event)}
      />,
      <GridActionsCellItem
        key={params.id}
        icon={<Delete />}
        label={'Delete'}
        showInMenu={false}
        onClick={event => onDelete?.(params, event)}
      />,
    ],
    ...columnDef,
  } as GridEnrichedColDef
}
