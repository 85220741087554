import { useEffect, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { getDefaultValueByType } from '@components/DisplayForm/helpers'
import { useCreateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useCreateObjectDataRecordWithFixedKey'
import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'
import { useFormFields } from '@components/DisplayForm/hooks/useFormFields'
import { useUpdateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useUpdateObjectDataRecordWithFixedKey'

import { FORM_TYPE } from '@constants'
import { ObjectDataRecord, Row } from '@types'

type UseFormResetLogicParams = {
  methods: UseFormReturn<Row>
  type: FORM_TYPE
  globalId: string
  formCode: string
}

export const useFormResetLogic = ({
  methods: formMethods,
  type,
  globalId,
  formCode,
}: UseFormResetLogicParams) => {
  const { reset } = formMethods

  const [, { data: fetchedRow }] = useFetchObjectDataWithFixedKey(globalId)

  const formFields = useFormFields({ formCode })

  const [, { isLoading: isLoadingUpdateRow }] = useUpdateObjectDataRecordWithFixedKey(globalId)

  const [, { isLoading: isLoadingCreateRow }] = useCreateObjectDataRecordWithFixedKey(globalId)

  const row = useMemo(() => (type === FORM_TYPE.CREATE ? {} : fetchedRow), [type, fetchedRow])

  const defaultValues: ObjectDataRecord = useMemo(
    () =>
      Object.fromEntries(
        formFields.map(({ code: valueName, valueType }) => {
          return getDefaultValueByType(valueType, valueName, row?.[valueName])
        })
      ),
    [formFields, row]
  )

  useEffect(() => {
    if (type === FORM_TYPE.CREATE) {
      reset(defaultValues)
    }
    if (row) {
      reset({ ...defaultValues, ...row })

      return
    }
  }, [row, defaultValues, type])

  useEffect(() => {
    if ((!isLoadingUpdateRow || !isLoadingCreateRow) && type !== FORM_TYPE.CREATE) {
      reset({ ...defaultValues, ...row })
    }
  }, [row, defaultValues, globalId])

  return { defaultValues }
}
