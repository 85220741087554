import { ColumnParametersType } from 'src/types'
import { PARAMETER_KEY } from '@constants'

export const getColumnParametersFromArray = (parameters: ColumnParametersType) => {
  const headerParam = parameters?.find(
    parameterData => parameterData.paramKey === PARAMETER_KEY.HEADER
  )?.param
  const textParam = parameters?.find(
    parameterData => parameterData.paramKey === PARAMETER_KEY.TEXT
  )?.param

  return {
    headerProperties: headerParam?.properties,
    textProperties: textParam?.properties,
    headerParamId: headerParam?.id,
    textParamId: textParam?.id,
  }
}
