import { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@microservices/wiskey-react-components'
import { Grid } from '@mui/material'

import { Buttons } from './components/Buttons'
import { CommonParameters } from './components/CommonParameters'
import { VisualParameters } from './components/VisualParameters'
import { useParameters } from './hooks'
import { ParametersContextType } from './types'

export const ParametersContext = createContext<ParametersContextType>({} as ParametersContextType)

export const Parameters = () => {
  const { t } = useTranslation()

  const { handlers, state, data } = useParameters()

  const { methods, isEdit, isShowResetConfirmModal, isDirtyVisualParameters } = state

  const {
    handleEditOrSave,
    handleCancelEdit,
    handleSubmitParameters,
    handleSetShowResetConfirmModal,
    handleResetParams,
    handleResetCommonParams,
    handleClickResetVisualParameters,
    handleHeaderExternalDirty,
    handleTextExternalDirty,
  } = handlers
  const {
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialGlobalColumnHeaderStyles,
    initialGlobalColumnTextStyles,
    initialCommonParametersData,
    initialCommonParameters,
  } = data

  return (
    <ParametersContext.Provider
      value={{
        isEdit,
        methods,
        initialCommonParametersData,
        initialCommonParameters,
        columnHeaderSettingsRef,
        columnTextSettingsRef,
        initialGlobalColumnHeaderStyles,
        initialGlobalColumnTextStyles,
        dirtyVisualParameters: isDirtyVisualParameters,
        onEditOrSave: handleEditOrSave,
        onCancelEdit: handleCancelEdit,
        onSubmitParameters: handleSubmitParameters,
        onSetShowResetConfirmModal: handleSetShowResetConfirmModal,
        onResetCommonParams: handleResetCommonParams,
        onClickResetVisualParameters: handleClickResetVisualParameters,
        onHeaderExternalDirty: handleHeaderExternalDirty,
        onTextExternalDirty: handleTextExternalDirty,
      }}
    >
      <Grid container flexDirection={'column'} p={1} pt={0}>
        <ConfirmModal
          actionBtnText={t('parameters.resetModal.actionBtnText')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={isShowResetConfirmModal}
          text={t('parameters.resetModal.text')}
          title={t('parameters.resetModal.title')}
          onClose={() => handleSetShowResetConfirmModal(false)}
          onConfirm={handleResetParams}
        />
        <CommonParameters />
        <VisualParameters />
        <Buttons />
      </Grid>
    </ParametersContext.Provider>
  )
}
