import { FC, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { URLS } from '@constants'

import { ErrorFallback } from '@components/ErrorFallback'
import { SnackMessage } from '@components/SnackMessage'

import { store } from '@redux/store'

import { AuthProvider } from '@providers/AuthProvider'
import { ThemeProvider } from '@providers/ThemeProvider'

import { DialogWindowManager } from '@features/DialogWindowManager'
import { GlobalUIOverlay } from '@features/GlobalUIOverlay'

import '../i18n'

import { StompSessionProvider } from './StompSessionProvider/StompSessionProvider'
import { AppRoutes } from './AppRoutes'

const App: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback='loading'>
        <Provider store={store}>
          <AuthProvider>
            <StompSessionProvider url={URLS.WS_URL}>
              <ThemeProvider>
                <BrowserRouter>
                  <GlobalUIOverlay />
                  <DialogWindowManager />
                  <SnackMessage />
                  <AppRoutes />
                </BrowserRouter>
              </ThemeProvider>
            </StompSessionProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  )
}

export default App
