import AdocBlack from '@assets/images/additional/adoc-black.ico'
import AirportMap from '@assets/images/additional/airport-map.ico'
import AlignBottomCenter from '@assets/images/additional/align-bottom-center.ico'
import AlignBottomLeft from '@assets/images/additional/align-bottom-left.ico'
import AlignBottomRight from '@assets/images/additional/align-bottom-right.ico'
import AlignMiddleCenter from '@assets/images/additional/align-middle-center.ico'
import AlignMiddleLeft from '@assets/images/additional/align-middle-left.ico'
import AlignMiddleRight from '@assets/images/additional/align-middle-right.ico'
import AlignTopCenter from '@assets/images/additional/align-top-center.ico'
import AlignTopLeft from '@assets/images/additional/align-top-left.ico'
import AlignTopRight from '@assets/images/additional/align-top-right.ico'
import Archive from '@assets/images/additional/archive.ico'
import ArchiveDelete from '@assets/images/additional/archive-delete.ico'
import ArrowDownGray from '@assets/images/additional/arrow-down-gray.ico'
import ArrowDownRed from '@assets/images/additional/arrow-down-red.ico'
import ArrowLeft from '@assets/images/additional/arrow-left.ico'
import ArrowLeftRight from '@assets/images/additional/arrow-left-right.ico'
import ArrowRight from '@assets/images/additional/arrow-right.ico'
import ArrowUpGray from '@assets/images/additional/arrow-up-gray.ico'
import ArrowUpGreen from '@assets/images/additional/arrow-up-green.ico'
import BalloonInfo from '@assets/images/additional/balloon-info.ico'
import BHMPgButtonNormal from '@assets/images/additional/bhm-pg-button-normal.ico'
import BHMPgButtonPressed from '@assets/images/additional/bhm-pg-button-pressed.ico'
import BHMTimeButtonNormal from '@assets/images/additional/bhm-time-button-normal.ico'
import BHMTimeButtonPressed from '@assets/images/additional/bhm-time-button-pressed.ico'
import BHMWorkingModeButtonDependencies from '@assets/images/additional/bhm-working-mode-button-dependencies.ico'
import BHMWorkingModeButtonMilestones from '@assets/images/additional/bhm-working-mode-button-milestones.ico'
import BHMWorkingModeButtonNormal from '@assets/images/additional/bhm-working-mode-button-normal.ico'
import BoxBlack from '@assets/images/additional/box-black.ico'
import BoxGrey from '@assets/images/additional/box-grey.ico'
import Calendar from '@assets/images/additional/calendar.ico'
import CheckButtonNone from '@assets/images/additional/check-button-none.ico'
import CheckButtonOff from '@assets/images/additional/check-button-off.ico'
import CheckButtonOn from '@assets/images/additional/check-button-on.ico'
import CheckIn from '@assets/images/additional/check-in.ico'
import CheckOut from '@assets/images/additional/check-out.ico'
import CircleUserIcon from '@assets/images/additional/circle-user-icon.ico'
import CircleUserIconForCounter from '@assets/images/additional/circle-user-icon-for-counter.ico'
import Clock from '@assets/images/additional/clock.ico'
import ClockBig from '@assets/images/additional/clock-big.ico'
import ComputeCurve from '@assets/images/additional/compute-curve.ico'
import ConvertDown from '@assets/images/additional/convert-down.ico'
import ConvertUp from '@assets/images/additional/convert-up.ico'
import Copy from '@assets/images/additional/copy.ico'
import Cup from '@assets/images/additional/cup.ico'
import CupBlack from '@assets/images/additional/cup-black.png'
import CupGrey from '@assets/images/additional/cup-grey.ico'
import Defragment from '@assets/images/additional/defragment.ico'
import Delete from '@assets/images/additional/delete.ico'
import DependencyWarning from '@assets/images/additional/dependency-warning.ico'
import Device from '@assets/images/additional/device.ico'
import DllBig from '@assets/images/additional/dll-big.ico'
import DollyBalancingBlack from '@assets/images/additional/dolly-balancing-black.ico'
import DollyBalancingGrey from '@assets/images/additional/dolly-balancing-grey.ico'
import Edit from '@assets/images/additional/edit.ico'
import EditText from '@assets/images/additional/edit-text.ico'
import EqualSign from '@assets/images/additional/equal-sign.ico'
import Equipment from '@assets/images/additional/equipment.ico'
import Error from '@assets/images/additional/error.ico'
import ErrorBig from '@assets/images/additional/error-big.ico'
import ErrorWarning from '@assets/images/additional/error-warning.ico'
import FaceSad from '@assets/images/additional/face-sad.ico'
import FaceSmirk from '@assets/images/additional/face-smirk.ico'
import FaceUncertain from '@assets/images/additional/face-uncertain.ico'
import Favorite from '@assets/images/additional/favorite.ico'
import Folder from '@assets/images/additional/folder.ico'
import Forward from '@assets/images/additional/forward.ico'
import Hand from '@assets/images/additional/hand.ico'
import Handshake from '@assets/images/additional/handshake.ico'
import HideAllRight from '@assets/images/additional/hide-all-right.png'
import HideRight from '@assets/images/additional/hide-right.png'
import Home from '@assets/images/additional/home.png'
import Inbound from '@assets/images/additional/inbound.png'
import InboundBlack from '@assets/images/additional/inbound-black.ico'
import InboundGrey from '@assets/images/additional/inbound-grey.ico'
import Info from '@assets/images/additional/info.ico'
import InfoAlternate from '@assets/images/additional/info-alternate.ico'
import Join from '@assets/images/additional/join.ico'
import KeysBig from '@assets/images/additional/keys-big.ico'
import ListHeaderAcknowledge from '@assets/images/additional/list-header-acknowledge.ico'
import ListHeaderExclamation from '@assets/images/additional/list-header-exclamation.ico'
import LoadBlack from '@assets/images/additional/load-black.ico'
import LoadBlackAlternate from '@assets/images/additional/load-black-alternate.png'
import LoadGrey from '@assets/images/additional/load-grey.ico'
import Lock from '@assets/images/additional/lock.ico'
import Login from '@assets/images/additional/login.bmp'
import Mail from '@assets/images/additional/mail.ico'
import MessageList from '@assets/images/additional/message-list.ico'
import MessageNotRead from '@assets/images/additional/message-not-read.ico'
import MessageRead from '@assets/images/additional/message-read.ico'
import Net from '@assets/images/additional/net.ico'
import New from '@assets/images/additional/new.ico'
import NoError from '@assets/images/additional/no-error.ico'
import NoFixate from '@assets/images/additional/no-fixate.ico'
import NonFavorite from '@assets/images/additional/non-favorite.ico'
import Outbound from '@assets/images/additional/outbound.png'
import OutboundBlack from '@assets/images/additional/outbound-black.ico'
import OutboundGrey from '@assets/images/additional/outbound-grey.ico'
import OverTimeAfter from '@assets/images/additional/over-time-after.ico'
import OverTimeBefore from '@assets/images/additional/over-time-before.ico'
import OverlayStateBlue from '@assets/images/additional/overlay-state-blue.ico'
import OverlayStateGray from '@assets/images/additional/overlay-state-gray.ico'
import OverlayStateGreen from '@assets/images/additional/overlay-state-green.ico'
import OverlayStateMultipleColors from '@assets/images/additional/overlay-state-multiple-colors.ico'
import OverlayStateRed from '@assets/images/additional/overlay-state-red.ico'
import OverlayStateWhite from '@assets/images/additional/overlay-state-white.ico'
import OverlayStateYellow from '@assets/images/additional/overlay-state-yellow.ico'
import Paste from '@assets/images/additional/paste.ico'
import PeopleFlat from '@assets/images/additional/people-flat.ico'
import Preferences from '@assets/images/additional/preferences.ico'
import Print from '@assets/images/additional/print.ico'
import Printer from '@assets/images/additional/printer.ico'
import ProfilerRecord from '@assets/images/additional/profiler-record.ico'
import ProfilerSave from '@assets/images/additional/profiler-save.ico'
import RadioButtonOff from '@assets/images/additional/radio-button-off.ico'
import RadioButtonOn from '@assets/images/additional/radio-button-on.ico'
import RedClock from '@assets/images/additional/red-clock.ico'
import Refresh from '@assets/images/additional/refresh.ico'
import RefreshWarningBlack from '@assets/images/additional/refresh-warning-black.ico'
import ResourceFixate from '@assets/images/additional/resource-fixate.ico'
import RightsError from '@assets/images/additional/rights-error.ico'
import RightsErrorGrayed from '@assets/images/additional/rights-error-grayed.ico'
import Save from '@assets/images/additional/save.ico'
import ScanBoardingCard from '@assets/images/additional/scan-boarding-card.ico'
import Screen from '@assets/images/additional/screen.ico'
import Send from '@assets/images/additional/send.ico'
import ServerError from '@assets/images/additional/server-error.ico'
import ServerStateGreen from '@assets/images/additional/server-state-green.ico'
import ServerStateRed from '@assets/images/additional/server-state-red.ico'
import ServerStateYellow from '@assets/images/additional/server-state-yellow.ico'
import ShowAllLeft from '@assets/images/additional/show-all-left.png'
import ShowLeft from '@assets/images/additional/show-left.png'
import Split from '@assets/images/additional/split.ico'
import Staff from '@assets/images/additional/staff.ico'
import StaffsBig from '@assets/images/additional/staffs-big.ico'
import StateAvail from '@assets/images/additional/state-avail.ico'
import StateFixedAvail from '@assets/images/additional/state-fixed-avail.ico'
import StateFixedInUse from '@assets/images/additional/state-fixed-in-use.ico'
import StateFixedNotAvail from '@assets/images/additional/state-fixed-not-avail.ico'
import StateInUse from '@assets/images/additional/state-in-use.ico'
import StateNone from '@assets/images/additional/state-none.ico'
import StateNotAvail from '@assets/images/additional/state-not-avail.ico'
import StateUnknown from '@assets/images/additional/state-unknown.ico'
import SwitchRelation from '@assets/images/additional/switch-relation.ico'
import SystemMessageErrorBlue from '@assets/images/additional/system-message-error-blue.ico'
import SystemMessageNormal from '@assets/images/additional/system-message-normal.ico'
import SystemMessageNormalBlue from '@assets/images/additional/system-message-normal-blue.ico'
import SystemMessageUnread from '@assets/images/additional/system-message-unread.ico'
import SystemMessageWarningBlue from '@assets/images/additional/system-message-warning-blue.ico'
import TableSheet from '@assets/images/additional/table-sheet.png'
import TaskBlack from '@assets/images/additional/task-black.ico'
import TaskGrey from '@assets/images/additional/task-grey.ico'
import TaskShiftConflictResolutionAllowAutomatic from '@assets/images/additional/task-shift-conflict-resolution-allow-automatic.ico'
import TaskShiftConflictResolutionAllowConfirm from '@assets/images/additional/task-shift-conflict-resolution-allow-confirm.ico'
import TaskShiftConflictResolutionDeny from '@assets/images/additional/task-shift-conflict-resolution-deny.ico'
import TaskShiftConflictResolutionSelectionDialog from '@assets/images/additional/task-shift-conflict-resolution-selection-dialog.ico'
import TaskShiftConflictResolutionSplitAutomatic from '@assets/images/additional/task-shift-conflict-resolution-split-automatic.ico'
import TaskShiftConflictResolutionSplitConfirm from '@assets/images/additional/task-shift-conflict-resolution-split-confirm.ico'
import TbMinibarFlight from '@assets/images/additional/tb-minibar-flight.png'
import Team from '@assets/images/additional/team.ico'
import TimeFixate from '@assets/images/additional/time-fixate.ico'
import Tip from '@assets/images/additional/tip.ico'
import ToggleLeft from '@assets/images/additional/toggle-left.ico'
import ToggleRight from '@assets/images/additional/toggle-right.ico'
import ToolbarAdditionalButtons from '@assets/images/additional/toolbar-additional-buttons.png'
import ToolbarMenuItems from '@assets/images/additional/toolbar-menu-items.png'
import TotalFixate from '@assets/images/additional/total-fixate.ico'
import TourEditor from '@assets/images/additional/tour-editor.ico'
import TourEditorPrm from '@assets/images/additional/tour-editor-prm.ico'
import TourEditorVip from '@assets/images/additional/tour-editor-vip.ico'
import TrayPlus from '@assets/images/additional/tray-plus.ico'
import TruckBlack from '@assets/images/additional/truck-black.ico'
import TruckGrey from '@assets/images/additional/truck-grey.ico'
import Undo from '@assets/images/additional/undo.ico'
import UnloadBlack from '@assets/images/additional/unload-black.ico'
import UnloadBlackAlternate from '@assets/images/additional/unload-black-alternate.png'
import UnloadGrey from '@assets/images/additional/unload-grey.ico'
import Unlock from '@assets/images/additional/unlock.ico'
import Warning from '@assets/images/additional/warning.ico'
import WarningBlack from '@assets/images/additional/warning-black.ico'
import WarningGrey from '@assets/images/additional/warning-grey.ico'

import { IconType } from '@components/AppBar/components/IconSection'

export const ADDITIONAL_ICONS: Record<string, IconType> = {
  AdocBlack: {
    name: 'AdocBlack',
    src: AdocBlack,
  },
  AirportMap: {
    name: 'AirportMap',
    src: AirportMap,
  },
  AlignBottomCenter: {
    name: 'AlignBottomCenter',
    src: AlignBottomCenter,
  },
  AlignBottomLeft: {
    name: 'AlignBottomLeft',
    src: AlignBottomLeft,
  },
  AlignBottomRight: {
    name: 'AlignBottomRight',
    src: AlignBottomRight,
  },
  AlignMiddleCenter: {
    name: 'AlignMiddleCenter',
    src: AlignMiddleCenter,
  },
  AlignMiddleLeft: {
    name: 'AlignMiddleLeft',
    src: AlignMiddleLeft,
  },
  AlignMiddleRight: {
    name: 'AlignMiddleRight',
    src: AlignMiddleRight,
  },
  AlignTopCenter: {
    name: 'AlignTopCenter',
    src: AlignTopCenter,
  },
  AlignTopLeft: {
    name: 'AlignTopLeft',
    src: AlignTopLeft,
  },
  AlignTopRight: {
    name: 'AlignTopRight',
    src: AlignTopRight,
  },
  Archive: {
    name: 'Archive',
    src: Archive,
  },
  ArchiveDelete: {
    name: 'ArchiveDelete',
    src: ArchiveDelete,
  },
  ArrowDownGray: {
    name: 'ArrowDownGray',
    src: ArrowDownGray,
  },
  ArrowDownRed: {
    name: 'ArrowDownRed',
    src: ArrowDownRed,
  },
  ArrowLeft: {
    name: 'ArrowLeft',
    src: ArrowLeft,
  },
  ArrowLeftRight: {
    name: 'ArrowLeftRight',
    src: ArrowLeftRight,
  },
  ArrowRight: {
    name: 'ArrowRight',
    src: ArrowRight,
  },
  ArrowUpGray: {
    name: 'ArrowUpGray',
    src: ArrowUpGray,
  },
  ArrowUpGreen: {
    name: 'ArrowUpGreen',
    src: ArrowUpGreen,
  },
  BalloonInfo: {
    name: 'BalloonInfo',
    src: BalloonInfo,
  },
  BHMPgButtonNormal: {
    name: 'BHMPgButtonNormal',
    src: BHMPgButtonNormal,
  },
  BHMPgButtonPressed: {
    name: 'BHMPgButtonPressed',
    src: BHMPgButtonPressed,
  },
  BHMTimeButtonNormal: {
    name: 'BHMTimeButtonNormal',
    src: BHMTimeButtonNormal,
  },
  BHMTimeButtonPressed: {
    name: 'BHMTimeButtonPressed',
    src: BHMTimeButtonPressed,
  },
  BHMWorkingModeButtonDependencies: {
    name: 'BHMWorkingModeButtonDependencies',
    src: BHMWorkingModeButtonDependencies,
  },
  BHMWorkingModeButtonMilestones: {
    name: 'BHMWorkingModeButtonMilestones',
    src: BHMWorkingModeButtonMilestones,
  },
  BHMWorkingModeButtonNormal: {
    name: 'BHMWorkingModeButtonNormal',
    src: BHMWorkingModeButtonNormal,
  },
  BoxBlack: {
    name: 'BoxBlack',
    src: BoxBlack,
  },
  BoxGrey: {
    name: 'BoxGrey',
    src: BoxGrey,
  },
  Calendar: {
    name: 'Calendar',
    src: Calendar,
  },
  CheckButtonNone: {
    name: 'CheckButtonNone',
    src: CheckButtonNone,
  },
  CheckButtonOff: {
    name: 'CheckButtonOff',
    src: CheckButtonOff,
  },
  CheckButtonOn: {
    name: 'CheckButtonOn',
    src: CheckButtonOn,
  },
  CheckIn: {
    name: 'CheckIn',
    src: CheckIn,
  },
  CheckOut: {
    name: '',
    src: CheckOut,
  },
  CircleUserIcon: {
    name: 'CircleUserIcon',
    src: CircleUserIcon,
  },
  CircleUserIconForCounter: {
    name: 'CircleUserIconForCounter',
    src: CircleUserIconForCounter,
  },
  Clock: {
    name: 'Clock',
    src: Clock,
  },
  ClockBig: {
    name: 'ClockBig',
    src: ClockBig,
  },
  ComputeCurve: {
    name: 'ComputeCurve',
    src: ComputeCurve,
  },
  ConvertDown: {
    name: 'ConvertDown',
    src: ConvertDown,
  },
  ConvertUp: {
    name: 'ConvertUp',
    src: ConvertUp,
  },
  Copy: {
    name: 'Copy',
    src: Copy,
  },
  Cup: {
    name: 'Cup',
    src: Cup,
  },
  CupBlack: {
    name: 'CupBlack',
    src: CupBlack,
  },
  CupGrey: {
    name: 'CupGrey',
    src: CupGrey,
  },
  Defragment: {
    name: 'Defragment',
    src: Defragment,
  },
  Delete: {
    name: 'Delete',
    src: Delete,
  },
  DependencyWarning: {
    name: 'DependencyWarning',
    src: DependencyWarning,
  },
  Device: {
    name: 'Device',
    src: Device,
  },
  DllBig: {
    name: 'DllBig',
    src: DllBig,
  },
  DollyBalancingBlack: {
    name: 'DollyBalancingBlack',
    src: DollyBalancingBlack,
  },
  DollyBalancingGrey: {
    name: 'DollyBalancingGrey',
    src: DollyBalancingGrey,
  },
  Edit: {
    name: 'Edit',
    src: Edit,
  },
  EditText: {
    name: 'EditText',
    src: EditText,
  },
  EqualSign: {
    name: 'EqualSign',
    src: EqualSign,
  },
  Equipment: {
    name: 'Equipment',
    src: Equipment,
  },
  Error: {
    name: 'Error',
    src: Error,
  },
  ErrorBig: {
    name: 'ErrorBig',
    src: ErrorBig,
  },
  ErrorWarning: {
    name: 'ErrorWarning',
    src: ErrorWarning,
  },
  FaceSad: {
    name: 'FaceSad',
    src: FaceSad,
  },
  FaceSmirk: {
    name: 'FaceSmirk',
    src: FaceSmirk,
  },
  FaceUncertain: {
    name: 'FaceUncertain',
    src: FaceUncertain,
  },
  Favorite: {
    name: 'Favorite',
    src: Favorite,
  },
  Folder: {
    name: 'Folder',
    src: Folder,
  },
  Forward: {
    name: 'Forward',
    src: Forward,
  },
  Hand: {
    name: 'Hand',
    src: Hand,
  },
  Handshake: {
    name: 'Handshake',
    src: Handshake,
  },
  HideAllRight: {
    name: 'HideAllRight',
    src: HideAllRight,
  },
  HideRight: {
    name: 'HideRight',
    src: HideRight,
  },
  Home: {
    name: 'Home',
    src: Home,
  },
  Inbound: {
    name: 'Inbound',
    src: Inbound,
  },
  InboundBlack: {
    name: 'InboundBlack',
    src: InboundBlack,
  },
  InboundGrey: {
    name: 'InboundGrey',
    src: InboundGrey,
  },
  Info: {
    name: 'Info',
    src: Info,
  },
  InfoAlternate: {
    name: 'InfoAlternate',
    src: InfoAlternate,
  },
  Join: {
    name: 'Join',
    src: Join,
  },
  KeysBig: {
    name: 'KeysBig',
    src: KeysBig,
  },
  ListHeaderAcknowledge: {
    name: 'ListHeaderAcknowledge',
    src: ListHeaderAcknowledge,
  },
  ListHeaderExclamation: {
    name: 'ListHeaderExclamation',
    src: ListHeaderExclamation,
  },
  LoadBlack: {
    name: 'LoadBlack',
    src: LoadBlack,
  },
  LoadBlackAlternate: {
    name: 'LoadBlackAlternate',
    src: LoadBlackAlternate,
  },
  LoadGrey: {
    name: 'LoadGrey',
    src: LoadGrey,
  },
  Lock: {
    name: 'Lock',
    src: Lock,
  },
  Login: {
    name: 'Login',
    src: Login,
  },
  Mail: {
    name: 'Mail',
    src: Mail,
  },
  MessageList: {
    name: 'MessageList',
    src: MessageList,
  },
  MessageNotRead: {
    name: 'MessageNotRead',
    src: MessageNotRead,
  },
  MessageRead: {
    name: 'MessageRead',
    src: MessageRead,
  },
  Net: {
    name: 'Net',
    src: Net,
  },
  New: {
    name: 'New',
    src: New,
  },
  NoError: {
    name: 'NoError',
    src: NoError,
  },
  NoFixate: {
    name: 'NoFixate',
    src: NoFixate,
  },
  NonFavorite: {
    name: 'NonFavorite',
    src: NonFavorite,
  },
  Outbound: {
    name: 'Outbound',
    src: Outbound,
  },
  OutboundBlack: {
    name: 'OutboundBlack',
    src: OutboundBlack,
  },
  OutboundGrey: {
    name: 'OutboundGrey',
    src: OutboundGrey,
  },
  OverlayStateBlue: {
    name: 'OverlayStateBlue',
    src: OverlayStateBlue,
  },
  OverlayStateGray: {
    name: 'OverlayStateGray',
    src: OverlayStateGray,
  },
  OverlayStateGreen: {
    name: 'OverlayStateGreen',
    src: OverlayStateGreen,
  },
  OverlayStateMultipleColors: {
    name: 'OverlayStateMultipleColors',
    src: OverlayStateMultipleColors,
  },
  OverlayStateRed: {
    name: 'OverlayStateRed',
    src: OverlayStateRed,
  },
  OverlayStateWhite: {
    name: 'OverlayStateWhite',
    src: OverlayStateWhite,
  },
  OverlayStateYellow: {
    name: 'OverlayStateYellow',
    src: OverlayStateYellow,
  },
  OverTimeAfter: {
    name: 'OverTimeAfter',
    src: OverTimeAfter,
  },
  OverTimeBefore: {
    name: 'OverTimeBefore',
    src: OverTimeBefore,
  },
  Paste: {
    name: 'Paste',
    src: Paste,
  },
  PeopleFlat: {
    name: 'PeopleFlat',
    src: PeopleFlat,
  },
  Preferences: {
    name: 'Preferences',
    src: Preferences,
  },
  Print: {
    name: 'Print',
    src: Print,
  },
  Printer: {
    name: 'Printer',
    src: Printer,
  },
  ProfilerRecord: {
    name: 'ProfilerRecord',
    src: ProfilerRecord,
  },
  ProfilerSave: {
    name: 'ProfilerSave',
    src: ProfilerSave,
  },
  RadioButtonOff: {
    name: 'RadioButtonOff',
    src: RadioButtonOff,
  },
  RadioButtonOn: {
    name: 'RadioButtonOn',
    src: RadioButtonOn,
  },
  RedClock: {
    name: 'RedClock',
    src: RedClock,
  },
  Refresh: {
    name: 'Refresh',
    src: Refresh,
  },
  RefreshWarningBlack: {
    name: 'RefreshWarningBlack',
    src: RefreshWarningBlack,
  },
  ResourceFixate: {
    name: 'ResourceFixate',
    src: ResourceFixate,
  },
  RightsError: {
    name: 'RightsError',
    src: RightsError,
  },
  RightsErrorGrayed: {
    name: 'RightsErrorGrayed',
    src: RightsErrorGrayed,
  },
  Save: {
    name: 'Save',
    src: Save,
  },
  ScanBoardingCard: {
    name: 'ScanBoardingCard',
    src: ScanBoardingCard,
  },
  Screen: {
    name: 'Screen',
    src: Screen,
  },
  Send: {
    name: 'Send',
    src: Send,
  },
  ServerError: {
    name: 'ServerError',
    src: ServerError,
  },
  ServerStateGreen: {
    name: 'ServerStateGreen',
    src: ServerStateGreen,
  },
  ServerStateRed: {
    name: 'ServerStateRed',
    src: ServerStateRed,
  },
  ServerStateYellow: {
    name: 'ServerStateYellow',
    src: ServerStateYellow,
  },
  ShowAllLeft: {
    name: 'ShowAllLeft',
    src: ShowAllLeft,
  },
  ShowLeft: {
    name: 'ShowLeft',
    src: ShowLeft,
  },
  Split: {
    name: 'Split',
    src: Split,
  },
  Staff: {
    name: 'Staff',
    src: Staff,
  },
  StaffsBig: {
    name: 'StaffsBig',
    src: StaffsBig,
  },
  StateAvail: {
    name: 'StateAvail',
    src: StateAvail,
  },
  StateFixedAvail: {
    name: 'StateFixedAvail',
    src: StateFixedAvail,
  },
  StateFixedInUse: {
    name: 'StateFixedInUse',
    src: StateFixedInUse,
  },
  StateFixedNotAvail: {
    name: 'StateFixedNotAvail',
    src: StateFixedNotAvail,
  },
  StateInUse: {
    name: 'StateInUse',
    src: StateInUse,
  },
  StateNone: {
    name: 'StateNone',
    src: StateNone,
  },
  StateNotAvail: {
    name: 'StateNotAvail',
    src: StateNotAvail,
  },
  StateUnknown: {
    name: 'StateUnknown',
    src: StateUnknown,
  },
  SwitchRelation: {
    name: 'SwitchRelation',
    src: SwitchRelation,
  },
  SystemMessageErrorBlue: {
    name: 'SystemMessageErrorBlue',
    src: SystemMessageErrorBlue,
  },
  SystemMessageNormal: {
    name: 'SystemMessageNormal',
    src: SystemMessageNormal,
  },
  SystemMessageNormalBlue: {
    name: 'SystemMessageNormalBlue',
    src: SystemMessageNormalBlue,
  },
  SystemMessageUnread: {
    name: 'SystemMessageUnread',
    src: SystemMessageUnread,
  },
  SystemMessageWarningBlue: {
    name: 'SystemMessageWarningBlue',
    src: SystemMessageWarningBlue,
  },
  TableSheet: {
    name: 'TableSheet',
    src: TableSheet,
  },
  TaskBlack: {
    name: 'TaskBlack',
    src: TaskBlack,
  },
  TaskGrey: {
    name: 'TaskGrey',
    src: TaskGrey,
  },
  TaskShiftConflictResolutionAllowAutomatic: {
    name: 'TaskShiftConflictResolutionAllowAutomatic',
    src: TaskShiftConflictResolutionAllowAutomatic,
  },
  TaskShiftConflictResolutionAllowConfirm: {
    name: 'TaskShiftConflictResolutionAllowConfirm',
    src: TaskShiftConflictResolutionAllowConfirm,
  },
  TaskShiftConflictResolutionDeny: {
    name: 'TaskShiftConflictResolutionDeny',
    src: TaskShiftConflictResolutionDeny,
  },
  TaskShiftConflictResolutionSelectionDialog: {
    name: 'TaskShiftConflictResolutionSelectionDialog',
    src: TaskShiftConflictResolutionSelectionDialog,
  },
  TaskShiftConflictResolutionSplitAutomatic: {
    name: 'TaskShiftConflictResolutionSplitAutomatic',
    src: TaskShiftConflictResolutionSplitAutomatic,
  },
  TaskShiftConflictResolutionSplitConfirm: {
    name: 'TaskShiftConflictResolutionSplitConfirm',
    src: TaskShiftConflictResolutionSplitConfirm,
  },
  TbMinibarFlight: {
    name: 'TbMinibarFlight',
    src: TbMinibarFlight,
  },
  Team: {
    name: 'Team',
    src: Team,
  },
  TimeFixate: {
    name: 'TimeFixate',
    src: TimeFixate,
  },
  Tip: {
    name: 'Tip',
    src: Tip,
  },
  ToggleLeft: {
    name: 'ToggleLeft',
    src: ToggleLeft,
  },
  ToggleRight: {
    name: 'ToggleRight',
    src: ToggleRight,
  },
  ToolbarAdditionalButtons: {
    name: 'ToolbarAdditionalButtons',
    src: ToolbarAdditionalButtons,
  },
  ToolbarMenuItems: {
    name: 'ToolbarMenuItems',
    src: ToolbarMenuItems,
  },
  TotalFixate: {
    name: 'TotalFixate',
    src: TotalFixate,
  },
  TourEditor: {
    name: 'TourEditor',
    src: TourEditor,
  },
  TourEditorPrm: {
    name: 'TourEditorPrm',
    src: TourEditorPrm,
  },
  TourEditorVip: {
    name: 'TourEditorVip',
    src: TourEditorVip,
  },
  TrayPlus: {
    name: 'TrayPlus',
    src: TrayPlus,
  },
  TruckBlack: {
    name: 'TruckBlack',
    src: TruckBlack,
  },
  TruckGrey: {
    name: 'TruckGrey',
    src: TruckGrey,
  },
  Undo: {
    name: 'Undo',
    src: Undo,
  },
  UnloadBlack: {
    name: 'UnloadBlack',
    src: UnloadBlack,
  },
  UnloadBlackAlternate: {
    name: 'UnloadBlackAlternate',
    src: UnloadBlackAlternate,
  },
  UnloadGrey: {
    name: 'UnloadGrey',
    src: UnloadGrey,
  },
  Unlock: {
    name: 'Unlock',
    src: Unlock,
  },
  Warning: {
    name: 'Warning',
    src: Warning,
  },
  WarningBlack: {
    name: 'WarningBlack',
    src: WarningBlack,
  },
  WarningGrey: {
    name: 'WarningGrey',
    src: WarningGrey,
  },
}
