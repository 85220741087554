export const VARIABLE_TYPE_OPTIONS = [
  {
    id: 'multiple',
    label: 'multiple',
  },
]

export const VALUE_TYPE_OPTIONS = [
  {
    id: 'string',
    label: 'string',
  },
]
