import { DialogUIOverlayData } from '@types'

export const getHighestZIndexPriority = (dialogTreeData: Record<string, DialogUIOverlayData>) => {
  let highestZIndexPriority = 1

  Object.keys(dialogTreeData).forEach(key => {
    if (dialogTreeData[key].zIndexPriority > highestZIndexPriority) {
      highestZIndexPriority = dialogTreeData[key].zIndexPriority
    }
  })

  return highestZIndexPriority
}
