import { FC, useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CONFIG_COLUMNS, GENERATOR_INPUT_TYPE, MODAL_TYPE } from '@constants'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Box, Grid } from '@mui/material'
import { AutocompleteOption } from '@types'

import { PageContext } from '@pages/ConfigForms'

import { EntityOrFormList } from '@components/EntityOrFormList'

type FormValues = {
  searchPattern: string
  objectCode: AutocompleteOption | null
}

const defaultValues = {
  searchPattern: '',
  objectCode: null,
}

export const AllForms: FC = () => {
  const methods = useForm<FormValues>({ defaultValues })
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    formTables,
    formsIsLoading,
    onDeleteForm,
    onShowDialog,
    onEditForm,
    onSetSorting,
    currentSort,
    objects,
    onSetObjectCode,
    onSetSearchPattern,
  } = useContext(PageContext)

  const { watch } = methods
  const watchObjectCode = watch('objectCode')
  const watchSearchPattern = watch('searchPattern')

  useEffect(() => {
    onSetObjectCode(watchObjectCode !== null ? (watchObjectCode.id as string) : '')
  }, [watchObjectCode])

  useEffect(() => {
    onSetSearchPattern(watchSearchPattern)
  }, [watchSearchPattern])

  return (
    <>
      <Grid container item alignItems='center' flexWrap='nowrap' gap={1}>
        <Grid item>
          <Button sx={{ mb: 1 }} variant={'contained'} onClick={() => navigate(MODAL_TYPE.CREATE)}>
            {t(`configViews.add.forms`)}
          </Button>
        </Grid>
        <Grid item flexGrow={1}>
          <FormProvider {...methods}>
            <form>
              <Box>
                <Grid container alignItems='center' justifyContent='center' spacing={0.5}>
                  <Grid item sm={6} xs={6}>
                    <FormInput
                      inputType={GENERATOR_INPUT_TYPE.INPUT}
                      name='searchPattern'
                      placeholder={t('configViews.allTab.search')}
                      size='small'
                    />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <FormInput
                      inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                      name='objectCode'
                      placeholder={t('configViews.allTab.objectFilter')}
                      size='small'
                      sx={{ maxWidth: '100%' }}
                      autocompleteOptions={objects?.map(obj => ({
                        id: obj.code,
                        label: obj.title,
                      }))}
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <EntityOrFormList
        columns={CONFIG_COLUMNS}
        currentSort={currentSort}
        loading={formsIsLoading}
        rows={formTables}
        onCopy={onShowDialog}
        onDelete={onDeleteForm}
        onEdit={onEditForm}
        onSetSorting={onSetSorting}
      />
    </>
  )
}
