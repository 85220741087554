import { useEffect } from 'react'

import { EVENT_NAME, EventListener, subscribe, unsubscribe } from '../events'

export const useSubscribeEvent = (eventName: EVENT_NAME, listener: EventListener) => {
  useEffect(() => {
    subscribe(eventName, listener)
    return () => {
      unsubscribe(eventName, listener)
    }
  }, [])
}
