import i18next from 'i18next'
import { checkAvailableVariablesInScript, ErrorValidJSType } from '@helpers'

import { checkReturnedValueType } from '@gantt/helpers/checkScriptReturnedValueType'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

export const checkValidGanttJS = (
  value: string,
  variables?: Record<string, string[]>,
  returnValueType?: GANTT_SCRIPT_RETURN_TYPE
): ErrorValidJSType | undefined => {
  if (!value) {
    return { error: false }
  }

  if (!value.includes('return')) {
    return { error: true, message: `${i18next.t('error.functionReturn')}` }
  }

  const notAvailableVarsError = checkAvailableVariablesInScript(value, variables)

  if (notAvailableVarsError) {
    return notAvailableVarsError
  }

  try {
    const runScript = new Function('mainData', 'restData', 'metaData', value)

    const returnedValue = runScript({}, {}, { timezone: '', dateFormatFn: () => '' })

    runScript(
      {},
      {},
      {
        timezone: '',
        dateFormatFn: () => '',
      }
    )
    const returnedValueTypeError = checkReturnedValueType(returnedValue, returnValueType)

    if (returnedValueTypeError) {
      return returnedValueTypeError
    }

    return undefined
  } catch (e: any & Error) {
    return { error: true, message: e?.message || `${i18next.t('error.valueJS')}` }
  }
}
