import { ELEMENT_TYPE_PANEL } from '@constants'
import { UpdatedElementType } from '@types'

export const getFindIndexUpdatedElement = (
  element: UpdatedElementType,
  updatedElements: UpdatedElementType[]
) => {
  const hasEqualIds = (current: UpdatedElementType) => {
    return element.data?.id
      ? current.data?.id === element.data?.id
      : current.data?.uuid === element.data?.uuid
  }

  switch (element.type) {
    case ELEMENT_TYPE_PANEL.PANEL:
      return updatedElements.findIndex(el => {
        return el.type === ELEMENT_TYPE_PANEL.PANEL && hasEqualIds(el)
      })
    case ELEMENT_TYPE_PANEL.LINE:
      return updatedElements.findIndex(el => {
        return el.type === ELEMENT_TYPE_PANEL.LINE && hasEqualIds(el)
      })
    case ELEMENT_TYPE_PANEL.ITEM:
      return updatedElements.findIndex(
        el => el.type === ELEMENT_TYPE_PANEL.ITEM && el.treeItemId === element.treeItemId
      )
    default:
      return -1
  }
}
