import { useEffect } from 'react'

import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'

type UseFetchRowProps = {
  // objectCode: string
  formCode: string
  objectId?: string
  globalId: string
  // fetchRow: MutationType<GETObjectDataRecordParams, ObjectDataRecord>
}

export const useFetchRow = ({ objectId, formCode, globalId }: UseFetchRowProps) => {
  const [fetchRow, { isLoading: isLoadingRow, data }] = useFetchObjectDataWithFixedKey(globalId)

  useEffect(() => {
    if (formCode && objectId) {
      fetchRow({ formCode, objectId })

      return
    }
  }, [formCode, objectId])

  return { isLoadingRow, row: data }
}
