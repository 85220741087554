import { useRef } from 'react'

export function usePrevious<V>(
  value: V | undefined,
  defaultValue?: V
): [V | undefined, () => void] {
  const currentRef = useRef(value || defaultValue)
  const previousRef = useRef()

  if (currentRef.current !== value) {
    previousRef.current = currentRef.current
    currentRef.current = value
  }

  const clear = () => {
    currentRef.current = defaultValue ?? undefined
  }

  return [previousRef.current, clear]
}
