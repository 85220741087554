import { useCallback } from 'react'
import { ActionData } from '@microservices/gantt-react-component'

import { ACTION_CODE } from '@constants'

import { AXIS_TYPE, GanttActionType, GanttFormValues } from '../types'
import { getAxisData, stopPropagation } from '../utils'

import { useExecuteCommand } from './useExecuteCommand'
import { useMutateObject } from './useMutateObject'
import { useOpenForm } from './useOpenForm'

export const useRunAction = (ganttConfig: GanttFormValues, routePath: string) => {
  const openFormModel = useOpenForm(ganttConfig, routePath)
  const executeCommand = useExecuteCommand(ganttConfig)
  const mutateObject = useMutateObject()

  return useCallback(
    (action: GanttActionType, actionData: ActionData, axis: AXIS_TYPE) => {
      const axisDefinedData = getAxisData[axis](actionData, ganttConfig)

      switch (action.actionCode) {
        case ACTION_CODE.EXECUTE_COMMAND: {
          executeCommand(action, actionData, axisDefinedData)

          break
        }
        case ACTION_CODE.OPEN_FORM_MODEL: {
          openFormModel(action, actionData, axisDefinedData)

          break
        }
        case ACTION_CODE.OPEN_CONTEXT_MENU: {
          //todo подключить виски сервис
          break
        }
        case ACTION_CODE.OPEN_TOOLTIP: {
          //todo подключить виски сервис
          break
        }
        case ACTION_CODE.ALLOW_EXECUTE_UI_ACTION: {
          mutateObject(action, actionData, axisDefinedData)
        }
      }

      stopPropagation(actionData)
    },
    [ganttConfig?.code, routePath, openFormModel, executeCommand, mutateObject]
  )
}
