import { FormContainer, FormLine, FormTab } from '../types'

// type ContainerReturnType = { container?: FormContainer }
// export const getContainerByIndex = (
//   containers: FormContainer[],
//   containerIndex: number
// ): ContainerReturnType => {
//   if (containers[containerIndex]) {
//     return { container: containers[containerIndex] }
//   }
//   return {}
// }
//
// type TabReturnType = ContainerReturnType & { tab?: FormTab }
// export const getTabByIndex = (
//   containers: FormContainer[],
//   containerIndex: number,
//   tabIndex: number
// ): TabReturnType => {
//   const result = getContainerByIndex(containers, containerIndex)
//   return { ...result, tab: result.container?.tabs[tabIndex] }
// }
//
// type LineReturnType = TabReturnType & { line?: FormLine }
// export const getLineByIndex = (
//   containers: FormContainer[],
//   containerIndex: number,
//   tabIndex: number,
//   lineIndex: number
// ): LineReturnType => {
//   const result = getTabByIndex(containers, containerIndex, tabIndex)
//   return { ...result, line: result.tab?.lines[lineIndex] }
// }

type ContainerReturnType = { container?: FormContainer }
export const getContainerById = (
  containers: FormContainer[],
  containerId: number
): ContainerReturnType => {
  const containerIndex = containers.findIndex(container => container.id === containerId)
  if (containerIndex !== -1) {
    return { container: containers[containerIndex] }
  }
  return {}
}

type TabReturnType = ContainerReturnType & { tab?: FormTab }
export const getTabById = (
  containers: FormContainer[],
  containerId: number,
  tabId: number
): TabReturnType => {
  const result = getContainerById(containers, containerId)
  const tabIndex = result.container?.tabs.findIndex(tab => tab.id === tabId) ?? -1
  if (tabIndex !== -1) {
    return { ...result, tab: result.container?.tabs[tabIndex] }
  }
  return { ...result }
}

type LineReturnType = TabReturnType & { line?: FormLine }
export const getLineById = (
  containers: FormContainer[],
  containerId: number,
  tabId: number,
  lineId: number
): LineReturnType => {
  const result = getTabById(containers, containerId, tabId)
  const lineIndex = result.tab?.lines.findIndex(line => line.id === lineId) ?? -1
  return { ...result, line: result.tab?.lines[lineIndex] }
}
