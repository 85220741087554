import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { AutocompleteOption, ObjectFieldDTO } from '../../../../../../../types'

type ValueTypeOfSelectedValueOptions = {
  watchedValueName: AutocompleteOption | null
  objectFields: ObjectFieldDTO[] | undefined
  valueTypeInputName: string
}

export const useValueTypeOfSelectedValue = ({
  watchedValueName,
  objectFields,
  valueTypeInputName,
}: ValueTypeOfSelectedValueOptions) => {
  const { setValue } = useFormContext()
  const [selectedValueType, setSelectedValueType] = useState('')

  useEffect(() => {
    const currentField = objectFields?.find(
      field => watchedValueName && field.name === watchedValueName.label
    )

    if (currentField) {
      setSelectedValueType(currentField.valueType as string)
      setValue(valueTypeInputName, currentField.valueType as string)
    }
  }, [watchedValueName])

  return { selectedValueType, setSelectedValueType }
}
