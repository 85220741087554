import { MouseEvent, useState } from 'react'

import { MenuPositionType } from './ElementContextMenu'

type useActionMenuParams = {
  isSmallSize?: boolean
  handleSetEditing?: (value: boolean) => void
}

export const useActionMenu = ({ handleSetEditing }: useActionMenuParams) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null)
  const [menuPosition, setMenuPosition] = useState<MenuPositionType>(null)
  const isPopoverOpen = Boolean(popoverAnchorEl)

  const handleOpenPopover = (event: MouseEvent<HTMLLIElement>) => {
    setPopoverAnchorEl(event.currentTarget)
    handleSetEditing?.(true)
  }

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
    handleSetEditing?.(false)
  }

  const handleCancelPoppedEdit = () => {
    setPopoverAnchorEl(null)
    handleSetEditing?.(false)
  }

  const handleElementContextClick = <HTMLElementType>(event: MouseEvent<HTMLElementType>) => {
    event.preventDefault()
    setMenuPosition({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
    })
  }

  const handleCloseMenu = () => {
    setMenuPosition(null)
  }

  const handleSetMenuPosition = (value: MenuPositionType) => setMenuPosition(value)

  const handleSetPopoverAnchorEl = (value: null | HTMLElement) => setPopoverAnchorEl(value)

  return {
    popoverAnchorEl,
    menuPosition,
    isPopoverOpen,
    handleOpenPopover,
    handlePopoverClose,
    handleCancelPoppedEdit,
    handleElementContextClick,
    handleCloseMenu,
    handleSetMenuPosition,
    handleSetPopoverAnchorEl,
  }
}
