import { FIELD_VALUE_FORMAT } from '@constants'

export const checkIfOuterValueIsInvalid = (
  outerValue: number | boolean,
  valueFormat: FIELD_VALUE_FORMAT
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (outerValue === '' || outerValue === null || outerValue === undefined) {
    return true
  }

  if (valueFormat === FIELD_VALUE_FORMAT.NUMBER && outerValue != 0 && outerValue != 1) {
    return true
  }

  return false
}
