import { ELEMENT_TYPE, ENTITY_TYPES_REQUEST } from '@constants'
import { AutocompleteOption } from '@types'

export const getEntityAutocompleteOptions = (
  entities: Array<any> | undefined,
  currentType: string | undefined
): Array<AutocompleteOption> => {
  return entities
    ? entities.map(entity => {
        switch (currentType) {
          case ENTITY_TYPES_REQUEST.VIEW: {
            return {
              id: entity.id,
              label: entity.title,
            }
          }
          case ELEMENT_TYPE.VIEW: {
            return {
              id: entity.id,
              label: entity.code,
            }
          }
          case ENTITY_TYPES_REQUEST.GANTT_CHART: {
            return {
              id: entity.id,
              label: entity.title,
            }
          }
          default:
            return {
              id: entity.id,
              label: entity.title,
            }
        }
      })
    : []
}
