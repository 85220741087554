import React, { FC, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Clear as ClearIcon } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'

import { useCommandOptions, useCommandTypeOptions } from '@hooks'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption } from '@types'

type CommandPickerControllerProps = {
  objectCode: string
  name?: string
  typeName?: string
  commandName?: string
  hasIndexNumber?: boolean
  hasAddButton?: boolean
}

export const CommandPickerController: FC<CommandPickerControllerProps> = ({
  objectCode,
  name = 'commands',
  typeName = 'type',
  commandName = 'code',
  hasIndexNumber = true,
  hasAddButton = true,
}) => {
  const { t } = useTranslation()

  const { control, getValues, watch } = useFormContext()

  const values = getValues(name)

  const commandOptions = useCommandOptions({ selectedObjectCode: objectCode })
  const commandTypeOptions = useCommandTypeOptions()

  const {
    fields: commandFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name,
  })

  const disableInputs = !objectCode

  const disableAddButton = useMemo(
    () => Boolean(commandFields.length >= commandTypeOptions.length),
    [commandTypeOptions, commandFields]
  )

  // TODO Дописать обработку
  // Когда меняется objectCode, список команд у нового объекта может быть иным
  // Необходимо сбрасывать выбранные команды, которые не соответствуют новому коду объекта

  const getOptionDisabled = (option: AutocompleteOption, optionType: string) => {
    return Boolean(
      values?.find(
        (command: Record<string, AutocompleteOption>) => command[optionType]?.id === option.id
      )
    )
  }

  const handleAddCommand = () => {
    append({ type: null, code: null })
  }

  const handleRemoveCommand = (index: number) => () => remove(index)

  return (
    <Box mb={1}>
      {commandFields.map((field, index) => (
        <React.Fragment key={field.id}>
          {hasAddButton && (
            <Box display='flex'>
              <Typography my={0.5} variant='h6'>
                {t(`label.${hasIndexNumber ? 'commandTitleWithIndex' : 'commandTitle'}`, {
                  number: index + 1,
                })}
              </Typography>
              <IconButton size={'small'} onClick={handleRemoveCommand(index)}>
                <ClearIcon />
              </IconButton>
            </Box>
          )}
          <FormInput
            autocompleteOptions={commandTypeOptions}
            getOptionDisabled={option => getOptionDisabled(option, typeName)}
            label={t('label.commandType')}
            name={`${name}.${index}.${typeName}`}
            rules={{ required: true }}
            disabled={disableInputs}
            // disabled={selectedCommand && isDisabledObject}
            inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
            placeholder={t('placeholder.selectCommandType')}
            // loading={isLoading}
          />
          <FormInput
            autocompleteOptions={commandOptions}
            getOptionDisabled={option => getOptionDisabled(option, commandName)}
            placeholder={t('placeholder.selectCommandCode')}
            rules={{ required: true }}
            disabled={disableInputs}
            // disabled={selectedCommand && isDisabledObject}
            inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
            label={t('label.commandCode')}
            // loading={isLoading}
            name={`${name}.${index}.${commandName}`}
          />
        </React.Fragment>
      ))}
      {hasAddButton && (
        <Button
          disabled={disableAddButton || disableInputs}
          variant='outlined'
          onClick={handleAddCommand}
        >
          {t('label.addCommand')}
        </Button>
      )}
    </Box>
  )
}
