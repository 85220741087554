import { MouseEvent, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import check from '@assets/images/check.svg'
import filter from '@assets/images/filter.svg'
import {
  Divider,
  Grid,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  Paper,
} from '@mui/material'

import { useClickOutside } from '@pages/Parameters/hooks'

import { AppLoader } from '@components/AppLoader'
import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { useFetchSearchByObjectAndSAQuery, useUpdateSearchMutation } from '@redux/api'
import { openSavedFiltersDialog } from '@redux/reducers/dialogWindowManager.reducer'

import { useAppDispatch, useOnClickOutside } from '@hooks'
import { getInitialPositionWindow } from '@helpers'
import { CriteriaType, FAVORITES_FILTERS_BUTTON_CLASSNAME, FIELD_VALUE_FORMAT } from '@constants'

import {
  DIALOG_WINDOW_TYPE,
  GETSearchType,
  GETStoredValueType,
  POSTCreateSearchType,
} from '../../../../../../types'
import { SearchAssistantContext } from '../../SearchAssistant'
import { FavoritesFiltersTableMode } from '../SavedFilterWindow'

const ListItem = ({
  selected,
  title,
  ...props
}: {
  title: string
} & ListItemButtonProps) => {
  return (
    <ListItemButton
      sx={{
        p: '0 50px',
        height: 24,
        display: 'flex',
        '&:hover': {
          bgcolor: theme => theme.palette.background.hovered,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
        '&.Mui-selected': {
          bgcolor: theme => theme.palette.background.hovered,
        },
      }}
      {...props}
    >
      {selected && (
        <Grid item alignSelf={'end'} left={15} position={'absolute'}>
          <CustomSvgIcon imgStyle={{}} src={check} />
        </Grid>
      )}
      <ListItemText
        sx={{
          m: 0,
          '& .MuiTypography-root': {
            fontSize: 13,
            lineHeight: '24px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontFamily: 'Montserrat Regular',
            color: theme => theme.palette.color.listItem,
          },
        }}
      >
        {title}
      </ListItemText>
    </ListItemButton>
  )
}

export const SavedSearchDropdown = ({
  criteriasDirty,
  currentSearchTitle,
  newSearch,
  onClose,
  onSearch,
  onSelected,
}: {
  criteriasDirty: boolean
  currentSearchTitle: null | string
  newSearch: POSTCreateSearchType
  onClose: () => void
  onSearch: (search: CriteriaType[]) => void
  onSelected: (search: GETSearchType) => void
}) => {
  const { entityId, isViewDialogWindow, entityObjectCode, searchAssistantId } =
    useContext(SearchAssistantContext)
  const [updateSearch] = useUpdateSearchMutation()
  const {
    data: savedSearch,
    isLoading,
    isFetching,
  } = useFetchSearchByObjectAndSAQuery(
    {
      objectCode: entityObjectCode,
      searchAssistantId,
    },
    {
      skip: !entityObjectCode,
    }
  )

  const loadingSavedSearch = isLoading || isFetching

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const ref = useRef(null)

  useClickOutside(ref, onClose)

  const handleSelected = (value: GETSearchType) => {
    onSelected(value)

    const getValueFormat = (value: GETStoredValueType) => {
      const parsedValue = JSON.parse(value.value)

      if (value.valueObject.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN) {
        if (parsedValue === 'false') {
          return false
        }
        if (parsedValue === 'true') {
          return true
        }
      }

      if (value.valueObject.valueFormat === FIELD_VALUE_FORMAT.NUMBER) {
        if (parsedValue === '1') {
          return 1
        }
        if (parsedValue === '0') {
          return 0
        }
      }

      return parsedValue
    }

    onSearch(
      value.storedSearchFilters.map(criteria => ({
        isPinning: criteria.searchFilterObject.isPinning,
        title: criteria.searchFilterObject.title,
        codeCriteria: criteria.searchFilterObject.code,
        storedValues: criteria.storedValues.map(value => ({
          id: value.valueObject.id,
          valueType: value.valueObject.valueType,
          name: value.valueObject.name,
          value: getValueFormat(value),
          fieldType: value.valueObject.fieldType,
          bindType: value.valueObject.bindType,
          objectValue: value.valueObject.objectValue,
          objectCode: value.valueObject.objectCode,
          linkedObjectCode: value.valueObject.linkedObjectCode,
          type: value.valueObject.type,
          isDefaultPlaceholder: value.valueObject.isDefaultPlaceholder,
          placeholderEnd: value.valueObject.placeholderEnd,
          placeholderStart: value.valueObject.placeholderStart,
          placeholderValue: value.valueObject.placeholderValue,
          dropDownList: value.valueObject.dropDownList,
          dropDownWindow: value.valueObject.dropDownWindow,
          dropDownListFilters: value.valueObject.dropDownListFilters,
          dropDownWindowFilters: value.valueObject.dropDownWindowFilters,
          searchRule: value.searchRule,
          isGroupRelated: value.valueObject.isGroupRelated,
          asCheckbox: value.valueObject.asCheckbox,
          asDuration: value.valueObject.asDuration,
          valueFormat: value.valueObject.valueFormat,
        })),
      }))
    )
  }

  const handleClickManage = (event: MouseEvent<HTMLDivElement>) => {
    const initialPosition = getInitialPositionWindow(event, { width: 320, height: 290 })
    if (entityId) {
      onClose?.()
      dispatch(
        openSavedFiltersDialog({
          id: uuid(),
          parentDialogId: null,
          type: DIALOG_WINDOW_TYPE.SAVED_FILTERS,
          title: t('filter.favorites.dialog.title'),
          icon: filter,
          initialPosition,
          initialSize: { height: 290, width: 320 },
          minWidth: 310,
          meta: {
            entityId: entityId.toString(),
            mode: FavoritesFiltersTableMode.VIEW,
            entityObjectCode,
            searchAssistantId,
          },
        })
      )
    }
  }

  const handleClickSaveAs = (event: MouseEvent<HTMLDivElement>) => {
    const initialPosition = getInitialPositionWindow(event, { width: 320, height: 290 })
    if (entityId) {
      onClose?.()
      dispatch(
        openSavedFiltersDialog({
          id: uuid(),
          parentDialogId: null,
          type: DIALOG_WINDOW_TYPE.SAVED_FILTERS,
          title: t('filter.favorites.dialog.title'),
          initialPosition,
          initialSize: { height: 290, width: 320 },
          minWidth: 310,
          icon: filter,
          meta: {
            entityId: entityId.toString(),
            mode: FavoritesFiltersTableMode.CREATE,
            entityObjectCode,
            searchAssistantId,
            newSearch: { ...newSearch, title: '' },
          },
        })
      )
    }
  }

  const handleClickSave = () => {
    if (currentSearchTitle) {
      onClose?.()
      updateSearch(newSearch)
    }
  }

  useOnClickOutside(ref, event => {
    // Чтобы выпадающий список не переоткрывался при нажатии на кнопку Favorites
    if (
      event.target &&
      (event.target as HTMLElement)?.classList.contains(FAVORITES_FILTERS_BUTTON_CLASSNAME)
    ) {
      return
    }

    onClose?.()
  })

  return (
    <Paper
      ref={ref}
      sx={{
        position: 'absolute',
        zIndex: theme => theme.zIndex.tooltip,
        left: '-4px',
        top: isViewDialogWindow ? 25 : 35,
        width: 280,
        borderRadius: 0,
        boxShadow: theme => theme.palette.boxShadow.paper,
        border: theme => theme.palette.border.field,
      }}
    >
      {loadingSavedSearch ? (
        <>
          <AppLoader size='1.5rem' sx={{ my: 0.5 }} />
          <Grid sx={{ p: '0 4px 0 50px' }}>
            <Divider sx={{ borderColor: theme => theme.palette.color.divider }} />
          </Grid>
        </>
      ) : (
        <>
          {savedSearch?.map(search => (
            <ListItem
              key={search.id}
              selected={search.title === currentSearchTitle}
              title={search.title}
              onClick={() => {
                handleSelected(search)
              }}
            />
          ))}
          {savedSearch && savedSearch.length > 0 && (
            <Grid sx={{ p: '0 4px 0 50px' }}>
              <Divider sx={{ borderColor: theme => theme.palette.color.divider }} />
            </Grid>
          )}
        </>
      )}

      <ListItem
        disabled={!criteriasDirty || !currentSearchTitle}
        title={t('filter.favorites.save')}
        onClick={handleClickSave}
      />
      <ListItem
        disabled={!criteriasDirty}
        title={t('filter.favorites.saveAs')}
        onClick={handleClickSaveAs}
      />
      <Grid sx={{ p: '0 4px 0 50px' }}>
        <Divider sx={{ borderColor: theme => theme.palette.color.divider }} />
      </Grid>
      <ListItem title={t('filter.favorites.manage')} onClick={handleClickManage} />
    </Paper>
  )
}
