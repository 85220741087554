import { FC, useContext, useEffect, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import AutoSizer from 'react-virtualized-auto-sizer'
import { DragHandle as DragHandleIcon } from '@mui/icons-material'
import { Box, Grid, SxProps } from '@mui/material'
import { alpha } from '@mui/material/styles'

import { FORM_DROPPABLE_TYPE, FORM_ELEMENT_TYPE } from '../../../../../constants'
import { EVENT_NAME, publish } from '../../../../../events'
import { FormLine } from '../../../../../types'
import { useSubscribeLineDragHandlerMouseOver } from '../../../hooks/useSubscribeLineDragHandlerMouseOver'
import { FieldsConfigContext } from '../FieldsConfig'

import { DraggableFieldOnForm } from './DraggableFieldOnForm'

type DraggableLineOnFormProps = {
  line: FormLine
  containerId: number
  tabId: number
  index: number
}

const lineSx: SxProps = {
  px: 1,
  pr: 5,
  mb: 1,
  height: 56,
}

export const DraggableLineOnForm: FC<DraggableLineOnFormProps> = ({
  line,
  containerId,
  tabId,
  index,
}) => {
  const { currentDragged } = useContext(FieldsConfigContext)
  const lineId = line.id
  const pathToLine = `containerId:${containerId}_tabId:${tabId}_lineId:${lineId}`
  const [countSizeRow, setCountSizeRow] = useState(line.elements.reduce((a, b) => a + b.size, 0))
  const isLineContainsView =
    line.elements.length && line.elements[0].type === FORM_ELEMENT_TYPE.VIEW
  const isDropDisabledForLineWithView = isLineContainsView && Boolean(currentDragged)

  useEffect(() => {
    if (line) {
      setCountSizeRow(line.elements.reduce((a, b) => a + b.size, 0))
    }
  }, [line])

  const isEmptyLine = line.elements.length === 0

  const { renderEmptyLine } = useSubscribeLineDragHandlerMouseOver({
    currentDragged,
    isEmptyLine,
  })

  const handleMouseEnter = () => {
    publish(EVENT_NAME.CONFIGURED_FORM_ELEMENTS_MOUSE_ENTER)
  }
  const handleMouseLeave = () => {
    publish(EVENT_NAME.CONFIGURED_FORM_ELEMENTS_MOUSE_LEAVE)
  }

  // Если курсор над drag handler линии, рендерим обычный Box, а не Draggable
  // Так как нельзя допускать возможности изменения порядка дефолтной пустой линии при изменении порядка остальных линий
  if (isEmptyLine && !renderEmptyLine) {
    return <Box key={line.id} sx={lineSx} onMouseLeave={handleMouseLeave} />
  }

  return (
    <Draggable draggableId={pathToLine} index={index} isDragDisabled={isEmptyLine}>
      {provided => (
        <Box ref={provided.innerRef} {...provided.draggableProps} sx={lineSx}>
          <Grid container alignItems={'baseline'}>
            <Grid
              item
              {...provided.dragHandleProps}
              sx={{ mr: 1, opacity: isEmptyLine ? 0 : 1 }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <DragHandleIcon color={'action'} />
            </Grid>
            <Droppable
              ignoreContainerClipping
              direction='horizontal'
              droppableId={pathToLine}
              type={FORM_DROPPABLE_TYPE.ELEMENTS}
              // isDropDisabled={isDropDisabledForLineWithView}
            >
              {(provided, snapshot) => {
                return (
                  <AutoSizer>
                    {({ width }) => (
                      <Grid
                        ref={provided.innerRef}
                        container
                        {...provided.droppableProps}
                        flexWrap={'nowrap'}
                        sx={{
                          width,
                          display: 'flex',
                          alignItems: 'center',
                          ...(snapshot.isDraggingOver && {
                            background: theme => alpha(theme.palette.grey[600], 0.1),
                          }),
                        }}
                      >
                        {line.elements.map((element, elementIndex) => (
                          <DraggableFieldOnForm
                            key={element.id}
                            countSizeRow={countSizeRow}
                            element={element}
                            index={elementIndex}
                            pathToLine={pathToLine}
                            width={width}
                          />
                        ))}
                        {provided.placeholder}
                      </Grid>
                    )}
                  </AutoSizer>
                )
              }}
            </Droppable>
          </Grid>
        </Box>
      )}
    </Draggable>
  )
}
