/**
 * Перевод строки в формате HH:mm в число(секунды)
 * @param time string
 * @returns number
 */
export const getSecondsFromTime = (time: string) => {
  const hoursAndMinutes = time.split(':')

  const hours = Number(hoursAndMinutes[0].replaceAll('-', '0')) * 3600
  const minutes = Number(hoursAndMinutes[1].replaceAll('-', '0')) * 60

  return hours + minutes
}
