import { createContext, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemId, TreeData, TreeItem } from '@atlaskit/tree'
import { Box, Divider, Grid, LinearProgress, Typography } from '@mui/material'

import {
  ConfigRowsType,
  DataOfCopiedModelType,
  NoIdOptionGroupType,
  POSTOptionGroupType,
  ViewShortDTO,
} from '../../types'

import { DraggableGroupList } from './DraggableGroupList'
import { UncertainList } from './UncertainList'

type ShareGroupModelType = 'view' | 'forms' | 'listControl'

type EntityOrFormGroupListProps = {
  tree: TreeData | null
  allData: ViewShortDTO[]
  uncertainData: ViewShortDTO[]
  isFetchingUncertain: boolean
  isFetchingGroups: boolean
  setTree: (tree: TreeData) => void
  onDeleteTreeItem: (itemId: ItemId) => void
  onAddTreeItem: (option: NoIdOptionGroupType, treeItem?: TreeItem) => void
  onEditTreeItem: (treeItem: TreeItem) => void
  updateGroups: (groups: POSTOptionGroupType[]) => void
  isDirty: boolean
  onCancel: () => void
  type: ShareGroupModelType
  onEdit: (code: string) => void
  onCopy: (data: DataOfCopiedModelType) => void
  onDelete: (code: string | number) => void
}

type EntityOrFormGroupListContextType = EntityOrFormGroupListProps
export const EntityOrFormGroupListContext = createContext<EntityOrFormGroupListContextType>(
  {} as EntityOrFormGroupListContextType
)

export const EntityOrFormGroupList: FC<EntityOrFormGroupListProps> = props => {
  const { t } = useTranslation()
  const { isFetchingUncertain, uncertainData, isFetchingGroups, onDelete, onEdit, onCopy } = props
  const [isListEdit, setIsListEdit] = useState(false)

  const handleSetIsListEdit = (value: boolean) => setIsListEdit(value)

  const handleCopy = (view: ConfigRowsType) => {
    const { id, objectCode, title, objectTitle } = view
    onCopy({ id, objectCode, title, objectTitle })
  }

  return (
    <EntityOrFormGroupListContext.Provider
      value={{
        ...props,
      }}
    >
      <Grid container sx={{ width: '100%' }}>
        <Grid item container flex={1} flexDirection={'column'}>
          <Grid item>
            <Typography
              variant='subtitle2'
              color={theme => theme.palette.text.secondary}
              fontWeight='bold'
            >
              {t('configViews.notAssigned')}
            </Typography>
            <Box sx={{ height: 20, display: 'flex', alignItems: 'center' }}>
              {isFetchingUncertain && <LinearProgress sx={{ width: '100%' }} />}
            </Box>
          </Grid>
          <Grid item flexGrow={1} sx={{ height: 516, overflowY: 'auto' }}>
            {uncertainData.map(view => (
              <UncertainList
                isListEdit={isListEdit}
                onDelete={() => onDelete(view.code)}
                onEdit={() => onEdit(view.code)}
                onCopy={() => handleCopy(view)}
                key={view.id}
                view={view}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <Divider sx={{ width: 1, mx: 0.2, p: 0 }} orientation='vertical' variant='middle' />
        </Grid>
        <Grid item container flex={4}>
          <Box sx={{ ml: 1, width: '100%' }}>
            <Typography
              variant='subtitle2'
              color={theme => theme.palette.text.secondary}
              fontWeight='bold'
            >
              {t('configViews.groups')}
            </Typography>
            <Box sx={{ height: 20, display: 'flex', alignItems: 'center' }}>
              {isFetchingGroups && <LinearProgress sx={{ width: '100%' }} />}
            </Box>
          </Box>
          <DraggableGroupList isListEdit={isListEdit} handleSetEdit={handleSetIsListEdit} />
        </Grid>
      </Grid>
    </EntityOrFormGroupListContext.Provider>
  )
}
