import { useContext, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { useFetchContextControlPanelByIdQuery, useFetchControlPanelListQuery } from '@redux/api'

import { PageContext } from '../ContextCreateOrEdit'

export const ControlPanels = () => {
  const methods = useFormContext()
  const { id } = useContext(PageContext)
  const { getValues, reset } = methods
  const { t } = useTranslation()

  const { data: savedControlPanel } = useFetchContextControlPanelByIdQuery(id, { skip: !id })
  const { data: controlPanels, isLoading: isLoadingControlPanels } = useFetchControlPanelListQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    reset({
      ...getValues(),
      controlPanel: savedControlPanel?.panel
        ? {
            id: savedControlPanel.panel.id,
            label: savedControlPanel.panel.title,
          }
        : null,
    })
  }, [savedControlPanel?.panel])

  const autocompleteOptionsControlPanels = useMemo(
    () => controlPanels?.map(({ id, title: label }) => ({ id, label })) || [],
    [controlPanels]
  )

  return (
    <Box>
      <FormInput
        autocompleteOptions={autocompleteOptionsControlPanels}
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={t('contexts.title.controlPanel')}
        loading={isLoadingControlPanels}
        name='controlPanel'
        placeholder={t('contexts.placeholder.selectControlPanel')}
        labelSx={{
          minWidth: 140,
          mr: 1,
        }}
      />
    </Box>
  )
}
