import i18next from '../i18n'

import { ROUTES } from './routes'

export const DEFAULT_SEARCH_ASSISTANT = {
  id: Date.now(),
  title: i18next.t('sidebar.links.search'),
  code: 'search',
  viewCode: ROUTES.SEARCH,
  hasChild: false,
  childrenMenu: [],
}
