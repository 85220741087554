import { FC } from 'react'
import { Box, Button, Grid } from '@mui/material'

type GeneralButtonsProps = {
  cancelText: string
  saveText: string
  onCancel: () => void
  onSave: () => void
  saveDisabled?: boolean
}

export const GeneralButtons: FC<GeneralButtonsProps> = ({
  cancelText,
  onCancel,
  onSave,
  saveText,
  saveDisabled,
}) => (
  <Box pr={1}>
    <Grid container spacing={0.5}>
      <Grid item>
        <Button variant='outlined' onClick={onCancel}>
          {cancelText}
        </Button>
      </Grid>
      <Grid item>
        <Button disabled={saveDisabled} variant='contained' onClick={onSave}>
          {saveText}
        </Button>
      </Grid>
    </Grid>
  </Box>
)
