import { useContext } from 'react'

import { AuthContext } from '@providers/AuthProvider'

export const useAuth = () => {
  const { getUsername, hasRole, doLogout, init } = useContext(AuthContext)

  return {
    username: getUsername(),
    init,
    hasRole,
    doLogout,
  }
}
